import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Pipe({
  name: 'cdn'
})
export class CdnPipe implements PipeTransform {

  constructor(private authService: AuthenticationService) {

  }

  transform(value: any, ...args: any[]): any {
    if(typeof value != "string" || value.length <= 0) return args[0] || "";
    if(value.indexOf('http') > -1) return value;

    if(value.indexOf('/') == -1) {
      value = `${this.authService.currentUserValue.siteSettings.customer}/${value}`;
    }

    let cdnServer = "https://beecdn.blob.core.windows.net/";

    if(this.authService.currentUserValue.siteSettings.cdnServer) {
      cdnServer = this.authService.currentUserValue.siteSettings.cdnServer;
    }

    return cdnServer + value;
  }

}
