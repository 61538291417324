<ng-container *ngTemplateOutlet="{'default': default, 'image': image}[templateType]"></ng-container>

<ng-template #default>
    <div class="play-overlay">
        <i class="material-icons">play_circle_outline</i>
    </div>
</ng-template>

<ng-template #image>
    <img [src]="imageIcon | cdn" alt="" class="play-img">
</ng-template>