<div class="module-search-settings-wrapper">
  <div>

    <mat-form-field>
      <input matInput placeholder="Search result margin top (Default: 165px)" name="limit" [(ngModel)]="module.settings.content.marginTop">
    </mat-form-field>


    <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showLabel" color="primary">Show label</mat-checkbox>
    </div>

    <!-- <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showHeaders" color="primary">Show headers for types (eg: blog, video)</mat-checkbox>
    </div> -->
  </div>
</div>