<form class="image-settings-wrapper settings-wrapper">
  <mat-form-field>
    <input matInput placeholder="Alt text (SEO / WCAG)" name="altText" [(ngModel)]="module.settings.content.altText">
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Image url" name="imageUrl" [(ngModel)]="module.settings.content.image.imgUrl">
  </mat-form-field>

  <div class="margin-top">
    <mat-checkbox name="interactiveEnabled" [(ngModel)]="module.settings.content.interactiveEnabled" color="primary">Enable interactive image </mat-checkbox>
  </div>

  <mediabank-file-handler [(mediaFile)]="module.settings.content.image"></mediabank-file-handler>

  <div class="margin-top">
    <mat-checkbox name="enableHoverImage" [(ngModel)]="module.settings.content.hoverEnabled" color="primary">Enable hover image</mat-checkbox>
  </div>

  <div class="margin-top">
    <mediabank-file-handler [(mediaFile)]="module.settings.content.hoverImage"></mediabank-file-handler>
  </div>

  <mat-form-field class="margin-top">
    <mat-label>Image scaling</mat-label>
    <mat-select [(value)]="module.settings.content.imageScaling">
      <mat-option value="default">16:9</mat-option>
      <mat-option value="fullwidth">Full width</mat-option>
      <mat-option value="noscale">No scaling</mat-option>
    </mat-select>
  </mat-form-field>
</form>
