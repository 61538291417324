import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { formatWithOptions } from 'node:util';
import { BeeTableSettings } from '../models/bee-table-settings';

@Component({
  selector: 'bee-table',
  templateUrl: './bee-table.component.html',
  styleUrls: ['./bee-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeTableComponent implements OnInit {
  @Input() settings: BeeTableSettings<any>;

  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getRowColumns(row) {
    let cols = [];
    let index = 0;
    for(var prop in row) {
      cols.push({
        name: prop,
        value: this.sanitizer.bypassSecurityTrustHtml(row[prop]),
        flex: this.settings.columns[index].flex,
        cssClass: this.settings.columns[index].cssClass
      });
      index++;
    }

    return cols;
  }

}
