import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { ResetPasswordDialogComponent } from '../dialogs/reset-password-dialog/reset-password-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeService {

  constructor(private http: HttpClient, private config: AppConfigService, private dialog: MatDialog) { }

  createChangePasswordRequest(username: string): Observable<any> {
    return this.http.get(this.config.apiEndpoint + "api-site/user/createChangePasswordRequest?username=" + username);
  }

  checkIfChangePasswordRequestIsValid(requestId: string): Observable<boolean> {
    return this.http.get<boolean>(this.config.apiEndpoint + "api-site/user/checkIfChangePasswordRequestIsValid?requestId=" + requestId);
  }

  updatePasswordFromRequest(requestId: string, password: string): Observable<SaveReturnModel<BeeUser>> {
    return this.http.get<SaveReturnModel<BeeUser>>(this.config.apiEndpoint + "api-site/user/updatePasswordFromRequest?requestId=" + requestId + "&password=" + password);
  }

  updatepassword(oldPassword: string, newPassword: string, username: string): Observable<SaveReturnModel<BeeUser>> {
    return this.http.patch<SaveReturnModel<BeeUser>>(this.config.apiEndpoint + "api-site/user/updatepassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
      username: username
    });
  }

  openResetPasswordDialog(): void {
    this.dialog.open(ResetPasswordDialogComponent, {
      panelClass: ["light-grey", "bee-dialog", "large-padding"]
    });
  }
}
