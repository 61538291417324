export class VerificationModuleBase {
    key: string;
    name: string;

    component?: any;
    injectSettings?: any;

    isCompleted: boolean;
    moi: string;
    accessLevelOptOuts: number[];
    content: any;
    isReportModule: boolean = false;

    constructor(data?: any) {
        if(data) {
            Object.assign(this, data);
        }
    }

    componentLoad? = () => {
        if(this.component) return;
        
        switch(this.key) {
            case "TEXT_IMAGE":
                import('../modules/text-image/text-image.component').then(mod => {
                    this.component = mod.TextImageComponent;
                });
            break;
            case "NOTES":
                import('../modules/notes/notes.component').then(mod => {
                    this.component = mod.NotesComponent;
                }
            );
            break;
            case "CHECKLIST":
                import('../modules/checklist/checklist.component').then(mod => {
                    this.component = mod.ChecklistComponent;
                }
            );
            break;
            case "FORM":
                import('../modules/form/form.component').then(mod => {
                    this.component = mod.FormComponent;
                }
            );
            break;
            case "FORM_VIEW":
                import('../modules/form-view/form-view.component').then(mod => {
                    this.component = mod.FormViewComponent;
                }
            );
            break;
            case "SCROLL_ANIMATION":
                import('../modules/scroll-animation/scroll-animation.component').then(mod => {
                    this.component = mod.VerificationFlowScrollAnimationComponent;
                }
            );
            break;
        }
    }

    componentLoadSettings? = () => {
        switch(this.key) {
            case "TEXT_IMAGE":
                import('../modules/settings/text-image-settings/text-image-settings.component').then(mod => {
                    this.component = mod.TextImageSettingsComponent;
                });
            break;
            case "FORM_VIEW":
                import('../modules/settings/form-view-settings/form-view-settings.component').then(mod => {
                    this.component = mod.FormViewSettingsComponent;
                }
            );
            break;
            case "FORM":
                import('../modules/settings/form-settings/form-settings.component').then(mod => {
                    this.component = mod.FormSettingsComponent;
                }
            );
            break;
            case "NOTES":
                import('../modules/settings/notes-settings/notes-settings.component').then(mod => {
                    this.component = mod.NotesSettingsComponent;
                }
            );
            break;
            case "CHECKLIST":
                import('../modules/settings/checklist-settings/checklist-settings.component').then(mod => {
                    this.component = mod.ChecklistSettingsComponent;
                }
            );
            break;
            case "SCROLL_ANIMATION":
                import('../modules/settings/scroll-animation-settings/scroll-animation-settings.component').then(mod => {
                    this.component = mod.ScrollAnimationSettingsComponent;
                }
            );
            break;
        }
    }
}