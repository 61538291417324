import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CmsPageService {

  constructor(private http: HttpClient, private config: AppConfigService) { 
  }

  /**
   * GetByUrl
   */
  public GetByUrl(urlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + 'api/cmspage/GetByUrl/' + urlRewrite);
  }
}
