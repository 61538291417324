import { ExecOptionsWithStringEncoding } from "child_process";
import { BeeUnit } from "./bee-unit";
import { UserRole } from "./user-role";

export class BeeUser {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  phone: string;
  email: string;
  defaultCultureIso2: string;
  defaultCultureIso4: string;
  userRoles: UserRole[] = [];
  isDeleted: boolean;
  isActive: boolean;
  activateDate?: Date;
  userPhotoUrl: string;
  lockoutEnd: Date;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  isSuperadmin: boolean;
  isAdmin: boolean;
  fullName: string;

  unitId: string;
  unit: BeeUnit = new BeeUnit();
  unitName: string;
  unitIds: string[] = [];
  units: BeeUnit[] = [];
  notificationOptOut: string[] = [];
}
