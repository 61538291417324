import { Component, OnInit, Inject } from '@angular/core';
import { BlogService } from '../services/blog.service';
import { Router } from '@angular/router';
import { BlogSiteSettings } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {
  public blogs: any[];
  public limit: number;
  public showingAll: boolean;

  public queryTag: string = undefined;

  public BASE_SHOW_LIMIT: number;

  public blogSiteSettings: BlogSiteSettings = new BlogSiteSettings();

  constructor(private blogService: BlogService, private router: Router, private authService: AuthenticationService) { 
    this.blogSiteSettings = this.authService.currentUserValue.siteSettings.blogSiteSettings;
    this.BASE_SHOW_LIMIT = 11;
    this.blogs = [];
    this.limit = this.BASE_SHOW_LIMIT;
    this.showingAll = false;
  }

  ngOnInit() {

    const params = new URLSearchParams(window.location.search);

    if(params.has('tag')) {
      this.queryTag = params.get('tag');
    }

    this.getBlogs();
  }

  getBlogs() {
    if(this.queryTag && this.queryTag.length > 0) {
      this.blogService.GetByTags([this.queryTag]).subscribe((response: any[]) => {
        if(response) {
          this.blogs = response;
        }
      });
    }
    else {
      this.blogService.GetBlogEntries().subscribe(response => {
        if(response.isSuccessful) {
          this.blogs = response.data;
        }
      });
    }
  }

  goToBlog(blog) {
    // const dom: HTMLElement = this.document.body;
    // const wrapper = dom.querySelectorAll('.app-wrapper')[0];
    // wrapper.scrollTo(0, 0);

    this.router.navigate(['/modules/blog/', blog.urlRewrite]);
  }

  toggleShowAll() {
    this.showingAll = !this.showingAll;

    if(this.showingAll) this.limit = 99;
    else this.limit = this.BASE_SHOW_LIMIT;
  }

  selectTag(tag) {
    this.router.navigate(['/modules/tag-blogs/', tag.urlRewrite]);
  }
}
