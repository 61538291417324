import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { LoginSettings, SiteSettings, UserSession } from '../models/user-session';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ForgotPasswordDialogComponent } from '../dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { PasswordChangeService } from '../services/password-change.service';
import { SignalrService } from 'src/app/frontend/services/signalr.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'bee-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public userName: string;
  public password: string;
  public customerName: string;
  public customerLogo: string;
  public backgroundImage: string;
  public gdprConsent: boolean = false;
  public showErrorMessage: boolean = false;
  public submitted: boolean = false;

  public loginSettings$: BehaviorSubject<LoginSettings> = new BehaviorSubject<LoginSettings>(new LoginSettings());

  returnUrl: string;

  constructor(public authService: AuthenticationService, 
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private passwordChangeService: PasswordChangeService,
    private signalrService: SignalrService,
    private el: ElementRef,
    private cdn: CdnPipe) { 

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
      this.customerName = this.authService.currentUserValue.siteSettings.siteTitle;
      this.customerLogo = this.authService.currentUserValue.siteSettings.siteImage;
      this.backgroundImage = this.authService.currentUserValue.siteSettings?.loginSettings?.loginBackgroundImage;

      if(this.backgroundImage) this.el.nativeElement.style.backgroundImage = "url('" + this.cdn.transform(this.backgroundImage) + "')";
  }

  ngOnInit() {
    this.gdprConsent = window.localStorage.getItem('gdprConsent') == "true";
    this.loginSettings$.next(this.authService.currentUserValue.siteSettings?.loginSettings);
  }

  login(form: NgForm) {


    this.submitted = true;
    
    if(form.valid) {
      this.authService.login(this.userName, this.password)
        .then((userSession: UserSession) => {
          window.localStorage.setItem('gdprConsent', "true");

          this.signalrService.initiateSignalrConnection().then(() => {});

          if(userSession.user.isAdmin) {
            if(this.authService.currentUserValue.siteSettings?.loginSettings?.redirectAdminToStartpage) {
              this.router.navigateByUrl("/");
            }
            else {
              this.router.navigateByUrl(this.returnUrl);
            }
          }
          else {
            this.router.navigateByUrl("/");
          }
        }
      ).catch(err => {
        this.showErrorMessage = true;
      });
    }
  }

  openForgotPassword() {
    var dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      panelClass: ["light-grey", "bee-dialog", "large-padding"]
    });
  }

  openChangePassword(): void {
    this.passwordChangeService.openResetPasswordDialog();
  }

}
