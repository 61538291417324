import { Component, OnInit } from '@angular/core';
import { IStepType } from '../components/bee-show-form/bee-show-form.component';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-show-form',
  templateUrl: './show-form.component.html',
  styleUrls: ['./show-form.component.scss']
})
export class ShowFormComponent implements OnInit {

  fields: FormlyFieldConfig[] = [
    {
      key: 'firstName',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Förnamn',
        required: true
      }
    },
    {
      key: 'lastName',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Efternamn',
        required: true
      }
    },
    {
      key: 'email',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Enter email',
        required: true,
      }
    },
    {
      key: 'phone',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Telefonnummer',
        required: true,
      }
    },
    {
      key: 'company',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Företag',
        required: false
      }
    },
    {
      key: 'title',
      type: 'bee-input',
      className: 'form-field-50',
      templateOptions: {
        placeholder: 'Titel/roll',
        required: false
      }
    },
    {
      key: 'line1',
      type: 'bee-line',
      className: 'form-field-100 margin-top lg'
    },
    {
      key: 'contacted-through',
      type: 'bee-multicheck',
      className: 'form-field-50 margin-top xlg',
      templateOptions: {
        label: 'Jag vill bli kontaktad via',
        options: [
          { label: 'E-post' },
          { label: 'Telefon' }
        ]
      }
    },
    {
      key: 'interested-of',
      type: 'bee-multicheck',
      className: 'form-field-50 margin-top xlg',
      templateOptions: {
        label: 'Jag är intresserad av',
        options: [
          { label: 'Information via mejl' },
          { label: 'Personligt möte' },
          { label: 'Information via telefon' }
        ]
      }
    },
    {
      key: 'line2',
      type: 'bee-line',
      className: 'form-field-100 margin-top lg'
    },
    {
      key: 'guide',
      type: 'bee-checkbox',
      className: 'form-field-100 margin-top xlg',
      templateOptions: {
        label: 'Jag har tagit del av guiden angående <a href="https://www.postnord.se/vara-losningar/tredjepartslogistik/guiden-till-tredjepartslogistik" target="_blank">tredjepartslogistik</a>',
        required: true
      }
    },
    {
      key: 'line3',
      type: 'bee-line',
      className: 'form-field-100 margin-top lg'
    },
    {
      key: 'policy',
      type: 'bee-checkbox',
      className: 'form-field-70 margin-top xlg',
      templateOptions: {
        label: 'Jag accepterar integritetspolicyn',
        description: 'Jag har tagit del av <a href="https://www.postnord.se/integritetspolicy" target="_blank">PostNords integritetspolicy</a> som beskriver hur PostNord behandlar mina personuppgifter. För sådan behandling som kräver mitt samtycke. Jag vet att jag kan återkalla mitt samtycke genom att kontakta PostNord via de kontaktuppgifter som finns i integritetspolicyn.',
        required: true
      }
    },
  ];

  fieldGrp1: FormlyFieldConfig[] = [
    {
      key: 'inleveranser-vecka',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Inleveranser / vecka',
        placeholder: 'Inleveranser/vecka',
        attributes: {
          min: 0,
          max: 150
        }
      }
    },
    {
      key: 'artikelnummer',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Antal unika artikelnummer / leverans',
        attributes: {
          min: 0,
          max: 1000
        }
      }
    },
    {
      key: 'st-leverans',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Antal styck / inleverans',
        attributes: {
          min: 0,
          max: 2000
        }
      }
    },
    {
      key: 'kubikmeter',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Antal kubikmeter / inleverans',
        attributes: {
          min: 0,
          max: 50,
          label: "m³"
        }
      }
    },
    {
      key: 'line1-1',
      type: 'bee-line',
      className: 'form-field-100 margin-top margin-bottom lg'
    },
    {
      key: 'typ-leverans',
      type: 'bee-multicheck',
      className: 'form-field-50',
      templateOptions: {
        label: 'Typ av leverans',
        options: [
          { label: 'Lastbil' },
          { label: 'Tåg' },
          { label: 'Båt / container' },
          { label: 'Flyg' }
        ]
      }
    },
  ];

  fieldGrp2: FormlyFieldConfig[] = [
    {
      key: 'lagring-artikelnummer',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Antal unika artikelnummer som lagras',
        placeholder: 'Antal unika artikelnummer som lagras',
        attributes: {
          min: 0,
          max: 2000
        }
      }
    },
    {
      key: 'lager-st',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Antal styck som lagras',
        attributes: {
          min: 0,
          max: 5000
        }
      }
    },
    {
      key: 'lagerbehov',
      type: 'bee-button-select',
      className: 'form-field-100',
      templateOptions: {
        label: 'Lagerbehov',
        options: [
          { label: 'Small', description: '< 250 m²' },
          { label: 'Medium', description: '250 - 1000 m²' },
          { label: 'Large', description: '> 1000 m²' },
        ]
      }
    }
  ];

  fieldGrp3: FormlyFieldConfig[] = [
    {
      key: 'utleverans-order',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Order / vecka',
        attributes: {
          min: 0,
          max: 500
        }
      }
    },
    {
      key: 'orderrader',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Orderrader / order i snitt',
        attributes: {
          min: 0,
          max: 10
        }
      }
    },
    {
      key: 'orderrader-styck',
      type: 'bee-slider',
      className: 'form-field-100',
      templateOptions: {
        label: 'Styck / orderrad i snitt',
        attributes: {
          min: 0,
          max: 10
        }
      }
    },
    {
      key: 'line-dist-1',
      type: 'bee-line',
      className: 'form-field-100 margin-top margin-bottom lg'
    },
    {
      key: 'distribition',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Typ av distribition',
        options: [
          { label: 'B2B' },
          { label: 'B2C' }
        ]
      }
    },
    {
      key: 'line-dist-2',
      type: 'bee-line',
      className: 'form-field-100 margin-top margin-bottom lg'
    }
  ];

  fieldGrp4: FormlyFieldConfig[] = [
    {
      key: 'size',
      type: 'bee-multicheck',
      className: 'form-field-100',
      templateOptions: {
        label: '<b>Hur stora är era artiklar</b> (Du kan välja flera)',
        attributes: {
          type: "images"
        },
        options: [
          { imgUrl: '/assets/images/customers/tpl/forms/begar_offert/small.png' },
          { imgUrl: '/assets/images/customers/tpl/forms/begar_offert/medium.png' },
          { imgUrl: '/assets/images/customers/tpl/forms/begar_offert/large.png' },
          { imgUrl: '/assets/images/customers/tpl/forms/begar_offert/x-large.png' } 
        ]
      }
    },
    {
      key: 'line-ov-1',
      type: 'bee-line',
      className: 'form-field-100 margin-top xlg'
    },
    {
      key: 'temp',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Krav på temperatur',
        options: [
          { label: 'Ja' },
          { label: 'Nej' }
        ]
      }
    },
    {
      key: 'farligt-gods',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Farligt gods',
        options: [
          { label: 'Ja' },
          { label: 'Nej' }
        ]
      }
    },
    {
      key: 'line-ov-2',
      type: 'bee-line',
      className: 'form-field-100 margin-bottom'
    },
    {
      key: 'input-retur',
      type: 'bee-input',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Returer e-handel (andel av levererade ordrar)',
        placeholder: '0%',
        required: true
      }
    },
    {
      key: 'line-ov-3',
      type: 'bee-line',
      className: 'form-field-100'
    },
    {
      key: 'kontrolltornet',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Kontrolltornet (se <a href="">beskrivning</a>)',
        options: [
          { label: 'Ja' },
          { label: 'Nej' }
        ]
      }
    },
    {
      key: 'line-ov-4',
      type: 'bee-line',
      className: 'form-field-100'
    },
    {
      key: 'tulltjanster',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'Tulltjänster',
        options: [
          { label: 'Ja' },
          { label: 'Nej' }
        ]
      }
    },
    {
      key: 'line-ov-5',
      type: 'bee-line',
      className: 'form-field-100'
    },
    {
      key: 'edi',
      type: 'bee-multicheck',
      className: 'form-field-100 horizontal',
      templateOptions: {
        label: 'EDI',
        options: [
          { label: 'Ja' },
          { label: 'Nej' }
        ]
      }
    },
    {
      key: 'line-ov-6',
      type: 'bee-line',
      className: 'form-field-100'
    },
    {
      key: 'marknad',
      type: 'bee-multicheck',
      className: 'form-field-100',
      templateOptions: {
        label: 'Marknad',
        options: [
          { label: 'Sverige' },
          { label: 'Norden' },
          { label: 'Europa' },
          { label: 'Världen' }
        ]
      }
    },
    {
      key: 'ovrig-info',
      type: 'bee-textarea',
      className: 'form-field-100',
      templateOptions: {
        label: 'Övrig information'
      }
    }
  ]

  fields2: FormlyFieldConfig[] = [
    {
      key: 'panel-inleverans',
      wrappers: ['bee-panel-wrapper'],
      className: 'form-field-100',
      templateOptions: {
        label: 'Inleverans',
        attributes: {
          image: 'https://beecdn.blob.core.windows.net/dev/5369600d-aebe-442f-b350-c8e2978b8ce1.png'
        }
      },
      fieldGroup: this.fieldGrp1
    },
    {
      key: 'panel-lagring',
      wrappers: ['bee-panel-wrapper'],
      className: 'form-field-100',
      templateOptions: {
        label: 'Lagring',
        attributes: {
          image: 'https://beecdn.blob.core.windows.net/dev/341a55aa-9c6b-4a81-9ddd-cca1489fe6d2.png'
        }
      },
      fieldGroup: this.fieldGrp2
    },
    {
      key: 'panel-utleverans',
      wrappers: ['bee-panel-wrapper'],
      className: 'form-field-100',
      templateOptions: {
        label: 'Utleverans',
        attributes: {
          image: 'https://beecdn.blob.core.windows.net/dev/023b14c9-493c-4c7f-98d0-c5dd405ceb06.png'
        }
      },
      fieldGroup: this.fieldGrp3
    },
    {
      key: 'panel-ovrigt',
      wrappers: ['bee-panel-wrapper'],
      className: 'form-field-100',
      templateOptions: {
        label: 'Övrigt',
        attributes: {
          image: 'https://beecdn.blob.core.windows.net/dev/1bb06d24-2b16-4f0d-b9ea-139c3bc4700d.png'
        }
      },
      fieldGroup: this.fieldGrp4
    }
  ];

  form: IStepType[] = [
    {
      label: "Fyll i dina uppgifter",
      fields: this.fields,
      type: "default",
      header: "",
      text: "",
      wrappers: []
    },
    {
      label: "Fyll i information om din verksamhet",
      fields: this.fields2,
      type: "default",
      header: "",
      text: "",
      wrappers: []
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
