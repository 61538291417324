<div class="notifications-wrapper">
  <div class="notification" *ngFor="let notification of userNotifications" (mouseleave)="markAsRead(notification)"
    [class]="authService.currentUserValue.siteSettings.defaultAppearance" (click)="navigate(notification)" [class.nav]="notification.linkData">
    <div class="notification-icon" *ngIf="showIcon">
      <img src="https://beecdn.blob.core.windows.net/cdn/Larresan.png" alt="">
    </div>

    <div class="notification-info">
      <div>
        <div class="not-read-indicator" *ngIf="!notification.isRead"></div>
        <p class="subject" [innerHtml]="notification.subject"></p>
        <span class="message" [innerHtml]="notification.message"></span>
      </div>
      
      <span class="date" *ngIf="showDate" [innerHtml]="notification.createdTimeUtc | beeFromNow"></span>

      <mat-icon class="img-icon" (click)="showImage(notification)" *ngIf="notification.imgUrl">image</mat-icon>
    </div>
  </div>
</div>