import { Component, OnInit, ViewEncapsulation, Input, ViewChild, HostBinding, ChangeDetectionStrategy, EventEmitter, Output, inject } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SubmissionDialogComponent } from '../../dialogs/submission-dialog/submission-dialog.component';
import { FormsSubmission } from '../../models/forms-submission';
import { FormsModel } from 'src/app/admin/forms/forms-model';
import { MatStepper } from '@angular/material/stepper';
import { FormSubmissionResponseModel } from '../../models/form-submission-response-model';
import { ObjectID } from 'bson';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FormArray, FormGroup, UntypedFormArray } from '@angular/forms';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FormSubmissionService } from '../../services/form-submission.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';


export interface IStepType {
  label: string;
  primaryIcon?: string;
  secondaryIcon?: string;
  fields: FormlyFieldConfig[];
  type: string;
  header: string;
  text: string;
  wrappers: string[];
}

export class BeeFormFieldConfig implements FormlyFieldConfig {
  templateOptions: FormlyTemplateOptions;
  id: string;
  type: string;
  component: any;
  injectSettings: any;
  fieldGroup: BeeFormFieldConfig[];
  hideExpression: boolean | string | ((model: any, formState: any, field?: FormlyFieldConfig) => boolean);
  className: string;
  key: string | number | string[];

  constructor(f: any) {
    Object.assign(this, f);

    if(!this.templateOptions) {
      this.templateOptions = {
        attributes: {}
      };
    }

    if(this.id) {
      f.id = new ObjectID().toHexString();
    }
  }
}

export class StepType implements IStepType {
  label: string;
  primaryIcon?: string;
  secondaryIcon?: string;
  fields: FormlyFieldConfig[];
  type: string;
  header: string;
  text: string;
  wrappers: string[];

  constructor(data?: any) {
    this.fields = [];
    if(data) {
      this.label = data.label;
      this.primaryIcon = data.primaryIcon;
      this.secondaryIcon = data.secondaryIcon;
      this.fields = data.fields.map((f) => {
        return new BeeFormFieldConfig(f);
      });
      this.type = data.type || "default";
      this.header = data.header || data.label;
      this.text = data.text;
      this.wrappers = data.wrappers;
    }
  }
}

@Component({
  selector: 'bee-show-form',
  templateUrl: './bee-show-form.component.html',
  styleUrls: ['./bee-show-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeShowFormComponent implements OnInit {
  formSubmissionService: FormSubmissionService = inject(FormSubmissionService);
  cdnPipe: CdnPipe = inject(CdnPipe);

  wrapByPanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  destroy$: Subject<void> = new Subject<void>();


  @HostBinding("attr.style")
  public get variablesAsStyle(): any {
    return `
      ${this._formModel?.styles?.flexDirection ? '--flex-direction:' + this._formModel.styles.flexDirection + ';' : ''}
      ${this._formModel?.styles?.color ? '--primary-text-color:' + this._formModel.styles.color + ';' : ''}
      ${this._formModel?.styles?.backgroundColor ? '--light-grey-bg:' + this._formModel.styles.backgroundColor + ';' : ''}
      ${this._formModel?.styles?.marginTop ? '--margin-top:' + this._formModel.styles.marginTop + ';' : ''}
      ${this._formModel?.styles?.marginRight ? '--margin-right:' + this._formModel.styles.marginRight + ';' : ''}
      ${this._formModel?.styles?.marginBottom ? '--margin-bottom:' + this._formModel.styles.marginBottom + ';' : ''}
      ${this._formModel?.styles?.marginLeft ? '--margin-left:' + this._formModel.styles.marginLeft + ';' : ''}
    `
  }


  @ViewChild('stepperElement') stepper: MatStepper;

  formResult: FormSubmissionResponseModel;

  activedStep = 0;
  @Input() referer_name?: string | undefined;
  @Input() referer_type?: string | undefined;
  @Input() referer_id?: string | undefined;
  @Input() referer_extra?: string | undefined;
  @Input() model: any = {};
  @Input() submissionId: string = null;
  @Input() readonly: boolean = false;
  @Input() showDownload: boolean = false;
  @Input() downloadLabel: string = "Skapa rapport";

  _formModel: FormsModel = undefined;
  public formModel$: BehaviorSubject<FormsModel> = new BehaviorSubject<FormsModel>(undefined);

  get formModel(): FormsModel {
    return this._formModel;
  }

  @Input()
  set formModel(f: FormsModel) {
    f.formsData.forEach((step) => {
      step.fields.forEach((field) => {
        field.templateOptions.disabled = this.readonly;
      });
    });

    this._formModel = f;
    this.formModel$.next(f);

    this.wrapByPanel$.next(f?.formsData[0]?.wrappers?.includes('bee-panel-wrapper') === true);
  }

  @Output() formSubmit: EventEmitter<FormSubmissionResponseModel> = new EventEmitter<FormSubmissionResponseModel>();

  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
  }

  goToStep(step) {
    this.stepper.selectedIndex = step;
    this.activedStep = step;
  }

  public reset() {
    if(this.formModel.clearOnSubmit) {
      this.model = {};
    }
    this.goToStep(0);
  }

  getResultStep() {
    return this.formModel.formsData.find(f => f.type == 'result');
  }

  getResultStepIndex(): number {
    let index = 0;

    for(let i = 0; i < this.formModel.formsData.length; i++) {
      if(this.formModel.formsData[i].type == 'result') {
        index = i;
        break;
      }
    }

    return index;
  }

  public export(submissionId: string): void {

    this.formSubmissionService.GetSubmissionAsPdf(submissionId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((r: any) => {
      var fullUrl = this.cdnPipe.transform(r.data);
      var link = document.createElement('a');
      link.href = fullUrl;
      link.target = "_blank";
      link.click();
    });

  }

  onSubmit() {
    var sub = new FormsSubmission();
    sub.formId = this.formModel.id;
    sub.formsData = this.model;
    if(this.formModel.isSingleEditable) {
      sub.id = this.submissionId;
    }

    if(this.referer_type && this.referer_id) {
      sub.REFERER_TYPE = this.referer_type;
      sub.REFERER_ID = this.referer_id;
      sub.referer_name = this.referer_name;
    }

    if(this.referer_extra) {
      sub.REFERER_EXTRA = this.referer_extra;
    }

    this.formsService.SaveSubmission(sub)
      .subscribe((data: FormSubmissionResponseModel) => {
        this.formResult = data;
        if(this.formModel.clearOnSubmit) {
          this.model = {};
        }

        if(this.getResultStep()) {
          this.goToStep(this.getResultStepIndex());
          
          const wrapper = document.querySelectorAll('.app-wrapper')[0];
          wrapper.scrollTo(0, 0)
        }
        else {
          let data = {};
          if(this.formModel.extraData && this.formModel.extraData.submissionData) {
            data = this.formModel.extraData.submissionData;
          }
          const dialogRef = this.dialog.open(SubmissionDialogComponent, {
            data: data
          });
        }
        this.formSubmit.emit(data);
      });
  }

  constructor(public formsService: FormsService, public dialog: MatDialog, private sanitizer: DomSanitizer) { 

  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.    
  }

  hasRequiredFields(): boolean {
    return this.formModel.formsData.some(d => d.fields.some(f => f.templateOptions.required == true));
  }

}
