<div class="default-list-wrapper">
    <div *ngFor="let file of files | limitTo:limit" class="margin-bottom">
        <a [routerLink]="['/modules/media/', file.urlRewrite]">
            <div class="list-item" [ngClass]="{'no-img': !file.thumbnail}">

                <div class="play-overlay">
                    <bee-play-icon></bee-play-icon>
                </div>

                <bee-landscape-image *ngIf="file.thumbnail" [image]="file.thumbnail | cdn" [alt]="file.name"
                    scaling="default"></bee-landscape-image>

                <div class="info">
                    <h6 [innerHtml]="file.name"></h6>

                    <div class="extra-info" *ngIf="showPublishDate || showTags">
                        <span class="publish-date" *ngIf="showPublishDate"
                            [innerHtml]="file.createdDateUtc | date"></span>
                        <span class="extra-divider"
                            *ngIf="(showPublishDate && showTags) && file.tags.length"></span>
                        <span class="tags" *ngIf="showTags" [innerHtml]="file.tags | tagNames | safeHtml"></span>
                    </div>

                    <p class="description" *ngIf="showDescription" [ngClass]="{'no-img': !file.thumbnail}"
                        [innerHtml]="file.description"></p>
                </div>
            </div>
        </a>
    </div>

    <div class="show-more-wrapper" *ngIf="files.length > limit" (click)="showMore(); false">
        <div class="bee-button secondary small">
            <span>
                {{ 'CORE.SHOW_MORE' | translate }}
            </span>
    
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    </div>
</div>