import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { UserNotification } from 'src/app/domain/notifications/models/user-notification';

@Component({
  selector: 'bee-user-notifications-bell',
  templateUrl: './bee-user-notifications-bell.component.html',
  styleUrls: ['./bee-user-notifications-bell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class BeeUserNotificationsBellComponent implements OnInit {
  @Input() userNotifications: PaginationReturnModel<UserNotification[]> = new PaginationReturnModel<UserNotification[]>();
  @Input() settings: UserNotificationBellSettings = new UserNotificationBellSettings();
  private destroy$ = new Subject<void>();

  public showNotifications: boolean = false;

  constructor(public authService: AuthenticationService, private _eref: ElementRef,
    private router: Router) { 
    if(!this.settings) {
      this.settings = new UserNotificationBellSettings();
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe((val) => {
      if(val instanceof NavigationStart) {
        this.showNotifications = false;
      }
    });
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
      this.showNotifications = false;
  }

  public hasUnreadNotification(): boolean {
    let hasUnreadNotification = false;
    if(this.userNotifications && this.userNotifications.data.length > 0) {

      for(let i = 0; i < 4; i++) {
        if(this.userNotifications.data[i] && !this.userNotifications.data[i].isRead) {
          hasUnreadNotification = true;
        }
      }

      // this.userNotifications.data.forEach(d => {
      //   if(!d.isRead) {
      //     hasUnreadNotification = true;
      //   }
      // });
    }

    return hasUnreadNotification;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}


export class UserNotificationBellSettings {
  public showType: 'slideFromRight' | 'dropdown' = 'dropdown';
  public fontSet: string;
  public showNotificationIcon: boolean = true;
  public showNotificationDate: boolean = true;
}
