<section id="search" class="container">
  <div class="form-group fg--search">
    <div class="input-container">
      <input #searchInput id="search-input" type="text" [(ngModel)]="query" class="input" placeholder="Sök" (keyup.enter)=searchRoute()>
      <button type="submit" (click)=searchRoute()>
        <bee-icon icon="search" class="small"></bee-icon>
      </button>
    </div>
    <div class="search-results" *ngIf="query != ''">
      <div class="search-category-box" *ngFor="let searchCategory of searchItem.indexes" >
        <div class="item-cat">
          <h4><label *ngIf="searchCategory.items.length" [innerHtml]="searchCategory.name.toUpperCase() | translate"></label></h4>
        </div>
        <ul>
        <li *ngFor="let searchItem of searchCategory.items"
        [ngClass]="{'news': searchCategory.indexType=='BLOG',
                    'services': searchCategory.indexType=='CMS_PAGE',
                    'play': searchCategory.indexType=='MEDIABANKFILE',
                    'courses': searchCategory.indexType=='COURSE'
                    }">
          <a (click)="navigateOnItemClick(searchItem.objectType, searchItem.objectKey)">
            <p class="item">{{searchItem.name}}</p>
          </a>
        </li>
      </ul>
      <hr>
      </div>
    </div>
  </div>
</section>
