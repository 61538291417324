import { Directive, ElementRef, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Directive({
  selector: '[beeAllowedToEdit]',
  standalone: true
})
export class AllowedToEditDirective {
  @Input('beeAllowedToEdit') item: any;
  @Input('remove') remove: boolean = false; 
  @Input('module') module: string = null;

  constructor(private el: ElementRef, private authService: AuthenticationService) { 
    
  }

  ngOnInit(): void {
    
    if(this.authService.isSuperadmin() || this.authService.isOwner(this.item) || this.authService.hasAccess(this.module, 'WRITE')) {
      // WE HAVE ACCESS TO EDIT
    }
    else {
      this.el.nativeElement.classList.add("disabled");
      if(this.remove) {
        let el : HTMLElement = this.el.nativeElement;
        el.parentNode.removeChild(el);
      }
    }
  }

}
