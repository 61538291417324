import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IUserNotificationService } from 'src/app/domain/notifications/interfaces/i-user-notification-service';
import { UserNotification } from 'src/app/domain/notifications/models/user-notification';
import { NotificationImageDialogComponent } from './dialogs/notification-image-dialog/notification-image-dialog.component';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BeeNavigationService } from 'src/app/bee-services/bee-navigation.service';

@Component({
  selector: 'bee-user-notifications-list',
  templateUrl: './bee-user-notifications-list.component.html',
  styleUrls: ['./bee-user-notifications-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeeUserNotificationsListComponent implements OnInit {
  @Input() userNotifications: UserNotification[];
  @Input() showIcon: boolean = true;
  @Input() showDate: boolean = true;

  navigationService: BeeNavigationService = inject(BeeNavigationService);

  private destroy$ = new Subject<void>();

  constructor(private userNotificationService: IUserNotificationService, 
    private dialog: MatDialog, 
    public authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  markAsRead(notification: UserNotification) {
    if(!notification.isRead) {
      this.userNotificationService.markAsRead(notification).pipe(
        takeUntil(this.destroy$)
      ).subscribe(response => {
        if(response.isSuccessful) {
          notification.isRead = true;
        }
      });
    }
  }

  showImage(notification: UserNotification): void {
    var dialogRef = this.dialog.open(NotificationImageDialogComponent, {
      panelClass: ["light-grey", "bee-dialog", "large-padding"],
      data: notification
    });
  }

  navigate(notification): void {
    this.markAsRead(notification);
    if(notification.linkData) {
      this.navigationService.navigateTo(notification.linkData.module, notification.linkData.id, notification.linkData.fragment, notification.linkData.querystring);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
