<div class="label-wrapper margin-right lg">
  <p>
    <strong [style.fontSize]="field.templateOptions.extraData?.styles?.labelTextSize">
      <span class="label" [innerHtml]="field.templateOptions.label"></span>
      <span class="required-label label" *ngIf="field.templateOptions.required && field.templateOptions.label">*</span>
    </strong>
  </p>
  
  <span class="description" [innerHTML]="field.templateOptions.description"></span>
</div>

<ng-container *ngIf="field.templateOptions.disabled else default;">
  <span class="margin-left margin-top" [innerHTML]="formControl.value"></span>
</ng-container>

<ng-template #default>  
  <div class="input-wrapper">
    <span class="required" *ngIf="field.templateOptions.required && !field.templateOptions.label">*</span>
    <textarea
      class="margin-top"
      rows="{{field.templateOptions?.extraData?.rows || 1}}"
      [formControl]="formControl" 
      [formlyAttributes]="field">
    </textarea>
  </div>
</ng-template>