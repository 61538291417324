import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { GenericContainer, GenericContainerType } from './generic-container';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericContainerService {

  constructor(private http: HttpClient, private config: AppConfigService) { 

  }

  replacer(key,value) {
    if(key == "injectSettings") return undefined;
    if(key == "eventHandler") return undefined;

    return value;
  }

  /**
   * GetList
objectType: string   */
  public GetList(objectType: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getlist?objectType=' + objectType.toLowerCase());
  }

  /**
   * Get
   */
  public Get(id: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/get?id=' + id);
  }

  /**
   * GetByUrlRewrite
urlRewrite: string, iso2Culture: string   */
  public GetByUrlRewrite(urlRewrite: string, iso2Culture: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getbyurlrewrite?urlRewrite=' + urlRewrite + '&culture=' + iso2Culture);
  }

  /**
   * Save
   */
  public Save(genericContainer: GenericContainer) {
    let data = JSON.parse(JSON.stringify(genericContainer, this.replacer));
    data.cmsContainer = JSON.stringify(data.cmsContainer);

    return this.http.post(this.config.adminApiEndpoint + 'api-container/genericcmscontainer/save', data);
  }

  /**
   * GetGenericContainerTypes
   */
  public GetGenericContainerTypes(): Observable<GenericContainerType[]> {
    return this.http.get<GenericContainerType[]>(this.config.apiEndpoint + 'api-container/genericcmscontainer/GetGenericContainerTypes');
  }

  /**
   * SaveGenericContainerType
   */
  public SaveGenericContainerType(genericContainerType: GenericContainerType) {
    return this.http.post(this.config.apiEndpoint + 'api-container/genericcmscontainer/SaveGenericContainerType', genericContainerType);
  }

    /**
     * GetContainerType
    containerType: string   */
    public GetContainerType(containerType: string) {
      return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/GetGenericContainerType?containerType=' + containerType);
    }
}
