import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bee-blog-list-legacy',
  templateUrl: './bee-blog-list-legacy.component.html',
  styleUrls: ['./bee-blog-list-legacy.component.scss']
})
export class BeeBlogListLegacyComponent implements OnInit {
  @Input() blogs: any[];

  constructor(@Inject(DOCUMENT) private document: Document,
  private router: Router) { }

  ngOnInit(): void {
  }

  goToBlog(blog) {
    const dom: HTMLElement = this.document.body;
    const wrapper = dom.querySelectorAll('.app-wrapper')[0];
    wrapper.scrollTo(0, 0);

    this.router.navigate(['/modules/blog/', blog.urlRewrite]);
  }

}
