import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { UserNotificationBellSettings } from 'src/app/bee-components/bee-user-notifications-bell/bee-user-notifications-bell.component';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { UserNotification } from 'src/app/domain/notifications/models/user-notification';
import { SignalrService } from 'src/app/frontend/services/signalr.service';
import { CMSModuleInstance } from '../../cms-models';
import { IUserNotification } from '../../interfaces/user-notification.interface';

@Component({
  selector: 'bee-cms-module-user-notifications',
  templateUrl: './cms-module-user-notifications.component.html',
  styleUrls: ['./cms-module-user-notifications.component.scss']
})
export class CmsModuleUserNotificationsComponent implements OnInit {
  userNotifications: PaginationReturnModel<UserNotification[]>;

  constructor(public moi: CMSModuleInstance, 
    private userNotificationService: IUserNotification, 
    public authenticationService: AuthenticationService,
    private signalrService: SignalrService) { }

  ngOnInit(): void {
    this.userNotificationService.get({pageIndex: 0, pageSize: 50}).subscribe(data => {
      this.userNotifications = data;
    });

    this.initSignalR();
  }

  initSignalR() {

    this.signalrService.subscribeToEvent(this.authenticationService.currentUserValue.user.id, "user-notification", (message: UserNotification) => {
      console.log('Get message from SignalR: ', message);
      this.userNotifications.data.unshift(message);
      this.userNotifications.totalCount++;
    });

    // if(this.signalrService.connection) {
    //   this.signalrService.connection.on("UserNotification", (message: UserNotification) => {
    //     console.log('Get message from SignalR: ', message);
    //     this.userNotifications.data.unshift(message);
    //     this.userNotifications.totalCount++;
    //   });
    // }
  }

  ngOnDestroy(): void {

    this.signalrService.unsubscribeToEvent(this.authenticationService.currentUserValue.user.id, "user-notification");

    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // if(this.signalrService.connection) {
    //   this.signalrService.connection.off("UserNotification");
    // }
  }

}


@Component({
  selector: 'bee-cms-module-user-notifications-settings',
  templateUrl: './cms-module-user-notifications-settings.component.html',
  styleUrls: ['./cms-module-user-notifications.component.scss']
})
export class CmsModuleUserNotificationsSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { 
    if(!this.moi.settings.content) {
      this.moi.settings.content = {
        settings: new UserNotificationBellSettings()
      };
    }

    if(!this.moi.settings.content.settings) {
      this.moi.settings.content.settings = new UserNotificationBellSettings();
    }
  }

  ngOnInit(): void {
  }

}