import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginationModel } from "../../core/help-models/pagination-model";
import { PaginationReturnModel } from "../../core/help-models/pagination-return-model";
import { SaveReturnModel } from "../../core/help-models/save-return-model";
import { UserNotification } from "../models/user-notification";

@Injectable()
export abstract class IUserNotificationService {
  abstract get(pagination: PaginationModel): Observable<PaginationReturnModel<UserNotification[]>>;
  abstract markAsRead(notification: UserNotification): Observable<SaveReturnModel<string>>;
  abstract addPushSubscriber(sub: string): Observable<SaveReturnModel<any>>;
  abstract unsubscribePushNotification(sub: string): Observable<SaveReturnModel<any>>;
}
