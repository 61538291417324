import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/frontend/services/menu.service';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { SiteCulture } from 'src/app/authorize/models/user-session';

@Component({
  selector: 'bee-thermoeyes-header',
  templateUrl: './thermoeyes-header.component.html',
  styleUrls: ['./thermoeyes-header.component.scss']
})
export class ThermoeyesHeaderComponent implements OnInit {
  public menuItems: any[];
  public menuOpen: boolean = false;

  constructor(private menuService: MenuService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.onCultureChange.subscribe((culture: SiteCulture) => {
      this.GetMenu()
    });
    
    this.GetMenu();
  }

  GetMenu() {
    this.menuService.Get().subscribe((data: any[]) => {
      this.menuItems = data;
    });
  }

}
