<div class="module-segment-settings-wrapper">
  <button (click)="addCopiedModule()" mat-stroked-button *ngIf="hasCopiedMoi">Add copied module</button>
  <div class="margin-top front-side module-frontsidelist-settings-wrapper" #frontSideList="cdkDropList" cdkDropList (cdkDropListDropped)="drop($event)">
    <h1>frontside</h1>
    <button (click)="addModule(false)" mat-stroked-button>Add Frontside module</button>
    <mat-card *ngFor="let moi of frontSide | sort:'order' | filter:['isDeleted', false]" cdkDrag cdkDragBoundary=".module-frontsidelist-settings-wrapper"
      class="margin-top sm segment-moi"
      (click)="selectModule(moi)" [cdkDragData]="moi">
      <div style="display: flex; align-items: center;">
        <i class="material-icons large md-inactive margin-right sm">{{moi.icon}}</i>
        <span>{{moi.name}}</span>
      </div>

      <div style="display: flex; align-items: center;">
        <i class="material-icons sm" (click)="saveCopy(moi); $event.stopPropagation();">save_alt</i>
        <i class="material-icons sm" (click)="copyMoi(moi); $event.stopPropagation();">file_copy</i>
        <i class="material-icons sm" (click)="deleteMoi(moi); $event.stopPropagation();">delete</i>
        <i class="material-icons large md-inactive margin-right sm drag" cdkDragHandle>drag_handle</i>
      </div>
    </mat-card>
  </div>


  <div class="margin-top back-side module-backsidelist-settings-wrapper" #backSideList="cdkDropList" cdkDropList (cdkDropListDropped)="drop($event)">
    <h1>backside</h1>
    <button (click)="addModule(true)" mat-stroked-button>Add backside module</button>
    <mat-card *ngFor="let moi of backSide | sort:'order' | filter:['isDeleted', false]" cdkDrag cdkDragBoundary=".module-backsidelist-settings-wrapper"
      class="margin-top sm segment-moi"
      (click)="selectModule(moi)">
      <div style="display: flex; align-items: center;">
        <i class="material-icons large md-inactive margin-right sm">{{moi.icon}}</i>
        <span>{{moi.abstractName}}</span>
      </div>

      <div style="display: flex; align-items: center;">
        <i class="material-icons sm" (click)="saveCopy(moi); $event.stopPropagation();">save_alt</i>
        <i class="material-icons sm" (click)="copyMoi(moi); $event.stopPropagation();">file_copy</i>
        <i class="material-icons sm" (click)="deleteMoi(moi); $event.stopPropagation();">delete</i>
        <i class="material-icons large md-inactive margin-right sm drag" cdkDragHandle>drag_handle</i>
      </div>
    </mat-card>
    <mat-checkbox (click)="previewBacksideToggler()">Backside preview</mat-checkbox>
</div>
