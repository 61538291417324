import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  public TYPES = {
    UNDEFINED: -1,
    IMAGE: 1,
    VIDEO: 2,
    AUDIO: 3,
    EXTERNAL_VIDEO: 9
  };

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * GetAll
   */
  public GetAll() {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetAll');
  }

  public GetAllByTypes(types: number[]) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetAllByTypes?types=' + types.join(","));  
  }

  public GetFile(fileId: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/Get?id=' + fileId);  
  }

  public GetMediaFilesByTagAndType(tagUrlRewrite: string, type: number) {
    return this.http.get(this.config.apiEndpoint + `api-mediabank/mediabank/GetMediaFilesByTagAndType?tagUrlRewrite=${tagUrlRewrite}&type=${type}`);  
  }

  public GetMediaFilesByTagAndTypes(tagUrlRewrite: string, types: number[]) {
    return this.http.get(this.config.apiEndpoint + `api-mediabank/mediabank/GetMediaFilesByTagAndTypes?tagUrlRewrite=${tagUrlRewrite}&types=${types.join(",")}`);  
  }

  /**
   * LogEvent
   */
  public LogEvent(mediabankFileEvent) {
    return this.http.post(this.config.apiEndpoint + 'api-mediabank/MediabankFileEvent/LogEvent', mediabankFileEvent);
  }

  /**
   * GetEvents
   */
  public GetEvents(mediabankFileId: string, eventType: number) {
    return this.http.get(this.config.apiEndpoint + 
      `api-mediabank/MediabankFileEvent/GetEvents?mediabankFileId=${mediabankFileId}&eventType=${eventType}`);
  }
}
