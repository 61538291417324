import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISearchItem } from '../../../search-item';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getSearchItems(query: string): Observable<ISearchItem>{
    return this.http.get<ISearchItem>(this.config.apiEndpoint + "api-search/getmoduledata?term=" + query);
  }

  genericSearch(indexName: string, term: string, fields: string[] = []) {
    return this.http.post(this.config.apiEndpoint + 'api-search/genericsearch',
      {
        indexName: indexName,
        term: term,
        fields: fields
      }
    );
  }
}
