import dayjs from 'dayjs'
import { IBeePermissionObject } from 'src/app/domain/core/interfaces/i-bee-permission-object';
import { GenericItemPermission } from 'src/app/domain/core/models/generic-item-permission';

export enum MediabankFileType {
  Undefined = -1,
  Image = 1,
  Video = 2,
  Audio = 3,
  Pdf = 4,
  Word = 5,
  Excel = 6,
  Powerpoint = 7,
  Subtitle = 8,
  ExternalVideo = 9,
  TranslatedFile = 10
}

export class MediabankFile implements IBeePermissionObject {
  oldId: number;
  id: string;
  relatedToId: string;
  name: string;
  description: string;
  fileUrl: string;
  thumbnail: string;
  thumbnailLarge: string;
  type: number;
  urlRewrite: string;
  isDeleted: Boolean;
  createdDateUtc: Date;
  isPublic: Boolean;
  file: File;
  tags: any[];
  viewCount: number;

  size: number;
  sizeInKb: number;
  sizeInMb: number;
  durationInSec: number;
  seconds: number;
  minutes: number;

  relatedFiles: MediabankFile[];

  iso2Culture: string;
  iso4Culture: string;

  sizes: any[];

  publishToYoutube: boolean = false;

  isLocked: boolean;
  lockedDateUtc: Date;
  lockedMessage: string;

  permission: GenericItemPermission;

  createdByUserId: string;
  createdTimeUtc: Date;
  lastEditedByUserId: string;
  lastEditedTimeUtc: Date;

  reF_TYPE: string;
  reF_ID: string;
  reF_EXTRA: string;

  constructor(data?: any) {
    if(!data) data = {};

    this.oldId = data.oldId || 0;
    this.id = data.id || data.oldId;
    this.relatedToId = data.relatedToId || "";
    this.name = data.name || "";
    this.description = data.description || "";
    this.fileUrl = data.fileUrl || "";
    this.thumbnail = data.thumbnail || "";
    this.type = data.type || MediabankFileType.Undefined;
    this.urlRewrite = data.urlRewrite || "";
    this.isDeleted = data.isDeleted || false;
    this.isPublic = data.isPublic || false;
    this.createdDateUtc = data.createdDateUtc || dayjs();
    this.file = data.file || undefined;
    this.tags = data.tags || [];
    this.viewCount = data.viewCount || 0;

    this.size = data.size;
    this.sizeInKb = data.sizeInKb;
    this.sizeInMb = data.sizeInMb;
    this.durationInSec = data.durationInSec;
    this.seconds = data.seconds;
    this.minutes = data.minutes;

    this.createdByUserId = data.createdByUserId;
    this.createdTimeUtc = data.createdTimeUtc;
    this.lastEditedByUserId = data.lastEditedByUserId;
    this.lastEditedTimeUtc = data.lastEditedTimeUtc;

    this.relatedFiles = [];

    if(data.relatedFiles && data.relatedFiles.length > 0) {
      for(let i = 0; i < data.relatedFiles.length; i++) {
        this.relatedFiles.push(new MediabankFile(data.relatedFiles[i]));
      }
    }

    this.iso2Culture = data.iso2Culture;
    this.iso4Culture = data.iso4Culture;

    this.sizes = data.sizes;

    this.isLocked = data.isLocked;
    this.lockedDateUtc = data.lockedDateUtc;
    this.lockedMessage = data.lockedMessage;

    this.reF_TYPE = data.REF_TYPE;
    this.reF_ID = data.REF_ID;
    this.reF_EXTRA = data.REF_EXTRA;

    this.permission = data.permission || new GenericItemPermission();
  }
}