export const environment = {
  production: true,
  apiEndpoint: "",
  vapidKey: "BJWv4uQf1cMJQrqAhj8i_7NIbaBxpPFJYdiuNfMfIZsJrKVHeOIIi82nVxDlFvyeHPbzbw45GA-7B_bcCAda5Xo",
  firebase: {
    apiKey: 'AIzaSyA47DI3YC0IWJrclDP0hGsXdXgOm_stwH0',
    authDomain: 'beebase-badad.firebaseapp.com',
    databaseURL: 'https://beebase-badad.firebaseio.com',
    projectId: 'beebase-badad',
    storageBucket: 'beebase-badad.appspot.com',
    messagingSenderId: '872960730399',
    appId: '1:872960730399:web:6a95131dd67d06fee0fc2a',
    measurementId: 'G-R3NHN1GRJE'
  }
};
