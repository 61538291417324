import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs'

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(date, format?): any {
    if(!format) format = "YYYY-MM-DD";
    return dayjs(date).format(format);
  }

}
