import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { ICourseAdminLogService } from 'src/app/domain/academy/interfaces/i-course-admin-log-service';

@Injectable({
  providedIn: 'root'
})
export class CourseAdminLogService implements ICourseAdminLogService {

  constructor(private http: HttpClient, private config: AppConfigService) { }


  getLogsForItem(objectId: string) {
    return this.http.get(this.config.apiEndpoint + 'api-academy/CourseAdminLog/GetLogsForItem?objectId=' + objectId);
  }
}
