<div class="bee-blog-list-wrapper">
  
  <bee-tag-cloud 
    *ngIf="blogSiteSettings.showTagCloud && blogSiteSettings.showTagCloud.show == true && blogSiteSettings.showTagCloud.template == 'fab'"
    (tagSelect)="selectTag($event)">
  </bee-tag-cloud>

  <div class="blog-list-header">
    <h1>Nyheter</h1>
  </div>

  <div class="blog-list">
    <div class="blog-list-item" *ngFor="let blog of blogs | limitTo:limit; let i = index" (click)="goToBlog(blog)">
      <bee-landscape-image [image]="blog.imgUrl | cdn"></bee-landscape-image>
      <!-- <img [src]="blog.imgUrl || '' | cdn" alt=""> -->
      <div class="blog-info">
        <h2 [innerHtml]="blog.name" *ngIf="i == 0"></h2>
        <h3 [innerHtml]="blog.name" *ngIf="i > 0"></h3>
        <span class="date" [innerHtml]="blog.startTimeUtc | date"></span>
      </div>
    </div>
  </div>

  <div class="show-more" *ngIf="blogs.length > BASE_SHOW_LIMIT" (click)="toggleShowAll(); $event.stopPropagation();">
    <h6 *ngIf="!showingAll">Visa mer </h6>
    <h6 *ngIf="showingAll">Visa mindre </h6>
    <bee-animated-icon
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [(animate)]="showingAll"></bee-animated-icon> 
  </div>
</div>

