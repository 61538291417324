<span class="required" *ngIf="field.templateOptions.required">*</span>

<p><strong [innerHtml]="field.templateOptions.label"></strong></p>
<span [innerHtml]="field.templateOptions.description"></span>

<div class="slider-wrapper min-max">
</div>
<div class="val-labels-wrapper flex space-between width-100 margin-top">
  <ng-container *ngIf="field.templateOptions.disabled else default;">
    <div class="val-label read-label" [class.checked]="formControl.value === label" *ngFor="let label of field.templateOptions.extraData.valLabels">
      {{label}}
    </div>
  </ng-container>
</div>

<ng-template #default>
  <div class="val-label" *ngFor="let label of field.templateOptions.extraData.valLabels" (click)="formControl.setValue(label)">
    {{label}}
    <mat-icon *ngIf="formControl.value !== label">radio_button_unchecked</mat-icon>
    <mat-icon style="color: var(--primary-color);" *ngIf="formControl.value === label">radio_button_checked</mat-icon>
  </div>
</ng-template>