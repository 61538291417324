import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { GenericContainer } from 'src/app/admin/generic-container/generic-container';
import { GenericContainerService } from 'src/app/admin/generic-container/generic-container.service';
import { BeePipesModule } from 'src/app/bee-pipes/bee-pipes.module';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { VoCollegeSuccessStoryCardModule } from 'src/app/frontend/customers/vo-college/vo-college-success-story-card/vo-college-success-story-card.component';

@Component({
  selector: 'vo-college-success-stories-template',
  templateUrl: './vo-college-success-stories-template.html',
  styleUrls: ['./vo-college-success-stories-template.scss']
})
export class CmsModuleGenericContainerListComponent implements OnInit {
  public genericContainers: GenericContainer[];
  loadMoreLimit: number = 12;

  constructor(public moi: CMSModuleInstance, private genericContainerService: GenericContainerService, private router: Router) { 
    this.genericContainers = [];
  }

  ngOnInit() {
    if(this.moi.settings.content.genericContainerType) {
      this.GetGenericContainersForType();
    }

    this.moi.settings.content.totalCount = ~~this.moi.settings.content.totalCount;
    this.loadMoreLimit = ~~this.moi.settings.content.totalCount;
  }


  GetGenericContainersForType() {
    this.genericContainerService.GetList(this.moi.settings.content.genericContainerType).subscribe((data: GenericContainer[]) => {
      this.genericContainers = data;
    });
  }

  click(container) {
    this.router.navigate(["modules/generic", container.objectType, container.urlRewrite]);
  }

  showMore() {
    this.moi.settings.content.totalCount += this.loadMoreLimit;
  }

}

@NgModule({
  declarations: [
    CmsModuleGenericContainerListComponent
  ],
  imports: [
    CommonModule,
    BeePipesModule,
    VoCollegeSuccessStoryCardModule,
    MatIconModule
  ]
})
export class VoCollegeCmsModuleGenericContainerListSuccessStoriesModule {}