<div>
  <bee-tag-cloud 
    *ngIf="mediabankSiteSettings.showTagCloud && mediabankSiteSettings.showTagCloud.show == true && mediabankSiteSettings.showTagCloud.template == 'fab'"
    [selectedTag]="tag" 
    (tagSelect)="selectTag($event)">
  </bee-tag-cloud>

  <div class="mediafile-tag-list-header">
    <i class="material-icons icon-15x">
      local_offer
    </i>
    <h2 [innerHtml]="tag.name" class="hide-mobile"></h2>
    <h1 [innerHtml]="tag.name" class="hide-desktop"></h1>
  </div>

  <div class="mediafile-tag-list-wrapper">
    <div class="mediafile-tag-list-items">
      <div class="mediafile-tag-list-item" *ngFor="let mediaFile of mediaFiles">

        <a class="tag-list-image-wrapper" [routerLink]="['/modules/media/', mediaFile.urlRewrite]">
          <img [src]="mediaFile.thumbnail | cdn" [alt]="mediaFile.name">
          <div class="play-overlay">
            <!-- <i class="material-icons">play_arrow</i> -->
            <bee-play-icon></bee-play-icon>
          </div>
        </a>

        <div class="tag-list-info">
          <h3 [innerHtml]="mediaFile.name" class="hide-mobile"></h3>
          <h2 [innerHtml]="mediaFile.name" class="hide-desktop"></h2>
          <p [innerHtml]="mediaFile.description" class="hide-mobile"></p>
          <div>
            <span>
              <span class="media-type-indicator" [ngClass]="getTypeName(mediaFile.type)" [innerHtml]="getTypeName(mediaFile.type)"></span>
              <bee-item-tags [tags]="mediaFile.tags" (tagSelect)="selectTag($event)"></bee-item-tags>
            </span>
          </div>
        </div>

        <div class="share">
          <bee-share title="{{mediaFile.name}}" url="{{getFullUrl(mediaFile)}}"></bee-share>
        </div>
      </div>
    </div>
  </div>
</div>
