import { CMSContainer } from 'src/app/container/cms-models';

export class LayoutEntity {
  id: string;
  isActive: boolean;
  type: LayoutType;
  iso2Culture: String;
  iso4Culture: String;

  cmsContainer: CMSContainer;

  constructor(data?: any, cmsContainer?: any) {
    if(!data) data = {};

    this.id = data.id || data.oldId;
    this.isActive = data.isActive;
    this.type = data.type;
    this.iso2Culture = data.iso2Culture;
    this.iso4Culture = data.iso4Culture;

    if(cmsContainer) {
      this.cmsContainer = new CMSContainer(cmsContainer)
    }
    else {
      this.cmsContainer = new CMSContainer(undefined);
    }
  }

  getLayoutName(): string {
    return this.type.toString();
  }
}

export enum LayoutType {
  Footer = "footer",
  Header = "header"
}