import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MediabankFileType } from 'src/app/admin/mediabank/mediabank-models';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { MediaService } from 'src/app/frontend/modules/services/media.service';

var publicCallback;

@Component({
  selector: 'bee-video',
  templateUrl: './bee-video.component.html',
  styleUrls: ['./bee-video.component.scss']
})
export class BeeVideoComponent implements OnInit {
  @Input() video: any;
  @Input() settings?: BeeVideoSettings;

  @Output() callback? = new EventEmitter();
  @Output() selectedVideoCallback? = new EventEmitter();

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('videoSrc') videoSrc: ElementRef;

  public _reloadVideo: boolean = false;

  videoStarted: boolean;
  selectedVideo: any;

  constructor(private cdnPipe: CdnPipe, private mediaService: MediaService,
    private cd: ChangeDetectorRef, public authService: AuthenticationService) { 
    if(!this.settings) {
      this.settings = new BeeVideoSettings();
    }
    this.selectedVideo = this.video;
  }

  ngOnInit(): void {
    if(this.callback) {
      publicCallback = this.callback;
    }

    if(this.video && this.video.type == 2 && this.video.relatedFiles) {
      setTimeout(() => {
        this.initSubsitles();
      }, 100);
    }
    else if(this.video && this.video.urlRewrite) {
      this.mediaService.GetByUrl(this.video.urlRewrite).subscribe(response => {
        if(response) {
          this.video = response;

          setTimeout(() => {
            this.initSubsitles();
          }, 100);
        }
      });
    }
  }

  getPoster(): string {
    return this.selectedVideo?.thumbnail || this.video?.thumbnail;
  }

  ngAfterViewInit(): void {
    this.selectedVideo = this.video;
  }

  initSubsitles() {
    console.log('INIT SUBTITLES');
    if(this.video.type == 2 && this.video && this.video.relatedFiles) {
      this.video.relatedFiles.forEach(r => {
        if(r.type === MediabankFileType.Subtitle) {
          let track = document.createElement('track');
          track.src = this.mediaService.getCdnPath(r.fileUrl);//this.cdnPipe.transform(r.fileUrl);
          if(r.iso2Culture === this.selectedVideo.iso2Culture) {
            track.setAttribute("default", "");
          }
          track.label = this.authService.getCultureFromIso2(r.iso2Culture).name;
          track.srclang = r.iso2Culture;
          track.kind = 'subtitles';
          if(this.videoplayer && this.videoplayer.nativeElement) {
            this.videoplayer.nativeElement.appendChild(track);
          }
          else {
            console.error("Video player not initialized");
          }
        }
      });
    }
  }

  startVideo() {
    if(this.videoplayer) {

      if(this.videoSrc) {
        if(this.selectedVideo.sizes && this.selectedVideo.sizes.length > 0) {
          //Getting HD480p
          this.videoSrc.nativeElement.src = this.cdnPipe.transform(this.selectedVideo.sizes[0].fileUrl);
        }
        else {
          this.videoSrc.nativeElement.src = this.cdnPipe.transform(this.selectedVideo.fileUrl);
        }

        this.videoplayer.nativeElement.load();
      }

      this.videoStarted = true;
      this.videoplayer.nativeElement.play();

      publicCallback.emit({ event: "start" });
  
      //this.mediaService.LogEvent({mediabankFileId: this.module.settings.content.video.mediabankFileId, eventType: 1}).subscribe(response => {});
  
      this.videoplayer.nativeElement.addEventListener('ended', this.videoEnd); 
      this.videoplayer.nativeElement.addEventListener('pause', this.videoPause); 
      this.videoplayer.nativeElement.addEventListener('play', this.videoPlay); 

      //mediaServiceGlobal = this.mediaService;
      //mediaFileGlobal = this.module.settings.content.video;
    }
  }

  videoEnd(event) {
    publicCallback.emit({ event: "end" });
    //mediaServiceGlobal.LogEvent({mediabankFileId: mediaFileGlobal.mediabankFileId, eventType: 2}).subscribe(response => {});
  }

  videoPause(event) {
    publicCallback.emit({ event: "pause" });
  }

  videoPlay(event) {
    publicCallback.emit({ event: "play" });
  }

  changeCulture(e): void {
    this.videoplayer.nativeElement.pause();
    this.selectedVideo = e;
    this.videoStarted = false;
    this._reloadVideo = true;
    this.cd.detectChanges();
    this._reloadVideo = false;
    this.cd.detectChanges();

    this.initSubsitles();

    if(this.selectedVideoCallback) {
      this.selectedVideoCallback.emit(this.selectedVideo);
    }
  }

}

export class BeeVideoSettings {
  showVideoLength: boolean = false;
}