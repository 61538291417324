import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TagService } from '../services/tag.service';

@Component({
  selector: 'bee-tag-cloud',
  templateUrl: './bee-tag-cloud.component.html',
  styleUrls: ['./bee-tag-cloud.component.scss']
})
export class BeeTagCloudComponent implements OnInit {
  public tags: any[];
  public isExpanded: boolean;

  @Input() selectedTag: any;

  @Output('tagSelect') tagSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private tagService: TagService) { 
    this.tags = [];
    this.isExpanded = false;
  }

  ngOnInit() {
    this.tagService.GetActiveTags().subscribe((data: any) => {
      this.tags = data;
    });
  }

  selectTag(tag) {
    this.isExpanded = false;
    this.tagSelect.emit(tag);
  }

  isSelected(tag) {
    if(this.selectedTag && this.selectedTag.id == tag.id) return "active";
  }
}
