<div *ngFor="let course of courses; let i = index;" class="course-chapter-table-info-wrapper">
  <h4 [innerHtml]="course.name"></h4>

  <bee-table [settings]="tableSettings[i]" class="margin-bottom lg"></bee-table>

  <!-- <div style="display: flex; justify-content: right;">
    <a href="#">Skriv ut aktuell utbildningslista</a>
  </div> -->

  <div class="course-certificate margin-bottom lg" *ngIf="course.certificateId" [class.completed]="isCourseCompleted(course)">
    <mat-icon fontSet="material-icons-outlined" class="margin-right">assignment_turned_in</mat-icon>

    <div>
      <p [innerHtml]="'ACADEMY.CERTIFICATE' | translate" class="bold"></p>
      <div *ngIf="!isCourseCompleted(course)">
        <span class="font-medium">När du är klar med hela din utbildning kommer du att kunna ladda ner ditt utbildningsbevis här.</span> 
      </div>
      <div *ngIf="isCourseCompleted(course)">
        <span class="font-medium">Nu är du klar med hela din utbildning! Ladda ner ditt utbildningsbevis här: <span class="cert-link" (click)="getCertificate(course)">utbildningsbevis_{{course.name}}.pdf</span></span>
      </div>
    </div>
  </div>
</div>
