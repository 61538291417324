import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { copyToClipboard } from 'src/app/helpers/copy-to-clipboard/copy-to-clipboard';

@Component({
  selector: 'bee-show-json-dialog',
  templateUrl: './show-json-dialog.component.html',
  styleUrls: ['./show-json-dialog.component.scss']
})
export class ShowJsonDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShowJsonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    
  }

  copyToClipboard() {
    copyToClipboard(this.data.json);

    var config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = 1000;
    this.snackbar.open("Copied to clipboard!", null, config);
  }

}
