import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs'

@Pipe({
  name: 'beeFromNow'
})
export class BeeFromNowPipe implements PipeTransform {

  transform(date): any {
    return dayjs(date).fromNow();
  }

}
