<div *ngIf="culture$ | async as culture" class="flag-outer-wrapper">
    <ng-container 
        *ngTemplateOutlet="culture.flagIcon == null ? defaultFlag : overrideFlag; 
        context: { $implicit: culture }">
    </ng-container>
</div>

<ng-template #defaultFlag let-obj>
    <span class="flag fi fi-{{obj.iso2}}">
    </span>
</ng-template>

<ng-template #overrideFlag let-obj>
    <img [src]="obj.flagIcon" class="override-flag flag" />
</ng-template>