import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { environment } from '../../../environments/environment';
import Pusher from "pusher-js";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  connections: any;

  pusher: any = "";

  constructor(private config: AppConfigService, private authService: AuthenticationService) { 
    this.connections = {};
  }

  public connectToChannel(channelName: string): void {
    if(!this.connections[channelName]) {
      this.connections[channelName] = this.pusher.subscribe(channelName);
    }
  }

  public subscribeToEvent(channelName: string, eventName: string, func: any): void {
    if(this.authService.currentUserValue && this.authService.currentUserValue.user.id.length > 3) {
      if(!this.connections[channelName]) {
        this.connectToChannel(channelName);
      }
  
      this.connections[channelName].bind(eventName, func);
    }
  }

  public initPusher(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if(this.authService.currentUserValue && this.authService.currentUserValue.user && this.authService.currentUserValue.user.id.length > 3) {
        this.pusher = new Pusher("5db1c96c6de3e1703479", {
          cluster: "eu"
        });

        this.subscribeToEvent("beebase", "global", function(data) {
          alert(JSON.stringify(data));
        });
      }

      resolve(undefined);
    });
  }

  public unsubscribeToEvent(channelName: string, eventName: string, func?: any) {
    if(this.connections[channelName]) {
      if(func) {
        this.connections[channelName].unbind(eventName, func);
      }
      else {
        this.connections[channelName].unbind(eventName);
      }
    }
  }

  public initiateSignalrConnection(): Promise<any>{

    return this.initPusher();

    // return new Promise((resolve, reject) => {

    //   if((!this.authService.currentUserValue || !this.authService.currentUserValue.siteSettings || !this.authService.currentUserValue.siteSettings.portalSettings) 
    //     || !this.authService.currentUserValue.siteSettings.portalSettings.isWebsocketsEnabled) {
    //     resolve(undefined);
    //   }
    //   else if(this.authService.currentUserValue.user && this.authService.currentUserValue.user.id 
    //     && this.authService.currentUserValue.user.id != "-1" && this.authService.currentUserValue.user.id.length > 0) {

    //       let url = `${this.config.apiEndpoint}hubs/signalrnotificationshub`;

    //       // if(window.location.protocol == "http:") {
    //       //   url = `${this.config.apiEndpoint}hubs/ws/signalrnotificationshub`;
    //       // }

    //       // url = `${this.config.apiEndpoint}hubs/ws/signalrnotificationshub`;

    //       // if(environment.production) {
    //       //   url = `${window.location.protocol}//ws.${window.location.hostname}/signalrnotificationshub`;
    //       // }
    
    //       // if(window.location.host.indexOf("localhost") <= -1) {
    //       //   url = `${this.config.apiEndpoint}/hubs/ws/signalrnotificationshub`;
    //       //   // var conn2 = new signalR.HubConnectionBuilder()
    //       //   // .withUrl(url2) // the SignalR server url
    //       //   // .build();
    
    //       //   // conn2.start().then(() => {});
    //       // }

    //       url = "https://ws.kcf.wisebeetech.se/signalrnotificationshub";
    
    //       this.connection = new signalR.HubConnectionBuilder()
    //         .withUrl(url) // the SignalR server url
    //         .build();
      
    //       this.connection
    //         .start()
    //         .then(() => {
    //           console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
    //           resolve(this.connection.connectionId);
    //         })
    //         .catch((error) => {
    //           console.log(`SignalR connection error: ${error}`);
    //           //We want to resolve event if we're not connected to WS...
    //           resolve(undefined);
    //         });
    //   }
    //   else {
    //     resolve(undefined);
    //   }
    // });
  }
}
