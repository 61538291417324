import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bee-generic-container-view-more',
  templateUrl: './generic-container-view-more.component.html',
  styleUrls: ['./generic-container-view-more.component.scss']
})
export class GenericContainerViewMoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
