import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Inject, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { MediaService } from '../services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SortDescPipe } from 'src/app/container/pipes/sort-desc.pipe';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { BeeMetaService } from '../../services/bee-meta.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { MediabankSiteSettings, SiteCulture, SiteStyle } from 'src/app/authorize/models/user-session';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

var mediaServiceGlobal;
var mediaFileGlobal;

@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaViewComponent implements OnInit {
  sub: any;
  urlRewrite: String;
  public mediaFile: any;
  public selectedMediaFile: any;
  public relatedFiles: any[];
  mediaItems: any[];
  public mediaItemsLatest: any[];
  public mediaItemsMostViewed: any[];
  public allMediaItemsOfSameType: any[];
  public readMore: boolean;
  started: boolean;
  
  public BASE_SHOW_LIMIT: number;
  public limit: number;
  public showingAll: boolean;

  public latestExpanded: boolean = false;
  public latestLimit: number = 4;

  public mostViewedExpanded: boolean = false;
  public mostViewedtLimit: number = 4;

  public _reload = true;
  public _reloadVideo: boolean = false;

  public isLoading = true;

  public mediabankSiteSettings: MediabankSiteSettings = new MediabankSiteSettings();

  public selectedCulture: SiteCulture = new SiteCulture();

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  @ViewChild('pdfObject', {static: false}) pdfObject: ElementRef;
  @ViewChild('wordObject', {static: false}) wordObject: ElementRef;

  constructor(private mediaService: MediaService, private route: ActivatedRoute, public sortDesc: SortDescPipe, private router: Router,
    private metaService: BeeMetaService, private cdnPipe: CdnPipe, @Inject(DOCUMENT) private document: Document, private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef, private renderer: Renderer2) {

      this.mediabankSiteSettings = this.authService.currentUserValue.siteSettings.mediabankSiteSettings;
      this.started = false; 
      this.relatedFiles = [];
      this.mediaItems = [];
      this.mediaItemsLatest = [];
      this.mediaItemsMostViewed = [];
      this.readMore = false;

      this.BASE_SHOW_LIMIT = 10;
      this.limit = this.BASE_SHOW_LIMIT;
      this.showingAll = false;

      mediaServiceGlobal = this.mediaService;

      console.log('MEDIABANK SETTINGS', this.mediabankSiteSettings);
  }

  getEmbedUrl(): string {
    console.log('GET EMBED URL', this.mediaFile.fileUrl)
    return 'https://docs.google.com/gview?embedded=true&url=' + this.cdnPipe.transform(this.mediaFile.fileUrl);
  }

  getEmbedUrlWord(): string {
    return 'https://view.officeapps.live.com/op/embed.aspx?src=' + this.cdnPipe.transform(this.mediaFile.fileUrl);
  }

  toggleShowAll() {
    this.showingAll = !this.showingAll;

    if(this.showingAll) this.limit = 99;
    else this.limit = this.BASE_SHOW_LIMIT;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.urlRewrite = params['urlRewrite']; 

      this.getMediaFile();
      this.getRelated();
   });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    //this.metaService.removeTag("og:video");
  }

  getTypeName(type) {
    return this.mediaService.getTypeName(type);
  }

  getMediaFile() {
    this.mediaService.GetByUrl(this.urlRewrite).pipe(
      tap((f: any) => {
        this.mediaService.LogEvent({mediabankFileId: f.id, eventType: 1}).pipe(
          tap(() => {
            this.mediaService.LogEvent({mediabankFileId: f.id, eventType: 2}).subscribe(response => {});
          })
        ).subscribe(response => {});
      })
    ).subscribe((data: any) => {
      this.mediaFile = data;
      this.selectedMediaFile = data;
      this.mediaFile.description = this.sanitizer.bypassSecurityTrustHtml(data.description);
      
      mediaFileGlobal = this.mediaFile;

      if(this.mediaFile.type == this.mediaService.TYPES.VIDEO) {
        this.metaService.setBasicMeta(this.mediaFile.name, this.mediaFile.description,
          this.cdnPipe.transform(this.mediaFile.thumbnail), this.cdnPipe.transform(this.mediaFile.fileUrl));
      }
      else {
        this.metaService.setBasicMeta(this.mediaFile.name, this.mediaFile.description,
          this.cdnPipe.transform(this.mediaFile.thumbnail));
      }

      console.log(this.mediaFile.description);

      this.getAllMediaFiles();

      setTimeout(() => {
        this.initSubsitles();
        if(this.mediaFile.type == 4) {
          this.renderer.removeAttribute(this.pdfObject.nativeElement, 'src');
          setTimeout(() => {
            this.renderer.setAttribute(this.pdfObject.nativeElement, 'src', this.cdnPipe.transform(this.mediaFile.fileUrl));
          }, 0);
        }
        else if(this.mediaFile.type == 5 && this.wordObject && this.wordObject.nativeElement) {
          this.wordObject.nativeElement.data = this.cdnPipe.transform(this.mediaFile.fileUrl);
        }
        else {
          this.reload();
        }
        //Needed for some reason when we navigate within the same route...
        //this.reload();
        this.started = false;
      }, 1000);

    }); 
  }

  initSubsitles() {
    console.log('INIT SUBTITLES');
    if(this.mediaFile.type == 2 && this.mediaFile && this.mediaFile.relatedFiles) {
      this.mediaFile.relatedFiles.forEach(r => {
        let track = document.createElement('track');
        track.src = this.mediaService.getCdnPath(r.fileUrl);//this.cdnPipe.transform(r.fileUrl);
        track.setAttribute("default", "");
        track.label = r.iso2Culture;
        track.srclang = r.iso2Culture;
        track.kind = 'subtitles';
        if(this.videoplayer && this.videoplayer.nativeElement) {
          this.videoplayer.nativeElement.appendChild(track);
        }
        else {
          console.error("Video player not initialized");
        }
      });
    }
  }

  private reload() {
    this._reload = false
    this.cd.detectChanges();
    this._reload = true
    this.cd.detectChanges();
    

    const dom: HTMLElement = this.document.body;
    const wrapper = dom.querySelectorAll('.app-wrapper')[0];
    wrapper.scrollTo(0, 0);
}

  getAllMediaFiles() {
    this.isLoading = true;

    this.mediaService.GetMediabankFiles([this.mediaFile.type]).subscribe((data: any) => {

      this.mediaItems = data;

      this.handleMediaSorting();

      this.isLoading = false;
    });
  }

  handleMediaSorting() {
    this.mediaItemsLatest = this.sortDesc.transform(JSON.parse(JSON.stringify(this.mediaItems)), 'id');
    this.mediaItemsMostViewed = this.sortDesc.transform(JSON.parse(JSON.stringify(this.mediaItems)), 'viewCount');
  }

  getRelated() {
    this.mediaService.GetRelated(this.urlRewrite).subscribe((data: any) => {
      this.relatedFiles = data;
    });
  }

  startExternalVideo() {

  }

  getFullYoutubteUrl() {
    return "https://www.youtube.com/embed/" + this.mediaService.youtube_parser(this.mediaFile.fileUrl);
  }

  getImage(media) {
    if(media.type == this.mediaService.TYPES.IMAGE) return media.fileUrl;
    else return media.thumbnail;
  }

  toggleLatestLimit() {
    if(this.latestLimit <= 4) {
      this.latestLimit = 99;
      this.latestExpanded = true;
    }
    else {
      this.latestLimit = 4;
      this.latestExpanded = false;
    }
  }

  toggleMostViewedLimit() {
    if(this.mostViewedtLimit <= 4) { 
      this.mostViewedtLimit = 99;
      this.mostViewedExpanded = true;
    }
    else {
      this.mostViewedtLimit = 4;
      this.mostViewedExpanded = false;
    }
  }

  goToMedia(media) {
    this.router.navigate(['/modules/media/', media.urlRewrite]);
  }

  selectTag(tag) { 
    this.router.navigate(['/modules/tag-medias/', (tag.urlRewrite || tag.tagUrlRewrite)]);
  }

  getFullUrl(mediaFile) {
    return window.location.origin + "/modules/media/" + mediaFile.urlRewrite;
  }

  changeCulture(e) {
    this.selectedMediaFile = e;
    this._reloadVideo = true;
    this.cd.detectChanges();
    this._reloadVideo = false;
    this.cd.detectChanges();
  }

  changeSelectedVideo(e) {
    this.selectedMediaFile = e;
  }

  getSelectedCultureDir(): string {
    return this.authService.getCultureFromIso2(this.selectedMediaFile.iso2Culture).direction;
  }

  public videoCallback(e: any) {

    switch(e.event) {
      case "start":
        this.mediaService.LogEvent({mediabankFileId: this.selectedMediaFile.id, eventType: 1}).subscribe(response => {});
        break;
      case "end":
        this.mediaService.LogEvent({mediabankFileId: this.selectedMediaFile.id, eventType: 2}).subscribe(response => {});
        break;
    }
  }
}
