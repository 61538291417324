import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'bee-play-icon',
  templateUrl: './bee-play-icon.component.html',
  styleUrls: ['./bee-play-icon.component.scss']
})
export class BeePlayIconComponent implements OnInit {
  public templateType: string = "default";
  public imageIcon: string;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    if(this.authService.currentUserValue.siteSettings.mediabankSiteSettings.playIconUrl) {
      this.imageIcon = this.authService.currentUserValue.siteSettings.mediabankSiteSettings.playIconUrl;

      console.log(this.imageIcon);
      this.templateType = "image";
    }
  }

}
