<mat-form-field>
  <input matInput placeholder="Text" name="text" [(ngModel)]="module.settings.content.text"> 
</mat-form-field>

<div class="content-item margin-top">
  <mat-button-toggle-group [(ngModel)]="module.settings.content.typeCss">
    <mat-button-toggle value="primary" aria-label="Text align left">
      Primary
    </mat-button-toggle>
    <mat-button-toggle value="secondary" aria-label="Text align center">
      Secondary
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="content-item margin-top">
  <mat-button-toggle-group [(ngModel)]="module.settings.content.size">
    <mat-button-toggle value="" aria-label="Normal">
      Normal
    </mat-button-toggle>
    <mat-button-toggle value="small" aria-label="Small">
      Small
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>