import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { IBeeSelectItem, BeeSelectItem } from '../models/bee-select-item';

@Component({
  selector: 'bee-select',
  templateUrl: './bee-select.component.html',
  styleUrls: ['./bee-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeeSelectComponent implements OnInit {
  public selectedItemValue: IBeeSelectItem;

  @Input() disabled: boolean = false;
  @Input() items: IBeeSelectItem[];
  @Input()
  get selectedItem(): IBeeSelectItem {
    return this.selectedItemValue;
  }
  set selectedItem(val: IBeeSelectItem) {
    if(!val) val = { name: "", value: "", shortName: ""};
    this.selectedItemValue = val;
    this.selectedItemChange.emit(val);
  }

  @Output() selectedItemChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
    
  }

}
