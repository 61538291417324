import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { SiteCulture } from 'src/app/authorize/models/user-session';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
  selector: 'bee-culture-select',
  templateUrl: './culture-select.component.html',
  styleUrls: ['./culture-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    TranslateModule
  ]
})
export class CultureSelectComponent implements OnInit {
  @Input() selectedCulture: SiteCulture;

  @Output() onChange = new EventEmitter();

  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
    if(!this.selectedCulture) {
      this.selectedCulture = this.authService.cultures[0];
      this.changeCulture();
    }
  }

  changeCulture(): void {
    this.onChange.emit(this.selectedCulture);
  }

}
