import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-tag-view',
  templateUrl: './media-tag-view.component.html',
  styleUrls: ['./media-tag-view.component.scss']
})
export class MediaTagViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
