import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-multicheck',
  templateUrl: './bee-form-multicheck.component.html',
  styleUrls: ['./bee-form-multicheck.component.scss']
})
export class BeeFormMulticheckComponent extends FieldType implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit() {
    if(!this.field.templateOptions.attributes) this.field.templateOptions.attributes = {};
  }

  checkboxChange() {
    let val = "";
    this.field.templateOptions.options.forEach((o: any) => {
      if(o.checked) {
        if(val.length > 0) val += ", ";
        
        val += o.label;
      }
    });

    this.formControl.setValue(val);
  }

}
