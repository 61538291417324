import { SearchService } from './../services/search.service';
import { Component, OnInit, Pipe } from '@angular/core';
import { Router } from '@angular/router';
import { ISearchItem } from 'src/app/search-item';


@Component({
  selector: 'search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent implements OnInit {

  public searchItem: ISearchItem;
  private _query: string;

  set query(value:string) {
    this._query = value;

    //Call for search
    this.search();
    console.log(this._query);
  }

  get query() {
    return this._query;
  }
  //ANVÄNDS DENNA?
  // filterValues(search: string) {
  //   return this.searchItems.filter(value=>
  //   value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  // }

  constructor(private _searchService: SearchService, public router: Router) {

  }

  ngOnInit() {

  }

  search() {
    if(this._query === ""){
      return this._query;
    }// is this really needed?

    this._searchService.getSearchItems(this._query.toLowerCase()) // to.lowercase solves matching issues
      .subscribe(data => this.searchItem = data);
  }

  searchRoute() {
    //console.log("function called");
    this.router.navigate(['modules/search/', this._query]);
  }

  navigateOnItemClick(term,name) {
    console.log(term);
    this.router.navigate([term.toLowerCase() , name.toLowerCase()]);
  }

  log(val){
    console.log(val);
  }
}
