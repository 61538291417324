<div class="generic-container-view-wrapper" *ngIf="genericContainer">
  <bee-tag-cloud 
    *ngIf="genericContainerSiteSettings.showTagCloud && genericContainerSiteSettings.showTagCloud.show == true && genericContainerSiteSettings.showTagCloud.template == 'fab'"
    (tagSelect)="selectTag($event)">

  </bee-tag-cloud>

  <div class="publisher-info" *ngIf="genericContainer.user && genericContainerSiteSettings.showPublisher.show && genericContainerSiteSettings.showPublisher.position != 1">
    <div class="publisher-img" [style.backgroundImage]="'url(' + (genericContainer.user.userPhotoUrl | cdn) + ')'"></div>
    <div class="publisher">
      <span class="publisher-name" [innerHtml]="genericContainer.user.fullName"></span>
      <span class="publishing-date" [innerHtml]="genericContainer.startTimeUtc | date:'D MMMM YYYY'"></span>
    </div>
  </div>

  <p *ngIf="genericContainerSiteSettings.showPublishedDate.show && genericContainerSiteSettings.showPublishedDate.position != 1" 
    class="published-date-bottom" 
    [style.width]="genericContainerSiteSettings.showPublishedDate.wrapperWidth"
    [style.textAlign]="genericContainerSiteSettings.showPublishedDate.textAlign">
    <span>Publicerad: </span>
    <span [innerHtml]="genericContainer.createdTimeUtc | date"></span>
  </p>

  <div *ngIf="genericContainerSiteSettings.showSocialShare.show && genericContainerSiteSettings.showSocialShare.position != 1"
    class="social-share" [style.width]="genericContainerSiteSettings.showSocialShare.wrapperWidth"
    [ngClass]="genericContainerSiteSettings.showSocialShare.textAlign">

    <bee-social-share-default></bee-social-share-default>
  </div>

  <container-cms-view [container]="genericContainer.container"></container-cms-view>

  <div class="generic-container-view-info margin-top lg">


    <bee-item-tags *ngIf="genericContainerSiteSettings.showTags.show && genericContainerSiteSettings.showTags.position == 1" 
      [tags]="genericContainer.tags" (tagSelect)="selectTag($event)"></bee-item-tags>


    <div *ngIf="genericContainerSiteSettings.showPublisher.show && genericContainerSiteSettings.showPublisher.position == 1">
      <ng-container 
        *ngTemplateOutlet="{'default': publisherDefault, 'legacy': publisherLegacy }[genericContainerSiteSettings.showPublisher.template]">
      </ng-container>
    </div>

    <!-- <div class="publisher-info" *ngIf="genericContainer.user && genericContainerSiteSettings.showPublisher.show && genericContainerSiteSettings.showPublisher.position == 1">
      <div class="publisher-img" [style.backgroundImage]="'url(' + (genericContainer.user.userPhotoUrl | cdn) + ')'"></div>
      <div class="publisher">
        <span class="publisher-name" [innerHtml]="genericContainer.user.fullName"></span>
        <span class="publishing-date" [innerHtml]="genericContainer.startTimeUtc | date:'D MMMM YYYY'"></span>
      </div>
    </div> -->

    <p *ngIf="genericContainerSiteSettings.showPublishedDate.show && genericContainerSiteSettings.showPublishedDate.position == 1" 
      class="published-date-bottom" 
      [style.width]="genericContainerSiteSettings.showPublishedDate.wrapperWidth"
      [style.textAlign]="genericContainerSiteSettings.showPublishedDate.textAlign">
      <span>Publicerad: </span>
      <span [innerHtml]="genericContainer.createdTimeUtc | date"></span>
    </p>

    <div *ngIf="genericContainerSiteSettings.showSocialShare.show && genericContainerSiteSettings.showSocialShare.position == 1"
      class="social-share" [style.width]="genericContainerSiteSettings.showSocialShare.wrapperWidth"
      [ngClass]="genericContainerSiteSettings.showSocialShare.textAlign">

      <bee-social-share-default></bee-social-share-default>
    </div>
  </div>

  <div class="divider margin-top lg"></div>
</div>

<div class="more-content">
  <ng-container #moreContent></ng-container>    
</div>


<ng-template #publisherDefault>
  <p class="publisher"
  [style.width]="genericContainerSiteSettings.showPublisher.wrapperWidth"
  [style.textAlign]="genericContainerSiteSettings.showPublisher.textAlign">
    <span [innerHtml]="genericContainer.user.fullName"></span>
  </p>
</ng-template>

<ng-template #publisherLegacy>
  <div class="publisher-info" 
  [style.width]="genericContainerSiteSettings.showPublisher.wrapperWidth"
  [style.textAlign]="genericContainerSiteSettings.showPublisher.textAlign">
    <div class="publisher-img" [style.backgroundImage]="'url(' + (genericContainer.user.userPhotoUrl | cdn) + ')'"></div>
    <div class="publisher">
      <span class="publisher-name" [innerHtml]="genericContainer.user.fullName"></span>
      <span class="publishing-date" [innerHtml]="genericContainer.startTimeUtc | date:'D MMMM YYYY'"></span>
    </div>
  </div>
</ng-template>