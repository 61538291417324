import { EducationLinkEntity } from "./education-link-entity";
import { EducationModule } from "./education-module";
import { ObjectID } from 'bson';
import { JourneyItem } from "src/app/bee-components/modules/academy/journey/journey.component";

export class CourseChapter {
  id: string = new ObjectID().toHexString();
  name: string;
  optional: boolean;
  description: string;
  image: any;
  imgUrl: string;
  introductionVideo: any;
  linkedEducationIds: EducationLinkEntity[];
  educations: EducationModule[];
  educationCount: number;

  constructor(init?: any) {
    this.educations = [];
    this.linkedEducationIds = [];

    Object.assign(this, init);
  }
}


export function getJourneyItemsFromChapters(chapters: CourseChapter[], completedItems: string[]): JourneyItem[] {
  let items = [];

  if(chapters) {
    chapters.forEach(c => {
      items.push(new JourneyItem({
        itemId: c.id,
        itemType: "ACADEMY_CHAPTER",
        name: c.name,
        description: c.description,
        image: c.image?.imgUrl,
        isCompleted: checkIfChapterIsDone(c, completedItems),
        isExpanded: false,
        optional: c.optional
      }));

      c.educations
      .sort((a, b) => {
        //Sort by date
        return <any>new Date(a.startTimeUtc) - <any>new Date(b.startTimeUtc);
      })
      .sort((a, b) => {
        return Number(a.education_type == 'EVALUATION') - Number(b.education_type == 'EVALUATION');
      })
      .sort((a, b) => {
        return Number(a.optional) - Number(b.optional);
      })
      .forEach(e => {
        items.push(new JourneyItem({
          itemId: e.id,
          itemType: `ACADEMY_${e.education_type}`,
          name: e.name,
          description: e.description,
          image: e.image?.imgUrl,
          isCompleted: checkIfEducationIsCompleted(e.id, completedItems),
          startTimeUtc: e.startTimeUtc,
          endTimeUtc: e.endTimeUtc,
          isExpanded: false,
          optional: e.optional,
        }));
      });
    });
  }

  return items;
}

export function checkIfEducationIsCompleted(educationId, completedItems: string[]): boolean {
  return completedItems.indexOf(educationId) >= 0;
}

export function checkIfChapterIsDone(c: CourseChapter, completedItems: string[]): boolean {
  let optionalCount = 0;
  let educationModulesCount = c.educationCount;
  let educationCompletedCount = 0;
  
  if(c.name == "Köttkvalitet") {
    debugger;
  }

  c.educations.forEach(e => {
    if(e.optional) optionalCount++;
  });
  
  for(let i = 0; i < c.educations.length; i++) {
    if(completedItems.indexOf(c.educations[i].id) >= 0) {
      educationCompletedCount++;
    }
  }
  
  return educationCompletedCount >= (educationModulesCount - optionalCount);
}