import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bee-item-tags',
  templateUrl: './bee-item-tags.component.html',
  styleUrls: ['./bee-item-tags.component.scss']
})
export class BeeItemTagsComponent implements OnInit {
  @Input() tags: any[];

  @Output('tagSelect') tagSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  selectTag(tag) {
    this.tagSelect.emit(tag);
  }

}
