import { Component, OnInit } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';

@Component({
  selector: 'app-cms-module-button',
  templateUrl: './cms-module-button.component.html',
  styleUrls: ['./cms-module-button.component.scss']
})
export class CmsModuleButtonComponent implements OnInit {

  constructor(public module: CMSModuleInstance) { }

  ngOnInit() {
    if(!this.module.settings.content.typeCss) {
      this.module.settings.content.typeCss = "primary";
    }
  }

  getClass(): string {
    var c = "";

    if(this.module.settings.content.size) {
      c = this.module.settings.content.size;
    }

    if(this.module.settings.content.typeCss) {
      c += " " + this.module.settings.content.typeCss;
    }

    return c;
  }

  
  getTarget() {
    if(this.module.settings.content.linkOpenNewWindow) {
      return "_blank";
    }

    return "_self";
  }

}

@Component({
  selector: 'app-cms-module-button-settings',
  templateUrl: './cms-module-button-settings.component.html',
  styleUrls: ['./cms-module-button.component.scss']
})
export class CmsModuleButtonSettingsComponent implements OnInit {

  constructor(public module: CMSModuleInstance) { }

  ngOnInit() {
  }

}

