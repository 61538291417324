import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class ISearchService {
  abstract getSearchItems(query: string): Observable<ISearchItem>
}

export interface ISearchItem {
  indexes: ISearchItemIndex[];
  totalResultCount: number;
}

export interface ISearchItemIndex {
  name: string;
  indexType: string;
  items: any[];
}