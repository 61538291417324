import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ContainerDialogService {

  public readonly CONTAINER_KEY: string = "DIALOG";
  
  constructor(private http: HttpClient, private config: AppConfigService) { 
    
  }
}
