import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { debug } from 'console';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseEntity } from 'src/app/admin/academy/models/course-entity';
import { ICourseService } from '../../interfaces/course.service.interface';

@Component({
  selector: 'bee-cms-module-course-chapter-table-info',
  templateUrl: './cms-module-course-chapter-table-info.component.html',
  styleUrls: ['./cms-module-course-chapter-table-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsModuleCourseChapterTableInfoComponent implements OnInit {
  public vm$: Observable<{
    courses: CourseEntity[],
    completedEducationIds: any,
    completedCourseIds: string[]
  }>;


  constructor(private courseService: ICourseService) { }

  ngOnInit(): void {
    this.vm$ = combineLatest([
        this.courseService.getAll(true), 
        this.courseService.getMyCompletedEducationModulesWithDate(), 
        this.courseService.getCompletedCourseIds()
    ]).pipe(
      map(results => {
        return {
          courses: results[0].data,
          completedEducationIds: results[1],
          completedCourseIds: results[2]
        }
      }));
  }
}


@Component({
  selector: 'bee-cms-module-course-chapter-table-info-settings',
  templateUrl: './cms-module-course-chapter-table-info-settings.component.html',
  styleUrls: ['./cms-module-course-chapter-table-info.component.scss']
})
export class CmsModuleCourseChapterTableInfoSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}