<ng-container *ngTemplateOutlet="{'fullwidth': fullwidth, 'noscale': noscale, 'default': default}[scaling]"></ng-container>

<ng-template #default>
  <div class="landscape-image default" [style.background-image]="'url(' + (image | cdn) + ')'">
    <span role="img" [attr.aria-label]="alt"></span>
  </div>
</ng-template>

<ng-template #noscale>
  <div class="landscape-image">
    <img class="no-scale" [src]="image | cdn" [alt]="alt">
  </div>
</ng-template>

<ng-template #fullwidth>
  <div class="landscape-image fullwidth">
    <img class="fullwidth" [src]="image | cdn" [alt]="alt">
  </div>
</ng-template>

