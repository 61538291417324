import { Directive, Input, NgZone, inject } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GSDevTools } from 'gsap/GSDevTools';

@Directive({
  selector: '[beeScrollAnimation]',
  standalone: true
})
export class ScrollAnimationDirective {
  zone: NgZone = inject(NgZone);

  @Input() beeScrollAnimation: any[];

  constructor() { 
  }

  ngAfterViewInit() {
    if(this.beeScrollAnimation) {
      this.beeScrollAnimation.forEach(trigger => {
        if(trigger && trigger.isActive) {
          this.zone.runOutsideAngular(() => {
    
            gsap.registerPlugin(ScrollTrigger, GSDevTools);
    
            let timeline = gsap.timeline({
              id: trigger.trigger,
              scrollTrigger: {
                trigger: `#${trigger.trigger}`,
                start: trigger.start || "top top",
                end: trigger.end || "bottom bottom",
                scrub: trigger.scrub,
                markers: trigger.debug,
                pin: trigger.pin,                
              }
            });
    
            for(let i = 0; i < trigger.items.length; i++) {
              const item = trigger.items[i];
              var propFormatted = {};
    
              for(let j = 0; j < item.properties.length; j++) {
                propFormatted[item.properties[j].key] = item.properties[j].value;
              }
    
              let position = ">";
    
              if(propFormatted["position"]) {
                position = `${propFormatted["position"]}`;
              }
              
              if(item.function == "from") {
                timeline.addLabel(item.name).from(`#${item.name}`, propFormatted, position);
              }
              else if(item.function == "to") {
                timeline.addLabel(item.name).to(`#${item.name}`, propFormatted, position);
              }
            }
    
            if(trigger.debug) {
              GSDevTools.create({
                animation: timeline
              });
            }
          });
    
        }
      });
    }
  }

  ngOnDestroy() {
    ScrollTrigger.killAll();
  }

}
