import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { getFieldId } from '@ngx-formly/core/lib/utils';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { MediaService } from '../services/media.service';

var mediaServiceGlobal;
var mediaFileGlobal;

@Component({
  selector: 'app-cms-module-video',
  templateUrl: './cms-module-video.component.html',
  styleUrls: ['./cms-module-video.component.scss']
})
export class CmsModuleVideoComponent implements OnInit {
  public file: any = {};
  public template: string = "youtube";
  public videoStarted: boolean = false;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('videoSrc') videoSrc: ElementRef;

  constructor(public module: CMSModuleInstance, private mediaService: MediaService, private cdnPipe: CdnPipe) { 
    
  }

  youtube_parser(url: string){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  getFullYoutubteUrl() {
    return "https://www.youtube.com/embed/" + this.youtube_parser(this.module.settings.content.video.fileUrl);
  }

  startVideo() {
    if(this.videoplayer) {
      if(this.videoSrc) {
        if(this.file.sizes && this.file.sizes.length > 0) {
          //Getting HD480p
          this.videoSrc.nativeElement.src = this.cdnPipe.transform(this.file.sizes[0].fileUrl);
        }
        else {
          this.videoSrc.nativeElement.src = this.cdnPipe.transform(this.module.settings.content.video.fileUrl);
        }

        this.videoplayer.nativeElement.load();
      }

      this.videoStarted = true;
      this.videoplayer.nativeElement.play();
  
      this.mediaService.LogEvent({mediabankFileId: this.module.settings.content.video.mediabankFileId, eventType: 1}).subscribe(response => {});
  
      this.videoplayer.nativeElement.addEventListener('ended', this.videoEnd); 

      mediaServiceGlobal = this.mediaService;
      mediaFileGlobal = this.module.settings.content.video;
    }
  }

  getPoster(): string {
    if(this.module.settings.content.videoPoster && this.module.settings.content.videoPoster.imgUrl) {
      return this.module.settings.content.videoPoster.imgUrl;
    }
    else {
      return this.module.settings.content.video.thumbnail;
    }
  }

  videoEnd(event) {
    mediaServiceGlobal.LogEvent({mediabankFileId: mediaFileGlobal.mediabankFileId, eventType: 2}).subscribe(response => {});
  }

  isYoutubeUrl(url)
  {
      var regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

      return url.match(regex) != null;
  }

  ngOnInit() {
    if(this.module.settings.content.video) {
      this.module.settings.content.videoUrl = undefined;
      if(!this.module.settings.content.videoPoster) {
        this.module.settings.content.videoPoster = this.module.settings.content.video.thumbnail;
      }
    }

    if(!this.module.settings.content.video) {
      this.module.settings.content.video = {
        fileUrl: this.module.settings.content.videoUrl || "https://www.youtube.com/watch?v=pQocN_c2uLI",
        thumbnail: this.module.settings.content.videoPoster || ""
      };
      
    }

    if(this.isYoutubeUrl(this.module.settings.content.video.fileUrl)) { 
      this.template = 'youtube';
    }
    else {
      this.template = 'mediabank';
    }

    this.getFile();
  }

  getFile() {
    this.mediaService.GetFile(this.module.settings.content.video.mediabankFileId).subscribe(f => {
      this.file = f || {};

      if(this.file && this.file.relatedFiles) {
        this.file.relatedFiles.forEach(r => {
          //We only want to add vtt files as subtitles...
          if(r.fileUrl.indexOf('.vtt') > 0) {
            let track = document.createElement('track');
            track.src = this.cdnPipe.transform(r.fileUrl);
            track.setAttribute("default", "");
            track.label = r.iso2Culture;
            track.srclang = r.iso2Culture;
            track.kind = 'subtitles';
            this.videoplayer.nativeElement.appendChild(track);
          }
        });
      }
    })
  }

}

@Component({
  selector: 'app-cms-module-video-settings',
  templateUrl: './cms-module-video-settings.component.html',
  styleUrls: ['./cms-module-video.component.scss']
})
export class CmsModuleVideoSettingsComponent implements OnInit {
  public file: any = {};
  public subtitle: any;
  public useCulture: boolean = true;

  constructor(public module: CMSModuleInstance, private mediaService: MediaService) {

  }

  ngOnInit() {
    this.getFile();
  }

  getFile() {
    this.mediaService.GetFile(this.module.settings.content.video.mediabankFileId).subscribe(f => {
      this.file = f || {};
    })
  }

  addSubtitleCallback(sub: any) {
    debugger;
    if(!this.file.relatedFiles) {
      this.file.relatedFiles = [];
    }

    this.file.relatedFiles.push(sub);
  }
}
