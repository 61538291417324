import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { IUnitService } from 'src/app/container/interfaces/unit.service.interface';
import { BeeUnit } from 'src/app/domain/core/models/bee-unit';
import { BeeUser } from 'src/app/domain/core/models/bee-user';

@Injectable({
  providedIn: 'root'
})
export class UnitService implements IUnitService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  get(id: string): Observable<BeeUnit> {
    return this.http.get<BeeUnit>(this.config.apiEndpoint + "api-site/unit/get?id=" + id);
  }
  getUnitAdministrators(): Observable<BeeUser[]> {
    return this.http.get<BeeUser[]>(this.config.apiEndpoint + "api-site/unit/GetUnitAdministrators");
  }
}
