<div class="section margin-top">


  <div class="margin-top margin-bottom">

    <mat-button-toggle-group [(ngModel)]="style.display">
      <mat-button-toggle value="" aria-label="Show the module">
        <span>Show</span>
      </mat-button-toggle>
      <mat-button-toggle value="none" aria-label="Hide the module">
        <span>Hide</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Text style
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Color" name="color" [(ngModel)]="style.color">
      </mat-form-field>
    </div>

    <div class="content-item">
      <div>Text align: </div>
      <mat-button-toggle-group [(ngModel)]="style.textAlign">
        <mat-button-toggle value="left" aria-label="Text align left">
          <mat-icon>format_align_left</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="center" aria-label="Text align center">
          <mat-icon>format_align_center</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="right" aria-label="Text align right">
          <mat-icon>format_align_right</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="content-item margin-top">
      <div>Font weight: </div>
      <mat-button-toggle-group [(ngModel)]="style.fontWeight">
        <mat-button-toggle value="inherit" aria-label="Normal">
          Normal
        </mat-button-toggle>
        <mat-button-toggle value="500" aria-label="500">
          500
        </mat-button-toggle>
        <mat-button-toggle value="600" aria-label="600">
          600
        </mat-button-toggle>
        <mat-button-toggle value="bold" aria-label="Bold">
          Bold
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Font size" name="fontSize" [(ngModel)]="style.fontSize">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Line height" name="lineHeight" [(ngModel)]="style.lineHeight">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Font family" name="fontFamily" [(ngModel)]="style.fontFamily">
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</div>

<div class="section margin-top">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Element style
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Background color" name="backgroundColor" [(ngModel)]="style.backgroundColor">
      </mat-form-field>
    </div>

    <div class="content-item">
      <div>Background image</div>
      <mediabank-file-handler [(mediaFile)]="style.backgroundImage" [fileType]="1" returnType="string"></mediabank-file-handler>
    </div>

    <div class="content-item margin-top">
      <div>Background video</div>
      <mediabank-file-handler [(mediaFile)]="style.backgroundVideo" [fileType]="2" returnType="string"></mediabank-file-handler>
    </div>

    <div class="content-item margin-top">
      <div>Background position:</div>
      <mat-select placeholder="Background position" [(ngModel)]="style.backgroundPosition" name="backgroundPosition">
        <mat-option value="bottom">Bottom</mat-option>
        <mat-option value="center">Center</mat-option>
        <mat-option value="inherit">Inherit</mat-option>
        <mat-option value="left">Left</mat-option>
        <mat-option value="revert">Revert</mat-option>
        <mat-option value="right">Right</mat-option>
        <mat-option value="top">Top</mat-option>
        <mat-option value="unset">Unset</mat-option>
      </mat-select>
    </div>

    <div class="content-item margin-top">
      <div>Background size:</div>
      <mat-select placeholder="Background size" [(ngModel)]="style.backgroundSize" name="backgroundSize">
        <mat-option value="auto">Auto</mat-option>
        <mat-option value="contain">Contain</mat-option>
        <mat-option value="cover">Cover</mat-option>
        <mat-option value="left">Left</mat-option>
        <mat-option value="inherit">Inherit</mat-option>
        <mat-option value="revert">Revert</mat-option>
        <mat-option value="right">Right</mat-option>
        <mat-option value="top">Top</mat-option>
        <mat-option value="unset">Unset</mat-option>
      </mat-select>
    </div>



    <div class="content-item margin-top">
      <div>Width:</div>
      <mat-select placeholder="Width" [(ngModel)]="style.width" name="width">
        <mat-option value="100%">100%</mat-option>
        <mat-option value="75%">75%</mat-option>
        <mat-option value="50%">50%</mat-option>
        <mat-option value="25%">25%</mat-option>
        <mat-option value="67%">67%</mat-option>
        <mat-option value="33%">33%</mat-option>
        <mat-option value="auto">Auto</mat-option>
      </mat-select>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Max width" name="maxWidth" [(ngModel)]="style.maxWidth">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Height" name="height" [(ngModel)]="style.height">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Max height" name="maxHeight" [(ngModel)]="style.maxHeight">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Aspect ratio" name="aspectRatio" [(ngModel)]="style.aspectRatio">
      </mat-form-field>
    </div>

    <div class="content-item">
      <div>Justify content:</div>
      <mat-button-toggle-group [(ngModel)]="style.justifyContent">
        <mat-button-toggle value="flex-start" aria-label="Left">
          <mat-icon>format_align_left</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="center" aria-label="Center">
          <mat-icon>format_align_center</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="flex-end" aria-label="Right">
          <mat-icon>format_align_right</mat-icon>
        </mat-button-toggle>

        <mat-button-toggle value="space-between" aria-label="Space between">
          <mat-icon>space_bar</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="content-item margin-top">
      <div>Align items:</div>

      <mat-button-toggle-group [(ngModel)]="style.alignItems">
        <mat-button-toggle value="flex-start" aria-label="Top">
          <mat-icon>vertical_align_top</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="center" aria-label="Center">
          <mat-icon>vertical_align_center</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="flex-end" aria-label="Bottom">
          <mat-icon>vertical_align_bottom</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Border width" name="borderWidth" [(ngModel)]="style.borderWidth">
      </mat-form-field>
    </div>

    <div class="content-item">
      <div>Border style: </div>
      <mat-select placeholder="Border style" [(ngModel)]="style.borderStyle" name="borderStyle">
        <mat-option value="">None</mat-option>
        <mat-option value="solid">Solid</mat-option>
        <mat-option value="dotted">Dotted</mat-option>
        <mat-option value="dashed">Dashed</mat-option>
      </mat-select>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Border color" name="borderColor" [(ngModel)]="style.borderColor">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Border radius" name="borderRadius" [(ngModel)]="style.borderRadius">
      </mat-form-field>
    </div>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Border advanced
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="content-item margin-top">
        <mat-form-field>
          <input matInput placeholder="Border top color" name="borderTopColor" [(ngModel)]="style.borderTopColor">
        </mat-form-field>
      </div>
      <div class="content-item margin-top">
        <mat-form-field>
          <input matInput placeholder="Border right color" name="borderRightColor" [(ngModel)]="style.borderRightColor">
        </mat-form-field>
      </div>
      <div class="content-item margin-top">
        <mat-form-field>
          <input matInput placeholder="Border bottom color" name="borderBottomColor" [(ngModel)]="style.borderBottomColor">
        </mat-form-field>
      </div>
      <div class="content-item margin-top">
        <mat-form-field>
          <input matInput placeholder="Border left color" name="borderLeftColor" [(ngModel)]="style.borderLeftColor">
        </mat-form-field>
      </div>

    </mat-expansion-panel>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Padding top" name="paddingTop" [(ngModel)]="style.paddingTop">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Padding right" name="paddingRight" [(ngModel)]="style.paddingRight">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Padding bottom" name="paddingBottom" [(ngModel)]="style.paddingBottom">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Padding left" name="paddingLeft" [(ngModel)]="style.paddingLeft">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Margin top" name="marginTop" [(ngModel)]="style.marginTop">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Margin right" name="marginRight" [(ngModel)]="style.marginRight">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Margin bottom" name="marginBottom" [(ngModel)]="style.marginBottom">
      </mat-form-field>
    </div>

    <div class="content-item">
      <mat-form-field>
        <input matInput placeholder="Margin left" name="marginLeft" [(ngModel)]="style.marginLeft">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Box shadow" name="boxShadow" [(ngModel)]="style.boxShadow">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Clip path (ex: polygon(0 5%, 100% 0, 100% 100%, 0% 100%))" name="clipPath" [(ngModel)]="style.clipPath">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Transform" name="clipPath" [(ngModel)]="style.transform">
      </mat-form-field>
    </div>

    <div class="content-item margin-top">
      <mat-form-field>
        <input matInput placeholder="Z-index" name="zIndex" [(ngModel)]="style.zIndex">
      </mat-form-field>
    </div>

    <div class="content-item">
      <div>Position: </div>
      <mat-select placeholder="Position" [(ngModel)]="style.position" name="position">
        <mat-option value="">None</mat-option>
        <mat-option value="relative">Relative</mat-option>
        <mat-option value="absolute">Absolute</mat-option>
      </mat-select>
    </div>

    <div class="content-item margin-top">
      <div>Left</div>
      <mat-slider
        style="width: 100%;"
        class="example-margin"
        max="100"
        min="-50"
        thumbLabel="true"
        [(ngModel)]="leftValue"
        (change)="changeLeftVal($event)" 
        color="primary">
    </mat-slider>
    </div>

    <div class="content-item margin-top">
      <div>Top</div>
      <mat-slider
        style="width: 100%;"
        class="example-margin"
        max="100"
        min="-50"
        thumbLabel="true"
        [(ngModel)]="topValue"
        (change)="changeTopVal($event)"
        color="primary">
    </mat-slider>
    </div>

    <div class="content-item margin-top">
      <div>Bottom</div>
      <mat-slider
        style="width: 100%;"
        class="example-margin"
        max="100"
        min="-50"
        thumbLabel="true"
        [(ngModel)]="bottomValue"
        (change)="changeBottomVal($event)"
        color="primary">
      </mat-slider>
    </div>

    <div class="content-item margin-top">
      <div>Right</div>
      <mat-slider
        style="width: 100%;"
        class="example-margin"
        max="100"
        min="-50"
        thumbLabel="true"
        [(ngModel)]="rightValue"
        (change)="changeRightVal($event)"
        color="primary">
      </mat-slider>
    </div>

    <div class="content-item margin-top">
      <mat-checkbox (change)="changeOverflow($event)" color="primary">Hide overflowing content</mat-checkbox>
    </div>

  </mat-expansion-panel>
</div>