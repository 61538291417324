import { Component, OnInit, Input, Injector, HostListener, HostBinding } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CmsModuleInstanceDialogComponent } from 'src/app/container/dialogs/cms-module-instance-dialog/cms-module-instance-dialog.component';
import { CmsModuleInstanceDialogContainerComponent } from '../../dialogs/cms-module-instance-dialog-container/cms-module-instance-dialog-container.component';
import { DEVICE_EMULATION } from '../../cms-editor/cms-editor.component';
import { CmsEventService } from '../../cms-editor/cms-event-service';

@Component({
  selector: 'app-cms-module-wrapper',
  templateUrl: './cms-module-wrapper.component.html',
  styleUrls: ['./cms-module-wrapper.component.scss']
})
export class CmsModuleWrapperComponent implements OnInit {
  @Input()
  public moi: CMSModuleInstance;
  @Input()
  public isAdmin?: boolean;
  @Input() public hasAdminAccess?: boolean = false;

  public initialized: boolean = false;

  public extraClass: string = "";
  public deviceEmulation: string;

  @HostBinding('style.height') public height: string = "100%";

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    console.log('click', this.moi);
    if(this.moi.showDialog) {
      this.openDialog();
      return;
    }
    else if(this.moi.settings.content.scrollOption && this.moi.settings.content.scrollOption=='none'){
      return;
    }
    else if(this.moi.link && this.moi.link.length > 0) {
      if(this.moi.settings.content.linkOpenNewWindow) {
        window.open(this.moi.link);
      }
      else {
        window.location.href = this.moi.link;
      }
    }
    else if(this.moi.settings.content.scrollOption && this.moi.settings.content.scrollOption=='event:top') {
      //Ugly hack, but it works
      setTimeout(() => document.querySelector('.app-wrapper').scrollTo(0, 0), 0);
      console.log(this.moi.settings.content.scrollOption);
    }
    else if(this.moi.settings.content.scrollOption && this.moi.settings.content.scrollOption=='event:bottom') {
      setTimeout(() => document.querySelector('.app-wrapper').scrollTo(0, 90000), 0);
      console.log( this.moi.settings.content.scrollOption);
    }
    else if(this.moi.settings.content.scrollOption && this.moi.settings.content.scrollOption=='event:page') {
      setTimeout(() => document.querySelector('.app-wrapper').scrollBy(0, window.innerHeight), 0);
      console.log(this.moi.settings.content.scrollOption);
    }
  }

  openDialog() {
    let config  = new MatDialogConfig();

    config.data = this.moi.dialogData;
    config.panelClass = "slim-dialog";
    config.autoFocus = false;

    if(this.moi.dialogData.type == 'container') {
      this.dialog.open(CmsModuleInstanceDialogContainerComponent, config);
    }
    else {
      this.dialog.open(CmsModuleInstanceDialogComponent, config);
    }
  }

  constructor(public injector: Injector, public dialog: MatDialog, private eventService: CmsEventService) { }

  ngOnInit() {

    this.eventService.setDeviceEmulation.subscribe(emu => {
      this.deviceEmulation = emu;
    });

    if((this.moi.link && this.moi.link.length > 0) || this.moi.showDialog) {
      this.extraClass = "linked-moi";
      this.height = "100%";
    }

    this.moi.hasAdminAccess = this.hasAdminAccess;
    
    if(this.moi.getComponent) {
      this.moi.getComponent().then(c =>  {
          this.moi.component = c[this.moi.componentName];
          this.moi.adminComponent = c[this.moi.adminComponentName];
          this.moi.adminSettingsComponent = c[this.moi.adminSettingsComponentName];

            if(!this.moi.injectSettings) {
              this.moi.injectSettings = Injector.create(
                {
                  providers: [
                    { provide: CMSModuleInstance, useValue: this.moi, deps: [] }
                  ], 
                  parent: this.injector
                }
              );
            }

            this.initialized = true;
      });
    }
    else {
      if(!this.moi.injectSettings) {
        this.moi.injectSettings = Injector.create(
          {
            providers: [
              { provide: CMSModuleInstance, useValue: this.moi, deps: [] }
            ], 
            parent: this.injector
          }
        );
      }

      this.initialized = true;
    }
  }
}

@Component({
  selector: 'app-cms-module-settings-wrapper',
  templateUrl: './cms-module-settings-wrapper.component.html',
  styleUrls: ['./cms-module-wrapper.component.scss']
})
export class CmsModuleSettingsWrapperComponent implements OnInit {
  @Input()
  public moi: CMSModuleInstance;

  @Input() public hasAdminAccess?: boolean = false;

  constructor(public injector: Injector) { }

  ngOnInit() {
    this.moi.hasAdminAccess = this.hasAdminAccess;
    if(!this.moi.injectSettings) {
      this.moi.injectSettings = Injector.create({providers: [{provide: CMSModuleInstance, useValue: this.moi, deps: []}], parent: this.injector});
    }
  }

}
