<div class="module-slider-settings-wrapper">
  <button (click)="addModule()" mat-stroked-button>Add module</button>
  <button (click)="addCopiedModule()" mat-stroked-button *ngIf="hasCopiedMoi">Add copied module</button>

  <div class="margin-top" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-card *ngFor="let moi of module.moduleInstances | sort:'order' | filter:['isDeleted', false]" cdkDrag cdkDragBoundary=".module-segment-settings-wrapper"
      class="margin-top sm segment-moi"
      (click)="selectModule(moi)">
      <div style="display: flex; align-items: center;">
        <i class="material-icons large md-inactive margin-right sm">{{moi.icon}}</i>
        <span>{{moi.abstractName}}</span>
      </div>

      <div style="display: flex; align-items: center;">
        <i class="material-icons sm" (click)="saveCopy(moi); $event.stopPropagation();" *ngIf="moi.hasAdminAccess">save_alt</i>
        <i class="material-icons sm" (click)="copyMoi(moi); $event.stopPropagation();">file_copy</i>
        <i class="material-icons sm" (click)="deleteMoi(moi); $event.stopPropagation();">delete</i>
        <i class="material-icons large md-inactive margin-right sm drag" cdkDragHandle>drag_handle</i>
      </div>
    </mat-card>
  </div>

  <div class="margin-top margin-bottom">
    <div>
      <mat-checkbox [(ngModel)]="module.settings.content.sliderSettings.dots" color="primary">Show dots</mat-checkbox>
    </div>
    <div>
      <mat-checkbox [(ngModel)]="module.settings.content.sliderSettings.center" color="primary">Center content</mat-checkbox>
    </div>
    <div class="margin-top">
      <mat-form-field>
        <input matInput placeholder="Items per slide" name="itemsPerSlide" [(ngModel)]="module.settings.content.sliderSettings.items">
      </mat-form-field>
    </div>
    <div class="margin-top">
      <mat-form-field>
        <input matInput placeholder="Animation type" name="animateType" [(ngModel)]="module.settings.content.sliderSettings.animateType">
      </mat-form-field>
    </div>
  </div>

</div>
