import { BeeUser } from "src/app/domain/core/models/bee-user";

export class UserSessionResult {
  public verified: boolean;
  public needsUpgrade: boolean;
  public userSession: UserSession;
}

export class UserSession {
  public sessionId: string;
  public token: string;
  public currentISO2CountryCode: string;
  public user?: BeeUser;

  public siteSettings: SiteSettings;
}

export class SiteSettings {
  id: string;
  customer: string;
  startpage: string;

  defaultAppearance: string;

  favicon: string;

  siteTitle: string;
  siteDescription: string;
  siteLocale: string;
  siteImage: string;

  siteStyle: SiteStyle = new SiteStyle();
  siteCultures: SiteCulture[] = [new SiteCulture()];

  gaId: string = "UA-172992143-1";
  gaEvents: GaEvent[];
  facebookPixelId: string;

  useSiteLoader: boolean;

  hasCultureOverrides: boolean = false;

  portalSettings: PortalSettings;

  mediabankSiteSettings: MediabankSiteSettings = new MediabankSiteSettings();
  blogSiteSettings: BlogSiteSettings = new BlogSiteSettings();
  genericContainerSiteSettings: GenericContainerSiteSettings = new GenericContainerSiteSettings();
  academySettings: AcademySettings = new AcademySettings();
  notificationsSettings: NotificationSettings = new NotificationSettings();
  loginSettings: LoginSettings = new LoginSettings();
  iconOverrides: any;

  baseUrl: string;
  notLoggedInRoleId: string;

  manualUrl: string;
  cdnServer: string;
  showScrollToTop: boolean = false;

  constructor(data?: any) {
    if(!data) data = {};

    this.id = data.id || "";
    this.startpage = data.startpage || "";
    this.favicon = data.favicon;
    this.siteTitle = data.siteTitle || "";
    this.siteDescription = data.siteDescription || "";
    this.siteLocale = data.siteLocale || "sv";
    this.siteImage = data.siteImage || "";
    this.siteStyle = data.siteStyle || new SiteStyle();
    this.siteCultures = data.siteCultures || [new SiteCulture()];
    this.gaId = data.gaId || "";
    this.facebookPixelId = data.facebookPixelId;
    this.useSiteLoader = data.useSiteLoader;
    this.hasCultureOverrides = data.hasCultureOverrides;
    this.portalSettings = data.portalSettings || {};
    this.mediabankSiteSettings = data.mediabankSiteSettings || new MediabankSiteSettings();
    this.blogSiteSettings = data.blogSiteSettings || new BlogSiteSettings();
    this.genericContainerSiteSettings = data.genericContainerSiteSettings || new GenericContainerSiteSettings();
    this.iconOverrides = data.iconOverrides;
    this.defaultAppearance = data.defaultAppearance || '';
    this.baseUrl = data.baseUrl || window.location.origin;
    this.notLoggedInRoleId = data.notLoggedInRoleId || '';
    this.manualUrl = data.manualUrl || '';
    this.cdnServer = data.cdnServer || '';
    this.academySettings = data.academySettings || new AcademySettings();
    this.notificationsSettings = data.notificationsSettings || new NotificationSettings();
    this.loginSettings = data.loginSettings || new LoginSettings();
    this.showScrollToTop = data.showScrollToTop;
  }
}

export class LoginSettings {
  isSiteLockedByLogin: boolean;
  loginBackgroundImage: string;
  showChangePassword: boolean;
  showForgotPassword: boolean;
  gdprConsentLink: string;
  redirectAdminToStartpage: boolean;
  loginTitle: string;
  theme: string;
}

export class NotificationSettings {
  isEmailNotificationsActivated: boolean = false;
  isSMSNotificationActivated: boolean = false;
}

export class AcademySettings {
  javascriptsToLoad: string[] = [];
}
export class BlogSiteSettings {
  showRelatedBlogs: boolean = false;
  showTags: boolean = false;
  showSocialShare: ModuleSiteSettings = new ModuleSiteSettings();
  showPublishedDate: ModuleSiteSettings = new ModuleSiteSettings();
  showPublisher: ModuleSiteSettings = new ModuleSiteSettings();
  showAllBlogs: ModuleSiteSettings = new ModuleSiteSettings();
  showTagCloud: ModuleSiteSettings = new ModuleSiteSettings();
}

export class MediabankSiteSettings {
  playIconUrl: string;
  showPublishedDate: ModuleSiteSettings = new ModuleSiteSettings();
  showSocialShare: ModuleSiteSettings = new ModuleSiteSettings();
  showPublisher: ModuleSiteSettings = new ModuleSiteSettings();
  showAllFilesOfSameType: ModuleSiteSettings = new ModuleSiteSettings();
  showRelatedFiles: ModuleSiteSettings = new ModuleSiteSettings();
  showLatestFiles: ModuleSiteSettings = new ModuleSiteSettings();
  showMostPopularFiles: ModuleSiteSettings = new ModuleSiteSettings();
  showTags: ModuleSiteSettings = new ModuleSiteSettings();
  showTagCloud: ModuleSiteSettings = new ModuleSiteSettings();
  showTranslatedVideos: ModuleSiteSettings = new ModuleSiteSettings();

  isYoutubePublishingEnabled: boolean = false
  youtubeClientId: string = "";
  youtubeClientSecret: string = "";
}

export class GenericContainerSiteSettings {
  showTags: ModuleSiteSettings = new ModuleSiteSettings();
  showSocialShare: ModuleSiteSettings = new ModuleSiteSettings();
  showPublishedDate: ModuleSiteSettings = new ModuleSiteSettings();
  showPublisher: ModuleSiteSettings = new ModuleSiteSettings();
  showTagCloud: ModuleSiteSettings = new ModuleSiteSettings();
}

export class ModuleSiteSettings {
  show: boolean = false;
  // 0 = top, 1 = bottom
  position: 0 | 1;
  wrapperWidth: string = "800px";
  textAlign: string = "left";
  template: string = "default";
}

export class GaEvent {
  allowCustomScripts: boolean;
  sendTo: string;
}

export class SiteCulture {
  id: string;
  name: string;
  flagIcon: string;
  iso2: string;
  iso4: string;
  isActive: boolean;
  phonePrefix: string;
  direction: string;
  shortName: string;

  constructor(data?: any) {
    if(!data) data = {};
    
    this.id = data.id || "";
    this.name = data.name || "Svenska";
    this.flagIcon = data.flagIcon;
    this.iso2 = data.iso2 || "sv";
    this.iso4 = data.iso4 || "sv-SE";
    if(data.isActive !== undefined) this.isActive = data.isActive;
    else this.isActive = true;
    this.phonePrefix = data.phonePrefix;
    this.direction = data.direction || "ltr";
    this.shortName = data.shortName || data.iso2 || '';
  }
}

export class PortalSettings {
  id: string;
  portalName: string;
  customerName: string;
  isPortalActive: boolean;

  isUnitsEnabled: boolean;
  isCMSModuleEnabled: boolean;
  isMediabankEnabled: boolean;
  isBlogEnabled: boolean;
  isFormsEnabled: boolean;
  isAcademyEnabled: boolean;
  isCalendarEnabled: boolean;
  isServiceworkerEnabled: boolean;
  isWebsocketsEnabled: boolean;
  isCommentsEnabled: boolean;
  isVerificationFlowEnabled: boolean;
}

export class SiteStyle {
  primaryColor: string = "#009FD6";
  primaryColorHover: string = "#0C7BAD";
  secondaryColor: string = "#0C7BAD";
  secondaryColorHover: string = "#0C7BAD";
  black: string = "black";
  white: string = "white";
  lightGrey: string = "#E9E9E9";
  lightGreyBg: string = "#f0f0f0";
  greyHover: string = "#E1E1E1";
  greyLine: string = "#A6A6A6";
  darkGreyLine: string = "#979797";
  darkGrey: string = "#3c3c3c";
  linkColor: string = "#008DA2";
  linkColorHover: string = "#001E24";

  primaryTextColor: string = "#3c3c3c";
  primaryTextInfoColor: string = "#757575";
  primaryFontFamily: string = 'Roboto, "Helvetica Neue", sans-serif';

  secondaryTextColor: string = "#000000";
  secondaryTextInfoColor: string = "#000000";
  secondaryFontFamily: string = 'Roboto, "Helvetica Neue", sans-serif';

  contentWidth: string = "1280px";

  mobileBreakpoint: string = "767px";
}

export function getStyleVariables(style: SiteStyle): string {
  return `
    --primary-color: ${style.primaryColor || "#009FD6"};
    --primary-color-hover: ${style.primaryColorHover || "#0C7BAD"};
    --secondary-color: ${style.secondaryColor || "#0C7BAD"};
    --secondary-color-hover: ${style.secondaryColorHover || "#0C7BAD"};
    --black: ${style.black || "#000000"};
    --white: ${style.white || "#FFFFFF"};
    --light-grey: ${style.lightGrey || "#E9E9E9"};
    --light-grey-bg: ${style.lightGreyBg || "#f0f0f0"};
    --grey-hover: ${style.greyHover || "#E1E1E1"};
    --dark-grey-line: ${style.darkGreyLine || "#979797"};
    --dark-grey: ${style.darkGrey || "#3c3c3c"};
    --link-color: ${style.linkColor} || "#008DA2";
    --link-color-hover: ${style.linkColorHover} || "#001E24";

    --primary-text-color: ${style.primaryTextColor || "#3c3c3c"};
    --primary-text-info-color: ${style.primaryTextInfoColor || "#757575"};
    --primary-font-family: ${style.primaryFontFamily || 'Roboto, "Helvetica Neue", sans-serif'};
  `;
}
