<div class="module-segment-settings-wrapper" *ngIf="module">
  <button *ngIf="module.hasAdminAccess" (click)="addModule()" mat-stroked-button>Add module</button>
  <button (click)="addCopiedModule()" mat-stroked-button *ngIf="module.hasAdminAccess && hasCopiedMoi">Add copied module</button>

  <div class="margin-top" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-card *ngFor="let moi of module.moduleInstances | sort:'order' | filter:['isDeleted', false]" [cdkDragDisabled]="!module.hasAdminAccess" cdkDrag cdkDragBoundary=".module-segment-settings-wrapper"
      class="margin-top sm segment-moi"
      (click)="selectModule(moi)">
      <div style="display: flex; align-items: center;">
        <i class="material-icons large md-inactive margin-right sm">{{moi.icon}}</i>
        <span>{{moi.abstractName}} {{getName(moi)}}</span> 
      </div>

      <div style="display: flex; align-items: center;" *ngIf="module.hasAdminAccess">
        <i class="material-icons sm" (click)="saveCopy(moi); $event.stopPropagation();">save_alt</i>
        <i class="material-icons sm" (click)="copyMoi(moi); $event.stopPropagation();">file_copy</i>
        <i class="material-icons sm" (click)="deleteMoi(moi); $event.stopPropagation();">delete</i>
        <i class="material-icons large md-inactive margin-right sm drag" cdkDragHandle>drag_handle</i>
      </div>
    </mat-card>
  </div>

  <div class="margin-top" *ngIf="module.hasAdminAccess">
    <mat-checkbox color="primary" [(ngModel)]="module.settings.content.reverseContent">Reverse content on mobile</mat-checkbox>
  </div>
</div>
