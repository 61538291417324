<div mat-dialog-content>
  <div class="content-wrapper-header">
    <h1 mat-dialog-title>Select module to add</h1>

    <h1 mat-dialog-title>Select module template to add</h1>
  </div>

  <div class="content-wrapper">
    <div class="add-dialog-content">
      <mat-card *ngFor="let moduleAbstract of moduleAbstracts" (click)="addModuleInstance(moduleAbstract)">
        <i class="material-icons large md-inactive">{{moduleAbstract.icon}}</i>
        <p>{{moduleAbstract.abstractName}}</p>
      </mat-card>
    </div>

    <div class="add-dialog-content" *ngIf="moduleInstanceTemplates.length">
      <mat-card *ngFor="let template of moduleInstanceTemplates" (click)="addModuleInstanceTemplate(template)">
        <i class="material-icons large md-inactive">{{template.icon}}</i>
        <p>{{template.name}}</p>
      </mat-card>
    </div>
  </div>

  <div class="margin-top margin-bottom lg"> 
    <button (click)="close()" class="margin-right" mat-stroked-button>Cancel</button> 
  </div>
</div>
