<ng-container *ngTemplateOutlet="{'add': add, 'default': default}[data.type]"></ng-container>

<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #default>
  <h1 mat-dialog-title>Select mediabank file to use or upload a new one</h1>

  <div class="margin-bottom">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
      (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        Drop file to upload...
      </ng-template>
    </ngx-file-drop>
  </div>

  <div class="margin-top">
    <mat-form-field>
      <input matInput placeholder="Search" name="searchTerm" [(ngModel)]="searchTerm">
    </mat-form-field>
  </div>

  <div mat-dialog-content>
    <div class="file-handler-dialog padding-bottom" *ngIf="!isLoading; else loading">
      <mat-card *ngFor="let file of mediabankFiles$ | async | searchFilter:[searchTerm]" [ngClass]="{'selected': selectedFile?.id == file.id}" (click)="selectFile(file);" class="file-card"> 
        <img [src]="(file.thumbnail || file.fileUrl) | cdn">
        <span class="file-name" [innerHtml]="file.name"></span>
      </mat-card>
    </div>
  </div>

  <div class="actions">

    <div>
      <mat-paginator 
        (page)="pageChange($event)"
        [length]="totalCount"
        [pageSize]="pageSize"
        [pageSizeOptions]="[40, 100, 200]">
      </mat-paginator>
    </div>

    <span class="bee-button" (click)="close();">Cancel</span>
    <span class="bee-button" [ngClass]="{'disabled': selectedFile.id <= 0}" (click)="ok();">Ok</span>
  </div>
</ng-template>

<ng-template #add>
  <h1 mat-dialog-title>Upload a file to be related.</h1>

  <div class="margin-bottom" [hidden]="!data.useCultures">
    <bee-culture-select (onChange)="changeCulture($event)"></bee-culture-select>
  </div>

  <div class="margin-bottom" [hidden]="!selectedCulture && data.useCultures">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
      (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        Drop file to upload... 
        <span *ngIf="(mediabankService.uploadProgressAsNumber | async) > 0">
          {{mediabankService.uploadProgressAsNumber | async}}%
        </span>
      </ng-template>
    </ngx-file-drop>
  </div>

  <div class="actions">
    <span class="bee-button" (click)="close();">Cancel</span>
    <span class="bee-button" [ngClass]="{'disabled': selectedFile.id <= 0}" (click)="ok();">Ok</span>
  </div>
</ng-template>
