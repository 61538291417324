<ng-template #loading>
  <bee-loader></bee-loader>
</ng-template>

<div class="bee-media-view-wrapper" *ngIf="_reload && mediaFile">

  <bee-tag-cloud 
    *ngIf="mediabankSiteSettings.showTagCloud && mediabankSiteSettings.showTagCloud.show == true && mediabankSiteSettings.showTagCloud.template == 'fab'" 
    (tagSelect)="selectTag($event)">
  </bee-tag-cloud>

  <div class="video-wrapper" *ngIf="mediaFile.type == 2">
    <bee-video [video]="mediaFile" 
      (selectedVideoCallback)="changeSelectedVideo($event)"
      (callback)="videoCallback($event)">
    </bee-video>
  </div>

  <div class="media-file-wrapper" *ngIf="mediaFile.type == 9">
    <iframe width="1080" height="607" 
      [src]="getFullYoutubteUrl() | safe" 
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>
  </div>

  <div *ngIf="mediaFile.type == 3" class="media-file-wrapper-audio">
    <img [src]="mediaFile.thumbnail | cdn" class="audio-img">

    <audio controls>
      <source [src]="mediaFile.fileUrl | cdn" type="audio/mp3">
    </audio>
  </div>

  <div class="inner-content">
    <div class="mediafile-info-wrapper">
      <div [attr.dir]="getSelectedCultureDir()" class="mediafile-info" [ngClass]="{'read-more': readMore}" [class.fullWidth]="!mediabankSiteSettings.showSocialShare.show || mediabankSiteSettings.showSocialShare.position != 0">

        <span class="info published" *ngIf="mediabankSiteSettings.showPublishedDate.show && mediabankSiteSettings.showPublishedDate.position == 0">
          <span>Publicerad: </span><span [innerHtml]="mediaFile.createdDateUtc | date"></span>
        </span>

        <p class="info" *ngIf="mediabankSiteSettings.showPublisher.show && mediabankSiteSettings.showPublisher.position == 0">
          <span class="created-by" [innerHtml]="mediaFile.createdByUserFullName"></span>
        </p>

        <h1 class="mediafile-header margin-bottom" [innerHtml]="selectedMediaFile.name"></h1>
        <div [innerHtml]="selectedMediaFile.description"></div>
  
        <!-- <span class="read-more-button">
          <bee-animated-icon
            start="keyboard_arrow_down" 
            end="keyboard_arrow_left" 
            [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
            [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
            [(animate)]="readMore"></bee-animated-icon>
  
            <span *ngIf="!readMore" (click)="readMore = !readMore">Läs mer</span>
            <span *ngIf="readMore" (click)="readMore = !readMore">Läs mindre</span>
        </span> -->
  
        <div *ngIf="mediabankSiteSettings.showTags.show">
          <span class="media-type-indicator" [ngClass]="getTypeName(mediaFile.type)" [innerHtml]="getTypeName(mediaFile.type)"></span>
          <bee-item-tags [tags]="mediaFile.tags" (tagSelect)="selectTag($event)"></bee-item-tags>
        </div>

        <p class="info" *ngIf="mediabankSiteSettings.showPublisher.show && mediabankSiteSettings.showPublisher.position == 1">
          <span class="created-by" [innerHtml]="mediaFile.createdByUserFullName"></span>
        </p>

        <span class="info published" *ngIf="mediabankSiteSettings.showPublishedDate.show && mediabankSiteSettings.showPublishedDate.position == 1">
          <span>Publicerad: </span><span [innerHtml]="mediaFile.createdDateUtc | date"></span>
        </span>
  
        <div *ngIf="mediabankSiteSettings.showSocialShare.show">
          <bee-social-share-default></bee-social-share-default>
        </div>
      </div>
  
      <div class="mediafile-share-wrapper" *ngIf="mediabankSiteSettings.showSocialShare.show && mediabankSiteSettings.showSocialShare.position == 0">
        <bee-share title="{{mediaFile.name}}"></bee-share>
      </div>
    </div>

    <ng-container *ngIf="mediaFile.type == 4">
      <iframe *ngIf="mediaFile.sizeInMb < 25; else embedPdf" class="margin-top lg a4-ratio" [src]="getEmbedUrl() | safe" style="width: 800px;"></iframe>

      
      <ng-template #embedPdf>
        <embed #pdfObject width="800px" height="1024px" 
          type="application/pdf">

      </ng-template>
    </ng-container>

    <ng-container *ngIf="mediaFile.type == 5">
      <iframe *ngIf="mediaFile.sizeInMb < 25; else embedWord" class="margin-top lg a4-ratio" [src]="getEmbedUrlWord() | safe" style="width: 800px;"></iframe>

      <ng-template #embedWord>
        <object
            #pdfObject
            type="application/word"
            width="100%"
            height="1024"
        >
        </object>
      </ng-template>
    </ng-container>
  
    <div class="mediafile-related-wrapper" *ngIf="mediabankSiteSettings.showRelatedFiles.show">
      <h3 class="hide-mobile">Relaterat</h3>
      <h1 class="hide-desktop">Relaterat</h1>
  
      <div class="mediafile-related-items">
        <div class="mediafile-related-item" *ngFor="let related of relatedFiles | filter:['type', 2] | limitTo:limit">
  
          <a class="related-image-wrapper" [routerLink]="['/modules/media/', related.urlRewrite]">
            <img [src]="related.thumbnail | cdn" [alt]="related.name">
            <div class="play-overlay">
              <!-- <i class="material-icons">play_arrow</i> -->
              <bee-play-icon></bee-play-icon>
            </div>
          </a>
  
          <div class="related-info">
            <h4 [innerHtml]="related.name"></h4>
            <p [innerHtml]="related.description" class="hide-mobile"></p>
            <div>
              <span>
                <span class="media-type-indicator" [ngClass]="getTypeName(related.type)" [innerHtml]="getTypeName(related.type)"></span>
                <bee-item-tags [tags]="related.tags" (tagSelect)="selectTag($event)"></bee-item-tags>
              </span>
            </div>
  
          </div>
  
          <div class="related-share"> 
            <bee-share title="{{related.name}}" url="{{getFullUrl(related)}}"></bee-share>
          </div>
        </div>
      </div>
  
      <div *ngIf="relatedFiles.length > BASE_SHOW_LIMIT" class="related-read-more-wrapper" (click)="toggleShowAll(); $event.stopPropagation();">
        <div class="related-read-more">
          <bee-animated-icon
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'"  
          [(animate)]="showingAll"></bee-animated-icon>
  
          <span>Läs mer</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mediafile-see-more-outer-wrapper" *ngIf="mediabankSiteSettings.showLatestFiles.show || mediabankSiteSettings.showMostPopularFiles.show">
  <div class="mediafile-see-more-wrapper">
    
    <h2>Se även</h2>

    <div class="media-list" *ngIf="mediabankSiteSettings.showLatestFiles.show">
      <div class="header">
        <bee-animated-icon class="md-36"
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'"  
          [(animate)]="latestExpanded" (click)="toggleLatestLimit(); $event.stopPropagation();"></bee-animated-icon> 
        <h3 (click)="toggleLatestLimit()" class="hide-mobile">Senaste</h3>
        <h2 (click)="toggleLatestLimit()" class="hide-desktop mobile-medium">Senaste</h2>
      </div>
  
      <div class="media-list-item" *ngFor="let media of mediaItemsLatest | limitTo:latestLimit" (click)="goToMedia(media)">
        <div class="media-list-image">
          <img [src]="getImage(media) | cdn" [alt]="media.name">
          <div class="play-overlay">
            <!-- <i class="material-icons">play_arrow</i> -->
            <bee-play-icon></bee-play-icon>
          </div>
        </div>
        <div class="media-info">
          <h6 [innerHtml]="media.name" class="margin-top"></h6>
          <span class="media-type-indicator" [ngClass]="getTypeName(media.type)" [innerHtml]="getTypeName(media.type)"></span>
        </div>
      </div>
    </div>
  
    <div class="media-list" *ngIf="mediabankSiteSettings.showMostPopularFiles.show">
      <div class="header">
        <bee-animated-icon class="md-36"
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'"  
          [(animate)]="mostViewedExpanded" (click)="toggleMostViewedLimit(); $event.stopPropagation();"></bee-animated-icon> 
        <h3 (click)="toggleMostViewedLimit()" class="hide-mobile">Mest populära</h3>
        <h2 (click)="toggleMostViewedLimit()" class="hide-desktop mobile-medium">Mest populära</h2> 
      </div>
  
      <div class="media-list-item" *ngFor="let media of mediaItemsMostViewed | limitTo:mostViewedtLimit" (click)="goToMedia(media)">
        <div class="media-list-image">
          <img [src]="getImage(media) | cdn" [alt]="media.name">
          <div class="play-overlay">
            <!-- <i class="material-icons">play_arrow</i> -->
            <bee-play-icon></bee-play-icon>
          </div>
        </div>
        <div class="media-info">
          <h6 [innerHtml]="media.name" class="margin-top"></h6>
          <span class="media-type-indicator" [ngClass]="getTypeName(media.type)" [innerHtml]="getTypeName(media.type)"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="media-more-outer-wrapper" *ngIf="!isLoading; else loading">

  <div class="module-divider"></div>

  <div class="media-more-inner-wrapper" *ngIf="mediabankSiteSettings.showAllFilesOfSameType.show">

    <bee-media-list-default [files]="mediaItemsLatest"></bee-media-list-default>

  </div>

</div>