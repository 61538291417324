import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { PasswordChangeService } from '../../services/password-change.service';

@Component({
  selector: 'bee-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {
  public showUsername: boolean = true;
  public resetPasswordFormGroup: UntypedFormGroup;

  public username: string;
  public currentPassword: string;
  public newPassword: string;
  public repeatNewPassword: string;

  public errorMessage: string;
  public error: boolean = false;

  constructor(private dialogRef: MatDialogRef<ResetPasswordDialogComponent>, 
    private passwordChangeService: PasswordChangeService,
    private authService: AuthenticationService) { 

      this.errorMessage = "Du måste fylla i alla fält.";
  }

  ngOnInit(): void {
    if(this.authService.currentUserValue.user && this.authService.currentUserValue.user.id != "-1" && this.authService.currentUserValue.user.id.length > 0) {
      this.showUsername = false;
    }
  }

  updatePassword(form: NgForm): void {
    if(form.valid) {
      if(this.newPassword != this.repeatNewPassword) {
        this.errorMessage = "Lösenorden matchar inte.";
        this.error = true;
        return;
      }

      this.passwordChangeService.updatepassword(this.currentPassword, this.newPassword, this.username).subscribe(response => {
        if(response.isSuccessful) {
          this.close();
        }
        else {
          this.error = true;
          this.errorMessage = response.errorMessage;
        }
      });

    }
    else {
      this.error = true;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
