import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { BeeGenericComment } from '../models/generic-comment.model';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';

@Injectable({
  providedIn: 'root'
})
export class GenericCommentService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  GetCommentsByObject(object_type: string, object_id: string): Observable<PaginationReturnModel<BeeGenericComment[]>> {
    return this.http.get<PaginationReturnModel<BeeGenericComment[]>>(this.config.getApiEndpoint() + 
      `api-social/genericcomment/getcommentsbyobject?object_type=${object_type}&object_id=${object_id}`);
  }

  GetCommentsByObjectAndReferer(object_type: string, object_id: string, referer_type: string, referer_id: string): Observable<PaginationReturnModel<BeeGenericComment[]>> {
    return this.http.get<PaginationReturnModel<BeeGenericComment[]>>(this.config.getApiEndpoint() + 
      `api-social/genericcomment/getcommentsbyobjectandreferer?object_type=${object_type}&object_id=${object_id}&referer_type=${referer_type}&referer_id=${referer_id}`);
  }

  GetCommentById(id: string): Observable<BeeGenericComment> {
    return this.http.get<BeeGenericComment>(this.config.getApiEndpoint() + `api-social/genericcomment/getcommentbyid?id=${id}`);
  }

  Save(data: any): Observable<SaveReturnModel<BeeGenericComment>> {
    return this.http.post<SaveReturnModel<BeeGenericComment>>(this.config.getApiEndpoint() + "api-social/genericcomment/save", data);
  }
}
