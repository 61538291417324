<div class="page-menu-settings-wrapper settings-wrapper">

  <mat-form-field class="margin-top">
    <mat-label>Select Template</mat-label>
    <mat-select [(value)]="moi.settings.content.template">
      <mat-option value="DEFAULT">Default</mat-option>
      <mat-option value="TABS">Tabs</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="margin-top" *ngIf="moi.settings.content.template == 'TABS'">
    <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.showBottomBorder">
      Show bottom border
    </mat-checkbox>
  </div>


  <div class="margin-top">
    <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.ignoreScroll">
      Don't scroll to top on navigation
    </mat-checkbox>
  </div>

  <div class="margin-top">
    <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.firstItemAsMaster">
      Show first item above the others
    </mat-checkbox>
  </div>

  <div class="margin-top">
    <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.showLines">
      Show lines between menu items
    </mat-checkbox>
  </div>

  <div class="margin-bottom margin-top">
    <button (click)="addMenuItem();" mat-raised-button color="primary">
      Add menu item
      <i class="material-icons">add</i>
    </button>
  </div>

  <div class="margin-top" cdkDropList (cdkDropListDropped)="drop($event)" class="menu-item-wrapper">
    <mat-expansion-panel *ngFor="let menuItem of moi.settings.content.menuItems | sort:'order'" 
      class="margin-bottom"
      cdkDrag cdkDragBoundary=".menu-item-wrapper">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{menuItem.text}}
        </mat-panel-title>
        <mat-panel-description>
          Edit menu item
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-form-field>
        <input matInput placeholder="Text" name="text" [(ngModel)]="menuItem.text">
      </mat-form-field>
  
      <mat-form-field>
        <input matInput placeholder="Url" name="url" [(ngModel)]="menuItem.url">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Selected color" name="selectedColor" [(ngModel)]="menuItem.selectedColor">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Selected by tag" name="Selected by tag" [(ngModel)]="menuItem.selectedByTag">
      </mat-form-field>

      <mat-checkbox class="margin-top" color="primary" [(ngModel)]="menuItem.isDefaultSelected">Is default selected</mat-checkbox>
  
      <mediabank-file-handler [(mediaFile)]="menuItem.imgUrl" returnType="string"></mediabank-file-handler>
    </mat-expansion-panel>
  </div>
</div>