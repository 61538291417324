import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';

@Component({
  selector: 'app-cms-module-image',
  templateUrl: './cms-module-image.component.html',
  styleUrls: ['./cms-module-image.component.scss']
})
export class CmsModuleImageComponent implements OnInit {
  constructor(public module: CMSModuleInstance) { }

  ngOnInit() {
    if(!this.module.settings.content.image) {
      this.module.settings.content.image = {
        imgUrl: "https://images.svd.se/v2/images/e633684c-a5f5-4978-adfd-4ae13ac987ac?fit=crop&h=427&q=80&upscale=true&w=640&s=76fced92793de83a9659d585b445174f24bef0e3",
        mediabankFileId: -1
      };
    }

    if(!this.module.settings.content.hoverImage) {
      this.module.settings.content.hoverImage = {
        imgUrl: "",
        mediabankFileId: -1
      };
    }

    if(!this.module.settings.content.imageScaling) {
      this.module.settings.content.imageScaling = "noscale";
    }

    // if(!this.module.settings.content.altText) {
    //   this.module.settings.content.altText = "";
    // }
  }
}


@Component({
  selector: 'app-cms-module-image-settings',
  templateUrl: './cms-module-image-settings.component.html',
  styleUrls: ['./cms-module-image.component.scss']
})
export class CmsModuleImageSettingsComponent implements OnInit {

  constructor(public module: CMSModuleInstance) { }

  ngOnInit() {
    
  }

}

