import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CourseEntity } from "src/app/admin/academy/models/course-entity";
import { CourseLogEntity } from "src/app/domain/academy/models/course-log-entity";
import { CourseStatusType } from "src/app/domain/academy/models/course-status-type";
import { PaginationReturnModel } from "src/app/domain/core/help-models/pagination-return-model";

@Injectable()
export abstract class ICourseLogService {
  abstract getMyCoursesWithStatus(status: CourseStatusType): Observable<CourseLogEntity[]>;
}
