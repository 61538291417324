import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-bee-form-textarea',
  templateUrl: './bee-form-textarea.component.html',
  styleUrls: ['./bee-form-textarea.component.scss']
})
export class BeeFormTextareaComponent extends FieldType implements OnInit {

  @HostBinding("attr.style")
  public get variablesAsStyle(): any {
    return `
      ${this.field.templateOptions?.extraData?.styles?.backgroundColor? '--background-color:' + this.field.templateOptions?.extraData?.styles?.backgroundColor + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.flexDirection? '--flex-direction:' + this.field.templateOptions?.extraData?.styles?.flexDirection + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.display? '--display:' + this.field.templateOptions?.extraData?.styles?.display + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.gridTemplateColumns? '--grid-template-columns:' + this.field.templateOptions?.extraData?.styles?.gridTemplateColumns + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.gridTemplateRows? '--grid-template-rows:' + this.field.templateOptions?.extraData?.styles?.gridTemplateRows + ';' : ''}
    `
  }

  constructor() { 
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}
