import { Observable } from 'rxjs';
import { BeeSearchOverlayComponent } from '../bee-search-overlay/bee-search-overlay.component';
import { Component, OnInit, ComponentRef, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { DomService } from'../../helpers/dom-service/dom.service';

@Component({
  selector: 'bee-search',
  templateUrl: './bee-search.component.html',
  styleUrls: ['./bee-search.component.scss']
})
export class BeeSearchComponent implements OnInit {

  @Input() settings: any;

  componentRef: ComponentRef<BeeSearchOverlayComponent>;

  constructor(public router: Router, public domService: DomService) { }
  ngOnInit() {
    console.log('SEARCH SETTINGS', this.settings);
  }

  public visible:boolean = false;

  showSearch() {
    //console.log('clicked')
    if(this.visible === false){
      this.componentRef = this.domService.appendComponentToBody(BeeSearchOverlayComponent);

      //We subscribe to the close event to change the visible variable..
      this.componentRef.instance.close.subscribe(() => {
        this.visible = false;
      });

      this.visible = true;
    }
    else{
      this.componentRef.destroy();
      this.visible = false;
    }
  }
}
