<a class="left-content" [routerLink]="['/']">
  <img src="/assets/images/customers/thermoeyes/header-logo.png" class="header-logo" alt="">

  <h2>ThermoEyes</h2>
</a>

<div class="right-content">

  <a *ngFor="let menuItem of menuItems" class="menu-item" [routerLink]="[menuItem.url || '/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
    {{menuItem.text}}
  </a>

  <bee-culture-picker></bee-culture-picker>

  <bee-thermoeyes-menu class="thermoeyes-menu" [menuItems]="menuItems"></bee-thermoeyes-menu>
</div>