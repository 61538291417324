import { Component, OnInit, Inject } from '@angular/core';
import { CMSModuleInstance, CMSModuleAbstract, CMSContainer, ModuleInstanceTemplate } from 'src/app/container/cms-models';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { IContainerService } from '../../interfaces/container.service.interface';
import { CmsModuleService } from '../../cms-services/cms-module-service.service';

@Component({
  selector: 'bee-add-module-dialog',
  templateUrl: './add-module-dialog.component.html',
  styleUrls: ['./add-module-dialog.component.scss']
})
export class AddModuleDialogComponent implements OnInit {
  moduleAbstracts: Array<CMSModuleAbstract>;
  moduleInstanceTemplates: ModuleInstanceTemplate[];

  constructor(public dialogRef: MatDialogRef<AddModuleDialogComponent>, private containerService: IContainerService, private cmsModuleService: CmsModuleService) { 
    this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
    this.moduleInstanceTemplates = [];
  }

  ngOnInit() {
    this.getModuleInstanceTemplates();
  }

  getModuleInstanceTemplates() {
    this.containerService.getModuleInstanceTemplates().subscribe((templates: ModuleInstanceTemplate[]) => {
      this.moduleInstanceTemplates = templates;
    });
  }

  close(moi?: CMSModuleInstance): void {
    this.dialogRef.close(moi);
  }

  addModuleInstance(moduleAbstract: CMSModuleAbstract) {
    moduleAbstract = new CMSModuleAbstract(moduleAbstract);
    debugger;
    var moi = moduleAbstract.createModuleInstance();
    this.close(moi);
  }

  addModuleInstanceTemplate(template: ModuleInstanceTemplate) {
    this.close(new CMSModuleInstance(template.moduleInstance));
  }
}
