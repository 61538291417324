import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-submission-dialog',
  templateUrl: './submission-dialog.component.html',
  styleUrls: ['./submission-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubmissionDialogComponent implements OnInit {
  text: string = "Tack! Nu är svaren inskickade.";

  constructor(public dialogRef: MatDialogRef<SubmissionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if(this.data && this.data.text) {
      this.text = this.data.text;
    }
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
}
