<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner *ngIf="loading"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<bee-course-list *ngIf="vm$ | async as vm else loading" 
  [courses]="vm.courses" 
  [completedItems]="vm.completedEducationModules"
  [courseStatuses]="vm.courseStatuses"
  [settings]="moi.settings.content.courseListSettings">
</bee-course-list>