import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'bee-object-culture-picker',
  templateUrl: './bee-object-culture-picker.component.html',
  styleUrls: ['./bee-object-culture-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeObjectCulturePickerComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() mainObject: any;
  @Input() objects: any[] = [];
  @Input() selectedObject: any;

  constructor(public authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  public selectObject(iso2: string): void {
    const o = this.objects.find(o => o.iso2Culture === iso2) ?? this.mainObject;
    this.selectedObject = o;
    this.onChange.emit(o);
  }

}
