import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ScormService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * GetReplyGuid
educationId: string   */
  public GetReplyGuid(educationId: string): Observable<any> {
    return this.http.get(this.config.apiEndpoint + "api-academy/scorm/GetReplyGuid?educationId=" + educationId);
  }
}
