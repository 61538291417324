import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthenticationService } from './authorize/services/authentication.service';
import { FaviconService } from './favicon.service';
import { SiteStyle } from './authorize/models/user-session';
import { CdnPipe } from './bee-pipes/cdn/cdn.pipe';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import 'dayjs/locale/sv';

// require('dayjs/locale/sv');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  displayToken: string;
  private elementRef = Inject(ElementRef);

  constructor(private titleService: Title, private metaService: Meta,
    private router: Router,
    private authService: AuthenticationService,
    private faviconService: FaviconService,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private cdnPipe: CdnPipe,
    public translate: TranslateService,
    private gtmService: GoogleTagManagerService) {

    translate.addLangs(['sv', 'en']);
    translate.setDefaultLang('sv');

    console.log(translate.langs);

    translate.use('sv');

    let iso2Code = this.authService.currentUserValue.currentISO2CountryCode;

    if(translate.langs.indexOf(iso2Code) <= -1) {
      iso2Code = 'sv';
    }

    if(this.authService.currentUserValue.siteSettings.hasCultureOverrides) {
      translate.use(`${this.authService.currentUserValue.siteSettings.customer}-${iso2Code}`);
      console.log('Current Lang =', `${this.authService.currentUserValue.siteSettings.customer}-${iso2Code}`);
    }
    else {
      translate.use(iso2Code);
      console.log('Current Lang =', iso2Code);
    }
    
    console.log(this.authService.currentUserValue.currentISO2CountryCode);
    dayjs.locale('sv');
    dayjs.locale(this.authService.currentUserValue.currentISO2CountryCode)
    dayjs.extend(isBetween);
    dayjs.extend(relativeTime);
    dayjs.extend(localeData);

    console.log("DayJs locale:", dayjs.locale());
    console.log(dayjs.monthsShort());

    this.titleService.setTitle(this.authService.currentUserValue.siteSettings.siteTitle);

    this.metaService.addTags([
      {
        name: "description", property: "og:description", content: this.authService.currentUserValue.siteSettings.siteDescription
      },
      {
        name: "title", property: "title", content: this.authService.currentUserValue.siteSettings.siteTitle
      },
      {
        name: "image", property: "og:image", content: this.cdnPipe.transform(this.authService.currentUserValue.siteSettings.siteImage)
      },
      {
        name: "url", property: "og:url", content: window.location.href
      },
      {
        name: "video", property: "og:video", content: ""
      },
      {
        name: "locale", property: "og:locale", content: this.authService.currentUserValue.siteSettings.siteLocale
      },
      {
        name: "site_name", property: "og:site_name", content: this.authService.currentUserValue.siteSettings.siteTitle
      }
    ]);

    if (this.authService.currentUserValue.siteSettings.favicon) {
      let iconUrl = this.cdnPipe.transform(this.authService.currentUserValue.siteSettings.favicon);
      this.faviconService.setFavicon(iconUrl);
    }

    // if(environment.production) {
    //   this.cssUrl = `/app/customers/${this.authService.currentUserValue.siteSettings.customer}/${this.authService.currentUserValue.siteSettings.customer}.css`;
    // }


    this.loadCustomerStyle();
  }

  loadCustomerStyle() {
    const headEl = this.document.getElementsByTagName('head')[0];

    const newLinkEl = this.document.createElement('link');
    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = `${this.authService.currentUserValue.siteSettings.customer}.css`;

    headEl.appendChild(newLinkEl);
  }

  getStyleAsCss(style: SiteStyle) {
    var backupStyle = new SiteStyle();
    return `
      --primary-color: ${style.primaryColor || backupStyle.primaryColor};
      --primary-color-rbg: ${this.hexToRgb(style.primaryColor ? style.primaryColor.substring(1, 7) : backupStyle.primaryColor.substring(1, 7))};
      --primary-color-hover: ${style.primaryColorHover || backupStyle.primaryColorHover};
      --secondary-color: ${style.secondaryColor || backupStyle.secondaryColor};
      --secondary-color-rbg: ${this.hexToRgb(style.secondaryColor ? style.secondaryColor.substring(1, 7) : backupStyle.secondaryColor.substring(1, 7))};
      --secondary-color-hover: ${style.secondaryColorHover || backupStyle.secondaryColorHover};
      --black: ${style.black || backupStyle.black};
      --white: ${style.white || backupStyle.white};
      --light-grey: ${style.lightGrey || backupStyle.lightGrey};
      --light-grey-bg: ${style.lightGreyBg || backupStyle.lightGreyBg};
      --grey-hover: ${style.greyHover || backupStyle.greyHover};
      --grey-line: ${style.greyLine || backupStyle.greyLine};
      --dark-grey-line: ${style.darkGreyLine || backupStyle.darkGreyLine};
      --dark-grey: ${style.darkGrey || backupStyle.darkGrey};
      --primary-text-color: ${style.primaryTextColor || backupStyle.primaryTextColor};
      --primary-text-info-color: ${style.primaryTextInfoColor || backupStyle.primaryTextInfoColor};
      --link-color: ${style.linkColor || backupStyle.linkColor};
      --link-color-hover: ${style.linkColorHover || backupStyle.linkColorHover};

      --primary-font-family: ${style.primaryFontFamily || backupStyle.primaryFontFamily};

      --secondary-text-color: ${style.secondaryTextColor || backupStyle.secondaryTextColor};
      --secondary-text-info-color: ${style.secondaryTextInfoColor || backupStyle.secondaryTextInfoColor};

      --secondary-font-family: ${style.secondaryFontFamily || backupStyle.secondaryFontFamily};

      --content-width: ${style.contentWidth || backupStyle.contentWidth};

      --mobile-breakpoint: 767px;
      --default-padding: 1.5rem;
      --section-margin-top: 4.375rem;
    `;
  }

   hexToRgb(hex) {
    var arrBuff = new ArrayBuffer(4);
    var vw = new DataView(arrBuff);
    vw.setUint32(0,parseInt(hex, 16),false);
    var arrByte = new Uint8Array(arrBuff);
  
    return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
  }

  ngAfterViewInit() {
    if(this.authService.currentUserValue && this.authService.currentUserValue.siteSettings 
      && this.authService.currentUserValue.siteSettings.siteStyle) {

        var css = `body { ${this.getStyleAsCss(this.authService.currentUserValue.siteSettings.siteStyle)} }`,
        head = document.head || document.getElementsByTagName('head')[0],
        style: any = document.createElement('style');
    
        head.appendChild(style);
    
        style.type = 'text/css';
        if (style.styleSheet){
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

    }

    if(this.authService.currentUserValue && this.authService.currentUserValue.siteSettings) {
      this.document.body.classList.add(this.authService.currentUserValue.siteSettings.customer);
    }

    if(this.authService.currentUserValue.siteSettings.defaultAppearance) {
      this.document.body.classList.add(this.authService.currentUserValue.siteSettings.defaultAppearance);
    }
  }

  ngOnInit(): void {
    this.gtmService.addGtmToDom();

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {

        if(this.authService.currentUserValue.siteSettings.gaEvents && this.authService.currentUserValue.siteSettings.gaEvents.length > 0) {
          for(let i = 0; i < this.authService.currentUserValue.siteSettings.gaEvents.length; i++) {

            this.gtmService.pushTag({
              allow_custom_scripts: this.authService.currentUserValue.siteSettings.gaEvents[i].allowCustomScripts,
              send_to: this.authService.currentUserValue.siteSettings.gaEvents[i].sendTo
            });

            console.log('SEND TO: ', this.authService.currentUserValue.siteSettings.gaEvents[i].sendTo);
          }
        }
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      //This is how we ignore the scrolling... Example of usage: this.router.navigate([menuItem.url], {state: {ignoreScroll: true}});
      if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras && this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.ignoreScroll) {
        return;
      }

      // const dom: HTMLElement = this.elementRef.nativeElement;
      // const wrapper = dom.querySelectorAll('.app-wrapper')[0];
      // wrapper.scrollTo(0, 0)
    });

    this.document.documentElement.lang = this.authService.currentUserValue.currentISO2CountryCode;

    // this.initServiceWorker();
  }

  // initServiceWorker() {
  //   if(this.authService.isLoggedIn() && !firebase.getApps().length) {
  //     firebase.initializeApp(environment.firebase);
  //   }
  // }
}
