<ng-container *ngTemplateOutlet="{'DEFAULT': default, 'TABS': tabs }[moi.settings.content.template]"></ng-container>


<ng-template #default>
  <div class="cms-module-page-menu-wrapper">
    <div class="page-menu-item-wrapper" *ngFor="let menuItem of moi.settings.content.menuItems | sort:'order'"
      [class.showLines]="moi.settings.content.showLines"
      [class.firstMaster]="moi.settings.content.firstItemAsMaster">
      <div class="page-menu-item" 
        [style.background-image]="'url(' + (menuItem.imgUrl || '' | cdn) + ')'"
        (click)="navigate(menuItem)"
        [class.active]="isActive(menuItem) || getActiveMenuItem() == menuItem"
        [id]="menuItem.url">
        <span [innerHtml]="menuItem.text"></span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #tabs>

  <div class="tabs-outer-wrapper" [style.--selected-color]="getActiveMenuItem()?.selectedColor" 
    [class.border]="moi.settings.content.showBottomBorder">
    <div class="tabs-wrapper">
      <div class="tab" *ngFor="let menuItem of moi.settings.content.menuItems"
        (click)="navigate(menuItem)"
        [style.--selected-color]="menuItem.selectedColor"
        [class.active]="isActive(menuItem) || getActiveMenuItem() == menuItem">
  
        <span [innerHtml]="menuItem.text"></span>
  
      </div>
    </div>
  </div>

</ng-template>