<div class="image-module-wrapper">

  <bee-landscape-image [alt]="module.settings.content.altText" *ngIf="!module.settings.content.interactiveEnabled" class="landscape-no-hover" [ngClass]="{'hover-enabled': module.settings.content.hoverEnabled}" [image]="((module.settings.content.image | beeImage) || module.settings.content.imgUrl) | cdn" [scaling]="module.settings.content.imageScaling"></bee-landscape-image>
  <!-- <img *ngIf="!module.settings.content.interactiveEnabled" [ngClass]="{'hover-enabled': module.settings.content.hoverEnabled}" src="{{(module.settings.content.image.imgUrl || module.settings.content.imgUrl) | cdn}}" alt=""> -->
  <object *ngIf="module.settings.content.interactiveEnabled" [ngClass]="{'hover-enabled': module.settings.content.hoverEnabled}" type="image/svg+xml" [data]="((module.settings.content.image | beeImage) || module.settings.content.imgUrl) | cdn | safe"></object>

  <bee-landscape-image [alt]="module.settings.content.altText" *ngIf="!module.settings.content.interactiveEnabled" class="hover-img landscape-no-hover" [image]="(module.settings.content.hoverImage.imgUrl || module.settings.content.hoverImage) | cdn" [scaling]="module.settings.content.imageScaling"></bee-landscape-image>
  <!-- <img *ngIf="!module.settings.content.interactiveEnabled" class="hover-img" src="{{(module.settings.content.hoverImage.imgUrl || module.settings.content.hoverImage) | cdn}}" alt=""> -->

  <object *ngIf="module.settings.content.interactiveEnabled" type="image/svg+xml" class="hover-img" [data]="(module.settings.content.hoverImage.imgUrl || module.settings.content.hoverImage) | cdn | safe"></object>
</div>
