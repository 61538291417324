import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bee-thermoeyes-menu',
  templateUrl: './thermoeyes-menu.component.html',
  styleUrls: ['./thermoeyes-menu.component.scss']
})
export class ThermoeyesMenuComponent implements OnInit {
  @Input() menuItems: any[];
  public menuOpen: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigate(item) {
    this.menuOpen = false;
    this.router.navigate([item.url]);
  }
}
