<div class="module-blog-list-settings-wrapper">
  <div>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="moi.settings.content.showTagFilter" color="primary">Show tag filter</mat-checkbox>
    </div>

    <div *ngIf="moi.settings.content.showTagFilter">
      <div class="margin-top">
        <mat-checkbox [(ngModel)]="moi.settings.content.tagMultiSelect" color="primary">Enable multi select for tags</mat-checkbox>
      </div>

      <div class="margin-top">
        <mat-checkbox [(ngModel)]="moi.settings.content.tagButtonSecondary" color="primary">Use secondary buttons for tags</mat-checkbox>
      </div>

      <div class="margin-top">
        <mat-checkbox [(ngModel)]="moi.settings.content.tagButtonSmall" color="primary">Use small tag buttons</mat-checkbox>
      </div>
    </div>

    <mat-form-field class="margin-top">
      <mat-label>Select Template</mat-label>
      <mat-select [(value)]="moi.settings.content.template">
        <mat-option value="default">Default</mat-option>
        <mat-option value="carousel">Carousel</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="moi.settings.content.hideDescription" color="primary">Hide description</mat-checkbox>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="moi.settings.content.showBlogPublishDate" color="primary">Show publish date</mat-checkbox>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="moi.settings.content.showBlogTags" color="primary">Show tags</mat-checkbox>
    </div>

    <mat-form-field>
      <input matInput placeholder="Blog items to show" name="limit" [(ngModel)]="moi.settings.content.limit">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Total blog items to load" name="maxLimit" [(ngModel)]="moi.settings.content.maxLimit">
    </mat-form-field>

    <mat-checkbox class="margin-top"
      [(ngModel)]="moi.settings.content.showMore" color="primary">
      
      Show the "show more" button
    </mat-checkbox>

    <div>
      <mat-checkbox [(ngModel)]="moi.settings.content.showDots" color="primary">Show dots</mat-checkbox>
    </div>

    <div>
      <mat-checkbox [(ngModel)]="moi.settings.content.showNav" color="primary">Show navigation arrows</mat-checkbox>
    </div>

    <mat-form-field class="margin-top">
      <mat-label>Image scaling</mat-label>
      <mat-select [(value)]="moi.settings.content.imageScaling">
        <mat-option value="default">16:9</mat-option>
        <mat-option value="noscale">No scaling</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="margin-top">
      <mat-label>Filter on tag</mat-label>
      <mat-select [(value)]="moi.settings.content.tag">
        <mat-option value="">Don't filter</mat-option>
        <mat-option *ngFor="let tag of tags" [value]="tag.urlRewrite">{{tag.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>