<div class="cms-module-page-menu-wrapper">
  <div class="page-menu-item-wrapper" *ngFor="let menuItem of moi.settings.content.menuItems"
    [class.firstMaster]="moi.settings.content.firstItemAsMaster"
    [class.showLines]="moi.settings.content.showLines">
    <div class="page-menu-item" 
      [style.background-image]="'url(' + (menuItem.imgUrl || '' | cdn) + ')'"
      (click)="navigate(menuItem)">
      <span [innerHtml]="menuItem.text"></span>
    </div>
  </div>
</div>