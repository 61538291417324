import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'admin/install', loadComponent: () => import('./standalone/admin/components/install/install.component').then(mod => mod.InstallComponent) },
  { path: "admin", loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
