export class BeeTableColumn {
  text: string;
  propertyName: string;
  flex: number = 1;
  cssClass: string;
  type: string = "text";

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
