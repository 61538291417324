import { Component, Input, NgZone, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { GuidService } from 'src/app/helpers/services/guid.service';
import { CdnPipe } from '../../pipes/cdn.pipe';


@Component({
  selector: 'bee-scroll-animation',
  standalone: true,
  imports: [
    CommonModule,
    CdnPipe
  ],
  templateUrl: './scroll-animation.component.html',
  styleUrls: ['./scroll-animation.component.scss']
})
export class ScrollAnimationComponent {
  guidService: GuidService = inject(GuidService);
  public id: string = this.guidService.shortUUID();

  @Input() items: string[] = [];
  @Input() template: string = 'container';

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      gsap.registerPlugin(ScrollTrigger);
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.items = [this.items[0]];
    switch (this.template) {
      case 'container':
        this.initGsapContainer();
        break;
      default:
        this.initGsap();
        break;
    }
  }

  initGsap(): void {
    this.zone.runOutsideAngular(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `section.scroll-section`,
          start: "center center",
          end: "bottom top",
          scrub: true,
          // markers: true,
          pin: true
        }
      });

      for (let i = 1; i < this.items.length; i++) {
        timeline.from(`.box${(i)}`, { display: "none" });
      }
    });
  }

  initGsapContainer() {
    this.zone.runOutsideAngular(() => {
      for(let i = 0; i < 1; i++) {
        let timeline = gsap.timeline({
          scrollTrigger: {
            trigger: `section#section-${i}`,
            start: "center center",
            end: "bottom top",
            scrub: true,
            // markers: true,
            pin: true
          }
        });

        var prop = [
          {
            key: 'x',
            value: -100
          },
          {
            key: 'opacity',
            value: 0
          },
          {
            key: 'duration',
            value: 1
          }
        ];

        var propFormatted = {};

        for(let j = 0; j < prop.length; j++) {
          propFormatted[prop[j].key] = prop[j].value;
        }

        console.log(propFormatted);
  
        timeline.addLabel("start")
        .from(".anim-from-left-" + i, propFormatted);
      }
    });
  }

}
