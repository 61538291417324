import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BeeNavigationService {
  private router: Router = inject(Router);

  constructor() { }

  public navigateTo(module: string, id: string, fragment: string, querystring: string): void {

    console.log(module, id, fragment, querystring);

    if(!module) {
      console.error('Module is required to navigate to a page')
      return;
    }

    if(!querystring) {
      this.router.navigate(['modules', this.getModuleLink(module), id], { fragment: fragment });
    }
    else {
      this.router.navigateByUrl(`modules/${this.getModuleLink(module)}/${id}${this.getQuerystring(querystring)}#${fragment}`);
    }

  }

  private getQuerystring(querystring: string): string {
    return '?' + querystring;
  }

  private getModuleLink(module: string): string {
    switch (module) {
      case 'VERIFICATION_FLOW':
        return 'verification-flow';
      case 'VERIFICATION_FLOW_REPORT':
        return 'verification-flow/report';
    }
  }
}
