import { Component, Inject } from '@angular/core';
import { CMSModuleInstance, CMSModuleAbstract } from 'src/app/container/cms-models';
import {MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import { CmsModuleService } from '../../cms-services/cms-module-service.service';

@Component({
  selector: 'app-cms-module-card-settings-add-module-dialog',
  templateUrl: 'app-cms-module-card-settings-add-module-dialog.html',
  styleUrls: ['./app-cms-module-card-settings-add-module-dialog.component.scss']
})
export class CmsModuleCardSettingsAddDialogComponent {
  moduleAbstracts: Array<CMSModuleAbstract>;

  constructor(public dialogRef: MatDialogRef<CmsModuleCardSettingsAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CMSModuleInstance, private cmsModuleService: CmsModuleService) {
    this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
  }

  close(moi: CMSModuleInstance): void {
    this.dialogRef.close(moi);
  }

  addModuleInstance(moduleAbstract: CMSModuleAbstract) {
    //console.log(moduleAbstract);
    var moi = moduleAbstract.createModuleInstance();
    this.data.moduleInstances.push(moi);
    //console.log(this.data.moduleInstances);
    this.close(moi);
  }

}
