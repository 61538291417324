import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontendComponent } from './frontend.component';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { BlogListComponent } from './modules/blog-list/blog-list.component';
import { BlogViewComponent } from './modules/blog-view/blog-view.component';
import { SearchViewComponent } from './modules/search-view/search-view.component';
import { MediaListComponent } from './modules/media-list/media-list.component';
import { combineLatest } from 'rxjs';
import { MediaViewComponent } from './modules/media-view/media-view.component';
import { MediaTagListComponent } from './modules/media-tag-list/media-tag-list.component';
import { MediaTagViewComponent } from './modules/media-tag-view/media-tag-view.component';
import { BlogTagListComponent } from './modules/blog-tag-list/blog-tag-list.component';
import { SearchListComponent } from './modules/search-list/search-list.component';
import { GenericContainerListComponent } from './modules/generic-container/generic-container-list/generic-container-list.component';
import { GenericContainerViewComponent } from './modules/generic-container/generic-container-view/generic-container-view.component';
import { OpenurlComponent } from './openurl/openurl.component';

const routes: Routes = [
  { path: "modules", component: FrontendComponent, children: [
    { path: "blogs", component: BlogListComponent },
    { path: "blog/:urlRewrite", component: BlogViewComponent },
    { path: "search/:query", component: SearchViewComponent },
    { path: "tag-blogs/:urlRewrite", component: BlogTagListComponent },
    { path: "medias", component: MediaListComponent },
    { path: "images", component: MediaListComponent, data: { MediaType: 1 }},
    { path: "videos", component: MediaListComponent, data: { MediaType: 2 }},
    { path: "audios", component: MediaListComponent, data: { MediaType: 3 }},
    { path: "media/:urlRewrite", component: MediaViewComponent },
    { path: "tag-medias/:urlRewrite", component: MediaTagListComponent },
    { path: "tag-media/:urlRewrite", component: MediaTagViewComponent },
    { path: "generic/:objectType", component: GenericContainerListComponent },
    { path: "generic/:objectType/:urlRewrite", component: GenericContainerViewComponent },
    {
      path: "academy",
      loadChildren: () => import('../modules/bee-academy/bee-academy.module').then(m => m.BeeAcademyModule)
    },
    {
      path: "user",
      loadChildren: () => import('../modules/core/bee-user/bee-user.module').then(m => m.BeeUserModule)
    },
    {
      path: "verification-flow/report",
      loadComponent: () => import('../modules/bee-verification-flow/report/report.component').then(m => m.ReportComponent)
    },
    {
      path: "verification-flow/report/:id",
      loadComponent: () => import('../modules/bee-verification-flow/report/report.component').then(m => m.ReportComponent)
    },
    {
      path: "verification-flow/files",
      loadComponent: () => import('../modules/bee-verification-flow/files/files.component').then(m => m.FilesComponent)
    },
    {
      path: "verification-flow/files/:id",
      loadComponent: () => import('../modules/bee-verification-flow/files/files.component').then(m => m.FilesComponent)
    },
    { 
      path: 'verification-flow', 
      loadComponent: () => import('../modules/bee-verification-flow/main/main.component').then(mod => mod.MainComponent) 
    },
    { 
      path: 'verification-flow/:id', 
      loadComponent: () => import('../modules/bee-verification-flow/main/main.component').then(mod => mod.MainComponent) 
    },
  ]},
  { path: "openurl", component: OpenurlComponent },
  { path: "", component: CmsPageComponent, pathMatch: 'full' },
  { path: ":urlRewrite", component: CmsPageComponent },
  { path: ":urlRewrite/:childPath", component: CmsPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class FrontendRoutingModule {

}
