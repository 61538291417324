import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { CMSModuleInstance } from 'src/app/container/cms-models';

import * as ClassicEditor from 'src/lib/ckeditor';

@Component({
  selector: 'app-cms-module-text',
  templateUrl: './cms-module-text.component.html',
  styleUrls: ['./cms-module-text.component.scss']
})
export class CmsModuleTextComponent implements OnInit {
  constructor(public module: CMSModuleInstance) {

  }

  ngOnInit() {
    if (this.module.settings.content.text === undefined) {
      this.module.settings.content.text = "This is a text module...";
    }
  }

}

@Component({
  selector: 'app-cms-module-text-settings',
  templateUrl: './cms-module-text-settings.component.html',
  styleUrls: ['./cms-module-text.component.scss']
})
export class CmsModuleTextSettingsComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig: any;

  constructor(public module: CMSModuleInstance, private authService: AuthenticationService) {

  }

  ngOnInit() {

    let customerHeadings = [];

    if(this.authService.currentUserValue.siteSettings.customer == 'vo-college' || this.authService.currentUserValue.siteSettings.customer == 'vo-college-dev') {
      customerHeadings.push({ model: 'underrubrik', view: { name: 'span', classes: 'h8' }, title: 'Underrubrik', class: 'h8' });
    }


    this.editorConfig = {
      fontColor: {
        colors: [
          {
            color: 'hsl(0, 0%, 0%)',
            label: 'Black'
          },
          {
            color: "var(--primary-color)",
            label: "Primary color"
          },
          {
            color: "#FFFFFF",
            label: "White"
          },
          {
            color: "#dc7d2f",
            label: "dc7d2f"
          }
        ]
      },
      toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'link', 'fontSize', 'fontColor', 'FontBackgroundColor'],
      heading: {
        options: [
          { model: 'span', view: 'span', title: 'Span' },
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
          { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
          { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          { model: 'preamble', view: 'preamble', title: 'Preamble', class: 'preamble' },
          ...customerHeadings
        ]
      },
      link: {
        decorators: {
          addTargetToLinks: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      }
    };
  }
}
