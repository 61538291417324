import { CMSContainer } from 'src/app/container/cms-models';

export class CmsPageEntity {
  id: number;
  urlRewrite: String;
  name: String;
  title: string;
  description: string;
  isDeleted: boolean;
  startTimeUtc: Date;
  endTimeUtc: Date;
  createdTimeUtc: Date;
  imgUrl: String;

  cmsContainer: CMSContainer;
  redirectToIfNoPermissions: string;

  constructor(data?: any, cmsContainer?: any) {
    if(!data) data = {};

    this.id = data.id || 0;
    this.urlRewrite = data.urlRewrite || "";
    this.name = data.name || "";
    this.title = data.title || "";
    this.imgUrl = data.imgUrl || "";
    this.description = data.description || "";
    this.isDeleted = data.isDeleted || false;
    this.startTimeUtc = data.startTimeUtc;
    this.endTimeUtc = data.endTimeUtc;
    this.createdTimeUtc = data.createdTimeUtc;
    this.redirectToIfNoPermissions = data.redirectToIfNoPermissions;
    

    if(cmsContainer) {
      this.cmsContainer = new CMSContainer(cmsContainer)
    }
    else {
      this.cmsContainer = new CMSContainer(undefined);
    }
  }  
}
