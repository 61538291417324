import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfigService } from './app-config.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  // providers: [
  //   {
  //     provide: APP_INITIALIZER,
  //     multi: true,
  //     deps: [AppConfigService],
  //     useFactory: (appConfigService: AppConfigService) => {
  //       return () => {
  //         //Make sure to return a promise!
  //         return appConfigService.loadAppConfig();
  //       };
  //     }
  //   }
  // ]
})
export class ConfigurationModule { }
