import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { scan, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'form-bee-radio-buttons',
  templateUrl: './bee-radio-buttons.component.html',
  styleUrls: ['./bee-radio-buttons.component.scss']
})
export class BeeRadioButtonsComponent extends FieldType {
  constructor() {
    super();
  }

}
