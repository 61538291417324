import { Component, OnInit, Injectable, Inject, ViewEncapsulation } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { ContainerBlogService } from '../services/container-blog.service';
import { BlogEntity } from '../entities/blog-entity';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ITagInterface } from '../../interfaces/tag.service.interface';

@Component({
  selector: 'app-cms-module-blog-list',
  templateUrl: './cms-module-blog-list.component.html',
  styleUrls: ['./cms-module-blog-list.component.scss']
})
export class CmsModuleBlogListComponent implements OnInit {
  blogs: BlogEntity[];
  loading: boolean;
  sliderOptions: OwlOptions;
  loadMoreLimit: number = 12;

  tags: any[] = [];
  selectedTags: string[] = [];

  constructor(public moi: CMSModuleInstance, private blogService: ContainerBlogService, private router: Router,
    private tagService: ITagInterface) {

      this.loading = true;
  }

  ngOnInit() {
    if(!this.moi.settings.content.template) {
      this.moi.settings.content.template = "carousel";
    }

    if(!this.moi.settings.content.imageScaling) {
      this.moi.settings.content.imageScaling = "default";
    }
    
    if(this.moi.settings.content.tag && this.moi.settings.content.tag.length > 0) {
      this.selectedTags.push(this.moi.settings.content.tag);
    }

    const params = new URLSearchParams(window.location.search);

    if(params.has('tag')) {
      this.selectedTags.push(params.get('tag'))
    }

    this.getBlogs();

    this.initSlider();

    this.moi.settings.content.limit = ~~this.moi.settings.content.limit;
    this.loadMoreLimit = ~~this.moi.settings.content.limit;

    this.getTags();
  }

  getTags() {
    this.tagService.getTags().subscribe(response => {
      this.tags = response;
    });
  }

  isSelectedTag(tagUrlRewrite) {
    return this.selectedTags.indexOf(tagUrlRewrite) >= 0;
  }

  selectTag(tag) {

    if(this.selectedTags.indexOf(tag.urlRewrite) < 0) {
      this.selectedTags.push(tag.urlRewrite);
    }
    else {
      this.selectedTags.splice(this.selectedTags.indexOf(tag.urlRewrite), 1);
    }

    // if(tag.urlRewrite == this.module.settings.content.tag) {
    //   this.module.settings.content.tag = undefined;
    // }
    // else {
    //   this.module.settings.content.tag = tag.urlRewrite;
    // }
    
    this.getBlogs();
  }

  showMoreBlogs() {
    this.moi.settings.content.limit += this.loadMoreLimit;
  }

  getBlogs() {

    this.blogs = undefined;

    if(this.selectedTags.length > 0) {

      this.blogService.GetByTags(this.selectedTags).subscribe((data: any) => {
        this.loading = false;
        this.blogs = [];
        if(data && data.length) {
          

          let l = data.length;

          if(l > this.moi.settings.content.maxLimit) {
            l = this.moi.settings.content.maxLimit;
          }

          for(let i = 0; i < l; i++) {
            this.blogs.push(new BlogEntity(data[i]));
          }
        }
      });
    }
    else {
      this.blogService.GetBlogEntries().subscribe((data: any) => {
        this.loading = false;
        this.blogs = [];
        if(data && data.data.length) {
          let l = data.data.length;

          if(l > this.moi.settings.content.maxLimit) {
            l = this.moi.settings.content.maxLimit;
          }

          for(let i = 0; i < l; i++) {
            this.blogs.push(new BlogEntity(data.data[i]));
          }
        }
      });
    }
  }

  getTagTextColor(color) {
    if(color && color.length > 0) {
      return "#FFFFFF";
    }
  }

  initSlider() {
    this.sliderOptions = {
      center: false,
      dots: this.moi.settings.content.showDots,
      autoWidth: false,
      autoplay: false,
      loop: true,
      autoplayHoverPause: true, 
      slideBy: this.moi.settings.content.limit,
      responsive: {
        981: {
          items: this.moi.settings.content.limit,
          slideBy: this.moi.settings.content.limit,
          nav: this.moi.settings.content.showNav
        },
        0: {
          items: 1,
          slideBy: 1,
          nav: this.moi.settings.content.showNav
        }
      }
    };
  }

  goToBlog(blog) {
    debugger;
    this.router.navigate(['/modules/blog/', blog.urlRewrite]);
  }

}

@Component({
  selector: 'app-cms-module-blog-list-settings',
  templateUrl: './cms-module-blog-list-settings.component.html',
  styleUrls: ['./cms-module-blog-list.component.scss']
})
export class CmsModuleBlogListSettingsComponent implements OnInit {
  public tags = [];

  constructor(public moi: CMSModuleInstance, private tagService: ITagInterface) { }

  ngOnInit() {
    if(!this.moi.settings.content.limit) {
      this.moi.settings.content.limit = 4;
    }

    this.tagService.getTags().subscribe(t => {
      this.tags = t;
    });
  }

}
