import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt/jwt.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { LoginComponent } from './login/login.component';
import { AuthorizeRoutingModule } from './authorize-routing.module';

import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { FormsModule } from '@angular/forms';
import { BeeServiceWorkerModule } from './bee-service-worker/bee-service-worker.module';
import { BeePipesModule } from '../bee-pipes/bee-pipes.module';
import { ForgotPasswordDialogComponent } from './dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordDialogComponent } from './dialogs/reset-password-dialog/reset-password-dialog.component';
import { UserSession } from './models/user-session';
import { MatCheckboxModule } from '@angular/material/checkbox';



@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordDialogComponent,
        ResetPasswordComponent,
        ResetPasswordDialogComponent
    ],
    imports: [
        CommonModule,
        AuthorizeRoutingModule,
        FormsModule,
        BeePipesModule,
        // BeeServiceWorkerModule,
        //Material
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AuthenticationService],
            useFactory: (authenticationService: AuthenticationService) => {
                return () => {
                    //Make sure to return a promise!
                    return authenticationService.getUserSession$();
                };
            }
        }
    ]
})
export class AuthorizeModule { }
