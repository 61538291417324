import { PaginationModel } from "./pagination-model";

export class PaginationReturnModel<T> {
  totalCount: number;
  data: T;
  pagination: PaginationModel;
  errorMessage: string;
  isSuccessful: boolean;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
