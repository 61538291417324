<div *ngIf="moi.settings.content.showTagFilter" class="blog-tag-filter-wrapper">
  <div class="bee-select-button lg filled" 
    [class.secondary]="moi.settings.content.tagButtonSecondary" 
    *ngFor="let tag of tags" 
    [innerHtml]="tag.name" 
    (click)="selectTag(tag)"
    [style.backgroundColor]="tag.color"
    [style.color]="getTagTextColor(tag.color)"
    [class.small]="moi.settings.content.tagButtonSmall"
    [class.selected]="isSelectedTag(tag.urlRewrite)"
    [class.hidden]="tag.urlRewrite == this.moi.settings.content.tag"></div>
</div>


<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner *ngIf="loading"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<div *ngIf="blogs; else loading" class="blog-list-wrapper">
  <p *ngIf="blogs.length <= 0" style="text-align: center;" [innerHtml]="'BLOG.NO_ENTRIES' | translate"></p>
  <ng-container *ngTemplateOutlet="{'carousel': carousel, 'default': default}[moi.settings.content.template]"></ng-container>
</div>

<ng-template #default>
  <bee-blog-list-default [blogs]="blogs" 
  [limit]="moi.settings.content.limit" 
  [showDescription]="!moi.settings.content.hideDescription"
  [showTags]="moi.settings.content.showBlogTags"
  [showPublishDate]="moi.settings.content.showBlogPublishDate"></bee-blog-list-default>
</ng-template>

<ng-template #carousel>
  <owl-carousel-o [options]="sliderOptions" *ngIf="moi.settings.content.limit > 1">
    <ng-container *ngFor="let blog of blogs">
      <ng-template carouselSlide>
        <a [routerLink]="['/modules/blog/', blog.urlRewrite]">
          <div class="blog-list-item">

            <bee-landscape-image [image]="blog.imgUrl | cdn" [alt]="blog.name" [scaling]="moi.settings.content.imageScaling"></bee-landscape-image>
      
            <div class="blog-info">
              <h6 [innerHtml]="blog.name"></h6>
              <!-- <h2 class="hide-desktop" [innerHtml]="blog.name"></h2> -->

              <div class="blog-extra-info" *ngIf="moi.settings.content.showBlogPublishDate || moi.settings.content.showBlogTags">
                <span class="blog-publish-date" [innerHtml]="blog.startTimeUtc | date" *ngIf="moi.settings.content.showBlogPublishDate"></span>
                <span class="blog-extra-divider" *ngIf="moi.settings.content.showBlogPublishDate && moi.settings.content.showBlogTags"></span>
                <span class="blog-tags" [innerHtml]="blog.tags | tagNames | safeHtml" *ngIf="moi.settings.content.showBlogTags"></span>
              </div>

              <span *ngIf="!moi.settings.content.hideDescription" class="blog-description" [innerHtml]="blog.description"></span>
            </div>
          </div>
        </a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>

  <a *ngIf="blogs && blogs.length > 0" [routerLink]="['/modules/blog/', blogs[0].urlRewrite]">
    <bee-landscape-image *ngIf="moi.settings.content.limit <= 1" [image]="blogs[0].imgUrl | cdn" [alt]="blogs[0].name"></bee-landscape-image>
  </a>
</ng-template>
