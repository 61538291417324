import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * Get
layout: string   */
  public Get(layout: string) {
    return this.http.get(this.config.apiEndpoint + 'api/layout/get/' + layout);
  }
} 
