<div>
  <div class="margin-top">
    <mat-checkbox name="unselectedTabEnabled" [(ngModel)]="moi.settings.content.unselectedTabEnabled" (change)="unselectedTabEnabledChange($event)" color="primary">Enable unselected tab</mat-checkbox>
  </div>

  <div class="margin-top">
    <button *ngIf="moi.settings.content.unselectedTabEnabled" (click)="selectMoi(moi.moduleInstances[0])" mat-stroked-button>Edit unselected tab</button>
    <button (click)="addTab()" mat-stroked-button>Add tab</button>
  </div>

  <div class="margin-top">
    <mat-checkbox name="tabShowNumbers" [(ngModel)]="moi.settings.content.showNumbers" color="primary">Show numbers</mat-checkbox>
  </div>

  <mat-form-field class="margin-top">
    <input matInput placeholder="Selected tab color" name="selectedColor" [(ngModel)]="moi.settings.content.selectedColor">
  </mat-form-field>

  <mat-form-field class="margin-top">
    <input matInput placeholder="Selected tab text color" name="selectedTextColor" [(ngModel)]="moi.settings.content.selectedTextColor">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Layout</mat-label>
    <mat-select [(ngModel)]="moi.settings.content.layout">
      <mat-option value="default">
        Default
      </mat-option>
      <mat-option value="atumn">
        Atumn
      </mat-option>
      <mat-option value="tertile">
        Tertile
      </mat-option>
      <mat-option value="advancedList">
        Advanced list
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="margin-top" *ngFor="let tab of getTabs()">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tab: {{tab.settings.content.tabName}}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div>
        <mat-form-field>
          <input matInput placeholder="Tab name" name="tabName" [(ngModel)]="tab.settings.content.tabName">
        </mat-form-field>

        <div class="margin-top">
          <mediabank-file-handler [(mediaFile)]="tab.settings.content.tabImage"></mediabank-file-handler>
        </div>

        <div class="margin-top" *ngIf="moi.settings.content.layout == 'advancedList'">
          <button (click)="addTab(tab)" mat-stroked-button>Add sub tab</button>
        </div>

        <div class="margin-top">
          <mat-checkbox name="hideTab" [(ngModel)]="tab.settings.content.hide" color="primary">Hide tab</mat-checkbox>
        </div>

        <div class="margin-top" *ngFor="let subTab of tab.settings.content.subTabs">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sub tab: {{subTab.settings.content.tabName}}
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
        
            <div>
              <mat-form-field>
                <input matInput placeholder="Sub tab name" name="subTabName" [(ngModel)]="subTab.settings.content.tabName">
              </mat-form-field>
      
              <div class="margin-top">
                <mediabank-file-handler [(mediaFile)]="subTab.settings.content.tabImage"></mediabank-file-handler>
              </div>
      
              <div class="margin-top">
                <button (click)="selectMoi(subTab)" mat-stroked-button>Edit tab content</button>
      
                <button (click)="deleteSubTab(tab, subTab)" mat-stroked-button color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
        
          </mat-expansion-panel>
        </div>


        <div class="margin-top">
          <button (click)="selectMoi(tab)" mat-stroked-button>Edit tab content</button>

          <button (click)="deleteTab(tab)" mat-stroked-button color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
  
    </mat-expansion-panel>
  </div>
</div>