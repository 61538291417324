import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';
import { BeeGenericComment } from 'src/app/modules/bee-social/models/generic-comment.model';
import { GenericCommentService } from 'src/app/modules/bee-social/services/generic-comment.service';
import { ConfirmDialogComponent } from 'src/app/standalone/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'bee-bee-comment-wrapper',
  templateUrl: './bee-comment-wrapper.component.html',
  styleUrls: ['./bee-comment-wrapper.component.scss']
})
export class BeeCommentWrapperComponent extends FieldWrapper {
  private dialog: MatDialog = inject(MatDialog);

  public showCommentWrapper$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public comments$: BehaviorSubject<BeeGenericComment[]> = new BehaviorSubject<BeeGenericComment[]>([]);
  private unsubscribe$: Subject<void> = new Subject<void>();
  comment: string;

  public get variablesAsStyle(): any {
    return `
      ${this.field.templateOptions?.extraData?.styles?.backgroundColor? '--background-color:' + this.field.templateOptions?.extraData?.styles?.backgroundColor + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.flexDirection? '--flex-direction:' + this.field.templateOptions?.extraData?.styles?.flexDirection + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.display? '--display:' + this.field.templateOptions?.extraData?.styles?.display + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.gridTemplateColumns? '--grid-template-columns:' + this.field.templateOptions?.extraData?.styles?.gridTemplateColumns + ';' : ''}
      ${this.field.templateOptions?.extraData?.styles?.gridTemplateRows? '--grid-template-rows:' + this.field.templateOptions?.extraData?.styles?.gridTemplateRows + ';' : ''}
    `
  }

  constructor(private authService: AuthenticationService,
    private genericCommentService: GenericCommentService) {
    super();
  }

  ngOnInit() {
    if(this.authService.currentUserValue?.siteSettings?.portalSettings?.isCommentsEnabled) {
      this.showCommentWrapper$.next(this.authService.currentUserValue?.siteSettings?.portalSettings?.isCommentsEnabled);
      this.genericCommentService.GetCommentsByObjectAndReferer("FORM_FIELD", this.field.key.toString(),
      this.field.templateOptions?.options["referer_type"], this.field.templateOptions?.options["referer_id"]).pipe(
        map((data: PaginationReturnModel<BeeGenericComment[]>) => {
          return data.data;
        }),
        takeUntil(this.unsubscribe$)
      ).subscribe((comments: BeeGenericComment[]) => {
        this.comments$.next(comments);
      });
    }
  }

  updateComment(comment: BeeGenericComment): void {
    this.genericCommentService.Save(comment).subscribe((data: any) => {
    });
    comment.isEditing = false;
  }

  saveComment(): void {
    this.genericCommentService.Save({
      comment: this.comment,
      referer_type: this.field.templateOptions?.options["referer_type"],
      referer_id: this.field.templateOptions?.options["referer_id"],
      object_type: "FORM_FIELD",
      object_id: this.field.key,
      isDeleted: false,
    }).pipe(
      map((data: SaveReturnModel<BeeGenericComment>) => {
        return data.data;
      })
    ).subscribe((data: BeeGenericComment) => {
      this.comments$.next([data, ...this.comments$.value]);
    });

    this.comment = "";
  }

  deleteComment(comment: BeeGenericComment): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Ta bort kommentar",
        message: "Är du säker på att du vill ta bort kommentaren?",
      }
    }).afterClosed().subscribe((proceed: boolean) => {
      if(proceed) {
        comment.isDeleted = true;
        this.genericCommentService.Save(comment).subscribe((data: any) => {
          this.comments$.next(this.comments$.value.filter((c: BeeGenericComment) => c.id != comment.id));
        });
      }
    });
  }

  editComment(comment: BeeGenericComment): void {
    comment.isEditing = true;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
