import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserNotification } from 'src/app/domain/notifications/models/user-notification';

@Component({
  selector: 'bee-notification-image-dialog',
  templateUrl: './notification-image-dialog.component.html',
  styleUrls: ['./notification-image-dialog.component.scss']
})
export class NotificationImageDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NotificationImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserNotification) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
