import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CourseEntity } from 'src/app/admin/academy/models/course-entity';
import { CMSModuleInstance } from '../../cms-models';
import { ICourseService } from '../../interfaces/course.service.interface';

import { Observable, forkJoin } from 'rxjs';
import { CourseLogEntity } from 'src/app/domain/academy/models/course-log-entity';
import { ICourseLogService } from '../../interfaces/course-log.service.interface';
import { CourseListSettings } from 'src/app/bee-components/modules/academy/course-list/course-list.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bee-cms-module-course-list',
  templateUrl: './cms-module-course-list.component.html',
  styleUrls: ['./cms-module-course-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsModuleCourseListComponent implements OnInit {
  public vm$: Observable<{
    courses: CourseEntity[],
    completedEducationModules: string[],
    courseStatuses: CourseLogEntity[]
  }>;

  constructor(public moi: CMSModuleInstance, private courseService: ICourseService, private courseLogService: ICourseLogService) { 
    if(!this.moi.settings.content.courseListSettings) {
      this.moi.settings.content.courseListSettings = new CourseListSettings();
    }
  }

  ngOnInit(): void {
    this.vm$ = forkJoin({
      coursesResponse: this.courseService.getAll(true),
      completedEducationsResponse: this.courseService.getMyCompletedEducationModules(),
      approvedCoursesResponse: this.courseLogService.getMyCoursesWithStatus("approved"),
      completedCoursesResponse: this.courseLogService.getMyCoursesWithStatus("completed"),
      awaitingApprovalCoursesResponse: this.courseLogService.getMyCoursesWithStatus("require_approval"),
      startedCoursesResponse: this.courseLogService.getMyCoursesWithStatus("started")
    }).pipe(
      map(results => {
        return {
          courses: results.coursesResponse.data,
          completedEducationModules: results.completedEducationsResponse,
          courseStatuses: [].concat(results.approvedCoursesResponse, 
            results.awaitingApprovalCoursesResponse, 
            results.completedCoursesResponse, 
            results.startedCoursesResponse)
        };
      })
    );
  }

}

@Component({
  selector: 'bee-cms-module-course-list-settings',
  templateUrl: './cms-module-course-list-settings.component.html',
  styleUrls: ['./cms-module-course-list.component.scss']
})
export class CmsModuleCourseListSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit(): void {
  }

}
