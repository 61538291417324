<p><strong [innerHtml]="field.templateOptions.label"></strong></p>

<div>
    <mat-form-field>
        <mat-label>{{field.templateOptions.placeholder}}</mat-label>
        <mat-select [formControl]="formControl">
          <mat-option *ngFor="let option of field.templateOptions.options" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
