import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CourseChapter } from 'src/app/admin/academy/models/chapter';
import { CourseEntity } from 'src/app/admin/academy/models/course-entity';
import { IComponentsCourseService } from 'src/app/bee-components/interfaces/i-course-service';
import { BeeTableColumn } from 'src/app/bee-components/models/bee-table-column';
import { BeeTableSettings } from 'src/app/bee-components/models/bee-table-settings';
import dayjs from 'dayjs'

@Component({
  selector: 'bee-course-chapter-table-info',
  templateUrl: './course-chapter-table-info.component.html',
  styleUrls: ['./course-chapter-table-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseChapterTableInfoComponent implements OnInit {
  tableSettings: BeeTableSettings<any>[];
  @Input() completedEducationIds?: any;
  @Input() completedCourseIds?: string[];

  _courses: CourseEntity[];

  public firstCourse: CourseEntity;

  get courses(): CourseEntity[] {
    return this._courses;
  }

  @Input() set courses(val: CourseEntity[]) {
    this._courses = val;
    if(val && val.length > 0) {
      this.firstCourse = val[0];
    }

    this.handleTableData();
  }

  constructor(private courseService: IComponentsCourseService, private translate: TranslateService) { 

    this.tableSettings = [];

    if(!this.completedEducationIds) this.completedEducationIds = {};
    if(!this.completedCourseIds) this.completedCourseIds = [];
  }

  ngOnInit(): void {
    if(!this.courses) {
      this.getCourses();
    }
  }

  getCourses() {
    this.courseService.getAll(true).subscribe(response => {
      if(response.isSuccessful) {
        this.courses = response.data;

        this.getCompletedEducationIds();
      }
    });
  }

  getCompletedEducationIds() {
    this.courseService.getMyCompletedEducationModules().subscribe(ids => {
      this.completedEducationIds = ids;

      this.handleTableData();
    });
  }

  getTableData(course: CourseEntity): BeeTableSettings<any> {
    let settings = new BeeTableSettings();

    let nameRow = new BeeTableColumn({text: this.translate.instant("ACADEMY.CHAPTER"), propertyName: "name", flex: 2});
    let isPassedRow = new BeeTableColumn({text: this.translate.instant("ACADEMY.GRADING"), propertyName: "isPassed" });
    let passedDate = new BeeTableColumn({text: this.translate.instant("ACADEMY.GRADING_DATE"), propertyName: "passedDate", cssClass: "hide-mobile"});
    let passedIcon = new BeeTableColumn({text: "", propertyName: "passedIcon", cssClass: "hide-mobile yellow flex end large-icon star", type: 'icon'});

    let tableData = [];

    let index = 0;

    course.chapters.forEach(chapter => {
      index++;
      let chapterComplete = this.checkIfChapterIsCompleted(chapter);
      tableData.push({
        name: index + ". " + chapter.name,
        isPassed: chapterComplete ? this.translate.instant("ACADEMY.PASSED") : this.translate.instant("ACADEMY.NOT_PASSED"),
        passedDate: this.getChapterPassedDate(chapter),
        passedIcon: chapterComplete ? "star" : null
      });
    });

    settings.data = tableData;
    settings.columns = [
      nameRow, 
      isPassedRow,
      passedDate, 
      passedIcon
    ];

    return settings;
  }

  getChapterPassedDate(chapter: CourseChapter): string {
    
    var date = this.completedEducationIds[chapter.linkedEducationIds[chapter.linkedEducationIds.length - 1].educationId];

    if(date == undefined) {
      return "-";
    }

    return dayjs(date).format("YYYY-MM-DD");
  }

  handleTableData() {
    this.tableSettings = [];
    this.courses.forEach(c => {
      this.tableSettings.push(this.getTableData(c));
    });
  }

  checkIfChapterIsCompleted(chapter: CourseChapter): boolean {

    let optionalCount = 0;

    let educationCompletedCount = 0;
    for(let i = 0; i < chapter.linkedEducationIds.length; i++) {

      if(this.completedEducationIds[chapter.linkedEducationIds[i].educationId] != undefined) {
        educationCompletedCount++;
      }
    }

    chapter.educations.forEach(e => {
      if(e.optional) optionalCount++;
    });

    return educationCompletedCount >= (chapter.linkedEducationIds.length - optionalCount);
  }

  isCourseCompleted(course: CourseEntity): boolean {
    if(this.completedCourseIds.includes(course.id)) {
      return true;
    }

    return false;
  }

  getCertificate(course: CourseEntity): void {
    this.courseService.getCertificate(course.certificateId, course.id);
  }

}
