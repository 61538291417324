import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-text',
  templateUrl: './bee-form-text.component.html',
  styleUrls: ['./bee-form-text.component.scss']
})
export class BeeFormTextComponent extends FieldType {

}
