import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beeImage'
})
export class BeeImagePipe implements PipeTransform {

  constructor(public readonly elementRef: ElementRef) {}

  transform(image: any, ...args: unknown[]): string {
    let url = image?.fileUrl;

    if(image && image.sizes) {
      for(let i = 0; i < image.sizes.length; i++) {
        if(image.sizes[i].width > this.elementRef.nativeElement.offsetWidth) {
          url = image.sizes[i].fileUrl;
        }
      }
    }

    return url;
  }

}
