import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class GenericContainerService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * GetList
objectType: string   */
  public GetList(objectType: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getlist?objectType=' + objectType.toLowerCase());
  }

  /**
   * GetByUrlRewrite
  urlRewrite: string, iso2Culture: string   */
  public GetByUrlRewrite(urlRewrite: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getbyurlrewrite?urlRewrite=' + urlRewrite);
  }

  /**
   * GetContainerType
containerType: string   */
  public GetContainerType(containerType: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/GetGenericContainerType?containerType=' + containerType);
  }
}
