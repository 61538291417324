import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ContainerBlogService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

    /**
   * GetBlogEntries
   */
  public GetBlogEntries() {
    return this.http.get(this.config.apiEndpoint + "api-blog/getall");
  }

  /**
   * GetByUrl
   */
  public GetByUrl(urlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + 'api-blog/GetByUrl/' + urlRewrite);
  }

  /**
   * GetByTag
tagUrlRewrite   */
  public GetByTag(tagUrlRewrite: string) {
    return this.http.get(this.config.apiEndpoint + `api-blog/GetByTag?tagUrlRewrite=${tagUrlRewrite}`);
  }

  /**
   * GetByTags
tags: string[]   */
  public GetByTags(tags: string[]) {
    let tagString = tags.join(',');
    return this.http.get(this.config.apiEndpoint + `api-blog/GetByTags?tags=${tagString}`);
  }
}
