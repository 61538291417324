import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlogEntry } from './blog-models';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { GenericContainer } from '../generic-container/generic-container';
import { Observable } from 'rxjs';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  replacer(key,value) {
    if(key == "injectSettings") return undefined;
    if(key == "eventHandler") return undefined;

    return value;
  }

  /**
   * GetBlogEntries
   */
  public GetBlogEntries(pageSize?: number, pageIndex?: number): Observable<PaginationReturnModel<BlogEntry[]>> {
    if(pageSize && pageIndex != null) {
      return this.http.get<PaginationReturnModel<BlogEntry[]>>(this.config.adminApiEndpoint + 'api-blog/getall?pageSize=' + pageSize + "&pageIndex=" + pageIndex);
    }
    else {
      return this.http.get<PaginationReturnModel<BlogEntry[]>>(this.config.adminApiEndpoint + "api-blog/getall");
    }
  }

  /**
   * GetByTag
   */
  public GetByTag(tagUrlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + "api-blog/GetByTag?tagUrlRewrite=" + tagUrlRewrite);
  }

  /**
   * Save
   */
  public Save(blogEntry: BlogEntry) {
    let data = JSON.parse(JSON.stringify(blogEntry, this.replacer));
    data.cmsContainer = JSON.stringify(data.cmsContainer);

    return this.http.post(this.config.adminApiEndpoint + 'api-blog/save', data);
  }

  /**
   * GetByUrl
   */
  public GetByUrl(urlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + 'api/blog/GetByUrl/' + urlRewrite);
  }

  /**
   * Copy
   */
  public Copy(id: string) {
    return this.http.post(this.config.adminApiEndpoint + 'api-blog/Copy', id);
  }

  /**
   * Delete
blog: BlogEntry   */
  public Delete(blog: BlogEntry) {
    return this.http.delete(this.config.adminApiEndpoint + 'api-blog/delete?blogId=' + blog.id);
  }


  public SaveBlogTemplate(template: GenericContainer) {
    template.objectType = "blog_template";

    let data = JSON.parse(JSON.stringify(template, this.replacer));
    data.cmsContainer = JSON.stringify(data.cmsContainer);

    return this.http.post(this.config.adminApiEndpoint + 'api-container/genericcmscontainer/save', data);
  }

  public GetBlogTemplates() {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getlist?objectType=blog_template');
  }

  public GetBlogTemplate(urlRewrite: string, iso2Culture: string) {
    return this.http.get(this.config.apiEndpoint + 'api-container/genericcmscontainer/getbyurlrewrite?urlRewrite=' + urlRewrite + '&culture=' + iso2Culture);
  }

  public DeleteTemplate(template: GenericContainer) {
    template.isDeleted = true;
    return this.SaveBlogTemplate(template);
  }
}
