<p>
  <strong>
    <span [innerHtml]="field.templateOptions.label"></span>
    <span class="required-label" *ngIf="field.templateOptions.required && field.templateOptions.label">*</span>
    <bee-info *ngIf="field.templateOptions.description" [label]="field.templateOptions.description">
      <!-- <p [innerHtml]="field.templateOptions.description"></p> -->
    </bee-info>
  </strong>
</p>

<div class="input-wrapper">
  <span class="required" *ngIf="field.templateOptions.required && !field.templateOptions.label">*</span>
  <input type="input" [formControl]="formControl" [formlyAttributes]="field" [ngClass]="{ 'no-suffix': !field.templateOptions.fieldSuffix }">
</div>

<span *ngIf="field.templateOptions.fieldSuffix" [innerHtml]="field.templateOptions.fieldSuffix" class="form-text-suffix"></span>
