<ng-container *ngIf="verificationFlowInstances$ | async as verificationFlowInstances">
    <div (click)="toggle();" class="flex center align-center">
        <span>{{(verificationFlowInstanceService.selectedVerificationFlowInstance$ | async)?.name}}</span>
        <bee-animated-icon class="md-36" start="keyboard_arrow_down" end="keyboard_arrow_left" [(animate)]="isOpen"
            (click)="toggle();" *ngIf="verificationFlowInstances.totalCount > 1"></bee-animated-icon>
    </div>
    
    <div class="dropdown-wrapper" [class.hidden]="!isOpen" *ngIf="verificationFlowInstances.totalCount > 1">
        <div *ngFor="let instance of verificationFlowInstances?.data" (click)="selectInstance(instance);">
            <span class="dropdown-item">{{instance.name}}</span>
        </div>
    </div>
</ng-container>