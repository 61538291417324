import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class FormSubmissionService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  GetSubmissionAsPdf(submissionId: string): Observable<string> {
    return this.http.get<string>(this.config.apiEndpoint + "api-forms/formsSubmission/GetSubmissionAsPdf?submissionId=" + submissionId);
  }
}
