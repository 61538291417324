import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-line',
  templateUrl: './bee-form-line.component.html',
  styleUrls: ['./bee-form-line.component.scss']
})
export class BeeFormLineComponent extends FieldType {
}
