import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { CMSModuleInstance } from '../../cms-models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CmsModuleInstanceDialogContainerComponent } from '../../dialogs/cms-module-instance-dialog-container/cms-module-instance-dialog-container.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'bee-cms-module-logged-in-mini',
  templateUrl: './cms-module-logged-in-mini.component.html',
  styleUrls: ['./cms-module-logged-in-mini.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class CmsModuleLoggedInMiniComponent implements OnInit {
  public template: string = "notloggedin";
  public user: BeeUser;
  public showUserMenu: boolean = false;
  public show: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(public moi: CMSModuleInstance, public authService: AuthenticationService, private router: Router,
    private dialog: MatDialog, private _eref: ElementRef) { }

  ngOnInit(): void {
    this.user = this.authService.currentUserValue.user;

    if(this.authService.isLoggedIn()) {
      this.template = "loggedin";
    }

    if(!this.moi.settings.content.template) {
      this.moi.settings.content.template = "default";
    }

    if(this.moi.settings.content.iconSize == undefined) {
      this.moi.settings.content.iconSize = "24px";
    }

    this.router.events.pipe(
      takeUntil(this.destroy$)
    ).subscribe((val) => {
      if(val instanceof NavigationStart) {
        this.show = false;
      }
    });
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }

  toggleUserMenu(): void {
    this.showUserMenu = !this.showUserMenu;
  }

  logout(): void {
    this.authService.logout();

    window.location.href = "/";
  }

  goTo(url: string): void {
    this.show = false;
    this.router.navigate([url]);
  }

  isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  openProfile(): void {
    if(!this.moi.settings.content.profileLinkEnabled) {
      this.router.navigate(['/profile']);
    }
    else {
      if(this.moi.settings.content.profileLink.indexOf('dialog:') == 0) {
        console.log('USE DIALOG: ', this.moi.settings.content.profileLink.replace('dialog:', ''));
        this.openDialog(this.moi.settings.content.profileLink.replace('dialog:', ''));

        this.show = false;
      }
      else {
        this.router.navigate([this.moi.settings.content.profileLink]);
      }
    }
  }

  openDialog(dialogId: string) {
    let config  = new MatDialogConfig();

    config.data = { dialogId: dialogId };
    config.panelClass = "";
    config.autoFocus = true;

    this.dialog.open(CmsModuleInstanceDialogContainerComponent, config);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

@Component({
  selector: 'bee-cms-module-logged-in-mini-settings',
  templateUrl: './cms-module-logged-in-mini-settings.component.html',
  styleUrls: ['./cms-module-logged-in-mini.component.scss']
})
export class CmsModuleLoggedInMiniSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit(): void {
  }

  addLink(): void {
    if(!this.moi.settings.content.links) this.moi.settings.content.links = [];

    this.moi.settings.content.links.push({text: "", url: ""});
  }

  removeLink(link: any): void {
    this.moi.settings.content.links.splice(this.moi.settings.content.links.indexOf(link), 1);
  }

}
