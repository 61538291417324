<!-- <div class="card">
  <h3 class="card-header">Its time to party</h3>
  <h3 class="card-header">{{ to.label }}</h3>
  <div class="card-body">
    <ng-container #fieldComponent></ng-container>
  </div>
</div> -->


<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="title-wrapper">
        <img [src]="field.templateOptions?.attributes?.image" alt="">
        <h6 [innerHtml]="field.templateOptions?.label"></h6>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <ng-container #fieldComponent></ng-container>
  </div>
  
</mat-expansion-panel>