<div class="bee-item-list-wrapper">
  
  <bee-tag-cloud (tagSelect)="selectTag($event)"></bee-tag-cloud>

  <div class="item-list-header">
    <h1 [innerHtml]="title"></h1>
  </div>

  <div class="item-list">
    <div class="item-list-item" *ngFor="let item of items | limitTo:limit; let i = index" (click)="goToItem(item)">
      <img [src]="item.imgUrl || '' | cdn" alt="">
      <div class="item-info">
        <h2 [innerHtml]="item.name" *ngIf="i == 0"></h2>
        <h3 [innerHtml]="item.name" *ngIf="i > 0"></h3>
        <span class="date" [innerHtml]="item.createdTimeUtc | date"></span>
      </div>
    </div>
  </div>

  <!-- <div class="show-more" *ngIf="blogs.length > BASE_SHOW_LIMIT" (click)="toggleShowAll(); $event.stopPropagation();">
    <h6 *ngIf="!showingAll">Visa mer </h6>
    <h6 *ngIf="showingAll">Visa mindre </h6>
    <bee-animated-icon
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
          [(animate)]="showingAll"></bee-animated-icon> 
  </div> -->
</div>

