<ng-container *ngTemplateOutlet="{'add': add, 'default': default}[getTemplate()]"></ng-container>

<ng-template #default>
  <div class="file-handler-wrapper">
    <div class="image-preview margin-bottom"> 
      <img *ngIf="mediaFile" [src]="(mediaFile.thumbnail || mediaFile.imgUrl || mediaFile) | cdn" />                 
    </div>
  
    <div class="form-group">
      <span class="bee-button" (click)="openMediabank('default')">Mediabank</span>
      <span class="bee-button" *ngIf="mediaFile" (click)="clear()">Clear</span>
    </div>
  </div>
</ng-template>

<ng-template #add>
  <div class="file-handler-wrapper">
    <div class="form-group">
      <span class="bee-button" (click)="openMediabank('add')">Add</span>
    </div>
  </div>
</ng-template>
