<div class="description-wrapper">
  <p [innerHtml]="item.description" class="description" [hidden]="isOdd()"></p>
</div>

<div class="bottom">
  <span class="org" [innerHtml]="getOrg(item)"></span>

  <div class="bee-button secondary margin-top" (click)="click()">LÄS MER</div>
</div>


<div class="overlay" [style.backgroundColor]="getBackgroundColor()" [class.small]="isOdd()"></div>