<div class="cms-module-slider-wrapper">
  <owl-carousel-o [options]="sliderOptions">
    <ng-container *ngFor="let moduleInstance of module.moduleInstances | sort:'order' | filter:['isDeleted', false]">
      <ng-template carouselSlide>
        <div id="{{moduleInstance.instanceId}}" class="module-instance"
          [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}"
          [style.background-image]="'url(' + (moduleInstance.settings.style.backgroundImage || '' | cdn) + ')'">
            <app-cms-module-wrapper [moi]="moduleInstance" [hasAdminAccess]="module.hasAdminAccess"></app-cms-module-wrapper>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>

<!-- [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}" -->