<div class="card-wrapper"
  [style.align-items]="module.settings.style.alignItems"
  [style.justify-content]="module.settings.style.justifyContent"
  [ngClass]="{'reverse': module.settings.content.reverseContent == true}">
  <div class="card-wrapper-inner">
    <div class="front">
      <div *ngFor="let moduleInstance of frontSideSettings() | sort:'order' | filter:['isDeleted', false]" id="{{moduleInstance.instanceId}}" class="module-instance"
      [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}"
      [class.videoBackground]="moduleInstance.settings.style.backgroundVideo"
      [style.background-image]="'url(' + ((moduleInstance.settings.style.backgroundImage | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle.backgroundImage}) || '' | cdn) + ')'"
      (click)="selectModuleInstance(moduleInstance, $event)">

        <video *ngIf="moduleInstance.settings.style.backgroundVideo" autoplay [muted]="'muted'" loop class="moi-bgVideo" id="bgVideo-{{moduleInstance.instanceId}}">
          <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
        </video>

        <app-cms-module-wrapper [moi]="moduleInstance"></app-cms-module-wrapper>
    </div>
  </div>



    <div class="back">
      <div *ngFor="let moduleInstance of backSideSettings() | sort:'order' | filter:['isDeleted', false]" id="{{moduleInstance.instanceId}}" class="module-instance"
      [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}"
      [class.videoBackground]="moduleInstance.settings.style.backgroundVideo"
      [style.background-image]="'url(' + ((moduleInstance.settings.style.backgroundImage | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle.backgroundImage}) || '' | cdn) + ')'"
      (click)="selectModuleInstance(moduleInstance, $event)"
      >

        <video *ngIf="moduleInstance.settings.style.backgroundVideo" autoplay [muted]="'muted'" loop class="moi-bgVideo" id="bgVideo-{{moduleInstance.instanceId}}">
          <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
        </video>

        <app-cms-module-wrapper [moi]="moduleInstance"></app-cms-module-wrapper>
      </div>
    </div>
  </div>
</div>

<div class="backpreview" [hidden]="!backsidePreview.backsidePreview">
  <p>Preview of backside:</p>
  <div class="card-preview">
    <div *ngFor="let moduleInstance of backSideSettings() | sort:'order' | filter:['isDeleted', false]" id="{{moduleInstance.instanceId}}" class="module-instance"
  [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}"
  [class.videoBackground]="moduleInstance.settings.style.backgroundVideo"
  [style.background-image]="'url(' + ((moduleInstance.settings.style.backgroundImage | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle.backgroundImage}) || '' | cdn) + ')'"
  (click)="selectModuleInstance(moduleInstance, $event)"
  >

      <video *ngIf="moduleInstance.settings.style.backgroundVideo" autoplay [muted]="'muted'" loop class="moi-bgVideo" id="bgVideo-{{moduleInstance.instanceId}}">
        <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
      </video>

      <app-cms-module-wrapper [moi]="moduleInstance"></app-cms-module-wrapper>
    </div>
  </div>
</div>



