import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-cms-module-instance-dialog',
  templateUrl: './cms-module-instance-dialog.component.html',
  styleUrls: ['./cms-module-instance-dialog.component.scss']
})
export class CmsModuleInstanceDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CmsModuleInstanceDialogComponent>) {
    this.dialogRef.disableClose = true;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
