<form class="image-settings-wrapper settings-wrapper">
  <mat-form-field>
    <mat-label>Slect form</mat-label>
    <mat-select [(value)]="module.settings.content.formsId" (selectionChange)="selectionChanged($event)">
      <mat-option>None</mat-option>
      <mat-option [value]="form.id" *ngFor="let form of forms$ | async">
        {{form.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>