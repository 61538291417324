<div class="settings-wrapper">
  <div>

    <div class="margin-top">
      <mat-form-field>
        <input matInput placeholder="List max width" name="listMaxWidth" [(ngModel)]="module.settings.content.maxWidth">
      </mat-form-field>
    </div>

    <mat-form-field class="margin-top">
      <mat-label>Select number of columns</mat-label>
      <mat-select [(value)]="module.settings.content.colCount">
        <mat-option value=1>1 column</mat-option>
        <mat-option value=2>2 columns</mat-option>
        <mat-option value=3>3 columns</mat-option>
        <mat-option value=4>4 columns</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Select number of videos to show</mat-label>
      <mat-select [(value)]="module.settings.content.limit">
        <mat-option value=1>1 item</mat-option>
        <mat-option value=2>2 items</mat-option>
        <mat-option value=3>3 items</mat-option>
        <mat-option value=4>4 items</mat-option>
        <mat-option value=5>5 item</mat-option>
        <mat-option value=6>6 items</mat-option>
        <mat-option value=7>7 items</mat-option>
        <mat-option value=8>8 items</mat-option>
        <mat-option value=9>9 item</mat-option>
        <mat-option value=10>10 items</mat-option>
        <mat-option value=11>11 items</mat-option>
        <mat-option value=12>12 items</mat-option>
        <mat-option value=24>24 items</mat-option>
        <mat-option value=36>36 items</mat-option>
        <mat-option value=48>48 items</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="margin-top">
      <mat-label>Filter on tag</mat-label>
      <mat-select [(value)]="module.settings.content.tag">
        <mat-option value="">Don't filter</mat-option>
        <mat-option *ngFor="let tag of tags" [value]="tag.urlRewrite">{{tag.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field>
      <input matInput placeholder="Videos to show" name="limit" [(ngModel)]="module.settings.content.limit">
    </mat-form-field> -->

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showMediaTypeSelector" color="primary">Show media type selector (Video and Audio)</mat-checkbox>
    </div>

    <div class="margin-top" *ngIf="module.settings.content.showMediaTypeSelector">
      <mat-checkbox [(ngModel)]="module.settings.content.showMediaTypeTag" color="primary">Show media type tag</mat-checkbox>
    </div>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showTagFilter" color="primary">Show tag filter</mat-checkbox>
    </div>

    <div *ngIf="module.settings.content.showTagFilter">
      <div class="margin-top">
        <mat-checkbox [(ngModel)]="module.settings.content.tagMultiSelect" color="primary">Enable multi select for tags</mat-checkbox>
      </div>

      <div class="margin-top">
        <mat-checkbox [(ngModel)]="module.settings.content.tagButtonSecondary" color="primary">Use secondary buttons for tags</mat-checkbox>
      </div>

      <div class="margin-top">
        <mat-checkbox [(ngModel)]="module.settings.content.tagButtonSmall" color="primary">Use small tag buttons</mat-checkbox>
      </div>
    </div>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showDescription" color="primary">Show video description</mat-checkbox>
    </div>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="module.settings.content.showDuration" color="primary">Show video duration</mat-checkbox>
    </div>

    <div class="margin-top">
      <mat-checkbox name="showVideoTags" [(ngModel)]="module.settings.content.showVideoTags" color="primary">Show video tags</mat-checkbox>
    </div>

    <div class="margin-top">
      <mat-checkbox name="hideShowMoreCheckbox" [(ngModel)]="module.settings.content.hideShowMore" color="primary">Hide 'Show more' button</mat-checkbox>
    </div>
  </div>

  <div class="margin-top" *ngIf="module.settings.content.showTagFilter">
    <mat-checkbox [(ngModel)]="module.settings.content.showSpecificTags" color="primary">Only show specific tags</mat-checkbox>


    <div class="margin-top" *ngIf="module.settings.content.showSpecificTags">
      <mat-form-field appearance="fill">
        <mat-label>Tags to show</mat-label>
        <mat-chip-list #chipList aria-label="Tags to show">
          <mat-chip
            [style.backgroundColor]="tag.color"
            [style.color]="getColor(tag)"
            *ngFor="let tag of module.settings.content.selectedTags"
            (removed)="remove(tag)">
            {{tag.name}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            placeholder="Add tag"
            #tagInput
            [formControl]="tagCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            {{tag}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

  </div>

</div>