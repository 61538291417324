import { Injectable, EventEmitter, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserSession, SiteCulture, SiteSettings, UserSessionResult } from '../models/user-session';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import jwt_decode from 'jwt-decode';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserSession>;
  public currentUser: Observable<UserSession>;
  public currentUserSessionStore$: BehaviorSubject<UserSession> = new BehaviorSubject<UserSession>(null);

  public onCultureChange = new EventEmitter;
  public onSiteSettingsChange = new EventEmitter;

  constructor(private http: HttpClient, private config: AppConfigService, public translate: TranslateService, @Inject(DOCUMENT) private document: Document) { 
    this.currentUserSubject = new BehaviorSubject<UserSession>
      (JSON.parse(localStorage.getItem('currentUserSession')));

      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get tokenExpired(): boolean {
    let expired = true;
    if(this.currentUserValue?.token) {
      var decodedToken = jwt_decode(this.currentUserValue.token) as any;
      if(decodedToken.exp * 1000 > new Date().getTime()) {
        expired = false;
      }
    }
    return expired;
  }

  public get currentUser$(): Observable<UserSession> {
    return this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserSession {
    return this.currentUserSubject.value;
  }

  public get cultures(): SiteCulture[] {
    return this.currentUserValue.siteSettings.siteCultures;
  }

  public get isSiteLockedByLogin(): boolean {
    return this.currentUserValue?.siteSettings?.loginSettings?.isSiteLockedByLogin ?? false;
  }

  public get isSiteLockedByLogin$(): Observable<boolean> {
    return this.currentUser.pipe(map((userSession: UserSession) => {
      return userSession?.siteSettings?.loginSettings?.isSiteLockedByLogin ?? false;
    }));
  }

  isOwner(item: any): boolean {
    return this.currentUserValue?.user?.id == item?.createdByUserId;
  }

  public setCulture(culture: SiteCulture): void {
    this.currentUserValue.currentISO2CountryCode = culture.iso2;
    console.log(this.currentUserValue.currentISO2CountryCode);
    this.getSiteSettings();

    this.translate.use(culture.iso2);

    this.onCultureChange.emit(culture);
    this.document.documentElement.lang = culture.iso2;
  }

  public getCurrentCulture(): SiteCulture {
    return this.getCultureFromIso2(this.getCurrentCultureIso2());
  }

  public getCultureFromIso2(iso2): SiteCulture {
    let culture: SiteCulture = new SiteCulture();

    for(let i = 0; i < this.cultures.length; i++) {
      if(this.cultures[i].iso2 == iso2) {
        culture = this.cultures[i];
        break;
      }
    }

    return culture;
  }

  public getCultureFromIso4(iso4): SiteCulture {
    let culture: SiteCulture;

    for(let i = 0; i < this.cultures.length; i++) {
      if(this.cultures[i].iso4 == iso4) {
        culture = this.cultures[i];
        break;
      }
    }

    return culture;
  }

  public getCurrentCultureIso2(): string {
    return this.currentUserValue.currentISO2CountryCode;
  }

  public isCultureActive(culture: SiteCulture): boolean {
    return this.getCurrentCultureIso2() == culture.iso2;
  }

  public isLoggedIn(): boolean {
    let loggedIn = false;
    if(this.currentUserValue?.user && this.currentUserValue?.user?.id && this.currentUserValue?.user?.id?.length > 0 && this.currentUserValue?.user?.id != "-1") {
      loggedIn = true;
    }

    return loggedIn;
  }

  public isSuperadmin(): boolean {
    let superadmin = false;

    const currentUser = this.currentUserValue;

    if(currentUser && currentUser.token) {
      var decodedToken = jwt_decode(currentUser.token) as any;
      
      if(typeof decodedToken.Role === "string") {
        if(decodedToken.Role == "superadmin") {
          superadmin = true;
        }
      }
      else {
        decodedToken.Role.forEach(role => {
          if(role == "superadmin") superadmin = true;
        });
      }
    }

    return superadmin;
  }

  public isAdmin(): boolean {
    let result = false;
    const currentUser = this.currentUserValue;
    if (currentUser && currentUser.token) {
      var decodedToken = jwt_decode(currentUser.token) as any;
      
      if(typeof decodedToken.Role === "string") {
        if(decodedToken.Role == "superadmin") {
          result = true;
        }
      }
      else {
        decodedToken.Role.forEach(role => {
          if(role == "superadmin") result = true;
          else {
            if(!result) {
              
              result = role.indexOf("_readextended") > 0 || role.indexOf("_write") > 0 || role.indexOf("_admin") > 0;

            }
          }
        })
      }

      return result;
    }
  }

  public getHighestAccessLevel(module: string): number {
    let result: number = 0;
    const currentUser = this.currentUserValue;
    if (currentUser && currentUser.token) {
      var decodedToken = jwt_decode(currentUser.token) as any;
      if(typeof decodedToken.Role === "string") {
        if(decodedToken.Role == "superadmin") {
          result = 8;
        }
      }
      else {
        decodedToken.Role.forEach(role => {
          if(role == "superadmin") result = 8;
          else {
            if(role.startsWith(module.toLocaleLowerCase())) {
              if(result < 8 && role == `${module.toLocaleLowerCase()}_admin`) result = 8;
              else if(result < 4 && role == `${module.toLocaleLowerCase()}_write`) result = 4;
              else if(result < 2 && role == `${module.toLocaleLowerCase()}_readextended`) result = 2;
              else if(result < 1 && role == `${module.toLocaleLowerCase()}_read`) result = 1;
            }
          }
        });
      }
    }

    return result;
  }

  public hasAccess(module: string, accessLevel: string): boolean {
    if(!module || module.length <= 1) return false;
    
    let result = false;
    const currentUser = this.currentUserValue;
    if (currentUser && currentUser.token) {
      var decodedToken = jwt_decode(currentUser.token) as any;
      
      if(typeof decodedToken.Role === "string") {
        if(decodedToken.Role == "superadmin") {
          result = true;
        }
      }
      else {
        decodedToken.Role.forEach(role => {
          if(role == "superadmin") result = true;
          else {
            if(role.startsWith(module.toLocaleLowerCase())) {
              if(!result) {
                switch(accessLevel.toLocaleLowerCase()) {
                  case "admin":
                    result = role == `${module.toLocaleLowerCase()}_${accessLevel.toLocaleLowerCase()}`;
                    break;
                  case "write":
                    result = role == `${module.toLocaleLowerCase()}_${accessLevel.toLocaleLowerCase()}` || role == `${module.toLocaleLowerCase()}_admin`;
                    break;
                  case "readextended":
                    result = role == `${module.toLocaleLowerCase()}_${accessLevel.toLocaleLowerCase()}` || role == `${module.toLocaleLowerCase()}_write` || role == `${module.toLocaleLowerCase()}_admin`;
                    break;
                  case "read":
                    result = role == `${module.toLocaleLowerCase()}_${accessLevel.toLocaleLowerCase()}`|| role == `${module.toLocaleLowerCase()}_readextended` || role == `${module.toLocaleLowerCase()}_write` || role == `${module.toLocaleLowerCase()}_admin`;
                    break;
                }
              }
            }
          }
        })
      }

      return result;
  }
  }

  private getRequestCulture(): string {
    let culture = localStorage.getItem('currentCulture');

    if(!culture) culture = navigator.languages.join(',');

    return culture;
  }

  private getHeaders(): any {
    return {
      "Bee-Culture-Request": this.getRequestCulture()
    }
  }

  private getSiteSettings(): void {
    this.http.get(this.config.apiEndpoint + "api-site/sitesettings/get").subscribe((settings: SiteSettings) => {
      this.currentUserValue.siteSettings = settings;
      localStorage.setItem('currentUserSession', JSON.stringify(this.currentUserValue));

      this.onSiteSettingsChange.emit(this.currentUserValue.siteSettings);
    });
  }

  setUser(user: BeeUser): void {
    var session = this.currentUserValue;
    session.user = user;
    localStorage.setItem('currentUserSession', JSON.stringify(session));
    this.currentUserSubject.next(session);
    this.currentUserSessionStore$.next(session);
  }

  getUserSession$(): Observable<any> {
    if(this.tokenExpired || (this.currentUserValue == null || this.currentUserValue.token.length <= 0)) {
      return this.http.get(this.config.apiEndpoint + "api-auth/token", { headers: this.getHeaders()}).pipe(
        tap(userSession => {
          localStorage.setItem('currentUserSession', JSON.stringify(userSession));
          this.currentUserSubject.next(userSession);
          this.currentUserSessionStore$.next(userSession);
        })
      );
    }
    else {
      return of(this.currentUserValue);
    }
  }

  getUserSession(): Promise<any> {
    if(this.tokenExpired || (this.currentUserValue == null || this.currentUserValue.token.length <= 0)) {
      return this.http.get(this.config.apiEndpoint + "api-auth/token", { headers: this.getHeaders()})
      .toPromise().then((userSession: UserSession) => {
        localStorage.setItem('currentUserSession', JSON.stringify(userSession));
        this.currentUserSubject.next(userSession);
        this.currentUserSessionStore$.next(userSession);
        return userSession;
      });
    }
    else {
      //Need to find a way to return a empty promise..
      return this.http.get(this.config.apiEndpoint + "api-auth/token", { headers: this.getHeaders()})
      .toPromise().then((userSession: UserSession) => {
        if(this.currentUserValue.siteSettings.customer && 
          this.currentUserValue.siteSettings.customer == userSession.siteSettings.customer) {
            //Do nothing
        }
        else {
          localStorage.setItem('currentUserSession', JSON.stringify(userSession));
          this.currentUserSubject.next(userSession);
          this.currentUserSessionStore$.next(userSession);
        }
        return userSession;
      });      
    }
  }

  login(userName: string, password: string) {
    let userData = {
      userName: userName,
      password: password
    };
    return this.http.post(this.config.apiEndpoint + "api-auth/login", userData, { headers: this.getHeaders()})
      .toPromise().then((userSessionResult: UserSessionResult) => {
        localStorage.setItem('currentUserSession', JSON.stringify(userSessionResult.userSession));
        this.currentUserSubject.next(userSessionResult.userSession);
        this.currentUserSessionStore$.next(userSessionResult.userSession);
        document.cookie = "Authorization=" + userSessionResult.userSession.token;
        document.cookie = "Bee-UserId=" + userSessionResult.userSession.user.id;
        return userSessionResult.userSession;
      });
  }

  logout(): void {
    localStorage.removeItem("currentUserSession");
    document.cookie = "Authorization= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "Bee-UserId= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  }

  refreshToken(): void {
    this.http.get(this.config.apiEndpoint + "api-auth/refreshToken").subscribe((userSession: UserSession) => {
      localStorage.setItem('currentUserSession', JSON.stringify(userSession));
      this.currentUserSubject.next(userSession);
      this.currentUserSessionStore$.next(userSession);
    });
  }

  impersonate(userId: string): void {
    this.http.get(this.config.apiEndpoint + `api-auth/impersonate?userId=${userId}`).subscribe((userSession: UserSession) => {
      if (userSession != null) {
        localStorage.setItem('currentUserSession', JSON.stringify(userSession));
        this.currentUserSubject.next(userSession);
        this.currentUserSessionStore$.next(userSession);
        window.location.href = "/";
      }
    });
  }
}
