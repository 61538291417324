<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner *ngIf="loading"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<div *ngIf="!(isLoading$ | async); else loading">
  <ng-container *ngTemplateOutlet="{'4': pdf, '1': image, '2': video, '3': audio, '-1': pdf}[module.settings.content.mediaType]; 
  context: {files: files$ | async}"></ng-container>
  <ng-container *ngIf="!(files$ | async).length">
    <p [innerHtml]="'MEDIA.NO_FILES_TO_SHOW' | translate"></p>
  </ng-container>
</div>

<ng-template #pdf let-files="files">
  
  <ng-container *ngTemplateOutlet="{'grid': pdfgrid, 'list': pdflist}[module.settings.content.template]; context: {files: files}"></ng-container>

  <ng-template #pdflist>
    <div class="flex">
      <div class="list-wrapper margin-right margin-top lg">
        <div class="item" *ngFor="let file of files" (click)="selectFile(file);" 
        [class.selected]="selectedFile?.id == file?.id"> 
          <div class="item-left">
            <a class="margin-right" [href]="file.fileUrl | cdn" download="{{file.name}}.pdf">
              <mat-icon>file_download</mat-icon>
            </a>
            <p [innerHtml]="file.name"></p>
            <!-- <p>&nbsp;({{file.sizeInMb}} MB)</p> -->
          </div>
        </div>
      </div>
      <div *ngIf="selectedFileUrl$ | async as url" style="width: 800px;">
        <iframe class="margin-top lg a4-ratio" [src]="url | safe" style="width: 800px;"></iframe>
        <mat-checkbox class="margin-top lg" color="primary" 
          *ngIf="authService.isLoggedIn()"
          [checked]="selectedFileVerified$ | async"
          [disabled]="selectedFileVerified$ | async"
          (change)="checkVerified($event)">
          {{ 'MEDIA.VERIFIED_VIEW_BY_USER_CHECKBOX' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </ng-template>

  <ng-template #pdfgrid>
    <div class="files-wrapper">
      <div class="file" *ngFor="let file of files">
        <div class="image" [style.backgroundImage]="'url(' + (file.thumbnail | cdn:'/assets/images/pdf-icon.png') + ')'">
          <div class="file-info" *ngIf="file.description">
            <bee-info [label]="file.description"></bee-info>
          </div>
        </div>
  
        <div class="file-content">
          <p>{{file.name}} <span class="file-size">({{file.sizeInKb}} kb)</span></p>
  
          <a class="bee-button" [href]="file.fileUrl | cdn" download="{{file.name}}.pdf">
            <span>HÄMTA PDF</span>
            <mat-icon>south</mat-icon>
          </a>
        </div>
      </div>
    </div>
  </ng-template>


</ng-template>

<ng-template #image let-files="files"></ng-template>

<ng-template #video let-files="files">
  <ng-container *ngTemplateOutlet="{'grid': videogrid, 'list': videolist}[module.settings.content.template]; context: {files: files}"></ng-container>

  <ng-template #videolist>
    <div class="list-wrapper">
      <div class="item" *ngFor="let file of files" (click)="navigateToFile(file)"> 
        <div class="item-left">
          <a class="margin-right" [routerLink]="['/modules', 'media', file.urlRewrite]">
            <mat-icon>play_circle</mat-icon>
          </a>
          <p [innerHtml]="file.name"></p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #videogrid></ng-template>
</ng-template>

<ng-template #audio let-files="files">
  <ng-container *ngTemplateOutlet="{'grid': audiogrid, 'list': audiolist}[module.settings.content.template]; context: {files: files}"></ng-container>

  <ng-template #audiolist>
    <div class="list-wrapper">
      <div class="item" *ngFor="let file of files" (click)="navigateToFile(file)"> 
        <div class="item-left">
          <a class="margin-right" [routerLink]="['/modules', 'media', file.urlRewrite]">
            <mat-icon>play_circle</mat-icon>
          </a>
          <p [innerHtml]="file.name"></p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #audiogrid></ng-template>
</ng-template>

