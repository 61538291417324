import { Component, inject } from '@angular/core';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { VerificationFlowInstanceService } from 'src/app/modules/bee-verification-flow/services/verification-flow-instance.service';

@Component({
  selector: 'bee-cms-module-verification-flow-select',
  templateUrl: './cms-module-verification-flow-select.component.html',
  styleUrls: ['./cms-module-verification-flow-select.component.scss']
})
export class CmsModuleVerificationFlowSelectComponent {
  public verificationFlowInstanceService: VerificationFlowInstanceService = inject(VerificationFlowInstanceService);
  private authService: AuthenticationService = inject(AuthenticationService);

  public verificationFlowInstances$ = this.authService.currentUserSessionStore$.pipe(
    switchMap(u => this.verificationFlowInstanceService.GetAll().pipe(
      tap((instances: any) => {
        if(instances.isSuccessful && instances.data.length > 0 
          && this.verificationFlowInstanceService.selectedVerificationFlowInstance$.value == null) {
  
            this.verificationFlowInstanceService.selectedVerificationFlowInstance$.next(instances.data[0]);
        }
      })
    ))
  );

  public isOpen: boolean = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  selectInstance(instance: any) {
    this.verificationFlowInstanceService.SetVerificationFlowInstance(instance);
    this.isOpen = false;
  }

}


@Component({
  selector: 'bee-cms-module-verification-flow-select-settings',
  templateUrl: './cms-module-verification-flow-select-settings.component.html',
  styleUrls: ['./cms-module-verification-flow-select.component.scss']
})
export class CmsModuleVerificationFlowSelectSettingsComponent {

}
