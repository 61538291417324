import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BeeUnit } from 'src/app/domain/core/models/bee-unit';
import { BeeUser } from 'src/app/domain/core/models/bee-user';

@Component({
  selector: 'bee-unit-information',
  templateUrl: './bee-unit-information.component.html',
  styleUrls: ['./bee-unit-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeUnitInformationComponent implements OnInit {
  @Input() unit?: BeeUnit;
  @Input() unitAdministrators?: BeeUser[];

  constructor() { }

  ngOnInit(): void {
  }

}
