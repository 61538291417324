<ng-container *ngTemplateOutlet="result && result.extraResponseData ? extra : default"></ng-container>

<ng-template #extra>
  <div class="forms-response-extra">
    <div *ngFor="let field of result.extraResponseData.fields" class="form-response-field" [ngClass]="field.cssClass">
      <p>
        <span [innerHtml]="field.label" [style.fontWeight]="field.fontWeight" class="form-response-label"></span>
        <bee-info *ngIf="field.description" [label]="field.description"></bee-info>
      </p>
      <span>
        <span *ngIf="field.valueType == 'number'" [innerHtml]="field.value | number" class="form-response-value" [style.fontWeight]="field.fontWeight"></span>
        <span *ngIf="field.valueType != 'number'" [innerHtml]="field.value" class="form-response-value" [style.fontWeight]="field.fontWeight"></span>
        <span [innerHtml]="field.fieldSuffix" class="form-response-suffix" [style.fontWeight]="field.fontWeight"></span>
      </span>
    </div>

    <div class="forms-response-actions">
      <div *ngFor="let action of result.extraResponseData.actions" class="bee-button margin-right" [ngClass]="action.cssClass" (click)="callAction(action)">
        <span [innerHtml]="action.label"></span>
        <mat-icon *ngIf="action.icon" style="margin-left: 10px;">{{action.icon}}</mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #default>
  <div *ngIf="!result" class="forms-response-default">
    Där finns inget resultat än.
  </div>
</ng-template>

