import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'form-bee-unit-name',
  templateUrl: './bee-unit-name.component.html',
  styleUrls: ['./bee-unit-name.component.scss']
})
export class BeeUnitNameComponent extends FieldType {

  constructor(public authService: AuthenticationService) {
    super();
  }

}
