import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bee-animated-icon',
  templateUrl: './bee-animated-icon.component.html',
  styleUrls: ['./bee-animated-icon.component.scss']
})
export class BeeAnimatedIconComponent implements OnInit {
  animateValue: boolean;

  @Input() start: String;
  @Input() end: String;
  @Input() colorStart: String;
  @Input() colorEnd: String;
  @Input() animateFromParent?: boolean = false;

  @Input()
  get animate() {
    return this.animateValue;
  }

  set animate(val) {
    this.animateValue = val;
    this.animateChange.emit(this.animateValue);
  }

  @Output()
  animateChange = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  toggle(e) {
    if(!this.animateFromParent) this.animate = !this.animate;
  }

}
