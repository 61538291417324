
<div class="feed-wrapper">
  <ng-container *ngTemplateOutlet="moi.settings.content.useLandscape == true ? landscape : normal"></ng-container>
  
</div>

<ng-template #normal>
  <div *ngFor="let item of feedItems | limitTo:moi.settings.content.instagramTotalCount"
    [ngClass]="{'col-1':moi.settings.content.instagramColCount == 1,
      'col-2':moi.settings.content.instagramColCount == 2,
      'col-3':moi.settings.content.instagramColCount == 3,
      'col-4':moi.settings.content.instagramColCount == 4}" >
    <a href="https://www.instagram.com/p/{{item.shortcode}}/" target="_blank">
      <div class="img-wrapper">
        <img [src]="item.thumbnail_src" alt="">
        <div *ngIf="moi.settings.content.showDescription && item.edge_media_to_caption && item.edge_media_to_caption.edges && item.edge_media_to_caption.edges[0]" class="item-description" >
          <p [innerHtml]="item.edge_media_to_caption.edges[0].node.text"></p>
        </div>
      </div>
    </a>
  </div>
</ng-template>


<ng-template #landscape>
  <div *ngFor="let item of feedItems | limitTo:moi.settings.content.instagramTotalCount"
    [ngClass]="{'col-1':moi.settings.content.instagramColCount == 1,
    'col-2':moi.settings.content.instagramColCount == 2,
    'col-3':moi.settings.content.instagramColCount == 3,
    'col-4':moi.settings.content.instagramColCount == 4}">

    <a href="https://www.instagram.com/p/{{item.shortcode}}/" target="_blank">
      <bee-landscape-image [image]="item.thumbnail_src"></bee-landscape-image>
    </a>

  </div>
</ng-template>

