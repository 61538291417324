<div class="search-inner-wrapper">
  <h2>{{'SEARCH.YOU_SEARCH_FOR' | translate}} <span>"{{searchContainerData.query}}"</span></h2>

  <div class="search-result">

    <div class="search-category-box" *ngFor="let searchCategory of searchContainerData.searchItem.indexes">

      <div class="item-cat" *ngIf="searchContainerData.settings.showHeaders">
        <h4>
          <label *ngIf="searchCategory.items.length"> {{searchCategory.name.toUpperCase()}} ({{searchCategory.items.length}}) </label>
        </h4>
      </div>

      <ul>
        <li *ngFor="let searchItem of searchCategory.items">
          <span class="material-icons">
            {{getIcon(searchCategory)}}
          </span> 
          <a (click)="navigateOnItemClick(searchItem.objectType, searchItem.objectKey)"><p class="item">{{searchItem.name}}</p></a>
        </li>
      </ul>

    </div>

  </div>
</div>