<div *ngFor="let data of animData; let x = index" class="mb-4">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <div class="grid grid-cols-4 gap-4 w-full">
                <div class="col-span-3">
                    <p>Trigger: <span [innerHTML]="data.trigger"></span></p>
                </div>
                <div class="col-span-1 flex items-center justify-center">
                    <mat-icon (click)="deleteTrigger(x)" class="text-red-300" matTooltip="Delete trigger">delete</mat-icon>
                </div>
            </div>
        </mat-expansion-panel-header>
    
        <div class="grid grid-cols-4 gap-4 mt-4 max-h-[600px] overflow-scroll">
            <div class="col-span-1 bg-slate-100 p-4">
                <mat-form-field>
                    <input matInput placeholder="Trigger" name="trigger" [(ngModel)]="data.trigger" [ngModelOptions]="{updateOn: 'blur'}">
                </mat-form-field>
        
                <mat-form-field>
                    <input matInput placeholder="Type" name="type" [(ngModel)]="data.type" [ngModelOptions]="{updateOn: 'blur'}">
                </mat-form-field>
        
                <mat-form-field>
                    <input matInput placeholder="Start" name="start" [(ngModel)]="data.start" [ngModelOptions]="{updateOn: 'blur'}">
                </mat-form-field>
        
                <mat-form-field>
                    <input matInput placeholder="End" name="end" [(ngModel)]="data.end" [ngModelOptions]="{updateOn: 'blur'}">
                </mat-form-field>
        
                <div class="mt-4">
                    <mat-checkbox [(ngModel)]="data.scrub" color="primary">Scrub</mat-checkbox>
                </div>
        
                <div class="mt-4">
                    <mat-checkbox [(ngModel)]="data.pin" color="primary">Pin</mat-checkbox>
                </div>
        
                <div class="mt-4">
                    <mat-checkbox [(ngModel)]="data.isActive" color="primary">Is active</mat-checkbox>
                </div>
        
                <div class="mt-4">
                    <mat-checkbox [(ngModel)]="data.debug" color="primary">
                        <div class="flex align-middle justify-center">
                            Debug <mat-icon class="ml-4">bug_report</mat-icon>
                        </div>
                    </mat-checkbox>
                </div>
        
                <div class="bee-button mt-4" (click)="addTarget(data)">Add target</div>
            </div>
            <div class="col-span-3 flex flex-col mb-4 p-4 bg-slate-50 target-list" 
                cdkDropList [cdkDropListData]="data.items" (cdkDropListDropped)="drop($event)">
        
                <div *ngFor="let item of data.items; let y = index" class="mb-4" cdkDrag>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <div class="grid grid-cols-10 gap-4 w-full">
                                <mat-icon cdkDragHandle class="col-span-1 margin-right self-center">drag_handle</mat-icon>
                                <div class="col-span-7">
                                    <p>Target: <span [innerHTML]="item.name"></span></p>
                                </div>
                                <div class="col-span-2 flex items-center justify-center">
                                    <mat-icon (click)="deleteTarget(data, y)" class="text-red-300" matTooltip="Delete target">delete</mat-icon>
                                </div>
                            </div>
                        </mat-expansion-panel-header>
            
                        <div class="grid grid-cols-2 gap-4">
                            <mat-form-field>
                                <input matInput placeholder="Target" name="name" [(ngModel)]="item.name" [ngModelOptions]="{updateOn: 'blur'}">
                            </mat-form-field>
                
                            <mat-form-field>
                                <input matInput placeholder="Function" name="function" [(ngModel)]="item.function" [ngModelOptions]="{updateOn: 'blur'}">
                            </mat-form-field>
        
                            <div class="col-span-2 grid grid-cols-12 gap-2">
                                <div class="col-span-10">
        
                                    <p>Properties:</p>
                                </div>
                                <div class="col-span-2 flex items-center justify-center" matTooltip="Add property">
                                    <mat-icon (click)="addProperty(item)">add</mat-icon>
                                </div>
                            </div>
        
                            <div class="col-span-2 border-t-2 grid grid-cols-12 gap-2 pt-4 pb-4" *ngFor="let prop of item.properties; let i = index">
                                <div class="col-span-5">
                                    <mat-form-field>
                                        <input matInput placeholder="Key" name="key" [(ngModel)]="prop.key" [ngModelOptions]="{updateOn: 'blur'}">
                                    </mat-form-field>
                                </div>
                                <div class="col-span-5">
                                    <mat-form-field>
                                        <input matInput placeholder="Value" name="value" [(ngModel)]="prop.value" [ngModelOptions]="{updateOn: 'blur'}">
                                    </mat-form-field>
                                </div>
                                <div class="col-span-2 flex items-center justify-center" matTooltip="Delete property">
                                    <mat-icon (click)="deleteProperty(item, i)">delete</mat-icon>
                                </div>
                            </div>
                        </div>
            
                    </mat-expansion-panel>
                </div>
        
            </div>
        </div>
    </mat-expansion-panel>
</div>



<div class="bee-button mt-4" (click)="addTrigger()">Add trigger</div>