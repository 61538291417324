import { Injectable } from '@angular/core';

@Injectable()
export abstract class IMediabankService {
  abstract getAllByTypes(types: string);
  abstract getAllByType(type: number);
  abstract getAllPublic();
  abstract getAllNonPublic();
  abstract getAll();
  abstract getByUrl(urlRewrite: string);
  abstract get(id: string);
  abstract getMediaFilesByTag(tagUrlRewrite: string);
  abstract getMediaFilesByTagAndType(tagUrlRewrite: string, type: number);
  abstract getMediaFilesByTagAndTypes(tagUrlRewrite: string, types: number[]);
  abstract getMediaTypes();
  abstract getTypeName(type: number): string;
}