<div class="form-checkbox-wrapper">
  <span class="required" *ngIf="field.templateOptions.required">*</span>

  <div class="form-checkbox-inner">
    <mat-checkbox class="form-checkbox" color="primary" [formControl]="formControl"></mat-checkbox>
    <span [innerHtml]="field.templateOptions.label"></span>
  </div>
</div>

<span class="description s4" [innerHtml]="field.templateOptions.description"></span>
