import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'form-bee-dropdown',
  templateUrl: './bee-dropdown.component.html',
  styleUrls: ['./bee-dropdown.component.scss']
})
export class BeeDropdownComponent extends FieldType {
  constructor() {
    super();
  }
}
