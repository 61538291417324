export class BeeComponentTemplate {
  name: string;
  key: string;
  loadComponent: Function;

  constructor(name: string, componentKey: string, loadComponent: Function) {
    this.name = name;
    this.key = componentKey;
    this.loadComponent = loadComponent;
  }
}

export class BeeComponentOverride {
  loadComponent: Function;
  componentKey: string;
  templates: BeeComponentTemplate[];

  constructor(key: string, loadComponent: Function, templates?: BeeComponentTemplate[]) {
    this.componentKey = key;
    this.loadComponent = loadComponent;
    this.templates = templates || [];
  }
}

const voCollegeOverrides = [
  new BeeComponentOverride("GenericContainerViewMoreComponent", () => {
    return import('../frontend/modules/generic-container/generic-container-view/customers/vo-college/generic-container-view-more-content.component');
  }),
  new BeeComponentOverride("CmsModuleGenericContainerListComponent", null, [
    new BeeComponentTemplate("Goda exempel", "success_stories", () => {
      return import('../customers/vo-college/components/cms-module-templates/success-stories/vo-college-success-stories-template.component');
    })
  ])
];

export const OVERRIDES = {
  "vo-college": voCollegeOverrides,
  "vo-college-dev": voCollegeOverrides
};