<div class="info-text">
    <h2>BYT LÖSENORD</h2>

    <p class="margin-top" *ngIf="showUsername">Fyll i ditt användarnamn samt nuvarande och nytt lösenord.</p>
    <p class="margin-top" *ngIf="!showUsername">Fyll i existerande lösenord och välj ett nytt för att ändra lösenord.
    </p>
</div>

<div class="forgot-password-wrapper">
    <form action="#" (ngSubmit)="updatePassword(myForm);" class="forgot-password-form" #myForm="ngForm">
        <label *ngIf="showUsername" for="username">ANVÄNDARNAMN *</label>
        <input *ngIf="showUsername" required type="text" id="username" [(ngModel)]="username" name="username"
            class="margin-bottom">

        <label for="currentPassword">NUVARANDE LÖSENORD *</label>
        <input required type="password" id="currentPassword" [(ngModel)]="currentPassword" name="currentPassword"
            class="margin-bottom">

        <label for="newPassword">NYTT LÖSENORD *</label>
        <input required type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword"
            class="margin-bottom">

        <label for="repeatNewPassword">SKRIV DET NYA IGEN *</label>
        <input required type="password" id="repeatNewPassword" [(ngModel)]="repeatNewPassword" name="repeatNewPassword"
            class="margin-bottom">


        <div class="margin-top form-alert" *ngIf="error">
            <p [innerHtml]="errorMessage"></p>
        </div>


        <div class="margin-top lg flex end">
            <button class="bee-button secondary margin-right" (click)="close()">Avbryt</button>
            <button class="bee-button">Skicka</button>
        </div>
    </form>
</div>