<!-- <bee-layout layout-type="header"></bee-layout> -->

<div class="bee-header-wrapper">

  <div (click)="goToStartPage()">
    <div class="logo">
      <img src="/assets/images/customers/tpl/logo.svg" alt="logo">
    </div>
    <!-- <div class="logo-text">TPL</div> -->
  </div>
  <div class="bee-header-actions">
    <div>
      <bee-search style="margin-top: -3px;"></bee-search>
      <!-- <img src="/assets/images/customers/tpl/search.svg" class="tpl-icon" alt="Search"> -->
      <!-- <span class="hide-mobile">Sök</span> -->
    </div>
    <a class="hide-mobile" routerLink="/kontrolltornet">
      <!-- <i class="material-icons">
        person
      </i> -->
      <img src="/assets/images/customers/tpl/control_tower.svg" class="tpl-icon" alt="Control tower" style="width: 36px; height: 23px;">
      <span>Kontrolltornet</span>
    </a>
    <a class="hide-mobile" routerLink="/about">
      <img src="/assets/images/customers/tpl/Engelsk_flagga.svg" class="tpl-icon" alt="Control tower" style="width: 36px; height: 23px;">
      <span>About us</span>
    </a>
    <!-- <div class="hide-mobile disabled">
      <img src="/assets/images/customers/tpl/intranet.svg" class="tpl-icon" alt="Intranät" style="width: 29px; height: 26px;">
      <span>Intranät</span>
    </div> -->
    <div>
      <bee-menu></bee-menu>
      <span class="hide-mobile" style="margin-top: 3px;">Meny</span>
    </div>
  </div>

</div>
