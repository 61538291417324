import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { BlogService } from '../services/blog.service';
import { CMSContainer } from 'src/app/container/cms-models';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { BeeMetaService } from '../../services/bee-meta.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BlogSiteSettings } from 'src/app/authorize/models/user-session';

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogViewComponent implements OnInit {
  sub: any;
  urlRewrite: String;
  public blog: any;
  public relatedBlogs: any[];
  public allBlogs: any[];
  sliderBlogViewOptions: OwlOptions;

  public blogSiteSettings: BlogSiteSettings = new BlogSiteSettings();

  public template: string = 'default';

  private scrollEv: any;

  constructor(private blogService: BlogService, private route: ActivatedRoute, private router: Router,
    private metaService: BeeMetaService, private cdnPipe: CdnPipe,
    @Inject(DOCUMENT) private document: Document, 
    private authService: AuthenticationService) { 

      this.blogSiteSettings = this.authService.currentUserValue.siteSettings.blogSiteSettings;
      console.log('BLOG SITE SETTINGS:', this.blogSiteSettings);

    this.initSlider();
    this.blog = {
      cmsContainer: new CMSContainer()
    };

    this.relatedBlogs = [];
    this.allBlogs = [];
  }

  goToBlog(blog) {
    const dom: HTMLElement = this.document.body;
    const wrapper = dom.querySelectorAll('.app-wrapper')[0];
    wrapper.scrollTo(0, 0);

    this.router.navigate(['/modules/blog/', blog.urlRewrite]);
  }

  ngOnInit() {

    this.scrollEv = this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const dom: HTMLElement = this.document.body;
      const wrapper = dom.querySelectorAll('.app-wrapper')[0];
      wrapper.scrollTo(0, 0);
    });

    this.sub = this.route.params.subscribe(params => {
      this.urlRewrite = params['urlRewrite']; 

      this.blogService.GetByUrl(this.urlRewrite).subscribe((data: any) => {
        if(data.Blog && data.CMSContainerBlog) {
          this.blog = data.Blog;
          this.blog.cmsContainer = new CMSContainer(data.CMSContainerBlog);

          this.metaService.setBasicMeta(this.blog.name, this.blog.description,
            this.cdnPipe.transform(this.blog.imgUrl));

          this.getRelated();
          this.getAllBlogs();
        }
      })
   });
  }

  getRelated() {
    if(this.blogSiteSettings.showRelatedBlogs) {
      this.blogService.GetRelated(this.blog.id).subscribe((data: any) => {
        this.relatedBlogs = data;
      });
    }
  }

  getAllBlogs() {
    this.blogService.GetBlogEntries().subscribe(response => {
      this.allBlogs = response.data;
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.scrollEv.unsubscribe();
  }

  initSlider() {
    this.sliderBlogViewOptions = {
      center: false,
      dots: true,
      autoWidth: false,
      autoplay: false,
      loop: true,
      autoplayHoverPause: true, 
      responsive: {
        720: {
          items: 4,
          slideBy: 4,
          nav: false
        },
        0: {
          items: 2,
          slideBy: 2,
          nav: false
        }
      }
    };
  }

  selectTag(tag) {
    this.router.navigate(['/modules/tag-blogs/', (tag.urlRewrite || tag.tagUrlRewrite)]);
  }
}
