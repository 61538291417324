import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';

@Component({
  selector: 'bee-cms-module-html-input',
  templateUrl: './cms-module-html-input.component.html',
  styleUrls: ['./cms-module-html-input.component.scss']
})
export class CmsModuleHtmlInputComponent implements OnInit {

  constructor(public module: CMSModuleInstance, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { 

      if(!this.module.settings.content.scripts) {
        this.module.settings.content.scripts = [];
      }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadJavascripts();
  }

  loadJavascripts() {

    for(let i = 0; i < this.module.settings.content.scripts.length; i++) {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.id = this.module.settings.content.scripts[i].id;
      script.src = this.module.settings.content.scripts[i].src;

      for(let y = 0; y < this.module.settings.content.scripts[i].attributes.length; y++) {
        script.setAttribute(this.module.settings.content.scripts[i].attributes[y].name,
          this.module.settings.content.scripts[i].attributes[y].val);
      }

      this.renderer.appendChild(this.document.head, script);
    }
  }

}


@Component({
  selector: 'bee-cms-module-html-input-settings',
  templateUrl: './cms-module-html-input-settings.component.html',
  styleUrls: ['./cms-module-html-input.component.scss']
})
export class CmsModuleHtmlInputSettingsComponent implements OnInit {

  constructor(public module: CMSModuleInstance) { }

  ngOnInit(): void {
  }

  addJavascript() {
    this.module.settings.content.scripts.push({
      id: "",
      src: "",
      attributes: []
    });
  }

  addAttributeToJavascript(script) {
    script.attributes.push({
      name: "",
      val: ""
    });
  }

}
