import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * Get
   */
  public Get() {
    return this.http.get(this.config.apiEndpoint + "api-menu/get");
  }
}
