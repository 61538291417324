<mat-form-field class="margin-top">
  <mat-label>Select Template</mat-label>
  <mat-select [(value)]="module.settings.content.template">
    <mat-option value="DEFAULT">Default</mat-option>
    <mat-option value="MOBILE">Mobile</mat-option>
    <mat-option value="BIG">Big</mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field>
  <input matInput placeholder="Font size" name="text" [(ngModel)]="module.settings.content.navFontSize">
</mat-form-field>

<mat-form-field>
  <input matInput placeholder="Font color" name="text" [(ngModel)]="module.settings.content.navFontColor">
</mat-form-field>

<mat-form-field>
  <input matInput placeholder="Hover color" name="text" [(ngModel)]="module.settings.content.navItemHoverColor">
</mat-form-field>

<mat-form-field>
  <mat-label>Position navigation items</mat-label>
  <mat-select [(ngModel)]="module.settings.content.navItemFlexPosition">
    <mat-option value="flex-start">
      Start
    </mat-option>
    <mat-option value="center">
      Center
    </mat-option>
    <mat-option value="flex-end">
      End
    </mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field *ngIf="!responsiveNavOption">
  <input matInput placeholder="Margin left for nav items" name="text" [(ngModel)]="module.settings.content.marginLeft">
</mat-form-field>

<mat-form-field *ngIf="!responsiveNavOption">
  <input matInput placeholder="Margin right for nav items" name="text" [(ngModel)]="module.settings.content.marginRight">
</mat-form-field>

<mat-form-field *ngIf="responsiveNavOption ">
  <input matInput placeholder="Opacity for background on expanded" name="text" [(ngModel)]="module.settings.content.navBgOpacity">
</mat-form-field>

<mat-form-field *ngIf="responsiveNavOption ">
  <input matInput placeholder="Padding from top of screen" name="text" [(ngModel)]="module.settings.content.navTopPadding">
</mat-form-field>

<mat-form-field *ngIf="responsiveNavOption ">
  <input matInput placeholder="Margin bottom for nav items" name="text" [(ngModel)]="module.settings.content.navItemsMarginBottom">
</mat-form-field>

<!-- <mat-checkbox class="responsive"
  color="primary"
              (click)="responsiveNavMode()">
              Responsive version
</mat-checkbox> -->

<div>
  <mat-checkbox class="responsive"
    color="primary"
    [(ngModel)]="module.settings.content.showIndicator">
    Show indicator
  </mat-checkbox>
</div>
