import { Pipe, PipeTransform } from '@angular/core';
import { IPermissionService } from '../interfaces/permission.service';

@Pipe({
  name: 'sortHasPermission'
})
export class SortHasPermissionPipe implements PipeTransform {

  constructor(private permissionService: IPermissionService) {

  }

  transform(value: any[], permissionFieldName: string = 'permission'): any[] {
    return value.filter(v => this.permissionService.hasPermission(v[permissionFieldName]));
  }

}
