import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo',
  pure: false
})
export class LimitToPipe implements PipeTransform {

  transform(value: any[], args: any) : any[] {
    let limit = args ? parseInt(args, 10) : 10;

    if(value && value.length > limit) {
      let newVal = [];
      for(let i = 0; i < limit; i++) {
        newVal.push(value[i]);
      }

      return newVal;
    }

    return value;
  }

}
