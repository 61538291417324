import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseEntity } from 'src/app/admin/academy/models/course-entity';
import { IComponentsCourseService } from 'src/app/bee-components/interfaces/i-course-service';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { ICourseService } from 'src/app/container/interfaces/course.service.interface';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseService implements ICourseService, IComponentsCourseService {

  constructor(private http: HttpClient, private config: AppConfigService) { }
  getMyCompletedEducationModulesWithDate(): Observable<any[]> {
    return this.http.get<any[]>(this.config.apiEndpoint + 'api-academy/education/getMyCompletedEducationModulesWithDate');
  }

  getCertificate(certificateId: string, courseId: string) {
    window.open(this.config.apiEndpoint + 'api-academy/certificate/getCertificate?certificateId=' + certificateId + '&courseId=' + courseId);
  }

  getCompletedCourseIds(): Observable<string[]> {
    return this.http.get<string[]>(this.config.apiEndpoint + 'api-academy/course/getCompletedCourseIds');
  }
  getMyCompletedEducationModules(): Observable<string[]> {
    return this.http.get<string[]>(this.config.apiEndpoint + 'api-academy/education/getMyCompletedEducationModules');
  }

  getAll(deepload?: boolean, pageSize?: number, pageIndex?: number): Observable<PaginationReturnModel<CourseEntity[]>> {
    if(!pageSize){
      pageSize = 10;
    }

    if(!pageIndex){
      pageIndex = 0;
    }

    return this.http.get<PaginationReturnModel<CourseEntity[]>>(this.config.apiEndpoint + 'api-academy/course/getall?deepload=' + (deepload || false) + '&pageSize=' + pageSize + '&pageIndex=' + pageIndex);
  }
  get(id: string): Observable<CourseEntity> {
    return this.http.get<CourseEntity>(this.config.apiEndpoint + 'api-academy/course/get?id=' + id);
  }
}
