import { NavService } from './nav-service.service';
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cms-module-navigation',
  templateUrl: './cms-module-navigation.component.html',
  styleUrls: ['./cms-module-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CmsModuleNavigationComponent implements OnInit {
  styleSheet: CSSStyleSheet = <CSSStyleSheet>document.styleSheets[0];
  expandedMenu: boolean = false;
  containsChildren: boolean = false;
  scrollInstance: any;
  public menuOpen: boolean = false;

  public nav$: Observable<any[]>;

  burgerToggle(){
    this.expandedMenu = !this.expandedMenu;
    console.log('clicked');
  }

  isNavItem(item: any): boolean {
    return false;
  }

  getRouterLinkOptions(link: string) {

    if(link == '' || link == '/') {
      return { exact: true };
    }

    return { exact: false };
  }

  constructor(public module: CMSModuleInstance, private navService: NavService, private authService: AuthenticationService, 
    public router: Router, private elementRef: ElementRef, private renderer: Renderer2) {

      if(!this.module.settings.content.template) {
        this.module.settings.content.template = "DEFAULT";
      }
  }

  navigate(url: string) {
    if(url.indexOf("http") >= 0) {
      window.location.href = url;
    }
    else {
      this.router.navigate([url]);
    }
  }

  ngOnInit() {

    this.nav$ = this.navService.Get(this.authService.getCurrentCulture().iso2).pipe(
      tap(n => n.forEach(n => {
        if(n.url == '' || n.url == '/') {
          n.linkActiveOptions = { exact: true };
        }
        else {
          n.linkActiveOptions = { exact: true };
        }
      }),
      map((n: any[]) => n.sort((a, b) => a.order - b.order))
    ));

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });

    const body = this.elementRef.nativeElement.closest('body');
    const wrapper = body.querySelectorAll('.app-wrapper')[0];
    this.scrollInstance = this.renderer.listen(wrapper, 'scroll', () => {
      this.menuOpen = false;
    });
  }

  setPseudoStyle(hoverColor){
    (this.styleSheet.insertRule(`ul li a:hover { color: ${hoverColor} !important; }`, this.styleSheet.cssRules.length));
  }

  dropdownChildren(){
    this.containsChildren = !this.containsChildren;
    console.log('this has children');
  }

  ngAfterViewInit(){
    this.setPseudoStyle(this.module.settings.content.navItemHoverColor);
    console.log(this.styleSheet);
  }
}

@Component({
  selector: 'app-cms-module-navigation-settings',
  templateUrl: './cms-module-navigation-settings.component.html',
  styleUrls: ['./cms-module-navigation.component.scss']
})
export class CmsModuleNavigationSettingsComponent implements OnInit {
  responsiveNavOption: boolean = false;

  constructor(public module: CMSModuleInstance) {
  }

  responsiveNavMode(){
    if(this.responsiveNavOption == false){
      this.module.settings.content.menuMode = 'burger';
      this.responsiveNavOption = !this.responsiveNavOption;
    }
    else{
      this.module.settings.content.menuMode = null;
      this.responsiveNavOption = !this.responsiveNavOption;
    }
  }

  ngOnInit() {
  }
}
