import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ScormService } from 'src/app/modules/bee-academy/services/scorm.service';
import dayjs from 'dayjs'
import { Router } from '@angular/router';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { SafeHtml } from 'src/app/bee-pipes/safe/safe.pipe';
import { DomSanitizer } from '@angular/platform-browser';


type JourneyTemplate = 'default' | 'vertical';

@Component({
  selector: 'bee-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class JourneyComponent implements OnInit {
  @Input() template: JourneyTemplate = 'default';

  hasHandeledItems: boolean = false;
  _items: JourneyItem[];

  public itemsToShow: JourneyItem[] = [];
  public overlayItems: JourneyItem[] = [];

  public itemsToShowVertical: any[] = [];
  

  private expandedChapters: string[];
 
  @Input()
  get items(): JourneyItem[] 
  {
    return this._items;
  }

  set items(val: JourneyItem[]) 
  {
    this._items = val;
    this.handleItemsVertical();
    this.handleItems();

    if(this.expandedChapters.length > 0) {
      this.initExpandedChapters();
    }
  }

  initExpandedChapters() {
    this.itemsToShowVertical.forEach(v => {
      if(this.expandedChapters.indexOf(v[0].itemId) >= 0) {
        v.isExpanded = true;
      }
    });
  }

  getTooltip(item: JourneyItem) {
    return this.sanitizer.bypassSecurityTrustHtml(`<div class="journey-tooltip-wrapper">
    ${this.getItemImageHtml(item)}
    <h3>${item.name}</h3>
    ${this.getItemDescriptionHtml(item)}
  </div>`)
  }

  getItemDescriptionHtml(item: JourneyItem) {
    if(item.description && item.description != null) {
      return `<div>${item.description}</div>`;
    }

    return "";
  }

  getItemImageHtml(item: JourneyItem) {
    if(item.image) {
      return `<div class="tooltip-img-wrapper"><img src="${this.cdnPipe.transform(item.image)}" /></div>`;
    }

    return "";
  }

  handleItems() {
    if(!this.hasHandeledItems) {
      this.overlayItems = [];
      this.itemsToShow = [];
      for(let i = 0; i < this._items.length; i++) {
        if(this._items[i].itemType == "ACADEMY_PHYSICAL") {
          this.overlayItems.push(this._items[i]);
        }
        else {
          this.itemsToShow.push(this._items[i]);
        }
      }
      if(this.itemsToShow.length > 0) {
        this.hasHandeledItems = true;
      }
    }
  }

  handleItemsVertical() {
    if(!this.hasHandeledItems) {
      this.itemsToShowVertical = [];
      var index = 0;
      for(let i = 0; i < this._items.length; i++) {
        if(i > 0 && this._items[i].itemType == 'ACADEMY_CHAPTER') {
          index++;
        }
        if(!this.itemsToShowVertical[index]) {
          this.itemsToShowVertical[index] = [];
        }
        this.itemsToShowVertical[index].push(this._items[i]);
      }
    }

    console.log('VERTICAL ITEMS: ', this.itemsToShowVertical);
  }


  constructor(private scormService: ScormService, private router: Router, private cdnPipe: CdnPipe, private sanitizer: DomSanitizer,
    private elementRef: ElementRef) { 

      this.expandedChapters = JSON.parse(sessionStorage.getItem('expandedChapters')) || [];

    }

  ngOnInit(): void {

    var css = `bee-journey { --primary-color: #008DA2; --dark-grey-line: #001E24; --star-done-color: #ED8E00 }`;
    var style: any = document.createElement('style');
    style.type = 'text/css';

    style.appendChild(document.createTextNode(css));

    this.elementRef.nativeElement.appendChild(style);    
  }

  getChapterIsFinishedIcon(chapter: JourneyItem[]) {
    let icon = 'star';

    // if(!this.isChapterFinished(chapter)) {
    //   icon = 'star';
    // }


    return icon;
  }

  isChapterFinished(chapter: JourneyItem[]): boolean {
    let completed = true;
    for(let i = 0; i < chapter.length; i++) {
      if(!chapter[i].isCompleted && !chapter[i].optional) {
        completed = false;
        break;
      }
    }

    return completed
  }

  getIcon(item): string {

    if(item.isCompleted) {
      return this.getFinishedIcon(item);
    }

    switch(item.itemType) {
      case "ACADEMY_EVALUATION":
        return "star";
      default:
        return "fiber_manual_record";
    }
  }

  getFinishedIcon(item): string {
    switch(item.itemType) {
      case "ACADEMY_EVALUATION":
        return "verified"
      case "ACADEMY_CHAPTER":
        return "fiber_manual_record";
      default:
        return "check_circle";
    }
  }

  openItem(item): void {    
    switch(item.itemType) {
      case "ACADEMY_SCORM":
        this.openScormEducation(item);
        break;
      case "COURSE_INTRO":
        this.router.navigate(["modules/academy/course/intro", item.itemId]);
        break;
      default:
        this.router.navigate(["modules/academy/education", item.itemId]);
    }
  }

  hasOverlayItem(item: JourneyItem): boolean {
    let hasOverlay = false;

    if(item.startTimeUtc) {
      for(let i = 0; i < this.overlayItems.length; i++) {
        if(dayjs(item.startTimeUtc).isBetween(this.overlayItems[i].startTimeUtc, dayjs(this.overlayItems[i].endTimeUtc).endOf('day')) ||
          dayjs(item.endTimeUtc).isBetween(this.overlayItems[i].startTimeUtc, dayjs(this.overlayItems[i].endTimeUtc).endOf('day'))) {
  
          hasOverlay = true;
          break;
        }
      }
    }

    return hasOverlay;
  }

  isLastInOverlayItems(index: number): boolean {
    let isLast = false;

    if(this.items.length > index + 1) {
      let nextItem = this.items[index + 1];
      for(let i = 0; i < this.overlayItems.length; i++) {
        if(!dayjs(nextItem.startTimeUtc).isBetween(this.overlayItems[i].startTimeUtc, dayjs(this.overlayItems[i].endTimeUtc).endOf('day')) &&
          !dayjs(nextItem.endTimeUtc).isBetween(this.overlayItems[i].startTimeUtc, dayjs(this.overlayItems[i].endTimeUtc).endOf('day'))) {
  
            isLast = true;
            break;
        }
      }
    }
    else {
      isLast = true;
    }

    return isLast;
  }

  openScormEducation(item: JourneyItem) {
    window.open(`/academy/SCORMPlayer?id=${item.itemId}`, "scorm", "height=720,width=1080")
  }

  toggleChapter(chapter: any) {
    if(chapter.isExpanded) {
      // this.itemsToShowVertical.forEach(c => {
      //   c.isExpanded = false;
      // });
  
      chapter.isExpanded = true;
      this.expandedChapters.push(chapter[0].itemId);
    }
    else {
      chapter.isExpanded = false;
      this.expandedChapters.splice(this.expandedChapters.indexOf(chapter[0].itemId), 1);
    }

    sessionStorage.setItem("expandedChapters", JSON.stringify(this.expandedChapters));
  }

}

export class JourneyItem {
  itemId: string;
  itemType: string;
  name: string;
  description: string;
  image: any;
  isCompleted: boolean;
  startTimeUtc?: Date;
  endTimeUtc?: Date;
  isExpanded: boolean = false;
  optional?: boolean = false;

  constructor(data: JourneyItem) {
    this.itemId = data.itemId;
    this.itemType = data.itemType;
    this.name = data.name;
    this.description = data.description;
    this.image = data.image;
    this.startTimeUtc = data.startTimeUtc;
    this.endTimeUtc = data.endTimeUtc;
    this.isCompleted = data.isCompleted;
    this.isExpanded = data.isExpanded;
    this.optional = data.optional;
  }
}
