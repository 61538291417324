import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { ITagInterface } from 'src/app/container/interfaces/tag.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TagService implements ITagInterface {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getTags() {
    return this.http.get(this.config.apiEndpoint + "api-tag/GetActiveTags");
  }
}
