import { Component, OnInit, Injector, Input, Output, EventEmitter, InjectionToken } from '@angular/core';
import { CMSModuleAbstract, CMSModuleInstance, CMSContainer, ModuleInstanceTemplate } from 'src/app/container/cms-models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CmsEventService } from 'src/app/container/cms-editor/cms-event-service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import * as ClassicEditor from 'src/lib/ckeditor';
import { GenericContainer } from 'src/app/admin/generic-container/generic-container';
import { GenericContainerService } from 'src/app/admin/generic-container/generic-container.service';
import { ContainerDialogService } from 'src/app/admin/container-dialog/container-dialog.service';
import { AddModuleDialogComponent } from './add-module-dialog/add-module-dialog.component';
import { IContainerService } from '../interfaces/container.service.interface';
import { ContainerService } from '../../admin/container/container.service';
import { CmsEditorCreateModuleInstanceTemplateDialogComponent } from './cms-editor-create-module-instance-template-dialog/cms-editor-create-module-instance-template-dialog.component';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CmsModuleService } from '../cms-services/cms-module-service.service';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { ShowJsonDialogComponent } from './show-json-dialog/show-json-dialog.component';
import { PasteJsonDialogComponent } from './paste-json-dialog/paste-json-dialog.component';
import { CmsAnimationEditDialogComponent } from './cms-animation-edit-dialog/cms-animation-edit-dialog.component';


export const DEVICE_EMULATION = new InjectionToken<string>('');

@Component({
  selector: 'app-cms-editor',
  templateUrl: './cms-editor.component.html',
  styleUrls: ['./cms-editor.component.scss'],
})
export class CmsEditorComponent implements OnInit {

  @Output() selModuleChange = new EventEmitter<CMSModuleInstance>();

  public deviceEmulation: string = "desktop";

  selectedModuleInstance: CMSModuleInstance;
  selectedModuleSettingsSection: any = {
    showContent: true,
    showTextStyle: false,
    showElementStyle: false
  };
  public hasCopiedMoi: boolean;
  public hasCopiedContainer: boolean;

  public Editor = ClassicEditor;
  public editorConfig: any;
  public dialogs: GenericContainer[];
  public eventChoice: any;
  public eventChoiceOptions = [
    {value:"none" ,key:"None"},
    {value:"link",key:"Link"},
    {value:"event:top",key:"Scroll to top"},
    {value:"event:bottom",key:"Scroll to bottom"},
    {value:"event:page",key:"Scroll one page"},
  ]

  @Input() container: CMSContainer;
  @Input() role: string = "admin";

  moduleAbstracts: Array<CMSModuleAbstract>;

  constructor(public injector: Injector,
    private eventService: CmsEventService,
    public dialog: MatDialog,
    private genericContainerService: GenericContainerService,
    private containerDialogService: ContainerDialogService,
    private containerService: IContainerService,
    private snackbar: MatSnackBar,
    private cmsModuleService: CmsModuleService) {
      this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
      this.dialogs = [];
  }

  selectDeviceEmulation(e: MatButtonToggleChange): void {
    this.deviceEmulation = e.value;
    this.eventService.setDeviceEmulation.emit(this.deviceEmulation);
  }

  dropModuleInstance(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.container.moduleInstances, event.previousIndex, event.currentIndex);

    //Give all items new order...
    for(let i = 0; i < this.container.moduleInstances.length; i++) {
      this.container.moduleInstances[i].order = i;
    }
  }

  getName(moi: CMSModuleInstance): string {
    if(moi.name) {
      return ", " + moi.name;
    }

    return "";
  }

  toggleShowHide(event: MatButtonToggle) {
    if(event.value == 'show') {
      this.selectedModuleInstance.settings.style.display = 'block';
      this.selectedModuleInstance.settings.mobileStyle.display = 'block';
    }
    else if (event.value == 'hide') {
      this.selectedModuleInstance.settings.style.display = 'none';
      this.selectedModuleInstance.settings.mobileStyle.display = 'none';
    }
  }

  getShowHideValue() {
    if(this.selectedModuleInstance.settings.style.display == 'none' && this.selectedModuleInstance.settings.mobileStyle.display == 'none') {
      return 'hide';
    }

    return 'show';  
  }

  addModule() {
    const dialogRef = this.dialog.open(AddModuleDialogComponent, {});

    dialogRef.afterClosed().subscribe((moi: CMSModuleInstance) => {
      if (moi){
        moi.order = this.container.moduleInstances.length + 1;
        this.container.moduleInstances.push(moi);
        this.setSelectedMoi(moi);
      }
    });
  }

  showJsonDialog(storageKey: string) {
    const dialogRef = this.dialog.open(ShowJsonDialogComponent, {data: {
      json: sessionStorage.getItem(storageKey)
    }});
  }

  pasteJsonDialog(storageKey: string) {
    const dialogRef = this.dialog.open(PasteJsonDialogComponent, {data: {
      storageKey: storageKey
    }});

    dialogRef.afterClosed().subscribe(t => {
      if(t) {
        if(storageKey == "COPY_CMS_CONTAINER") {
          this.hasCopiedContainer = true;
        }
        else {
          this.hasCopiedMoi = true;
        }
      }
    });
  }

  deleteMoi(moi: CMSModuleInstance) {
    const dialogRef = this.dialog.open(CmsEditorComponentDeleteMoiDialogComponent, {});

    dialogRef.afterClosed().subscribe(truthy => {
      if (truthy){
        moi.isDeleted = true;
        this.setSelectedMoi(undefined);
      }
    });
  }

  createModuleInstanceTemplate(moi: CMSModuleInstance) {
    let template = new ModuleInstanceTemplate(moi);

    const dialogRef = this.dialog.open(CmsEditorCreateModuleInstanceTemplateDialogComponent, { data: template });

    dialogRef.afterClosed().subscribe(temp => {
      if(temp) {
        this.containerService.saveModuleInstanceTemplate(temp).subscribe((data: any) => {

          var config: MatSnackBarConfig = new MatSnackBarConfig();
          config.duration = 1000;
          this.snackbar.open("Created module template successfully!", null, config);

        });
      }
    })
  }

  moveDown(moi: CMSModuleInstance) {
    moveItemInArray(this.container.moduleInstances, moi.order, moi.order + 1);

    //Give all items new order...
    for (let i = 0; i < this.container.moduleInstances.length; i++) {
      this.container.moduleInstances[i].order = i;
    }
  }

  moveUp(moi: CMSModuleInstance) {
    moveItemInArray(this.container.moduleInstances, moi.order, moi.order - 1);

    //Give all items new order...
    for (let i = 0; i < this.container.moduleInstances.length; i++) {
      this.container.moduleInstances[i].order = i;
    }
  }

  drop(event: CdkDragDrop<CMSModuleAbstract>) {
    if (event.previousContainer !== event.container) {
      var abstract = event.previousContainer.data[event.previousIndex];
      var instance: CMSModuleInstance = abstract.createModuleInstance();
      instance.order = this.container.moduleInstances.length + 1;
      instance.settings.style.paddingTop = "50px";
      instance.settings.style.paddingBottom = "50px";
      instance.settings.style.paddingLeft = "25px";
      instance.settings.style.justifyContent = "center";
      this.container.moduleInstances.push(instance);
    }
  }

  dropInner(event: CdkDragDrop<string[]>) {
    debugger;
    moveItemInArray(this.container.moduleInstances, event.previousIndex, event.currentIndex);
    //Give all items new order...
    for (let i = 0; i < this.container.moduleInstances.length; i++) {
      this.container.moduleInstances[i].order = i;
    }
  }

  setSelectedMoiById(id: string, $event) {
    let moi: CMSModuleInstance = this.container.getModuleInstance(id);

    this.setSelectedMoi(moi);

    $event.stopPropagation();
  }

  setSelectedMoi(moi: CMSModuleInstance) {
    this.selectedModuleInstance = moi;
    if(this.selectedModuleInstance.settings.content.scrollOption == undefined){
      this.selectedModuleInstance.settings.content.scrollOption = "none";
    }

    this.selModuleChange.emit(moi);
  }

  copyMoi(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    this.container.moduleInstances.push(newMoi);
  }

  copyContainer() {
    let moduleInstances = [];
    for(let i = 0; i < this.container.moduleInstances.length; i++) {
      let newMoi = this.container.moduleInstances[i].copy();
      moduleInstances.push(newMoi);
    }
    sessionStorage.setItem("COPY_CMS_CONTAINER", JSON.stringify(moduleInstances));
    this.hasCopiedContainer = true;
  }

  addCopiedContainer() {
    let moduleInstances = JSON.parse(sessionStorage.getItem("COPY_CMS_CONTAINER"));
    for(let i = 0; i < moduleInstances.length; i++) {
      this.container.moduleInstances.push(new CMSModuleInstance(moduleInstances[i]));
    }
    this.hasCopiedContainer = false;
    sessionStorage.removeItem("COPY_CMS_CONTAINER");
  }

  setMobileBackgroundImage(url) {
    this.selectedModuleInstance.settings.mobileStyle.backgroundImage = url;
  }

  setMobileBackgroundVideo(url) {
    this.selectedModuleInstance.settings.mobileStyle.backgroundVideo = url;
  }

  saveCopy(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    sessionStorage.setItem("COPY_CMS_MOI", JSON.stringify(newMoi));
    this.hasCopiedMoi = true;
  }

  addCopiedModule() {
    let newMoi = new CMSModuleInstance(JSON.parse(sessionStorage.getItem("COPY_CMS_MOI")));
    newMoi.parentId = "";
    this.container.moduleInstances.push(newMoi);
  }

  isAdmin(): boolean {
    if(this.container.templateId && this.container.template) {
      return false;
    }

    return this.role.toLowerCase() == 'admin';
  }

  ngOnInit() {
    this.eventService.setSelectedModuleInstance.subscribe((data: CMSModuleInstance) => {
      
      if (data) {
        if(!data.injectSettings) {
          data.injectSettings = Injector.create({providers: [{provide: CMSModuleInstance, useValue: data, deps: []}], parent: this.injector});
        }

        this.setSelectedMoi(data);
      }
    });

    this.initCkEditorConfig();

    if(sessionStorage.getItem("COPY_CMS_MOI")) {
      this.hasCopiedMoi = true;
    }
    else {
      this.hasCopiedMoi = false;
    }

    if(sessionStorage.getItem("COPY_CMS_CONTAINER")) {
      this.hasCopiedContainer = true;
    }
    else {
      this.hasCopiedContainer = false;
    }

    this.getDialogs();
  }

  getDialogs() {
    this.genericContainerService.GetList(this.containerDialogService.CONTAINER_KEY)
      .subscribe((data: GenericContainer[]) => {
        this.dialogs = data;
      });
  }

  initCkEditorConfig() {
    this.editorConfig = {
      toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'link'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
        ]
      },
      link: {
        decorators: {
          addTargetToLinks: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      }
    };
  }

  animations(): void {
    const dialogRef = this.dialog.open(CmsAnimationEditDialogComponent, { data: this.container.animationData });
  }

}

@Component({
  selector: 'app-cms-editor-delete-moi-dialog',
  templateUrl: 'app-cms-editor-delete-moi-dialog.html',
  styleUrls: ['./app-cms-editor-delete-moi-dialog.component.scss']
})
export class CmsEditorComponentDeleteMoiDialogComponent {
  moduleAbstracts: Array<CMSModuleAbstract>;

  constructor(public dialogRef: MatDialogRef<CmsEditorComponentDeleteMoiDialogComponent>, private cmsModuleService: CmsModuleService) {
    this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
  }

  close(truthy: boolean): void {
    this.dialogRef.close(truthy);
  }

}
