import { Injectable } from '@angular/core';
import { CountryModel } from '../models/country-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  public scandinavianCountries: CountryModel[];
  private allCountries: CountryModel[];

  constructor(public http: HttpClient) { 
    this.initScandinavianCountries();
    //this.initAllCountries();
  }

  initScandinavianCountries() {
    this.scandinavianCountries = [
      new CountryModel({
        name: "Sweden",
        countryCode: "SE",
        countryCodeLong: "sv-SE",
        flagImgUrl: "cdn/sv.png",
        phonePrefix: "+46"
      }),
      new CountryModel({
        name: "Norway",
        countryCode: "NO",
        countryCodeLong: "no-NO",
        flagImgUrl: "cdn/no.png",
        phonePrefix: "+47"
      }),
      new CountryModel({
        name: "Denmark",
        countryCode: "DK",
        countryCodeLong: "de-DK",
        flagImgUrl: "cdn/dk.png",
        phonePrefix: "+45"
      }),
      new CountryModel({
        name: "Finland",
        countryCode: "FI",
        countryCodeLong: "fi-FI",
        flagImgUrl: "cdn/fi.png",
        phonePrefix: "+358"
      })
    ]
  }

  initAllCountries() {
    if(!this.allCountries) {
      this.http.get("assets/data/countries.json").subscribe((data: CountryModel[]) => {
        this.allCountries = data;
      });
    }
  }

  getAllCountries() {
    return this.http.get("assets/data/countries.json");
  }
}
