import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-checkbox',
  templateUrl: './bee-form-checkbox.component.html',
  styleUrls: ['./bee-form-checkbox.component.scss']
})
export class BeeFormCheckboxComponent extends FieldType {
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
}
