import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-panel-wrapper',
  templateUrl: './bee-form-panel-wrapper.component.html',
  styleUrls: ['./bee-form-panel-wrapper.component.scss']
})
export class BeeFormPanelWrapperComponent extends FieldWrapper {
}
