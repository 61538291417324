import { Injectable, Type } from '@angular/core';
import { AuthenticationService } from '../authorize/services/authentication.service';
import { BeeComponentTemplate, OVERRIDES } from './bee-component-overrides';

@Injectable({
  providedIn: 'root'
})
export class BeeComponentService {

  constructor(private authService: AuthenticationService) { 

  }

  getComponent(key: string, fallback: Type<any>): Promise<Type<any>> {
    let promise = new Promise<Type<any>>((resolve, reject) => {

      if(OVERRIDES[this.authService.currentUserValue.siteSettings.customer]) {
        for(let i = 0; i < OVERRIDES[this.authService.currentUserValue.siteSettings.customer].length; i++) {
          if(OVERRIDES[this.authService.currentUserValue.siteSettings.customer][i].componentKey == key) {
            OVERRIDES[this.authService.currentUserValue.siteSettings.customer][i].loadComponent().then(r => {
              console.log('Loaded customer specific component: ' + key);
              resolve(r[key]);
            }, err => {
              resolve(fallback);
            });
          }
        }
      }
      else {
        resolve(fallback);
      }

    });

    return promise;
  }

  getComponentTemplates(key: string): BeeComponentTemplate[] {
    let templates = [];

    if(OVERRIDES[this.authService.currentUserValue.siteSettings.customer]) {
      for(let i = 0; i < OVERRIDES[this.authService.currentUserValue.siteSettings.customer].length; i++) {
        if(OVERRIDES[this.authService.currentUserValue.siteSettings.customer][i].componentKey == key) {
          templates = OVERRIDES[this.authService.currentUserValue.siteSettings.customer][i].templates;
        }
      }
    }

    return templates;
  }

  getComponentTemplateComponent(componentKey: string, templateKey: string): Promise<Type<any>> {
    let promise = new Promise<Type<any>>((resolve, reject) => {
      let templates = this.getComponentTemplates(componentKey);

      for(let i = 0; i < templates.length; i++) {
        if(templates[i].key == templateKey) {
          templates[i].loadComponent().then(r => {
            console.log('Loaded customer specific template componet: ' + templateKey);
            resolve(r[componentKey]);
          }, err => {
            console.error('Error loading component template component: ' + templateKey);
            resolve(null);
          })
        }
      }

      if(templates.length <= 0) {
        console.warn('No templates registerd...');
        resolve(null);
      }
    });

    return promise;
  }
}
