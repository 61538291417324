import { IBeePermissionObject } from "src/app/domain/core/interfaces/i-bee-permission-object";
import { VerificationFlowModel } from "./verification-flow.model";
import { GenericItemPermission } from "src/app/domain/core/models/generic-item-permission";

export class VerificationFlowInstance implements IBeePermissionObject {
    id: string = "";
    name: string = "";
    description: string = "";
    createdByUserId: string = "";
    lastEditedByUserId: string = "";
    lastEditedTimeUtc: Date = new Date();
    createdTimeUtc: Date = new Date();
    templateId: string = "";
    permission: GenericItemPermission = new GenericItemPermission();
    verificationFlow: VerificationFlowModel = new VerificationFlowModel();
    completedModuleIds: string[] = [];
    content: any = {};

    constructor(data?: VerificationFlowInstance) {
        if(data) {
            Object.assign(this, data);
            this.verificationFlow = new VerificationFlowModel(data.verificationFlow);
        }
    }
}