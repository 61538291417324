import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MenuService } from '../services/menu.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'bee-menu',
  templateUrl: './bee-menu.component.html',
  styleUrls: ['./bee-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeeMenuComponent implements OnInit, OnDestroy {
  menuItems: any[];
  menuOpen: boolean = false;

  scrollInstance: any;

  @ViewChild('menuTrigger', {static: true}) menuTrigger: MatMenuTrigger;

  constructor(private menuService: MenuService, private router: Router,
    private elementRef: ElementRef, private renderer: Renderer2) {
    this.menuItems = [];
  }

  ngOnInit() {

    this.menuService.Get().subscribe((data: any) => {
      this.menuItems = data;
    });

    this.menuTrigger.onMenuClose.subscribe(() => {
      this.menuOpen = false;
    });

    this.menuTrigger.onMenuOpen.subscribe(() => {
      this.menuOpen = true;
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          this.menuOpen = false;
          this.menuTrigger.closeMenu();
      }
    });

    const body = this.elementRef.nativeElement.closest('body');
    const wrapper = body.querySelectorAll('.app-wrapper')[0];
    this.scrollInstance = this.renderer.listen(wrapper, 'scroll', () => {
      this.menuOpen = false;
      this.menuTrigger.closeMenu();
    });
  }

  ngOnDestroy() {
    this.scrollInstance();
  }

  toggle(item, e) {
    item = !item;
  }

  navigate(item) {
    if(item.children.filter(c => c.isDeleted == false).length <= 0) {
      this.router.navigate([item.url]);
    }
    else {
      item.isOpen = !item.isOpen;
    }
  }

}
