import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CalendarEvent } from 'src/app/admin/calendar/calendar-models';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { ICalendarService } from 'src/app/container/interfaces/calendar.service.interface';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import dayjs from 'dayjs'

@Injectable({
  providedIn: 'root'
})
export class CalendarService implements ICalendarService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getCalendarEventsBetweenDates(startTime: Date, endTime: Date, pageIndex: number, pageSize: number): Observable<PaginationReturnModel<CalendarEvent[]>> {
    return this.http.get<PaginationReturnModel<CalendarEvent[]>>(this.config.apiEndpoint + 
      `api-calendar/calendar/getCalendarEventsBetweenDates?startTime=${dayjs(startTime).toString()}&endTime=${dayjs(endTime).toString()}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
  }
  getCalendarEvents(pageIndex: number, pageSize: number): Observable<PaginationReturnModel<CalendarEvent[]>> {
    return this.http.get<PaginationReturnModel<CalendarEvent[]>>(this.config.apiEndpoint + 
      `api-calendar/calendar/getCalendarEvents?pageSize=${pageSize}&pageIndex=${pageIndex}`);
  }
  getCalendarEvent(id: string): Observable<CalendarEvent> {
    return this.http.get<CalendarEvent>(this.config.apiEndpoint + 
      `api-calendar/calendar/getCalendarEvent?id=${id}`);
  }
}
