<ng-container *ngIf="formModel$ | async as form">
  <div>
    <ng-container *ngTemplateOutlet="form.formsData.length > 1 ? stepper : normal"></ng-container>
  </div>
  
  
  <ng-template #stepper>
    <ng-container *ngTemplateOutlet="{'tabs': stepper_tabs, 'default': stepper_default}[form.slidesType]"></ng-container>
  </ng-template>
  
  <ng-template #stepper_default>
    <form [formGroup]="form.form" (ngSubmit)="onSubmit()" id="{{'formId-' + form.id}}" [ngClass]="form.cssClass">
  
      <div class="stepper-wrapper">
        <div class="form-step" 
          [ngClass]="{'selected': activedStep == index}" 
          [class.disabled]="!form.form.at(index).valid && activedStep !== index"
          (click)="goToStep(index);"
          *ngFor="let step of form.formsData; let index = index">
          
          <div class="img-wrapper">
            <img *ngIf="activedStep == index" [src]="step.primaryIcon" alt="">
            <img *ngIf="activedStep != index" [src]="step.secondaryIcon" alt="">
          </div>
  
          <div class="text">
            <p><strong [innerHtml]="step.label"></strong></p>
          </div>
  
        </div>
      </div>
  
      <mat-horizontal-stepper labelPosition="bottom" #stepperElement>
        <mat-step *ngFor="let step of form.formsData; let index = index; let last = last;" 
          [state]="step.icon"
          label="{{ step.label }}">
          <!-- <ng-template matStepLabel>{{ step.label }}</ng-template> -->
  
          <div class="form-outer-wrapper">
  
            <div class="form-header">
              <h3>{{ step.label }}</h3>
            </div>
  
            <div class="form-wrapper">
              <formly-form [form]="form.form.at(index)" [model]="model" [fields]="step.fields"
                [options]="form.options[index]"> 
              </formly-form>
            </div>
          </div>
  
          <div class="required-info-wrapper" [class.hide]="!hasRequiredFields()">
            <span>*</span>
            <p>{{ 'FORMS.MANDATORY_INFO' | translate }}</p>
          </div>
  
          <div class="form-buttons">
            <!-- <button *ngIf="index !== 0" matStepperPrevious class="bee-button margin-top margin-right" type="button"
              (click)="prevStep(index)">Tillbaka</button> -->
            <button *ngIf="!last" matStepperNext class="bee-button margin-top" type="button"
              [disabled]="!form.form.at(index).valid" (click)="nextStep(index)">Gå vidare till steg
              {{index + 2}}</button>
            <button *ngIf="last" class="bee-button margin-top" [disabled]="!form.form.valid"
              type="submit">{{form.submitButtonText || ('FORMS.SUBMIT' | translate)}}</button>
          </div>
  
        </mat-step>
  
        <ng-template matStepperIcon="person">
          <img style="width: 70px; height: 70px;" src="/assets/images/customers/tpl/person_white.svg" alt="">
        </ng-template>
  
        <ng-template matStepperIcon="package">
          <img style="width: 70px; height: 70px;" src="/assets/images/customers/tpl/package_white.svg" alt="">
        </ng-template>
  
      </mat-horizontal-stepper>
    </form>
  </ng-template>
  
  <ng-template #stepper_tabs>
    <form [formGroup]="form.form" (ngSubmit)="onSubmit()" id="{{'formId-' + form.id}}" [ngClass]="form.cssClass">
  
      <div class="stepper-tabs-wrapper">
        <div class="tab" 
          [ngClass]="{'selected': activedStep == index}" 
          [class.disabled]="!form.form.at(index).valid && activedStep !== index"
          (click)="goToStep(index);"
          *ngFor="let step of form.formsData; let index = index">
  
          <div class="text">
            <p><strong [innerHtml]="step.label"></strong></p>
          </div>
  
        </div>
      </div>
  
      <mat-horizontal-stepper labelPosition="bottom" #stepperElement>
        <mat-step *ngFor="let step of form.formsData | filter:['type', 'default']; let index = index; let last = last;" 
          [state]="step.icon"
          label="{{ step.label }}">
          <!-- <ng-template matStepLabel>{{ step.label }}</ng-template> -->
  
          <div class="form-tabs-outer-wrapper">
  
            <div class="form-header">
              <h2 [innerHtml]="step.header"></h2>
  
              <p class="step-text" [innerHtml]="step.text"></p>
            </div>
  
            <div class="form-wrapper">
              <formly-form [form]="form.form.at(index)" [model]="model" [fields]="step.fields"
                [options]="form.options[index]"> 
              </formly-form>
            </div>
          </div>
  
          <div class="required-info-wrapper" [class.hide]="!hasRequiredFields()">
            <span>*</span>
            <p>{{ 'FORMS.MANDATORY_INFO' | translate }}</p>
          </div>
  
          <div class="form-buttons">
            <!-- <button *ngIf="index !== 0" matStepperPrevious class="bee-button margin-top margin-right" type="button"
              (click)="prevStep(index)">Tillbaka</button> -->
            <button *ngIf="!last" matStepperNext class="bee-button margin-top" type="button"
              [disabled]="!form.form.at(index).valid" (click)="nextStep(index)">Gå vidare till steg
              {{index + 2}}</button>
            <button *ngIf="last" class="bee-button margin-top" [disabled]="!form.form.valid"
              type="submit">{{form.submitButtonText || ('FORMS.SUBMIT' | translate)}}</button>
          </div>
  
        </mat-step>
  
        <mat-step *ngIf="getResultStep()"> 
          <div class="form-tabs-outer-wrapper">
            <div class="form-header result-step-header">
              <h2>{{getResultStep().header}}</h2>
  
              <p class="step-text">{{getResultStep().text}}</p>
            </div> 
  
            <div class="form-wrapper result-step">
              <bee-form-result [result]="formResult" [form]="form" (reset)="reset()"></bee-form-result>
            </div>
          </div>
        </mat-step>
  
      </mat-horizontal-stepper>
    </form>
  </ng-template>
  
  
  <ng-template #normal>
    <ng-container *ngIf="(wrapByPanel$ | async) else normalForm;">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="title-wrapper">
              <h3 [innerHTML]="form.formsData[0].label"></h3>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
      
        <div class="margin-top margin-bottom lg">
          <ng-container *ngTemplateOutlet="normalFormWOHeader"></ng-container>
        </div>
        
      </mat-expansion-panel>
    </ng-container>
  </ng-template>

  <ng-template #normalForm>
    <form [formGroup]="form.form" (ngSubmit)="onSubmit()" id="{{'formId-' + form.id}}" [ngClass]="form.cssClass">
      <div class="form-outer-wrapper" [class.no-wrap]="!form.formsData[0].label">
        <div class="form-header">
          <h3 [innerHtml]="form.formsData[0].label"></h3>
        </div>
  
        <div class="form-wrapper">
  
          <formly-form
            [fields]="form.formsData[0].fields" 
            [model]="model"
            [options]="form.options[0]"
            [form]="form.form.at(0)"></formly-form>
        </div>
      </div>
  
      <div class="required-info-wrapper" *ngIf="hasRequiredFields()">
        <span>*</span>
        <p>{{ 'FORMS.MANDATORY_INFO' | translate }}</p>
      </div>
  
      <div class="form-buttons" *ngIf="!readonly">
        <button type="submit" 
          [disabled]="!form.form.valid"
          class="bee-button margin-top">{{form.submitButtonText || ('FORMS.SUBMIT' | translate)}}</button>

        <div *ngIf="showDownload" 
          class="bee-button margin-left margin-top margin-right secondary" 
          (click)="export(submissionId)" 
          [innerHtml]="downloadLabel"></div>
      </div>
    </form>
  </ng-template>

  <ng-template #normalFormWOHeader>
    <form [formGroup]="form.form" (ngSubmit)="onSubmit()" id="{{'formId-' + form.id}}" [ngClass]="form.cssClass">
      <div class="form-outer-wrapper" [class.no-wrap]="!form.formsData[0].label">
  
        <div class="form-wrapper">
  
          <formly-form
            [fields]="form.formsData[0].fields" 
            [model]="model"
            [options]="form.options[0]"
            [form]="form.form.at(0)"></formly-form>
        </div>
      </div>
  
      <div class="required-info-wrapper" *ngIf="hasRequiredFields()">
        <span>*</span>
        <p>{{ 'FORMS.MANDATORY_INFO' | translate }}</p>
      </div>
  
      <div class="form-buttons" *ngIf="!readonly">
        <button type="submit" 
          [disabled]="!form.form.valid"
          class="bee-button margin-top">{{form.submitButtonText || ('FORMS.SUBMIT' | translate)}}</button>
        <div *ngIf="showDownload" 
          class="bee-button margin-left margin-top margin-right secondary" 
          (click)="export(submissionId)" 
          [innerHtml]="downloadLabel"></div>
          
      </div>
    </form>
  </ng-template>
</ng-container>