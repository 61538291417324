import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PasswordChangeService } from '../../services/password-change.service';

@Component({
  selector: 'bee-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {
  public username: string;

  constructor(private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>, private passwordChangeService: PasswordChangeService) { }

  ngOnInit(): void {
  }

  createChangePasswordRequest(form: NgForm) {
    if(this.username) {
      this.passwordChangeService.createChangePasswordRequest(this.username).subscribe(() => {
        this.close();
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
