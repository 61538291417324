import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';
import { Router, NavigationExtras } from '@angular/router';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cms-module-page-menu',
  templateUrl: './cms-module-page-menu.component.html',
  styleUrls: ['./cms-module-page-menu.component.scss']
})
export class CmsModulePageMenuComponent implements OnInit {

  activeTag: string;

  constructor(public moi: CMSModuleInstance, private router: Router, 
    @Inject(DOCUMENT) private document: Document,
    private elRef: ElementRef) {

    const params = new URLSearchParams(window.location.search);

    if(params.has('tag')) {
      this.activeTag = params.get('tag');
    }
  }

  ngOnInit() {
    if(!this.moi.settings.content.template) {
      this.moi.settings.content.template = "DEFAULT";
    }
  }

  getActiveMenuItem() {
    for(let i = 0; i < this.moi.settings.content.menuItems.length; i++) {
      if(this.isActive(this.moi.settings.content.menuItems[i])) {
        return this.moi.settings.content.menuItems[i];
      }
    }

    for(let i = 0; i < this.moi.settings.content.menuItems.length; i++) {
      if(this.moi.settings.content.menuItems[i].isDefaultSelected) {
        return this.moi.settings.content.menuItems[i];
      }
    }
  }

  navigate(menuItem) {
    if(this.moi.settings.content.ignoreScroll && window.innerWidth > 980) {
      this.router.navigate([menuItem.url], {state: {ignoreScroll: true}});
    }
    else {

      window.location.href = menuItem.url;

      // this.router.navigateByUrl(menuItem.url, { replaceUrl: true }).then(() => {
      //   const dom: HTMLElement = this.document.body;
      //   const wrapper = dom.querySelectorAll('.app-wrapper')[0];
      //   wrapper.scrollTo(0, 0);
      // });
    }
  }
  
  isActive(item: any): boolean {
    if(this.router.isActive(item.url, true)) {
      return true;
    }
    else if(item.selectedByTag && item.selectedByTag == this.activeTag) {
      return true;
    }

    return false;
  }

}

@Component({
  selector: 'app-cms-module-page-menu-admin',
  templateUrl: './cms-module-page-menu-admin.component.html',
  styleUrls: ['./cms-module-page-menu.component.scss']
})
export class CmsModulePageMenuAdminComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) {
    if(!this.moi.settings.content.menuItems) {
      this.moi.settings.content.menuItems = [{
        imgUrl: "",
        text: "Inleveranser",
        link: ""
      }];
    }
  }

  ngOnInit() {
  }

  navigate(menuItem) {

  }

}

@Component({
  selector: 'app-cms-module-page-menu-settings',
  templateUrl: './cms-module-page-menu-settings.component.html',
  styleUrls: ['./cms-module-page-menu.component.scss']
})
export class CmsModulePageMenuSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.moi.settings.content.menuItems, event.previousIndex, event.currentIndex);

    //Give all items new order...
    for(let i = 0; i < this.moi.settings.content.menuItems.length; i++) {
      this.moi.settings.content.menuItems[i].order = i;
    }
  }

  addMenuItem() {
    if(!this.moi.settings.content.menuItems) this.moi.settings.content.menuItems = [];
    
    this.moi.settings.content.menuItems.push({
      imgUrl: "",
      text: "",
      link: "",
      order: this.moi.settings.content.menuItems.length + 1
    });
  }

}
