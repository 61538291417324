import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor(private http: HttpClient) { }

  GetFeed(igAccount: string) {
    return this.http.get('https://www.instagram.com/' + igAccount + '/?__a=1');
  }
}
