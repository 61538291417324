<div class="container-wrapper">
  <vo-college-success-story-card 
    [style.backgroundImage]="'url(' + (container.imgUrl | cdn) + ')'" 
    *ngFor="let container of genericContainers | limitTo:moi.settings.content.totalCount; let i = index" 
    class="container" 
    [item]="container"
    [index]="i"
    (buttonClick)="click($event)"
    [ngClass]="{ 'wide': moi.settings.content.totalCount == 1 }">
  </vo-college-success-story-card>

  <div class="show-more-wrapper margin-top" *ngIf="moi.settings.content.showMore && genericContainers.length > moi.settings.content.totalCount" (click)="showMore(); false">
    <span>
      Visa fler
    </span>

    <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
</div>