<bee-animated-icon class="md-36"
  start="menu" 
  end="close" 
  colorStart="none" 
  colorEnd="none" 
  [matMenuTriggerFor]="menu" 
  [animate]="menuOpen"
  [animateFromParent]="true"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"></bee-animated-icon>

<mat-menu #menu="matMenu" [hasBackdrop]="false">
  <div class="bee-menu-wrapper">

    <div *ngFor="let menuItem of menuItems | filter:['isDeleted', false]" class="bee-menu-item" [ngClass]="menuItem.cssClass" (click)="navigate(menuItem); $event.stopPropagation();">
      <p>
        {{menuItem.text}}
        <bee-animated-icon *ngIf="(menuItem.children | filter:['isDeleted', false]).length > 0" 
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          colorStart="#757575" 
          colorEnd="#00a0d6" 
          [(animate)]="menuItem.isOpen" (click)="$event.stopPropagation();"></bee-animated-icon> 
      </p>

      <div class="bee-menu-sub-wrapper" *ngIf="menuItem.children.length > 0 && menuItem.isOpen">
        <div *ngFor="let subItem of menuItem.children | filter:['isDeleted', false]" class="bee-menu-sub-item" [ngClass]="subItem.cssClass" (click)="navigate(subItem)">
          <p>{{subItem.text}}</p>
        </div>
      </div>
    </div>

    <div class="bee-menu-item blue horizontal hide-desktop">
      <a routerLink="/kontrolltornet">
        <img src="/assets/images/customers/tpl/control_tower.svg" class="tpl-icon" alt="Control tower" style="width: 36px; height: 23px;">
        <p>Kontrolltornet</p>
      </a>

      <div class="divider"></div>

      <a routerLink="/about">
        <img src="/assets/images/customers/tpl/Engelsk_flagga.svg" class="tpl-icon" alt="Control tower" style="width: 36px; height: 23px;">
        <p>About us</p>
      </a>

      <!-- <div class="divider"></div> -->
<!-- 
      <a href="#" class="disabled">
        <img src="/assets/images/customers/tpl/intranet.svg" class="tpl-icon" alt="Intranät" style="width: 29px; height: 26px;">
        <p>Intranät</p>
      </a> -->
    </div>
  </div>
</mat-menu>