import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPageService } from './cms-page.service';
import { CmsPageEntity } from './cms-page';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BeeMetaService } from '../services/bee-meta.service';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss']
})
export class CmsPageComponent implements OnInit {
  sub: any;
  urlRewrite: String;
  cmsPage$: BehaviorSubject<CmsPageEntity> = new BehaviorSubject<CmsPageEntity>(null);
  unsubscribe$ = new Subject<void>();
  cr: ChangeDetectorRef = inject(ChangeDetectorRef);

  cmsPageLoaded: boolean = false;

  constructor(private route: ActivatedRoute, private cmsService: CmsPageService, 
    public authService: AuthenticationService, private router: Router,
    private metaService: BeeMetaService, private cdnPipe: CdnPipe) {

    this.authService.onSiteSettingsChange.subscribe(() => {

      if(this.authService.currentUserValue.siteSettings.startpage) {
        this.router.navigate([this.authService.currentUserValue.siteSettings.startpage]);
      }
    });

    this.authService.currentUserSessionStore$.pipe(takeUntil(this.unsubscribe$)).subscribe((session) => {
      if(session) {
        this.cr.detectChanges();
      }
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      var childPath = params['childPath'];
      this.urlRewrite = params['urlRewrite'];

      if(childPath) {
        this.urlRewrite = this.urlRewrite + "/" + childPath;
      }

      if(!this.urlRewrite) this.urlRewrite = this.authService.currentUserValue.siteSettings.startpage || "startpage";

      this.getCMSPage();
   });
  }

  getCMSPage() {
    this.cmsService.GetByUrl(this.urlRewrite).subscribe((data: any) => {
      if((data.CMSPage.id == null || data.CMSPage.id == "") && data.CMSPage.redirectToIfNoPermissions) {
        
        this.urlRewrite = data.CMSPage.redirectToIfNoPermissions;
        this.getCMSPage();
        //this.router.navigate([data.CMSPage.redirectToIfNoPermissions]);
      }
      else if(data.CMSPage && data.CMSContainer) {
        this.cmsPage$.next(new CmsPageEntity(data.CMSPage, data.CMSContainer));

        this.metaService.setBasicMeta(data.CMSPage.title, data.CMSPage.description,
          this.cdnPipe.transform(data.CMSPage.imgUrl));

        this.cmsPageLoaded = true;
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
