<ng-container [ngSwitch]="authService.currentUserValue.siteSettings.customer">

  <ng-container *ngSwitchCase="'tpl'">
    <bee-header></bee-header>
  </ng-container>

  <ng-container *ngSwitchCase="'tpl-dev'">
    <bee-header></bee-header>
  </ng-container>

  <ng-container *ngSwitchCase="'thermoeyes'">
    <bee-thermoeyes-header></bee-thermoeyes-header>
  </ng-container>

  <ng-container *ngSwitchCase="'thermoeyes-dev'">
    <bee-thermoeyes-header></bee-thermoeyes-header>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <bee-layout layout-type="header"></bee-layout>
  </ng-container>
  
</ng-container>

<div class="beebase-content">
  <router-outlet></router-outlet>
</div>


<bee-layout layout-type="footer"></bee-layout>

<div class="bee-fab" (click)="scrollToTop();" *ngIf="(authService.currentUser$ | async).siteSettings.showScrollToTop">
  <mat-icon>expand_less</mat-icon>
</div>