import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon'
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';

import { FrontendModule } from './frontend/frontend.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizeModule } from './authorize/authorize.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { IUserNotificationService } from './domain/notifications/interfaces/i-user-notification-service';
import { UserNotificationService } from './frontend/services/user-notification.service';
import { SignalrService } from './frontend/services/signalr.service';
import { AuthenticationService } from './authorize/services/authentication.service';
import { UserSession } from './authorize/models/user-session';
import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { PathPreserveQueryLocationStrategy } from './helpers/path-preserve-query-location-strategy';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatButtonToggleModule,
        MatIconModule,
        MatExpansionModule,
        MatSelectModule,
        MatButtonModule,
        MatDialogModule,
        MatSnackBarModule,
        //MODULES
        //AdminModule,
        AuthorizeModule,
        FrontendModule,
        ReactiveFormsModule,
        //TRANSLATE
        TranslateModule.forRoot({
            useDefaultLang: true,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        SignalrService,
        { provide: IUserNotificationService, useClass: UserNotificationService },
        {
            provide: APP_INITIALIZER,
            useFactory: (signalrService: SignalrService, authService: AuthenticationService) => {
                authService.isSiteLockedByLogin$.subscribe((isLocked) => {
                    if(isLocked &&
                        (!authService.isLoggedIn() && window.location.pathname !== '/login' && window.location.pathname !== '/admin/install')) {
                        window.location.href = '/login';
                    }
                });
                
                return () => signalrService.initiateSignalrConnection();
            },
            deps: [SignalrService, AuthenticationService],
            multi: true,
        },
        {
            provide: 'googleTagManagerId',
            useFactory: (authService: AuthenticationService) => {
                console.log('GTM: ', authService.currentUserValue.siteSettings.gaId);
                return authService.currentUserValue.siteSettings.gaId || "GTM-NQT36XW";
            },
            deps: [AuthenticationService],
        },
        {
            provide: LocationStrategy,
            useClass: PathPreserveQueryLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
