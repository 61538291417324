<mat-form-field>
    <textarea matInput placeholder="HTML code" name="HTML" rows="10" [(ngModel)]="module.settings.content.html"></textarea>
</mat-form-field>

<div class="margin-top">
    <button class="bee-button" (click)="addJavascript()">Add javascript</button>
</div>

<div class="margin-top">
    <div *ngFor="let script of this.module.settings.content.scripts">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Javascript: {{script.id}}
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
        
            <div>
              <mat-form-field>
                <input matInput placeholder="Id" name="Id" [(ngModel)]="script.id">
              </mat-form-field>

              <div class="margin-top">
                <mat-form-field>
                    <input matInput placeholder="Src" name="Src" [(ngModel)]="script.src">
                  </mat-form-field>
              </div>
      
              <div class="margin-top">
                  <button class="bee-button small" (click)="addAttributeToJavascript(script)">Add script attribute</button>
              </div>

              <div class="margin-top">
                <mat-expansion-panel *ngFor="let attribute of script.attributes">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Attribute: {{attribute.name}}
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                
                    <div>
                      <mat-form-field>
                        <input matInput placeholder="Attribute name" name="AttributeName" [(ngModel)]="attribute.name">
                      </mat-form-field>
        
                      <div class="margin-top">
                        <mat-form-field>
                            <input matInput placeholder="Attribute value" name="AttrVal" [(ngModel)]="attribute.val">
                          </mat-form-field>
                      </div>
                    </div>
                
                  </mat-expansion-panel>
              </div>
            </div>
        
          </mat-expansion-panel>
    </div>
</div>