import { Component, OnInit } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';

@Component({
  selector: 'app-cms-module-social-share',
  templateUrl: './cms-module-social-share.component.html',
  styleUrls: ['./cms-module-social-share.component.scss']
})
export class CmsModuleSocialShareComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit() {
    if(!this.moi.settings.content.shareSettings) {
      this.moi.settings.content.shareSettings = {
        icon: 'more_vert'
      };
    }
  }

}

@Component({
  selector: 'app-cms-module-social-share-settings',
  templateUrl: './cms-module-social-share-settings.component.html',
  styleUrls: ['./cms-module-social-share.component.scss']
})
export class CmsModuleSocialShareSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit() {
    if(this.moi.settings.content.isOpen === undefined) {
      this.moi.settings.content.isOpen = false; 
    }
    if(!this.moi.settings.content.shareSettings) {
      this.moi.settings.content.shareSettings = {
        icon: 'more_vert'
      };
    }
  }

}
