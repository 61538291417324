<div class="flex center video-list-media-type-wrapper">

  <mat-checkbox class="margin-right" color="primary" [(ngModel)]="videoChecked" (change)="toggleVideoChecked($event)">
    <span class="margin-left sm" [innerHtml]="'MEDIABANK.VIDEO' | translate">Video</span>
  </mat-checkbox>
  <mat-checkbox color="primary" [(ngModel)]="audioChecked" (change)="toggleAudioChecked($event)"> 
    <span class="margin-left sm" [innerHtml]="'MEDIABANK.AUDIO' | translate">Audio</span>
  </mat-checkbox>

</div>


<div *ngIf="module.settings.content.showTagFilter" class="video-tag-filter-wrapper">
  <div class="bee-select-button lg filled" 
    [class.secondary]="module.settings.content.tagButtonSecondary" 
    *ngFor="let tag of getTagsToShow()" 
    [innerHtml]="tag.name" 
    (click)="selectTag(tag)"
    [style.backgroundColor]="tag.color"
    [style.color]="getTagTextColor(tag.color)"
    [class.small]="module.settings.content.tagButtonSmall"
    [class.selected]="isSelectedTag(tag.urlRewrite)"></div>
</div>

<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<div class="video-list-module-wrapper" *ngIf="videos$ | async as v; else loading" [ngClass]="deviceEmulation"
  [style.maxWidth]="module.settings.content.maxWidth">

  <!-- [routerLink]="['/modules/media/', video.urlRewrite]" -->
  <a *ngFor="let video of v | limitTo:module.settings.content.limit" class="video-list-item" [routerLink]="['/modules/media/', video.urlRewrite]"
    [ngClass]="{'col-1':module.settings.content.colCount == 1,
    'col-2':module.settings.content.colCount == 2,
    'col-3':module.settings.content.colCount == 3,
    'col-4':module.settings.content.colCount == 4}">

    <div class="video-list-image" [style.background-image]="'url(' + (video.thumbnail | cdn) + ')'">
      <div class="play-overlay">
        <!-- <i class="material-icons">play_arrow</i> -->
        <bee-play-icon></bee-play-icon>
      </div>

      <span class="video-duration" *ngIf="module.settings.content.showDuration && video.type == 2">
        {{handleTime(video.minutes)}}:{{handleTime(video.seconds)}}
      </span>
    </div>

    <div class="video-information">
      <h6 class="hide-mobile video-header" [innerHtml]="video.name"></h6>
      <h6 class="hide-desktop video-header" [innerHtml]="video.name"></h6>

      <p *ngIf="module.settings.content.showDescription" class="video-description medium" [innerHtml]="video.description | beeStripHtml"></p>

      <div *ngIf="module.settings.content.showVideoTags" class="video-extra-info">
        <span class="video-tags date" [innerHtml]="video.tags | tagNames | safeHtml"></span> 
      </div>

      <div *ngIf="module.settings.content.showMediaTypeTag" class="margin-top">
        <span *ngIf="video.type == 2" class="media-tag video" [innerHtml]="'MEDIABANK.VIDEO' | translate"></span>
        <span *ngIf="video.type == 3" class="media-tag audio" [innerHtml]="'MEDIABANK.AUDIO' | translate"></span>
      </div>
    </div>
  </a>

  <div class="show-more-wrapper margin-top" *ngIf="!module.settings.content.hideShowMore && videos.length > module.settings.content.limit" (click)="showMore(); false">
    <div class="bee-button secondary small">
      <span>
        {{ 'MEDIA.SHOW_MORE' | translate }}
      </span>
  
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </div>
</div>
