export const VO_COLLEGE_MODULES: Array<any> = [
  {
    componentName: "VoCollegeQuoteWidgetComponent",
    adminComponentName: "VoCollegeQuoteWidgetComponent",
    adminSettingsComponentName: "VoCollegeQuoteWidgetSettingsComponent",
    key: "VO_COLLEGE_QUOTE_WIDGET",
    abstractName: "Quote widget",
    icon: "format_quote",
    description: "Created a quote widget",
    id: 1001,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/quote-widget/vo-college-quote-widget.component");
    }
  },
  {
    componentName: "VoCollegeFactBoxWidgetComponent",
    adminComponentName: "VoCollegeFactBoxWidgetComponent",
    adminSettingsComponentName: "VoCollegeFactBoxWidgetSettingsComponent",
    key: "VO_COLLEGE_FACT_BOX_WIDGET",
    abstractName: "Fact box widget",
    icon: "fact_check",
    description: "Create a fact box widget",
    id: 1002,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/fact-box-widget/vo-college-fact-box-widget.component");
    }
  },
  {
    componentName: "VoCollegeVideoImageWidgetComponent",
    adminComponentName: "VoCollegeVideoImageWidgetComponent",
    adminSettingsComponentName: "VoCollegeVideoImageWidgetSettingsComponent",
    key: "VO_COLLEGE_VIDEO_IMAGE_WIDGET",
    abstractName: "Video/image widget",
    icon: "camera_roll",
    description: "Create a video/image widget",
    id: 1003,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/video-image-widget/vo-college-video-image-widget.component");
    }
  },
  {
    componentName: "VoCollegeListingWidgetComponent",
    adminComponentName: "VoCollegeListingWidgetComponent",
    adminSettingsComponentName: "VoCollegeListingWidgetSettingsComponent",
    key: "VO_COLLEGE_LISTING_WIDGET",
    abstractName: "Listing widget",
    icon: "list",
    description: "Create a listing widget",
    id: 1004,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/listing-widget/vo-college-listing-widget.component");
    }
  },
  {
    componentName: "VoCollegeFramedTextWidgetComponent",
    adminComponentName: "VoCollegeFramedTextWidgetComponent",
    adminSettingsComponentName: "VoCollegeFramedTextWidgetSettingsComponent",
    key: "VO_COLLEGE_FRAMED_TEXT_WIDGET",
    abstractName: "Framed text widget",
    icon: "filter_frames",
    description: "Create a framed text widget",
    id: 1005,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/framed-text/vo-college-framed-text-widget.component");
    }
  },
  {
    componentName: "VOCollegeTextWidgetComponent",
    adminComponentName: "VOCollegeTextWidgetComponent",
    adminSettingsComponentName: "VOCollegeTextWidgetSettingsComponent",
    key: "VO_COLLEGE_TEXT_WIDGET",
    abstractName: "Text widget",
    icon: "text_snippet",
    description: "Create a text widget",
    id: 1006,
    getComponent: () => {
      return import("../../cms-modules/customers/vo-college/text-widget/text-widget.component");
    }
  },
];