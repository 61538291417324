import { BeeFormFieldConfig, IStepType, StepType } from 'src/app/modules/bee-forms/components/bee-show-form/bee-show-form.component';
import { FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export class FormsModel {
  id: string;
  name: string;
  description: string;
  emails: string[];
  isDeleted: boolean;
  submitButtonText: string;
  formsDocument: any[];
  formsData: IStepType[];
  cssClass: string;
  slidesType: string;

  options: FormlyFormOptions[];
  form: UntypedFormArray;
  extraData: any;
  clearOnSubmit: boolean;
  styles: any;
  isSingleEditable: boolean = false;
  sendNotifications: boolean = false;

  REFERER_TYPE: string;
  REFERER_ID: string;
  REFERER_EXTRA: string;

  constructor(data?: any) {
    this.formsData = [];
    if(data) {

      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.emails = data.emails;
      this.isDeleted = data.isDeleted;
      this.submitButtonText = data.submitButtonText;
      this.cssClass = data.cssClass;
      this.slidesType = data.slidesType || "default";
      this.REFERER_TYPE = data.REFERER_TYPE;
      this.REFERER_ID = data.REFERER_ID;
      this.REFERER_EXTRA = data.REFERER_EXTRA;

      let formsDocument = JSON.parse(data.formJsonDocument);

      if(formsDocument.slides && formsDocument.slides.length > 0) {
        formsDocument.slides.forEach(s => {
          s.fields.forEach((f) => {
            f.templateOptions.options = {
              ...f.templateOptions.options, 
              formId: this.id,
              referer_type: this.REFERER_TYPE,
              referer_id: this.REFERER_ID
            }
          });
          this.formsData.push(new StepType(s));
        });
      }

      this.form = new UntypedFormArray(this.formsData.map(() => new UntypedFormGroup({})));
      this.options = this.formsData.map(() => <FormlyFormOptions> {});
      this.extraData = data.extraData;
      this.clearOnSubmit = data.clearOnSubmit;
      this.isSingleEditable = data.isSingleEditable;
      this.sendNotifications = data.sendNotifications;
      this.styles = data.styles || {
        flexDirection: "row",
        color: "#000000",
      };
    }
  }
}


export class FormsSaveModel extends FormsModel {
  formsDocument: any;

  constructor(model: FormsModel) {
    super();
    Object.assign(this, model);
    
    this.formsDocument = {
      slides: []
    };

    if(model.formsData) {
      model.formsData.forEach(d => {
        d.fields.forEach((f: BeeFormFieldConfig) => {
          f.component = null;
          f.injectSettings = null;
        });
  
        this.formsDocument.slides.push(d);
      });
    }

    this.form = undefined;
    this.formsData = undefined;
    this.options = undefined;
  }
}
