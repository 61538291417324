<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<bee-course-chapter-table-info *ngIf="vm$ | async as vm; else loading" 
  [completedEducationIds]="vm.completedEducationIds" 
  [completedCourseIds]="vm.completedCourseIds"
  [courses]="vm.courses" >
</bee-course-chapter-table-info>