import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bee-blog-list-default',
  templateUrl: './bee-blog-list-default.component.html',
  styleUrls: ['./bee-blog-list-default.component.scss']
})
export class BeeBlogListDefaultComponent implements OnInit {
  @Input() blogs: any[];
  @Input() limit: number = 12;
  @Input() showDescription: boolean = false;
  @Input() showPublishDate: boolean = true;
  @Input() showTags: boolean = true;
  public loadMoreLimit: number = 12;

  constructor() { }

  ngOnInit(): void {
  }

  showMoreBlogs() {
    this.limit += this.loadMoreLimit;
  }

}
