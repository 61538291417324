<mat-form-field appearance="outline">
  <mat-select [(value)]="selectedItem" [disabled]="disabled">
    <mat-select-trigger>
      <img class="select-image" *ngIf="selectedItem.imageUrl" [src]="selectedItem.imageUrl | cdn"> 
      <span [innerHtml]="selectedItem.shortName"></span>
    </mat-select-trigger>
    <mat-option *ngFor="let item of items" [value]="item">
      <img class="select-image" *ngIf="item.imageUrl" [src]="item.imageUrl | cdn">
      <span [innerHtml]="item.name"></span>
    </mat-option>
  </mat-select>
</mat-form-field>