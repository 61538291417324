import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'bee-paste-json-dialog',
  templateUrl: './paste-json-dialog.component.html',
  styleUrls: ['./paste-json-dialog.component.scss']
})
export class PasteJsonDialogComponent implements OnInit {
  json: string;

  constructor(public dialogRef: MatDialogRef<PasteJsonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  insertJson() {
    let data = sessionStorage.getItem(this.data.storageKey);

    if(data) {
      sessionStorage.removeItem(this.data.storageKey);
    }

    sessionStorage.setItem(this.data.storageKey, this.json);

    this.dialogRef.close(true);
  }

}
