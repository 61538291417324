<ng-container *ngIf="authService.currentUser | async as user">
    <div *ngIf="objects.length" class="flex wrap center">
        <div class="flag-wrapper" [matTooltip]="authService.getCultureFromIso2(mainObject.iso2Culture).name" 
            [class.selected]="selectedObject === undefined || mainObject.id == selectedObject?.id" (click)="selectObject(mainObject)">
            <div class="flag-inner-wrapper">
                <bee-flag-icon [iso2]="mainObject.iso2Culture"></bee-flag-icon>
            </div>
    
            <span class="margin-top sm bold">{{authService.getCultureFromIso2(mainObject.iso2Culture).shortName | uppercase}}</span>
        </div>
        <div class="flag-wrapper" *ngFor="let cul of user.siteSettings.siteCultures" 
            [class.hidden]="cul.iso2 == mainObject.iso2Culture"
            [class.selected]="cul.iso2 == selectedObject?.iso2Culture"
            [matTooltip]="cul.name"
            (click)="selectObject(cul.iso2)">
    
            <div class="flag-inner-wrapper">
                <bee-flag-icon [iso2]="cul.iso2"></bee-flag-icon>
            </div>
    
            <span class="margin-top sm bold">{{cul.shortName | uppercase}}</span>
            
        </div>
    </div>
</ng-container>
