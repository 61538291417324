<ng-container [ngSwitch]="template">
  <ng-container *ngSwitchCase="'youtube'" [ngTemplateOutlet]="youtube"></ng-container>
  <ng-container *ngSwitchCase="'mediabank'" [ngTemplateOutlet]="mediabank"></ng-container>
</ng-container>


<ng-template #youtube>
  <iframe width="560" height="315" 
    [src]="getFullYoutubteUrl() | safe" 
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
  </iframe>
</ng-template>

<ng-template #mediabank>
  <div class="video-module-mediabank-video-wrapper"> 
    <video class="video" controls #videoPlayer [title]="module.settings.content.title">
      <source #videoSrc type="video/mp4">
    </video>
  
    <div class="play-overlay" [style.background-image]="'url(' + (getPoster() | cdn) + ')'" (click)="startVideo()" *ngIf="!videoStarted">
      <div class="play-icon">
        <i class="material-icons">play_arrow</i>
      </div>
    </div>
  </div>
</ng-template>
