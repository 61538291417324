import { CMSContainer } from '../../cms-models';

export class BlogEntity {
  id: number;
  urlRewrite: string;
  name: string;
  description: string;
  startTimeUtc: Date;
  endTimeUtc: Date;
  createdTimeUtc: Date;
  isDeleted: Boolean;
  imgUrl: string;
  mediabankFileId: number;
  tags: any[];

  cmsContainer: CMSContainer;

  constructor(data?: any, cmsContainer?: any) {
    if(!data) data = {};

    this.id = data.id || 0;
    this.urlRewrite = data.urlRewrite || "";
    this.name = data.name || "";
    this.description = data.description || "";
    this.startTimeUtc = data.startTimeUtc;
    this.endTimeUtc = data.endTimeUtc;
    this.createdTimeUtc = data.createdTimeUtc;
    this.isDeleted = data.isDeleted || false;
    this.imgUrl = data.imgUrl || "";
    this.mediabankFileId = data.mediabankFileId || 0;
    this.tags = data.tags || [];

    if(cmsContainer) {
      this.cmsContainer = new CMSContainer(cmsContainer)
    }
    else {
      this.cmsContainer = new CMSContainer(undefined);
    }
  }
}
