<div class="default-blog-list-wrapper">
    <div *ngFor="let blog of blogs | limitTo:limit">
      <a [routerLink]="['/modules/blog/', blog.urlRewrite]">
        <div class="blog-list-item" [ngClass]="{'no-img': !blog.imgUrl}">

          <bee-landscape-image *ngIf="blog.imgUrl" [image]="blog.imgUrl | cdn" [alt]="blog.name" scaling="default"></bee-landscape-image>
    
          <div class="blog-info">
            <h6 [innerHtml]="blog.name"></h6>
            <!-- <h2 class="hide-desktop" [innerHtml]="blog.name"></h2> -->

            <div class="blog-extra-info" *ngIf="showPublishDate || showTags">
              <span class="blog-publish-date" *ngIf="showPublishDate" [innerHtml]="blog.startTimeUtc | date"></span>
              <span class="blog-extra-divider" *ngIf="(showPublishDate && showTags) && blog.tags.length"></span>
              <span class="blog-tags" *ngIf="showTags" [innerHtml]="blog.tags | tagNames | safeHtml"></span>
            </div>

            <p class="blog-description" *ngIf="showDescription" [ngClass]="{'no-img': !blog.imgUrl}" [innerHtml]="blog.description"></p>
          </div>
        </div>
      </a>
    </div>

    <div class="show-more-wrapper margin-top" *ngIf="blogs.length > limit" (click)="showMoreBlogs(); false">
      <span>
        {{ 'CORE.SHOW_MORE' | translate }}
      </span>

      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </div>