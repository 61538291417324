import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaService } from '../services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SortDescPipe } from 'src/app/container/pipes/sort-desc.pipe';
import { MediabankSiteSettings } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaListComponent implements OnInit {
  public mediaItems: any[];
  public mediaItemsLatest: any[];
  public mediaItemsMostViewed: any[];

  sub: any;
  urlRewrite: String;
  mediaTypes: number[];
  avalibleMediaTypes: any[];
  isSingleTypeMode: boolean;

  public latestExpanded: boolean = false;
  public latestLimit: number = 4;

  public mostViewedExpanded: boolean = false;
  public mostViewedtLimit: number = 4;

  public mediabankSiteSettings: MediabankSiteSettings = new MediabankSiteSettings();

  constructor(private mediaService: MediaService, private route: ActivatedRoute, private router: Router, public sortDesc: SortDescPipe,
    private authService: AuthenticationService) {
      this.mediabankSiteSettings = this.authService.currentUserValue.siteSettings.mediabankSiteSettings;
      this.mediaTypes = [];
      this.isSingleTypeMode = false;

      this.avalibleMediaTypes = [
        {
          type: 2,
          name: "Video",
          checked: true
        },
        {
          type: 3,
          name: "Podd",
          checked: true
        }
      ];
  }

  ngOnInit() {
    this.sub = this.route
      .data
      .subscribe(v => {
        let type = ~~v.MediaType
        if(type > 0) {
          this.mediaTypes.push(type);
          this.isSingleTypeMode = true;
        }
        else {
          this.handleTypes();
        }

        this.getMediaItemsByType();
      });
  }

  handleTypes() {
    this.mediaTypes = [];
    for(let i = 0; i < this.avalibleMediaTypes.length; i++) {
      if(this.avalibleMediaTypes[i].checked === true) this.mediaTypes.push(this.avalibleMediaTypes[i].type);
    }
  }

  typeChange(type) {
    type.checked = !type.checked;
    this.handleTypes();
    if(this.mediaTypes.length <= 0) this.mediaItems = [];
    else this.getMediaItemsByType();
  }

  getMediaItems() {
    this.mediaService.GetAllMediabankFiles().subscribe((data: any) => {
      this.mediaItems = data;

      this.handleMediaSorting();
    });
  }

  handleMediaSorting() {
    this.mediaItemsLatest = this.sortDesc.transform(JSON.parse(JSON.stringify(this.mediaItems)), 'id');
    this.mediaItemsMostViewed = this.sortDesc.transform(JSON.parse(JSON.stringify(this.mediaItems)), 'viewCount');
  }

  toggleLatestLimit() {
    if(this.latestLimit <= 4) {
      this.latestLimit = 99;
      this.latestExpanded = true;
    }
    else {
      this.latestLimit = 4;
      this.latestExpanded = false;
    }
  }

  toggleMostViewedLimit() {
    if(this.mostViewedtLimit <= 4) {
      this.mostViewedtLimit = 99;
      this.mostViewedExpanded = true;
    }
    else {
      this.mostViewedtLimit = 4;
      this.mostViewedExpanded = false;
    }
  }

  getTypeName(type) {
    return this.mediaService.getTypeName(type);
  }

  getMediaItemsByType() {
    this.mediaService.GetMediabankFiles(this.mediaTypes).subscribe((data: any) => {

      this.mediaItems = data;

      // for(let i = 0; i < data.length; i++) {
      //   for(let y = 0; y < this.mediaTypes.length; y++) {
      //     if(data[i].isPublic == true && data[i].type == this.mediaTypes[y]) {
      //       this.mediaItems.push(data[i]); 
      //     }
      //   }
      // }

      this.handleMediaSorting();
    });
  }

  getImage(media) {
    if(media.type == this.mediaService.TYPES.IMAGE) return media.fileUrl;
    else return media.thumbnail;
  }

  goToMedia(media) {
    this.router.navigate(['/modules/media/', media.urlRewrite]);
  }

  selectTag(tag) {
    this.router.navigate(['/modules/tag-medias/', tag.urlRewrite]);
  }
}
