export class GenericItemPermission {
  id: string;
  objectId: string;
  objectType: string;
  isPublic: boolean = true;
  userIds: string[];
  unitIds: string[];
  roleIds: string[];
  iso2Cultures: string[];
}
