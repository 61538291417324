import { CMSModuleInstance } from 'src/app/container/cms-models';
import { Component, OnInit } from '@angular/core';
import { InstagramService } from '../services/instagram.service';

@Component({
  selector: 'bee-cms-module-social-feed',
  templateUrl: './cms-module-social-feed.component.html',
  styleUrls: ['./cms-module-social-feed.component.scss']
})
export class CmsModuleSocialFeedComponent implements OnInit {
  public feedItems: Array<any>;


  constructor(public moi: CMSModuleInstance, private instagramService: InstagramService) {
    //this.feedItems = Array(moi.settings.content.instagramColCount).fill(0).map((v, i) => ({_id: `${i}`}));
}

  ngOnInit() {
    this.getInstagramFeed();
    console.log(this.feedItems + " number of feed items")
  }

  getInstagramFeed() {
    this.instagramService.GetFeed(this.moi.settings.content.instagramAccountName).subscribe((data: any) => {
      console.log(data);

      if(data && data.graphql && data.graphql.user && data.graphql.user.edge_owner_to_timeline_media) {
        this.feedItems = [];
        data.graphql.user.edge_owner_to_timeline_media.edges.forEach(edge => {
          this.feedItems.push(edge.node);
        });

        console.log('feedItems', this.feedItems);
      }

    });
  }

  getTemplate(): string {
    return "landscape";
  }
}



@Component({
  selector: 'bee-cms-module-social-feed-settings',
  templateUrl: './cms-module-social-feed-settings.component.html',
  styleUrls: ['./cms-module-social-feed.component.scss']
})
export class CmsModuleSocialFeedSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit() {

  }

}
