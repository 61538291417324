import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

//export interface IFrontendConfig {
//  apiEndpoint: string;
//}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;
  public apiEndpoint: string;
  public adminApiEndpoint: string;

  constructor(private http: HttpClient) {
    this.appConfig = environment;
    
    if(environment.apiEndpoint && environment.apiEndpoint.length > 0) {
      this.apiEndpoint = environment.apiEndpoint;
    }
    else {
      if(window.location.host.indexOf("localhost") >= 0) {
        this.apiEndpoint = "http://localhost:5000/"
      }
      else {
        var host = window.location.host;
        if(host.indexOf('www.') >= 0) {
          host = host.substring(4);
        }
        this.apiEndpoint = window.location.protocol + "//" + host + "/";
      }
    }

    if(environment.production == true) {
      this.apiEndpoint = "";
    }

    this.adminApiEndpoint = this.getApiEndpoint() + "admin/";

    if(environment.production === false) {
      console.log("You're running in development and against backend (API): " + this.apiEndpoint);
    }
  }

  // loadAppConfig() {
  //   return this.http.get('/assets/settings.json')
  //     .toPromise()
  //     .then(data => {
  //       this.appConfig = data;

  //       this.apiEndpoint = this.getApiEndpoint();
  //       this.adminApiEndpoint = this.getApiEndpoint() + "admin/";

  //       if(!this.apiEndpoint.match(/\/$/)){
  //         this.apiEndpoint += '/';
  //       }
  //     });
  // }

  getAppConfig() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig;
  }

  getApiEndpoint(): string {
    if(this.appConfig.apiEndpoint && this.appConfig.apiEndpoint.length > 0) {
      return this.appConfig.apiEndpoint;
    }
    else {
      return window.location.protocol + "//" + window.location.host + "/";
    }
  }
}
