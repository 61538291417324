import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IStepType } from '../components/bee-show-form/bee-show-form.component';
import { FormsSubmission } from '../models/forms-submission';
import { FormsModel } from 'src/app/admin/forms/forms-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * Get 
  id: string   */
  public Get(id: string) {
    return this.http.get(this.config.apiEndpoint + "api-forms/forms/getform?id=" + id);
  }

  /**
   * GetByJson
  name: string   */
  public GetByJson(name: string) {
    return this.http.get<IStepType[]>("/assets/customers/tpl/forms/" + name);
  }

  /**
   * GetForms
   */
  public GetForms(): Observable<FormsModel[]> {
    return this.http.get<FormsModel[]>(this.config.apiEndpoint + "api-forms/forms/getforms");
  }

  /**
   * SaveSubmission
submission   */
  public SaveSubmission(submission: FormsSubmission) {
    return this.http.put(this.config.apiEndpoint + "api-forms/FormsSubmission/Save", submission);
  }

  public GetMySubmission(formId: string): Observable<FormsSubmission> {
    return this.http.get<FormsSubmission>(this.config.apiEndpoint + "api-forms/FormsSubmission/GetMySubmission?formId=" + formId);
  }

  public GetSubmissionByReferer(formId: string, referer_type: string, referer_id: string): Observable<FormsSubmission> {
    return this.http.get<FormsSubmission>(this.config.apiEndpoint + 
      `api-forms/FormsSubmission/GetSubmissionByReferer?formId=${formId}&referer_type=${referer_type}&referer_id=${referer_id}`);
  }
}
