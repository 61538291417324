import { Component, OnInit } from '@angular/core';
import { MediaService } from '../services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TagService } from '../../services/tag.service';
import { MediabankSiteSettings } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'app-media-tag-list',
  templateUrl: './media-tag-list.component.html',
  styleUrls: ['./media-tag-list.component.scss']
})
export class MediaTagListComponent implements OnInit {
  sub: any;
  urlRewrite: String;
  public mediaFiles: any[];
  public tag: any;

  public mediabankSiteSettings: MediabankSiteSettings = new MediabankSiteSettings();

  constructor(private mediaService: MediaService, private route: ActivatedRoute, private tagService: TagService, private router: Router, 
    private authService: AuthenticationService) { 

      this.mediaFiles = [];
      this.tag = {};
      this.mediabankSiteSettings = this.authService.currentUserValue.siteSettings.mediabankSiteSettings;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.urlRewrite = params['urlRewrite'];
      this.getTag();
      this.getMediaFilesByTag();
   });
  }

  getTag() {
    this.tagService.GetTagByUrlRewrite(this.urlRewrite).subscribe((data: any) => {
      this.tag = data;
    })
  }

  getMediaFilesByTag() {
    this.mediaService.GetMediaFilesByTag(this.urlRewrite).subscribe((data: any) => {
      this.mediaFiles = data;
    })
  }

  getTypeName(type) {
    return this.mediaService.getTypeName(type);
  }

  selectTag(tag) { 
    this.router.navigate(['/modules/tag-medias/', (tag.urlRewrite || tag.tagUrlRewrite)]);
  }

  getFullUrl(mediaFile) {
    return window.location.origin + "/modules/media/" + mediaFile.urlRewrite;
  }

}
