<div class="blog-tag-list-wrapper">
  <bee-tag-cloud 
    *ngIf="blogSiteSettings.showTagCloud && blogSiteSettings.showTagCloud.show == true && blogSiteSettings.showTagCloud.template == 'fab'"
    [selectedTag]="tag" 
    (tagSelect)="selectTag($event)">

  </bee-tag-cloud>

  <div class="mediafile-tag-list-header">
    <i class="material-icons icon-15x">
      local_offer
    </i>
    <h2 [innerHtml]="tag.name" class="hide-mobile"></h2>
    <h1 [innerHtml]="tag.name" class="hide-desktop"></h1>
  </div>

  <div class="mediafile-tag-list-wrapper">
    <div class="mediafile-tag-list-items">
      <div class="mediafile-tag-list-item" *ngFor="let blog of blogs">

        <a class="tag-list-image-wrapper" [routerLink]="['/modules/blog/', blog.urlRewrite]">
          <bee-landscape-image [image]="blog.imgUrl | cdn" [alt]="blog.name"></bee-landscape-image>
          <!-- <img [src]="blog.imgUrl | cdn" [alt]="blog.name"> -->
        </a>

        <div class="tag-list-info">
          <h3 [innerHtml]="blog.name" class="hide-mobile"></h3>
          <h2 [innerHtml]="blog.name" class="hide-desktop"></h2>
          <p [innerHtml]="blog.description" class="hide-mobile"></p>
          <div>
            <span>
              <bee-item-tags [tags]="blog.tags" (tagSelect)="selectTag($event)"></bee-item-tags>
            </span>
          </div>
        </div>

        <div class="share">
          <bee-share title="{{blog.name}}" url="{{getFullUrl(blog)}}"></bee-share>
        </div>
      </div>
    </div>
  </div>
</div>
