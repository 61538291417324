import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { CdnPipe } from '../cdn/cdn.pipe';

@Pipe({
  name: 'beePlaceholders'
})
export class BeePlaceholdersPipe implements PipeTransform {

  constructor(private authService: AuthenticationService, private cdnPipe: CdnPipe) {
    
  }

  transform(value: string, ...args: unknown[]): unknown {
    var user = this.authService.currentUserValue.user || new BeeUser(); 

    if(value.indexOf("#USER_NAME#") > -1) {
      value = value.replace("#USER_NAME#", user.firstName || "");
    }

    if(value.indexOf("#USER_LASTNAME#") > -1) {
      value = value.replace("#USER_LASTNAME#", user.lastName || "");
    }

    if(value.indexOf("#UNIT_NAME#") > -1) {
      value = value.replace("#UNIT_NAME#", user.unit.name || "");
    }

    if(value.indexOf("#UNIT_IMAGE#") > -1) {
      var imgUrl = "";
      if(user.unit.logoUrl) {
        imgUrl = this.cdnPipe.transform(user.unit.logoUrl);
      }

      value = value.replace("#UNIT_IMAGE#", `<img class="unit-logo" src="${imgUrl}" />`);
    }

    return value;
  }

}
