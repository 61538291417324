import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { SiteCulture } from 'src/app/authorize/models/user-session';

@Component({
  selector: 'bee-culture-picker',
  templateUrl: './bee-culture-picker.component.html',
  styleUrls: ['./bee-culture-picker.component.scss']
})
export class BeeCulturePickerComponent implements OnInit {
  public cultureOpen: boolean = false;

  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
  }

  setCulture(culture: SiteCulture): void {
    this.authService.setCulture(culture);
    this.cultureOpen = false;
  }

  public getFlagIcon(culture: SiteCulture) {
    if(!culture.flagIcon || culture.flagIcon.length <= 0) return `cdn/${culture.iso2}.png`

    return culture.flagIcon;
  }

}
