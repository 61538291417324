<section id="search" (click)="showSearch()">
  <div class="search-option">

    <!-- <bee-icon class="small" icon="search"></bee-icon> -->

    <bee-animated-icon class="md-36"
      start="search" 
      end="close" 
      colorStart="none" 
      colorEnd="none" 
      [animate]="visible"
      [animateFromParent]="true"></bee-animated-icon>
      
    <span class="hide-mobile normal" *ngIf="settings?.showLabel">Sök</span>
  </div>
</section>
