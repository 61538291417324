import { Component, OnInit } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';

import dayjs from 'dayjs'
import { ICalendarService } from '../../interfaces/calendar.service.interface';
import { CalendarEvent } from 'src/app/admin/calendar/calendar-models';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-cms-module-calendar',
  templateUrl: './cms-module-calendar.component.html',
  styleUrls: ['./cms-module-calendar.component.scss']
})
export class CmsModuleCalendarComponent implements OnInit {
  public selectedDay: CalendarDate;
  public calendarEvents: CalendarEvent[] = [];
  public calendarDates: CalendarDate[] = [];
  sliderOptions: OwlOptions;

  constructor(public moi: CMSModuleInstance, private calendarService: ICalendarService) { 
    if(!this.moi.settings.content.startDayOffset) {
      this.moi.settings.content.startDayOffset = 0;
    }

    if(!this.moi.settings.content.endDayOffset) {
      this.moi.settings.content.endDayOffset = 60;
    }

    if(!this.moi.settings.content.limit) {
      this.moi.settings.content.limit = 5;
    }

    if(!this.moi.settings.content.template) {
      this.moi.settings.content.template = "default"
    }
  }

  ngOnInit() {
    this.getCalendarEvents();
    this.initSlider();
  }

  selectDay(day: CalendarDate): void {
    this.selectedDay = day;
  }

  getCalendarEvents() {
    let startTime = dayjs().add(this.moi.settings.content.startDayOffset, 'day').toDate();
    let endTime = dayjs().add(this.moi.settings.content.endDayOffset, 'day').toDate();
    this.calendarService.getCalendarEventsBetweenDates(startTime, endTime, 0, 99).subscribe(response => {
      if(response.isSuccessful) {
        this.calendarEvents = response.data;

        this.handleCalendarDates();
      }
    })
  }

  handleCalendarDates() {
    var prevEvent = null;
    this.calendarEvents.forEach((event) => {
      if(prevEvent == null) {
        this.calendarDates.push(new CalendarDate(event.startTimeUtc, [event]));
      }
      else {
        if(dayjs(event.startTimeUtc).isSame(prevEvent.startTimeUtc, 'day')) {
          this.calendarDates[this.calendarDates.length - 1].events.push(event);
        }
        else {
          this.calendarDates.push(new CalendarDate(event.startTimeUtc, [event]));
        }
      }

      prevEvent = event;
    });

    console.log(this.calendarDates);

    this.selectedDay = this.calendarDates[0];
  }

  getYear(date) {
    return dayjs(date).year();
  }

  getMonth(date) {
    return dayjs(date).format('MMM');
  }

  getMonthLong(date) {
    return dayjs(date).format('MMMM');
  } 

  getDayNumber(date) {
    return dayjs(date).format('DD');
  }

  initSlider() {
    this.sliderOptions = {
      center: false,
      dots: this.moi.settings.content.showDots,
      autoWidth: false,
      autoplay: false,
      loop: true,
      autoplayHoverPause: true, 
      slideBy: this.moi.settings.content.limit,
      responsive: {
        981: {
          items: this.moi.settings.content.limit,
          slideBy: this.moi.settings.content.limit,
          nav: this.moi.settings.content.showNav
        },
        0: {
          items: 1,
          slideBy: 1,
          nav: this.moi.settings.content.showNav
        }
      }
    };
  }

}

@Component({
  selector: 'app-cms-module-calendar-settings',
  templateUrl: './cms-module-calendar-settings.component.html',
  styleUrls: ['./cms-module-calendar.component.scss']
})
export class CmsModuleCalendarSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { }

  ngOnInit() {
  }

}


class CalendarDate {
  date: Date;
  events: CalendarEvent[];

  constructor(date: Date, events: CalendarEvent[]) {
    this.date = date;
    this.events = events;
  }
}
