import { Component, OnInit, Input } from '@angular/core';
import { LayoutEntity } from 'src/app/admin/layout/layout-models';
import { LayoutService } from './layout.service';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { SiteCulture } from 'src/app/authorize/models/user-session';

@Component({
  selector: 'bee-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  layout: LayoutEntity;
  @Input('layout-type') layoutType: string;

  constructor(private layoutService: LayoutService, private authService: AuthenticationService) {
    this.layout = new LayoutEntity();
  }

  ngOnInit() {
    this.authService.onCultureChange.subscribe((culture: SiteCulture) => {
      this.GetLayout();
    });

    this.GetLayout();
  }

  GetLayout() {
    this.layoutService.Get(this.layoutType).subscribe((data: any) => {
      if(data.Layout && data.CMSContainerLayout) {
        if(data.Layout.isActive) {
          this.layout = new LayoutEntity(data.Layout, data.CMSContainerLayout);
        }
        else {
          //Want to delete itself...
        }
      }
    });
  }

}
