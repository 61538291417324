export const KCF_MODULES: Array<any> = [
    {
      componentName: "LanguagePracticeComponent",
      adminComponentName: "LanguagePracticeComponent",
      adminSettingsComponentName: "LanguagePracticeSettingsComponent",
      key: "KCF_LANGUAGE_PRACTICE_WIDGET",
      abstractName: "Language Practice widget",
      icon: "format_quote",
      description: "Created a language practice widget",
      id: 2001,
      getComponent: () => {
        return import("../../cms-modules/customers/kcf/meatlingo/language-practice/language-practice.component");
      }
    },
  ];