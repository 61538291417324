import { Injectable } from '@angular/core';
import { ISearchService, ISearchItem } from 'src/app/container/interfaces/search.service.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService implements ISearchService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  public getSearchItems(query: string): Observable<ISearchItem> {
    return this.http.get<ISearchItem>(this.config.apiEndpoint + "api-search/getmoduledata?term=" + query);
  }
}
