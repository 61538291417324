import { Component, OnInit } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from '@firebase/util';

@Component({
  selector: 'bee-cms-module-my-profile',
  templateUrl: './cms-module-my-profile.component.html',
  styleUrls: ['./cms-module-my-profile.component.scss']
})
export class CmsModuleMyProfileComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { 
    if(this.moi.settings.content.template == null) {
      this.moi.settings.content.template = 'default';
    }
  }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    
  }

}

@Component({
  selector: 'bee-cms-module-my-profile-settings',
  templateUrl: './cms-module-my-profile-settings.component.html',
  styleUrls: ['./cms-module-my-profile.component.scss']
})
export class CmsModuleMyProfileSettingsComponent implements OnInit {

  constructor(public moi: CMSModuleInstance) { 
  }

  ngOnInit(): void {
  }

}
