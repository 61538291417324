<bee-animated-icon class="md-36"
  start="menu" 
  end="close" 
  colorStart="#707070" 
  colorEnd="#3C3C3C" 
  [(animate)]="menuOpen"
  [animateFromParent]="false"></bee-animated-icon>


  <div class="thermoeyes-menu-wrapper" [class.show]="menuOpen">
    <div *ngFor="let menuItem of menuItems | filter:['isDeleted', false]" class="thermoeyes-menu-item" (click)="navigate(menuItem); $event.stopPropagation();">
      <p>
        {{menuItem.text}}
        <bee-animated-icon *ngIf="(menuItem.children | filter:['isDeleted', false]).length > 0" 
          start="keyboard_arrow_down" 
          end="keyboard_arrow_left" 
          colorStart="#757575" 
          colorEnd="#00a0d6" 
          [(animate)]="menuItem.isOpen" (click)="$event.stopPropagation();"></bee-animated-icon> 
      </p>

      <div class="thermoeyes-menu-sub-wrapper" *ngIf="menuItem.children.length > 0 && menuItem.isOpen">
        <div *ngFor="let subItem of menuItem.children | filter:['isDeleted', false]" class="thermoeyes-menu-sub-item" (click)="navigate(subItem)">
          <p>{{subItem.text}}</p>
        </div>
      </div>
    </div>
  </div>