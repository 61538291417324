import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'bee-bee-show-date',
  templateUrl: './bee-show-date.component.html',
  styleUrls: ['./bee-show-date.component.scss']
})
export class BeeShowDateComponent extends FieldType {
  public now = new Date();

}
