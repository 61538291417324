import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import { MediaService } from '../services/media.service';
import { Router } from '@angular/router';
import { ITagInterface } from '../../interfaces/tag.service.interface';
import { CmsEventService } from '../../cms-editor/cms-event-service';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';

@Component({
  selector: 'app-cms-module-video-list',
  templateUrl: './cms-module-video-list.component.html',
  styleUrls: ['./cms-module-video-list.component.scss']
})
export class CmsModuleVideoListComponent implements OnInit {
  videos: any[];
  public videos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  loadMoreLimit: number = 12;
  tags: any[] = [];

  selectedTags: string[] = [];

  deviceEmulation: string;

  queryTag: string = undefined;

  mediaTypes: number[] = [this.mediaService.TYPES.VIDEO, this.mediaService.TYPES.EXTERNAL_VIDEO]
  public videoChecked: boolean = true;
  public audioChecked: boolean = true; 

  constructor(public module: CMSModuleInstance, private mediaService: MediaService, private router: Router,
    private tagService: ITagInterface, private eventService: CmsEventService) { 
    this.videos = [];
    if(!this.module.settings.content.selectedTags) {
      this.module.settings.content.selectedTags = [];
    }
  }

  toggleVideoChecked(e: MatCheckboxChange): void {

    if(e.checked) {
      if(this.audioChecked) {
        this.mediaTypes = [this.mediaService.TYPES.VIDEO, this.mediaService.TYPES.EXTERNAL_VIDEO, this.mediaService.TYPES.AUDIO];
      }
      else {
        this.mediaTypes = [this.mediaService.TYPES.VIDEO, this.mediaService.TYPES.EXTERNAL_VIDEO];
      }
    }
    else {
      if(this.audioChecked) {
        this.mediaTypes = [this.mediaService.TYPES.AUDIO];
      }
      else {
        this.mediaTypes = [];
      }
    }

    this.getVideos();

  }

  toggleAudioChecked(e: MatCheckboxChange): void {
    if(e.checked) {
      if(this.videoChecked) {
        this.mediaTypes = [this.mediaService.TYPES.VIDEO, this.mediaService.TYPES.EXTERNAL_VIDEO, this.mediaService.TYPES.AUDIO];
      }
      else {
        this.mediaTypes = [this.mediaService.TYPES.AUDIO];
      }
    }
    else {
      if(this.videoChecked) {
        this.mediaTypes = [this.mediaService.TYPES.VIDEO, this.mediaService.TYPES.EXTERNAL_VIDEO];
      }
      else {
        this.mediaTypes = [];
      }
    }

    this.getVideos();
  }

  ngOnInit() {

    if(this.module.settings.content.showMediaTypeSelector) {
      this.mediaTypes.push(this.mediaService.TYPES.AUDIO);
    }

    const params = new URLSearchParams(window.location.search);

    if(params.has('tag')) {
      this.queryTag = params.get('tag');

      this.selectedTags = this.queryTag.split(',');
    }

    this.eventService.setDeviceEmulation.subscribe(emu => {
      this.deviceEmulation = emu;
    });

    if(!this.module.settings.content.colCount) {
      this.module.settings.content.colCount = 3;
    }

    this.getVideos();

    this.module.settings.content.limit = ~~this.module.settings.content.limit;
    this.loadMoreLimit = ~~this.module.settings.content.limit;

    this.getTags();
  }

  getTags() {
    this.tagService.getTags().subscribe(response => {
      this.tags = response;
    });
  }

  getTagsToShow(): any[] {
    if(this.module.settings.content.showSpecificTags) {
      return this.module.settings.content.selectedTags;
    }
    else {
      return this.tags;
    }
  }

  showMore() {
    this.module.settings.content.limit += this.loadMoreLimit;
  }

  isSelectedTag(tagUrlRewrite) {
    return this.selectedTags.indexOf(tagUrlRewrite) >= 0;
  }

  selectTag(tag) {

    if(this.selectedTags.indexOf(tag.urlRewrite) < 0) {
      this.selectedTags.push(tag.urlRewrite);
    }
    else {
      this.selectedTags.splice(this.selectedTags.indexOf(tag.urlRewrite), 1);
    }

    // if(tag.urlRewrite == this.module.settings.content.tag) {
    //   this.module.settings.content.tag = undefined;
    // }
    // else {
    //   this.module.settings.content.tag = tag.urlRewrite;
    // }
    
    this.getVideos();
  }

  getVideos() {
    this.videos$.next(null);
    if(this.mediaTypes.length <= 0) {
      this.videos = [];
      this.videos$.next(this.videos);
      return;
    }

    var tags = this.selectedTags.join(',');

    if(tags && tags.length > 0) {
      this.mediaService.GetMediaFilesByTagAndTypes(tags, this.mediaTypes).subscribe((data: any) => {
        this.videos = data;
        this.videos$.next(this.videos);
      });
    }
    else {
      this.mediaService.GetAllByTypes(this.mediaTypes).subscribe((data: any) => {
        this.videos = data;
        this.videos$.next(this.videos);
      });
    }
  }

  handleTime(time) {
    if(!time || time <= 0) {
      return "00";
    }
    if(time && time <= 9) {
      return "0" + time;
    }

    return time;
  }

  goToMedia(video) {
    this.router.navigate(['/modules/media/', video.urlRewrite]);
  }

  getTagTextColor(color) {
    if(color && color.length > 0) {
      return "#FFFFFF";
    }
  }

}

@Component({
  selector: 'app-cms-module-video-list-settings',
  templateUrl: './cms-module-video-list-settings.component.html',
  styleUrls: ['./cms-module-video-list.component.scss']
})
export class CmsModuleVideoListSettingsComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public tags = [];
  public tagCtrl = new UntypedFormControl();
  public tagNames: string[] = [];
  filteredTags: Observable<any[]>;

  @ViewChild('tagInput') fruitInput: ElementRef<HTMLInputElement>;

  constructor(public module: CMSModuleInstance, private mediaService: MediaService, private tagService: ITagInterface) { 
  }

  ngOnInit() {
    if(!this.module.settings.content.mediaType) {
      this.module.settings.content.mediaType = this.mediaService.TYPES.VIDEO;
    }

    if(!this.module.settings.content.limit) {
      this.module.settings.content.limit = 3;
    }

    this.tagService.getTags().subscribe(t => {
      this.tags = t;

      this.tags.forEach(t => {
        this.tagNames.push(t.name);
      });

      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => (tag ? this._filter(tag) : this.tagNames.slice())),
      );
    });
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;

    // Add our fruit
    if (value) {
      this.tags.forEach(t => {
        if(t.name.toLowerCase() == value.toLowerCase())  {
          console.log("Adding", value);
          this.module.settings.content.selectedTags.push(t);
        }
      });
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.module.settings.content.selectedTags.indexOf(fruit);

    if (index >= 0) {
      this.module.settings.content.selectedTags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.forEach(t => {
      if(t.name.toLowerCase() == event.option.viewValue.toLowerCase()) {
        this.module.settings.content.selectedTags.push(t);
      }
    })
    this.fruitInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.tagNames.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  getColor(tag: any): string {
    if(tag.color) {
      return "#FFFFFF";
    }

    return "#000000";
  }

}
