<span class="required" *ngIf="field.templateOptions.required">*</span>
<p><strong [innerHtml]="field.templateOptions.label"></strong></p>

<div class="buttons-wrapper">
  <div *ngFor="let option of field.templateOptions.options" 
    class="bee-select-button margin-right" (click)="selectOption(option)"
    [ngClass]="{'selected': option.selected}">
    <span><strong [innerHtml]="option.label"></strong></span>
    <span class="description" [innerHtml]="option.description"></span>
  </div>
</div>
