import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CalendarEvent } from "src/app/admin/calendar/calendar-models";
import { PaginationReturnModel } from "src/app/domain/core/help-models/pagination-return-model";

@Injectable()
export abstract class ICalendarService {
    abstract getCalendarEventsBetweenDates(startTime: Date, endTime: Date, pageIndex: number, pageSize: number): 
        Observable<PaginationReturnModel<CalendarEvent[]>>;
    abstract getCalendarEvents(pageIndex: number, pageSize: number): 
        Observable<PaginationReturnModel<CalendarEvent[]>>;
    abstract getCalendarEvent(id: string): Observable<CalendarEvent>;
}
