<form class="video-settings-wrapper settings-wrapper">
  <mat-form-field>
    <input matInput placeholder="Video url" name="videoUrl" [(ngModel)]="module.settings.content.video.fileUrl">
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Title" name="title" [(ngModel)]="module.settings.content.title">
  </mat-form-field>

  <p class="margin-top bold">Video:</p>
  <mediabank-file-handler [(mediaFile)]="module.settings.content.video" [fileType]="2"></mediabank-file-handler>

  <p class="margin-top bold">Poster:</p>

  <mediabank-file-handler [(mediaFile)]="module.settings.content.videoPoster"></mediabank-file-handler>

  <div class="margin-top" *ngIf="module.settings.content.video && module.settings.content.video.mediabankFileId">
    <p>Subtitles:</p>

    <div class="subtitle-wrapper">
      <div *ngFor="let sub of file.relatedFiles | filter:['type', 8]" class="subtitle">
        <span [innerHtml]="sub.name"></span>
        <span [innerHtml]="sub.iso2Culture"></span>
      </div>
    </div>

    <mediabank-file-handler [useCultures]="useCulture" [realtedId]="module.settings.content.video.mediabankFileId" (callback)="addSubtitleCallback($event)" [(mediaFile)]="subtitle" [fileType]="2"></mediabank-file-handler>
  </div>

  <div class="margin-top">
    <p>Views: <span [innerHtml]="file.viewCount"></span></p>
  </div>
</form>