import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/admin/blog/blog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TagService } from '../../services/tag.service';
import { BlogSiteSettings } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'app-blog-tag-list',
  templateUrl: './blog-tag-list.component.html',
  styleUrls: ['./blog-tag-list.component.scss']
})
export class BlogTagListComponent implements OnInit {
  sub: any;
  urlRewrite: String;
  public blogs: any[];
  public tag: any;
  public blogSiteSettings: BlogSiteSettings = new BlogSiteSettings();	

  constructor(private blogService: BlogService, private route: ActivatedRoute, private tagService: TagService, private router: Router,
    private authService: AuthenticationService) { 
      this.blogSiteSettings = this.authService.currentUserValue.siteSettings.blogSiteSettings;
      this.blogs = [];
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.urlRewrite = params['urlRewrite'];
      this.getTag();
      this.getBlogsByTag();
   });
  }

  getTag() {
    this.tagService.GetTagByUrlRewrite(this.urlRewrite).subscribe((data: any) => {
      this.tag = data;
    })
  }

  getBlogsByTag() {
    this.blogService.GetByTag(this.urlRewrite).subscribe((data: any) => {
      this.blogs = data;
    });
  }

  selectTag(tag) { 
    this.router.navigate(['/modules/tag-blogs/', (tag.urlRewrite || tag.tagUrlRewrite)]);
  }

  getFullUrl(mediaFile) {
    return window.location.origin + "/modules/media/" + mediaFile.urlRewrite;
  }

}
