<div class="notification-bell-wrapper" (click)="showNotifications = !showNotifications">
  <mat-icon [fontSet]="settings.fontSet">notifications</mat-icon>
  <div class="indicator" *ngIf="hasUnreadNotification()"></div>
</div>

<ng-container *ngTemplateOutlet="{'dropdown': dropdown,'slideFromRight': slideFromRight}[settings.showType]"></ng-container>


<ng-template #dropdown>
  <div class="notifications" (beeBeeClickOutside)="onClick($event);" 
  [class]="authService.currentUserValue.siteSettings.defaultAppearance" *ngIf="userNotifications" [hidden]="!showNotifications">
    <h4 class="margin-left margin-top margin-bottom">Notiser</h4>
    <bee-user-notifications-list [showIcon]="settings.showNotificationIcon" [showDate]="settings.showNotificationDate" [userNotifications]="userNotifications.data"></bee-user-notifications-list>
  </div>
</ng-template>

<ng-template #slideFromRight>
  <div class="slide-from-right" [class.show]="showNotifications" (beeBeeClickOutside)="onClick($event);">
    <div class="header">
      <span>NOTISER</span>
      <mat-icon (click)="showNotifications = false;">close</mat-icon>
    </div>

    <div class="notifications-wrapper">
      <bee-user-notifications-list *ngIf="userNotifications" [userNotifications]="userNotifications.data | limitTo:4"></bee-user-notifications-list>
    </div>

    <div class="footer">
      <!-- <mat-icon>chevron_right</mat-icon> -->
      <a routerLink="/modules/user/notifications" (click)="showNotifications = false;" [innerHtml]="'USER.SHOW_ALL_NOTIFICATIONS' | translate"></a>
    </div>
  </div>
</ng-template>