<div class="video-wrapper margin-bottom lg"> 
  <video class="video" controls #videoPlayer *ngIf="!_reloadVideo">
    <source #videoSrc type="video/mp4">
  </video>

  <div class="play-overlay" [style.background-image]="'url(' + (getPoster() | cdn) + ')'" (click)="startVideo()" *ngIf="!videoStarted">
    <!-- <div class="play-icon">
      <i class="material-icons">play_arrow</i>
    </div> -->

    <bee-play-icon></bee-play-icon>
  </div>
</div>

<bee-object-culture-picker class="margin-top lg" 
  *ngIf="authService.currentUserValue.siteSettings.mediabankSiteSettings.showTranslatedVideos.show"
  (onChange)="changeCulture($event)" 
  [mainObject]="video"
  [selectedObject]="selectedVideo"
  [objects]="video.relatedFiles | filter:['type', 10]">
</bee-object-culture-picker>