<ng-container *ngTemplateOutlet="{'advancedList': advancedList,'atumn': atumn, 'tertile': tertile, 'default': default}[moi.settings.content.layout]"></ng-container>

<ng-template #atumn>
  <div class="atumn-wrapper">
    <div class="tabs">
      <div class="tab" [hidden]="tab.settings.content.hide" *ngFor="let tab of getTabs(); let i = index" [class.selected]="tab == selectedTab" [style.--data-color]="moi.settings.content.selectedColor || 'black'"
        [style.--data-text-color]="moi.settings.content.selectedTextColor || 'black'"
        (click)="selectTab(tab)">

        <img class="tab-img" *ngIf="tab.settings.content.tabImage && tab.settings.content.tabImage.imgUrl" [src]="tab.settings.content.tabImage.imgUrl | cdn" alt="">
        <span class="tab-number" *ngIf="moi.settings.content.showNumbers" [innerHtml]="i + 1"></span>
        <span class="tab-name" [innerHtml]="tab.settings.content.tabName"></span>

        <div class="tab-line" [style.backgroundColor]="moi.settings.content.selectedColor"></div>
      </div>
    </div>

    <div class="tab-content-wrapper margin-top lg" [style.borderColor]="moi.settings.content.selectedColor" *ngFor="let t of moi.moduleInstances" [hidden]="t != selectedTab || t.moduleInstances <= 0">
      <app-cms-module-wrapper class="tab-content" [moi]="t" [hasAdminAccess]="moi.hasAdminAccess"
        [ngStyle]="t.settings.style | mediaQuery:{'mobile': t.settings.mobileStyle}"></app-cms-module-wrapper>
    </div>
  </div>
</ng-template>

<ng-template #tertile>
  <div class="tertile-wrapper">
    <div class="tab-content-wrapper margin-top lg" [style.borderColor]="moi.settings.content.selectedColor">
      <app-cms-module-wrapper [hasAdminAccess]="moi.hasAdminAccess" class="tab-content" *ngFor="let t of moi.moduleInstances" [moi]="t" [hidden]="t != selectedTab" 
        [ngStyle]="t.settings.style | mediaQuery:{'mobile': t.settings.mobileStyle}"></app-cms-module-wrapper>
    </div>

    <div class="tabs margin-top lg">
      <div class="tab" [hidden]="tab.settings.content.hide" *ngFor="let tab of getTabs(); let i = index" [class.selected]="tab == selectedTab" [style.--data-color]="moi.settings.content.selectedColor || 'black'"
        [style.--data-text-color]="moi.settings.content.selectedTextColor || 'black'"
        (click)="selectTab(tab)">
        <span *ngIf="moi.settings.content.showNumbers" [innerHtml]="i + 1"></span>
        <img *ngIf="tab.settings.content.tabImage" [src]="tab.settings.content.tabImage | cdn" alt="">
        <span class="tab-name" [innerHtml]="tab.settings.content.tabName"></span>

        <div class="tab-line" [style.backgroundColor]="moi.settings.content.selectedColor"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #advancedList>
  <div class="advanced-list-wrapper">
    <div class="tab-content-wrapper margin-top lg" [style.borderColor]="moi.settings.content.selectedColor">
      <app-cms-module-wrapper [hasAdminAccess]="moi.hasAdminAccess" class="tab-content" *ngFor="let t of getAllModuleInstances()" [moi]="t" [hidden]="t != selectedTab"
      [ngStyle]="t.settings.style | mediaQuery:{'mobile': t.settings.mobileStyle}"></app-cms-module-wrapper>
    </div>

    <div class="tabs margin-top lg">
      <div class="tab" [hidden]="tab.settings.content.hide" *ngFor="let tab of getTabs(); let i = index" [class.selected]="tab == selectedTab" [class.hasSubTabs]="tab.settings.content.subTabs && tab.isOpen" [style.--data-color]="moi.settings.content.selectedColor || 'black'"
        [style.--data-text-color]="moi.settings.content.selectedTextColor || 'black'"
        (click)="selectTab(tab);">
        <span *ngIf="moi.settings.content.showNumbers" [innerHtml]="i + 1"></span>
        <img *ngIf="tab.settings.content.tabImage" [src]="tab.settings.content.tabImage | cdn" alt="">
        <div class="tab-name-wrapper">
          <span class="tab-name" [innerHtml]="tab.settings.content.tabName"></span>

          <bee-animated-icon
            *ngIf="tab.settings.content.subTabs && tab.settings.content.subTabs.length > 0"
            start="keyboard_arrow_right" 
            end="keyboard_arrow_right"  
            [(animate)]="tab.isOpen"></bee-animated-icon> 
        </div>

        <div class="sub-tabs" *ngIf="tab.settings.content.subTabs" [hidden]="!tab.isOpen">
          <div *ngFor="let subTab of tab.settings.content.subTabs" (click)="selectTab(subTab, true); $event.stopPropagation(); false" class="sub-tab" [class.selected]="subTab == selectedTab">
            <img *ngIf="subTab.settings.content.tabImage" [src]="subTab.settings.content.tabImage | cdn" alt="">
            <span class="tab-name" [innerHtml]="subTab.settings.content.tabName"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #default>
  <div class="default-wrapper">
    <div class="tabs">
      <div class="tab" *ngFor="let tab of getTabs(); let i = index" [class.selected]="tab == selectedTab" [style.--data-color]="moi.settings.content.selectedColor || 'black'"
          [style.--data-text-color]="moi.settings.content.selectedTextColor || 'black'"
          (click)="selectTab(tab)">
          <span class="tab-number" *ngIf="moi.settings.content.showNumbers" [innerHtml]="i + 1"></span>
          <span class="tab-name" [innerHtml]="tab.settings.content.tabName"></span>
  
          <div class="tab-line" [style.backgroundColor]="moi.settings.content.selectedColor"></div>
        </div>
      </div>
  </div>
  <span>DEFAULT</span>
</ng-template>