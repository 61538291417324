<ng-container *ngTemplateOutlet="{ 'default': default, 'carousel': carousel }[moi.settings.content.template]"></ng-container>

<ng-template #default>
  <div class="calendar-module-wrapper">
    <div class="calendar-events">
      <div class="calendar-event" *ngFor="let day of calendarDates | limitTo:moi.settings.content.limit" (click)="selectDay(day)">
        <div class="calendar-date-info" [ngClass]="{'selected': selectedDay?.date == day.date}">
          <p *ngIf="moi.settings.content.showYear" [innerHtml]="getYear(day.date)" class="year"></p>
          <p [innerHtml]="getDayNumber(day.date)" class="day"></p>
          <p [innerHtml]="getMonth(day.date)" class="month"></p>
        </div>
      </div>
    </div>
  
    <div *ngIf="selectedDay">
      <div class="selected-event-info" *ngFor="let event of selectedDay.events">  
        <div class="calendar-icon-wrapper">
          <mat-icon>event</mat-icon>
        </div>
    
        <p [innerHtml]="event.name" class="selected-event-title"></p> 
        <p class="description" [innerHtml]="event.description"></p>
    
        <a class="bee-button secondary small margin-top" [href]="event.link" target="_blank">Mer info och anmälan</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #carousel>
  <div class="calendar-module-wrapper">
    <div class="calendar-events">

      <owl-carousel-o [options]="sliderOptions">
        <ng-container *ngFor="let day of calendarDates">
          <ng-template carouselSlide>

            <div class="calendar-event" (click)="selectDay(day)">
              <div class="calendar-date-info" [ngClass]="{'selected': selectedDay?.date == day.date}">
                <p *ngIf="moi.settings.content.showYear" [innerHtml]="getYear(day.date)" class="year"></p>
                <p [innerHtml]="getDayNumber(day.date)" class="day"></p>
                <p [innerHtml]="getMonth(day.date)" class="month"></p>
              </div>
            </div>
            
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  
    <div *ngIf="selectedDay">
      <div class="selected-event-info" *ngFor="let event of selectedDay.events">  
        <div class="calendar-icon-wrapper">
          <mat-icon>event</mat-icon>
        </div>
    
        <p [innerHtml]="event.name" class="selected-event-title"></p> 
        <p class="description" [innerHtml]="event.description"></p>
    
        <a class="bee-button secondary small margin-top" [href]="event.link" target="_blank">Mer info och anmälan</a>
      </div>
    </div>
  </div>
</ng-template>