import { Component, inject } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CmsEventService } from '../../cms-editor/cms-event-service';

@Component({
  selector: 'bee-cms-module-scroll-animation',
  templateUrl: './cms-module-scroll-animation.component.html',
  styleUrls: ['./cms-module-scroll-animation.component.scss']
})
export class CmsModuleScrollAnimationComponent {
  public module: CMSModuleInstance = inject(CMSModuleInstance);
  cmsEventService: CmsEventService = inject(CmsEventService);

  public items$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit() {

    this.items$.next(this.module?.settings?.content?.file?.relatedFiles?.map(item => {
      return item.fileUrl;
    }));

    this.cmsEventService.genericMessage.pipe(
      takeUntil(this.destroy$)
    ).subscribe((message: any) => {
      if (message.TYPE == "UPDATE_SCROLL_ANIM_FILE:" + this.module.id) {
        this.items$.next(message.PAYLOAD.relatedFiles.map(item => {
          return item.fileUrl;
        }));
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@Component({
  selector: 'bee-cms-module-scroll-animation-settings',
  templateUrl: './cms-module-scroll-animation-settings.component.html',
  styleUrls: ['./cms-module-scroll-animation.component.scss']
})
export class CmsModuleScrollAnimationSettingsComponent {
  public module: CMSModuleInstance = inject(CMSModuleInstance);
  cmsEventService: CmsEventService = inject(CmsEventService);

  public setFile(file: any) {
    console.log(file);
    this.module.settings.content.file = file;
    this.cmsEventService.genericMessage.next({ TYPE: "UPDATE_SCROLL_ANIM_FILE:" + this.module.id, PAYLOAD: file })
  }

}
