export class FormsSubmission {
  id: string;
  formId: string;
  formsData: any;
  userId: string;
  formsDocument: any;
  REFERER_TYPE?: string;
  REFERER_ID?: string;
  REFERER_EXTRA?: string;
  referer_name?: string;
}
