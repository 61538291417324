import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-slider',
  templateUrl: './bee-form-slider.component.html',
  styleUrls: ['./bee-form-slider.component.scss'],
})
export class BeeFormSliderComponent extends FieldType implements OnInit {
  public sliderVal;

  constructor() { 
    super();
    this.sliderVal = 0;
  }

  ngOnInit() {  
    if(!this.field.templateOptions.extraData) {
      this.field.templateOptions.extraData = {
        template: 'default'
      };
    }
    else if(!this.field.templateOptions.extraData.template) {
      this.field.templateOptions.extraData.template = 'default';
    }
  }

  getText() : string {
    if(this.sliderVal == 1) {
      if(this.field.templateOptions.attributes.label) {
        return this.sliderVal + " " + this.field.templateOptions.attributes.label + " eller mindre";
      }
      else {
        return this.sliderVal + " st eller mindre";
      }
    }
    if(this.sliderVal == this.field.templateOptions.attributes.max) {
      if(this.field.templateOptions.attributes.label) {
        return this.sliderVal + " " + this.field.templateOptions.attributes.label + " eller mer";
      }
      else {
        return this.sliderVal + " st eller mer";
      }
    }
    else {
      if(this.field.templateOptions.attributes.label) {
        return this.sliderVal + " " + this.field.templateOptions.attributes.label;
      }
      else {
        return this.sliderVal + " st";
      }
    }
  }
}
