import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';

@Injectable({
  providedIn: 'root'
})
export class BeeMetaService {

  constructor(private titleService: Title, private metaService: Meta, private cdnPipe: CdnPipe) { 

  }

  setBasicMeta(title: string, description: string, image?: string, video?: string) : void {
    this.titleService.setTitle(title);


    this.metaService.updateTag({name: "title", property: "og:title", content: title});
    this.metaService.updateTag({name: "description", property: "og:description", content: description});
    this.metaService.updateTag({name: "url", property: "og:url", content: window.location.href});

    if(image) {
      this.metaService.updateTag({name: "image", property: "og:image", content: this.cdnPipe.transform(image)});
    }

    if(video) {
      this.metaService.updateTag({name: "video", property: "og:video", content: this.cdnPipe.transform(video)});
    }
    
    setTimeout(function() {
      window["prerenderReady"] = true;
    }, 500);
  }
}
