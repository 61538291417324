import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bee-openurl',
  templateUrl: './openurl.component.html',
  styleUrls: ['./openurl.component.scss']
})
export class OpenurlComponent implements OnInit {
  sub: any;
  url: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    var url = new URL(window.location.href);
    this.url = atob(url.searchParams.get("url"));
    setTimeout(() => {
      window.location.href = this.url;
    }, 4000);
  }

}
