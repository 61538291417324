import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * GetBlogEntries
   */
  public GetBlogEntries(): Observable<PaginationReturnModel<any>> {
    return this.http.get<PaginationReturnModel<any>>(this.config.apiEndpoint + "api-blog/getall");
  }

  /**
   * GetByUrl
   */
  public GetByUrl(urlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + 'api/blog/GetByUrl/' + urlRewrite);
  }

  /**
   * GetRelated
blogId   */
  public GetRelated(blogId) {
    return this.http.get(this.config.apiEndpoint + 'api-blog/GetRelated?id=' + blogId);
  }

  /**
   * GetByTags
tags: string[]   */
  public GetByTags(tags: string[]) {
    let tagString = tags.join(',');
    return this.http.get(this.config.apiEndpoint + `api-blog/GetByTags?tags=${tagString}`);
  }
}
