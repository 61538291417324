<div class="blog-view-wrapper">
  <bee-tag-cloud 
    *ngIf="blogSiteSettings.showTagCloud && blogSiteSettings.showTagCloud.show == true && blogSiteSettings.showTagCloud.template == 'fab'"
    (tagSelect)="selectTag($event)">

  </bee-tag-cloud>

  <div *ngIf="blog.user && blogSiteSettings.showPublisher.show && blogSiteSettings.showPublisher.position != 1">
    <ng-container 
      *ngTemplateOutlet="{'default': publisherDefault, 'legacy': publisherLegacy }[blogSiteSettings.showPublisher.template]">
    </ng-container>
  </div>

  <p *ngIf="blogSiteSettings.showPublishedDate.show && blogSiteSettings.showPublishedDate.position != 1" 
    class="published-date" 
    [style.width]="blogSiteSettings.showPublishedDate.wrapperWidth"
    [style.textAlign]="blogSiteSettings.showPublishedDate.textAlign">
    <span>Publicerad: </span>
    <span [innerHtml]="blog.startTimeUtc | date"></span>
  </p>

  <div *ngIf="blogSiteSettings.showSocialShare.show && blogSiteSettings.showSocialShare.position != 1"
    class="social-share" [style.width]="blogSiteSettings.showSocialShare.wrapperWidth"
    [ngClass]="blogSiteSettings.showSocialShare.textAlign">

    <bee-social-share-default></bee-social-share-default>
  </div>

  <container-cms-view [container]="blog.cmsContainer"></container-cms-view>

  <div class="blog-view-info">
    <bee-item-tags *ngIf="blogSiteSettings.showTags" [tags]="blog.tags" (tagSelect)="selectTag($event)"></bee-item-tags>

    <div *ngIf="blog.user && blogSiteSettings.showPublisher.show && blogSiteSettings.showPublisher.position == 1">
      <ng-container 
        *ngTemplateOutlet="{'default': publisherDefault, 'legacy': publisherLegacy }[blogSiteSettings.showPublisher.template]">
      </ng-container>
    </div>

    <p *ngIf="blogSiteSettings.showPublishedDate.show && blogSiteSettings.showPublishedDate.position == 1" 
      class="published-date-bottom" 
      [style.width]="blogSiteSettings.showPublishedDate.wrapperWidth"
      [style.textAlign]="blogSiteSettings.showPublishedDate.textAlign">
      <span>Publicerad: </span>
      <span [innerHtml]="blog.startTimeUtc | date"></span>
    </p>

    <div *ngIf="blogSiteSettings.showSocialShare.show && blogSiteSettings.showSocialShare.position == 1"
      class="social-share" [style.width]="blogSiteSettings.showSocialShare.wrapperWidth"
      [ngClass]="blogSiteSettings.showSocialShare.textAlign">

      <bee-social-share-default></bee-social-share-default>
    </div>
  </div>
</div>

<div class="blog-view-related-outer-wrapper" *ngIf="blogSiteSettings.showRelatedBlogs">
  <div class="blog-view-related-wrapper" *ngIf="relatedBlogs.length > 0">
    <h2 class="hide-mobile">Relaterat</h2>
    <h1 class="hide-desktop">Relaterat</h1>

    <owl-carousel-o [options]="sliderBlogViewOptions">
      <ng-container *ngFor="let blog of relatedBlogs">
        <ng-template carouselSlide>
          <a [routerLink]="['/modules/blog/', blog.urlRewrite]">
            <div class="blog-list-item">
              <div class="blog-image">
                <bee-landscape-image [image]="blog.imgUrl | cdn" [alt]="blog.name"></bee-landscape-image>
                <!-- <img [src]="blog.imgUrl | cdn" alt="">  -->
              </div>
        
              <div class="blog-info">
                <h6 [innerHtml]="blog.name"></h6>
                <!-- <h2 class="hide-desktop" [innerHtml]="blog.name"></h2> -->
                <span [innerHtml]="blog.description"></span>
              </div>
            </div>
          </a>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>

<div class="blog-view-latest-items" *ngIf="blogSiteSettings.showAllBlogs.show">
  <ng-container *ngTemplateOutlet="{'default': default, 'legacy': legacy }[blogSiteSettings.showAllBlogs.template]"></ng-container>
</div>

<ng-template #default>
  <div class="default-all-blogs-wrapper">
    <bee-blog-list-default [blogs]="allBlogs"></bee-blog-list-default>
  </div>
</ng-template>

<ng-template #legacy>
  <bee-blog-list-legacy [blogs]="allBlogs"></bee-blog-list-legacy>
</ng-template>

<ng-template #publisherDefault>
  <p class="publisher"
  [style.width]="blogSiteSettings.showPublisher.wrapperWidth"
  [style.textAlign]="blogSiteSettings.showPublisher.textAlign">
    <span [innerHtml]="blog.user.fullName"></span>
  </p>
</ng-template>

<ng-template #publisherLegacy>
  <div class="publisher-info" 
  [style.width]="blogSiteSettings.showPublisher.wrapperWidth"
  [style.textAlign]="blogSiteSettings.showPublisher.textAlign">
    <div class="publisher-img" [style.backgroundImage]="'url(' + (blog.user.userPhotoUrl | cdn) + ')'"></div>
    <div class="publisher">
      <span class="publisher-name" [innerHtml]="blog.user.fullName"></span>
      <span class="publishing-date" [innerHtml]="blog.startTimeUtc | date:'D MMMM YYYY'"></span>
    </div>
  </div>
</ng-template>