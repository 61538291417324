import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModuleInstanceTemplate } from '../../cms-models';

@Component({
  selector: 'bee-cms-editor-create-module-instance-template-dialog',
  templateUrl: './cms-editor-create-module-instance-template-dialog.component.html',
  styleUrls: ['./cms-editor-create-module-instance-template-dialog.component.scss']
})
export class CmsEditorCreateModuleInstanceTemplateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CmsEditorCreateModuleInstanceTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ModuleInstanceTemplate) { }

  ngOnInit() {
  }

  ok(): void {
    this.dialogRef.close(this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
