import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  public Get(iso2: string): Observable<any[]> {
    return this.http.get<any[]>(this.config.apiEndpoint + "api-menu/get?iso2Culture=" + iso2);
  }
}
