import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bee-info',
  templateUrl: './bee-info.component.html',
  styleUrls: ['./bee-info.component.scss']
})
export class BeeInfoComponent implements OnInit {
  @Input() label;
  
  open: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.open = !this.open;
  }
}
