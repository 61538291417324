<div class="tag-cloud-button" (click)="isExpanded = !isExpanded">
  <i class="material-icons icon-125x">
    local_offer
  </i>
  <span>TAGGAR</span>
</div>


<div class="tag-cloud-tags-wrapper" [ngClass]="{'expanded': isExpanded}">
  <h2 class="hide-mobile">Välj tagg</h2>
  <h1 class="hide-desktop">Välj tagg</h1>

  <div class="tag-items">

    <div class="bee-button tag-item" *ngFor="let tag of tags" (click)="selectTag(tag)" [ngClass]="isSelected(tag)" [style.backgroundColor]="tag.color">
      <span [innerHtml]="tag.name"></span>
    </div>

  </div>

  <div class="tag-cloud-close-button" *ngIf="isExpanded" (click)="isExpanded = false;">
    <i class="material-icons">
      close
    </i>
  </div>
</div>
