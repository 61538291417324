import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'bee-icon',
  templateUrl: './bee-icon.component.html',
  styleUrls: ['./bee-icon.component.scss']
})
export class BeeIconComponent implements OnInit {
  @Input() icon: string;

  public fullIconPath: string;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  getTemplate(): string {
    if(this.authService.currentUserValue.siteSettings.iconOverrides) {
      if(this.authService.currentUserValue.siteSettings.iconOverrides[this.icon]) {
        return "custom";
      }
    }

    return "default";
  }

  getIcon(): string {
    if(this.authService.currentUserValue.siteSettings.iconOverrides) {
      if(this.authService.currentUserValue.siteSettings.iconOverrides[this.icon]) {
        return this.authService.currentUserValue.siteSettings.iconOverrides[this.icon];
      }
    }

    return this.icon;
  }

}
