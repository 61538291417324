import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { VerificationFlowInstance } from '../models/verification-flow-instance.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerificationFlowInstanceService {
  public selectedVerificationFlowInstance$: BehaviorSubject<VerificationFlowInstance> = new BehaviorSubject<VerificationFlowInstance>(null);

  constructor(private http: HttpClient, private config: AppConfigService) { 
    let instance = sessionStorage.getItem("verificationFlowInstance");
    if(instance) {
      this.selectedVerificationFlowInstance$.next(JSON.parse(instance));
    }
  }

  SetVerificationFlowInstance(instance: VerificationFlowInstance) {
    sessionStorage.setItem("verificationFlowInstance", JSON.stringify(instance));
    this.selectedVerificationFlowInstance$.next(instance);
  }

  GetMyLatestInstance(): Observable<VerificationFlowInstance> {
    return this.http.get<VerificationFlowInstance>(this.config.getApiEndpoint() + 
      "api-verificationflow/verificationflowinstance/getmylatestinstance").pipe(
        map((i: any) => {
          return new VerificationFlowInstance(i);
        })
      );
  }

  Get(id: string): Observable<VerificationFlowInstance> {
    return this.http.get<VerificationFlowInstance>(this.config.getApiEndpoint() + 
      `api-verificationflow/verificationflowinstance/get?id=${id}`).pipe(
        map((i: any) => { 
          let instance = new VerificationFlowInstance(i);
          return instance;
        })
      );
  }

  GetAll(): Observable<VerificationFlowInstance[]> {
    return this.http.get<VerificationFlowInstance[]>(this.config.getApiEndpoint() +
      "api-verificationflow/verificationflowinstance/getall");
  }

  SaveContent(content: any, instanceId: string, notificationType: string = "", subjectOverride: string = "",
    fragment: string = "", querystring: string = ""): Observable<SaveReturnModel<VerificationFlowInstance>> {
    return this.http.post<SaveReturnModel<VerificationFlowInstance>>(this.config.apiEndpoint + 
      `api-verificationflow/verificationflowinstance/SaveContent?instanceId=${instanceId}&notificationType=${notificationType}&subjectOverride=${subjectOverride}&fragment=${fragment}&querystring=${querystring}`, 
        content);
  }

  SendSaveNotification(instanceId: string, notificationType: string = "", subjectOverride: string = "", messageOverride: string = "",
    fragment: string = "", querystring: string = ""): Observable<any> {
    return this.http.get(this.config.apiEndpoint + 
      `api-verificationflow/verificationflowinstance/SendSaveNotification?instanceId=${instanceId}&notificationType=${notificationType}&subjectOverride=${subjectOverride}&messageOverride=${messageOverride}&fragment=${fragment}&querystring=${querystring}`);
  }
}
