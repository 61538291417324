import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


@Component({
  selector: 'bee-cms-animation-edit-dialog',
  templateUrl: './cms-animation-edit-dialog.component.html',
  styleUrls: ['./cms-animation-edit-dialog.component.scss']
})
export class CmsAnimationEditDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public animData) {
    
  }

  ngOnInit(): void {
    if(!this.animData) {
      this.animData = [{
        items: [],
        trigger: '',
        type: 'scroll',
        pin: false,
        scrub: false,
        start: 'top top',
        end: 'bottom bottom'
      }];
    }
    else if(this.animData.length <= 0) {
      this.animData.push({
        items: [],
        trigger: '',
        type: 'scroll',
        pin: false,
        scrub: false,
        start: 'top top',
        end: 'bottom bottom'
      });
    }
  }

  addProperty(item): void {
    if(!item.properties) item.properties = [];

    item.properties.push({key: "", value: ""});
  }

  deleteProperty(item, index): void {
    item.properties.splice(index, 1);
  }

  deleteTarget(data, index): void {
    data.items.splice(index, 1);
  }

  addTarget(data): void {
    if(!data.items) data.items = [];
    data.items.push({name: "", function: "from", properties: []});
  }

  addTrigger(): void {
    this.animData.push({
      items: [],
      trigger: '',
      type: 'scroll',
      pin: false,
      scrub: false,
      start: 'top top',
      end: 'bottom bottom'
    });
  }

  deleteTrigger(index): void {
    this.animData.splice(index, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}
