import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsEditorComponent, CmsEditorComponentDeleteMoiDialogComponent } from './cms-editor/cms-editor.component';
import { CmsModuleTextComponent, CmsModuleTextSettingsComponent } from './cms-modules/cms-module-text/cms-module-text.component';
import { CmsModuleImageComponent, CmsModuleImageSettingsComponent } from './cms-modules/cms-module-image/cms-module-image.component';
import { CmsModuleVideoComponent, CmsModuleVideoSettingsComponent } from './cms-modules/cms-module-video/cms-module-video.component';
import { CmsModuleSegmentComponent, CmsModuleSegmentSettingsComponent } from './cms-modules/cms-module-segment/cms-module-segment.component';
import { CmsModuleGoogleMapsComponent, CmsModuleGoogleMapsSettingsComponent } from './cms-modules/cms-module-google-maps/cms-module-google-maps.component';
import { CmsModuleWrapperComponent, CmsModuleSettingsWrapperComponent } from './cms-modules/cms-module-wrapper/cms-module-wrapper.component';
import { CmsModuleVideoListComponent, CmsModuleVideoListSettingsComponent } from './cms-modules/cms-module-video-list/cms-module-video-list.component';
import { CmsModuleCalendarComponent, CmsModuleCalendarSettingsComponent } from './cms-modules/cms-module-calendar/cms-module-calendar.component';
import { CmsModuleBlogListComponent, CmsModuleBlogListSettingsComponent } from './cms-modules/cms-module-blog-list/cms-module-blog-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import { SortPipe } from './pipes/sort.pipe';
import { FormsModule } from '@angular/forms';
import { CmsViewComponent } from './cms-view/cms-view.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CmsModulePageMenuComponent, CmsModulePageMenuSettingsComponent, CmsModulePageMenuAdminComponent } from './cms-modules/cms-module-page-menu/cms-module-page-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CmsModuleSliderComponent, CmsModuleSliderSettingsComponent, CmsModuleSliderSettingsAddDialogComponent } from './cms-modules/cms-module-slider/cms-module-slider.component';
import { ConfigurationModule } from '../configuration/configuration.module';
import { CmsModuleInstanceDialogComponent } from './dialogs/cms-module-instance-dialog/cms-module-instance-dialog.component';
import { MediaQueryPipe } from './pipes/media-query.pipe';
import { CmsStyleEditComponent } from './cms-editor/cms-style-edit/cms-style-edit.component';
import { CmsModuleButtonComponent, CmsModuleButtonSettingsComponent } from './cms-modules/cms-module-button/cms-module-button.component';
import { SortDescPipe } from './pipes/sort-desc.pipe';
import { BeeShareComponent } from './components/bee-share/bee-share.component';
import { CmsModuleSocialShareComponent, CmsModuleSocialShareSettingsComponent } from './cms-modules/cms-module-social-share/cms-module-social-share.component';
import { CmsModuleInstanceDialogContainerComponent } from './dialogs/cms-module-instance-dialog-container/cms-module-instance-dialog-container.component';
import { RouterModule } from '@angular/router';
import { BeeFormsModule } from '../modules/bee-forms/bee-forms.module';
import { CmsModuleFormsComponent, CmsModuleFormsSettingsComponent } from './cms-modules/cms-module-forms/cms-module-forms.component';
import { CmsModuleCardComponent, CmsModuleCardSettingsComponent} from './cms-modules/cms-module-card/cms-module-card.component';
import { CmsModuleCardSettingsAddDialogComponent } from './cms-modules/cms-module-card/cms-module-card-dialog.component';
import { BeePipesModule } from '../bee-pipes/bee-pipes.module';
import { CmsModuleNavigationComponent, CmsModuleNavigationSettingsComponent } from './cms-modules/cms-module-navigation/cms-module-navigation.component';
import { AddModuleDialogComponent } from './cms-editor/add-module-dialog/add-module-dialog.component';
import { CmsEditorCreateModuleInstanceTemplateDialogComponent } from './cms-editor/cms-editor-create-module-instance-template-dialog/cms-editor-create-module-instance-template-dialog.component';
import { CmsModuleSearchComponent, CmsModuleSearchSettingsComponent, SearchPanel } from './cms-modules/cms-module-search/cms-module-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { LowerPipe } from './pipes/lower.pipe';
import { CmsModuleSocialFeedComponent, CmsModuleSocialFeedSettingsComponent } from './cms-modules/cms-module-social-feed/cms-module-social-feed.component';
import { BeeComponentsModule } from '../bee-components/bee-components.module';
import { CmsModuleGenericContainerListComponent, CmsModuleGenericContainerListSettingsComponent } from './cms-modules/cms-module-generic-container-list/cms-module-generic-container-list.component';
import { CmsModuleTabsComponent, CmsModuleTabsSettingsComponent } from './cms-modules/cms-module-tabs/cms-module-tabs.component';
import { CmsModuleMediaListComponent, CmsModuleMediaListSettingsComponent } from './cms-modules/cms-module-media-list/cms-module-media-list.component';
import { CmsModuleCourseListComponent, CmsModuleCourseListSettingsComponent } from './cms-modules/cms-module-course-list/cms-module-course-list.component';
import { ShowJsonDialogComponent } from './cms-editor/show-json-dialog/show-json-dialog.component';
import { PasteJsonDialogComponent } from './cms-editor/paste-json-dialog/paste-json-dialog.component';
import { CmsModuleUserNotificationsComponent, CmsModuleUserNotificationsSettingsComponent } from './cms-modules/cms-module-user-notifications/cms-module-user-notifications.component';
import { CmsModuleCourseChapterTableInfoComponent, CmsModuleCourseChapterTableInfoSettingsComponent } from './cms-modules/cms-module-course-chapter-table-info/cms-module-course-chapter-table-info.component';
import { CmsModuleMyProfileComponent, CmsModuleMyProfileSettingsComponent } from './cms-modules/cms-module-my-profile/cms-module-my-profile.component';
import { CmsModuleUnitInformationComponent, CmsModuleUnitInformationSettingsComponent } from './cms-modules/cms-module-unit-information/cms-module-unit-information.component';
import { CmsModuleLoggedInMiniComponent, CmsModuleLoggedInMiniSettingsComponent } from './cms-modules/cms-module-logged-in-mini/cms-module-logged-in-mini.component';
import { CmsModuleHtmlInputComponent, CmsModuleHtmlInputSettingsComponent } from './cms-modules/cms-module-html-input/cms-module-html-input.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SortHasPermissionPipe } from './pipes/sort-has-permission.pipe';
import { CmsModuleVerificationFlowSelectComponent, CmsModuleVerificationFlowSelectSettingsComponent } from './cms-modules/cms-module-verification-flow-select/cms-module-verification-flow-select.component';
import { CmsModuleScrollAnimationComponent, CmsModuleScrollAnimationSettingsComponent } from './cms-modules/cms-module-scroll-animation/cms-module-scroll-animation.component';
import { ScrollAnimationComponent } from '../standalone/components/scroll-animation/scroll-animation.component';
import { ScrollAnimationDirective } from '../standalone/directives/scroll-animation.directive';
import { CmsAnimationEditDialogComponent } from './cms-editor/cms-animation-edit-dialog/cms-animation-edit-dialog.component';

export let AppInjector: Injector;

@NgModule({
    declarations: [
        CmsEditorComponent,
        CmsEditorComponentDeleteMoiDialogComponent,
        CmsModuleTextComponent,
        CmsModuleTextSettingsComponent,
        CmsModuleImageComponent,
        CmsModuleImageSettingsComponent,
        CmsModuleVideoComponent,
        CmsModuleVideoSettingsComponent,
        CmsModuleSegmentComponent,
        CmsModuleSegmentSettingsComponent,
        CmsModuleGoogleMapsComponent,
        CmsModuleGoogleMapsSettingsComponent,
        CmsModuleWrapperComponent,
        CmsModuleSettingsWrapperComponent,
        CmsModuleVideoListComponent,
        CmsModuleVideoListSettingsComponent,
        CmsModuleCalendarComponent,
        CmsModuleCalendarSettingsComponent,
        CmsModuleBlogListComponent,
        CmsModuleBlogListSettingsComponent,
        SortPipe,
        CmsViewComponent,
        CmsModulePageMenuComponent,
        CmsModulePageMenuSettingsComponent,
        CmsModulePageMenuAdminComponent,
        CmsModuleSliderComponent,
        CmsModuleSliderSettingsComponent,
        CmsModuleSliderSettingsAddDialogComponent,
        CmsModuleInstanceDialogComponent,
        MediaQueryPipe,
        CmsStyleEditComponent,
        CmsModuleButtonComponent,
        CmsModuleButtonSettingsComponent,
        SortDescPipe,
        BeeShareComponent,
        CmsModuleSocialShareComponent,
        CmsModuleSocialShareSettingsComponent,
        CmsModuleInstanceDialogContainerComponent,
        CmsModuleFormsComponent,
        CmsModuleFormsSettingsComponent,
        CmsModuleCardComponent,
        CmsModuleCardSettingsComponent,
        CmsModuleCardSettingsAddDialogComponent,
        CmsModuleNavigationComponent,
        CmsModuleNavigationSettingsComponent,
        AddModuleDialogComponent,
        CmsEditorCreateModuleInstanceTemplateDialogComponent,
        CmsModuleSearchComponent,
        CmsModuleSearchSettingsComponent,
        SearchPanel,
        LowerPipe,
        CmsModuleSocialFeedComponent,
        CmsModuleSocialFeedSettingsComponent,
        CmsModuleGenericContainerListComponent,
        CmsModuleGenericContainerListSettingsComponent,
        CmsModuleTabsComponent,
        CmsModuleTabsSettingsComponent,
        CmsModuleMediaListComponent,
        CmsModuleMediaListSettingsComponent,
        CmsModuleCourseListComponent,
        CmsModuleCourseListSettingsComponent,
        ShowJsonDialogComponent,
        PasteJsonDialogComponent,
        CmsModuleUserNotificationsComponent,
        CmsModuleUserNotificationsSettingsComponent,
        CmsModuleCourseChapterTableInfoComponent,
        CmsModuleCourseChapterTableInfoSettingsComponent,
        CmsModuleMyProfileComponent,
        CmsModuleMyProfileSettingsComponent,
        CmsModuleUnitInformationComponent,
        CmsModuleUnitInformationSettingsComponent,
        CmsModuleLoggedInMiniComponent,
        CmsModuleLoggedInMiniSettingsComponent,
        CmsModuleHtmlInputComponent,
        CmsModuleHtmlInputSettingsComponent,
        SortHasPermissionPipe,
        CmsModuleVerificationFlowSelectComponent,
        CmsModuleVerificationFlowSelectSettingsComponent,
        CmsModuleScrollAnimationComponent,
        CmsModuleScrollAnimationSettingsComponent,
        CmsAnimationEditDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        CKEditorModule,
        NgxFileDropModule,
        CarouselModule,
        ConfigurationModule,
        RouterModule,
        BeeFormsModule,
        BeePipesModule,
        //MATERIAL DESIGN
        MatDividerModule,
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
        MatGridListModule,
        MatInputModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatSelectModule,
        MatButtonModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTabsModule,
        MatBottomSheetModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSliderModule,
        MatChipsModule,
        MatAutocompleteModule,
        OverlayModule,
        //END MATERIAL DESIGN
        BeeComponentsModule,
        ScrollAnimationComponent,

        ScrollAnimationDirective,

        TranslateModule.forRoot()
    ],
    exports: [
        CmsEditorComponent,
        CmsViewComponent,
        CmsEditorComponentDeleteMoiDialogComponent,
        CmsModuleTextComponent,
        CmsModuleTextSettingsComponent,
        CmsModuleImageComponent,
        CmsModuleImageSettingsComponent,
        CmsModuleVideoComponent,
        CmsModuleVideoSettingsComponent,
        CmsModuleSegmentComponent,
        CmsModuleSegmentSettingsComponent,
        CmsModuleGoogleMapsComponent,
        CmsModuleGoogleMapsSettingsComponent,
        CmsModuleWrapperComponent,
        CmsModuleSettingsWrapperComponent,
        CmsModuleVideoListComponent,
        CmsModuleVideoListSettingsComponent,
        CmsModuleCalendarComponent,
        CmsModuleCalendarSettingsComponent,
        CmsModuleCardComponent,
        CmsModuleCardSettingsComponent,
        CmsModuleCardSettingsAddDialogComponent,
        CmsModuleBlogListComponent,
        CmsModuleBlogListSettingsComponent,
        CmsModulePageMenuComponent,
        CmsModulePageMenuSettingsComponent,
        CmsModulePageMenuAdminComponent,
        CmsModuleSliderComponent,
        CmsModuleSliderSettingsComponent,
        CmsModuleSliderSettingsAddDialogComponent,
        CmsModuleInstanceDialogComponent,
        CmsStyleEditComponent,
        CmsModuleSocialFeedComponent,
        CmsModuleSocialFeedSettingsComponent,
        CmsModuleGenericContainerListComponent,
        CmsModuleGenericContainerListSettingsComponent,
        DragDropModule,
        CKEditorModule,
        NgxFileDropModule,
        SortPipe,
        SortDescPipe,
        CarouselModule,
        BeeShareComponent,
        CmsModuleInstanceDialogContainerComponent,
        FormsModule,
        BeeFormsModule,
        LowerPipe,
        BeeComponentsModule,
        CmsModuleVerificationFlowSelectComponent,
        CmsModuleScrollAnimationComponent,
        CmsModuleScrollAnimationSettingsComponent,
        CmsAnimationEditDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        SortPipe,
        SortDescPipe
    ]
})
export class ContainerModule { 
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
