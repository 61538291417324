<div class="header">
  <div class="haeder-content">
    <img [src]="data.icon | cdn" alt="">
    <h3 [innerHtml]="data.header"></h3>
  </div>

  <i class="material-icons" (click)="close()">close</i>
</div>

<div class="content-wrapper">
  <div class="content">
    <h4 [innerHtml]="data.rightHeader"></h4>
    <span [innerHtml]="data.rightText"></span>
  </div>

  <div class="content">
    <h4 [innerHtml]="data.leftHeader"></h4>
    <span [innerHtml]="data.leftText"></span>
  </div>

</div>