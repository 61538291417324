import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(list: any[], args: any[]): any {
    var filterText = args[0];
    var prop = "name";

    if(args[1]) {
      prop = args[1];
    }

    return list ? list.filter(item => item[prop].search(new RegExp(filterText, 'i')) > -1) : [];
  }

}
