import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-input',
  templateUrl: './bee-form-input.component.html',
  styleUrls: ['./bee-form-input.component.scss']
})
export class BeeFormInputComponent extends FieldType  {

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
}
