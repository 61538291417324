import { Component, OnInit, ViewContainerRef, InjectionToken, Inject, Injector, ComponentRef, Input, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { ISearchService, ISearchItem } from '../../interfaces/search.service.interface';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { CMSModuleInstance } from '../../cms-models';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'bee-cms-module-search',
  templateUrl: './cms-module-search.component.html',
  styleUrls: ['./cms-module-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CmsModuleSearchComponent implements OnInit {
  public searchItem: ISearchItem;
  public isSearchOpen: boolean;
  public searchOpen: boolean = false;
  scrollInstance: any;

  private _query: string;
  private overlayRef: OverlayRef;

  set query(value:string) {
    this._query = value;

    //Call for search
    this.search();
  }

  get query() {
    return this._query;
  }

  constructor(public module: CMSModuleInstance, private searchService: ISearchService, public overlay: Overlay, public viewContainerRef: ViewContainerRef, 
    private _injector: Injector, private router: Router, private elementRef: ElementRef, private renderer: Renderer2) { 
    this.isSearchOpen = true;
  }

  ngOnInit() {

    if(!this.module.settings.content.template) {
      this.module.settings.content.template = "icon";
    }

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.searchOpen = false;
        this.query = "";
      }
    });

    const body = this.elementRef.nativeElement.closest('body');
    const wrapper = body.querySelectorAll('.app-wrapper')[0];
    this.scrollInstance = this.renderer.listen(wrapper, 'scroll', () => {
      this.searchOpen = false;
      this.query = "";
    });
  }

  searchRoute() {
    this.router.navigate(['modules/search/', this.query]);
    this.overlayRef.dispose();    
  }

  search() {
    if(this.query.length >= 2){ 
      this.searchService.getSearchItems(this._query.toLowerCase())
        .subscribe(data => {
          this.searchItem = data

          this.openSearchPanel();
          // if(!this.overlayRef || this.overlayRef.hostElement == null) {
            
          // }
        });
    }
    else {
      this.searchItem = null;
      if(this.overlayRef) this.overlayRef.dispose();
    }
  }

  openSearchPanel() {
    if(this.overlayRef) this.overlayRef.dispose();

    let config = new OverlayConfig();

    config.positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .width(`100%`)
        .top(this.module.settings.content.marginTop || "165px")
        .height(`calc(100vh - ${this.module.settings.content.marginTop || "165px"})`);

    config.hasBackdrop = false;

    this.overlayRef = this.overlay.create(config);

    // overlayRef.backdropClick().subscribe(() => {
    //   overlayRef.dispose();
    // });
    
    this.overlayRef.attach(new ComponentPortal(SearchPanel, this.viewContainerRef, this.createInjector({
      searchItem: this.searchItem,
      query: this.query,
      settings: this.module.settings.content,
      overlayRef: this.overlayRef
    })));
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(SEARCH_CONTAINER_DATA, dataToPass);  
    return new PortalInjector(this._injector, injectorTokens);
  }
}

export const SEARCH_CONTAINER_DATA = new InjectionToken<{}>('SEARCH_CONTAINER_DATA');

@Component({
  selector: 'bee-cms-module-search-panel',
  templateUrl: './cms-module-search-panel.component-settings.html',
  styleUrls: ['./cms-module-search-panel.component.scss']
  //template: '<p class="demo-search">Du söker på "<span>{{searchContainerData.query}}</span>"</p>'
})
export class SearchPanel {
  constructor(@Inject(SEARCH_CONTAINER_DATA) public searchContainerData: any, private router: Router) {
  }

  navigateOnItemClick(indexType,name) {
    if(indexType == "CMS_PAGE"){
      this.router.navigate(["/" , name.toLowerCase()]);
    }
    else if(indexType == "BLOG"){
      this.router.navigate(["/modules/blog/" , name.toLowerCase()]);
    }
    else if(indexType == "MEDIABANKFILE"){
      this.router.navigate(["/modules/media/" , name.toLowerCase()]);
    }

    this.searchContainerData.overlayRef.dispose();
  }

  getIcon(searchCategory) {
    switch(searchCategory.indexType) {
      case "CMS_PAGE":
        return "pages";
      case "BLOG":
        return "create";
      case "MEDIABANKFILE":
        return "play_circle_outline";
    }
  }
}

@Component({
  selector: 'bee-cms-module-search-settings',
  templateUrl: './cms-module-search.component-settings.html',
  styleUrls: ['./cms-module-search.component.scss']
})
export class CmsModuleSearchSettingsComponent implements OnInit {

  constructor(public module: CMSModuleInstance) { }

  ngOnInit() {
  }

}
