import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsService } from 'src/app/modules/bee-forms/services/forms.service';
import { CMSModuleInstance } from '../../cms-models';
import { FormsModel } from 'src/app/admin/forms/forms-model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-cms-module-forms',
  templateUrl: './cms-module-forms.component.html',
  styleUrls: ['./cms-module-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsModuleFormsComponent implements OnInit {
  public form$: Observable<FormsModel>;
  formId: string;

  constructor(public module: CMSModuleInstance, private formsService: FormsService) { 
    if(!this.module.settings.content.formJsonName) {
      this.module.settings.content.formJsonName = "";
    }
  }

  ngOnInit() {
    this.form$ = this.module.settings$.pipe(
      switchMap(settings => this.formsService.Get(settings.content.formsId)),
      map((f: any) => { 
        return new FormsModel(f);
      }));
  }
}

@Component({
  selector: 'app-cms-module-forms-settings',
  templateUrl: './cms-module-forms-settings.component.html',
  styleUrls: ['./cms-module-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsModuleFormsSettingsComponent implements OnInit {
  public forms$: Observable<FormsModel[]>;

  constructor(public module: CMSModuleInstance, private formsService: FormsService,
    private cd: ChangeDetectorRef) { 
  }

  ngOnInit() {
    this.forms$ = this.formsService.GetForms();
  }

  selectionChanged(e) {
    this.module.settings$.next(this.module.settings);
  }
}
