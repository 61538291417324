<ng-template #noLink>
  <span class="bee-button" [ngClass]="getClass()" [innerHtml]="module.settings.content.text"
  [style.fontFamily]="module.settings.style.fontFamily"
  [style.fontSize]="module.settings.style.fontSize"
  [style.lineHeight]="module.settings.style.lineHeight"
  [style.fontWeight]="module.settings.style.fontWeight"
  [style.textAlign]="module.settings.style.textAlign"
  [style.color]="module.settings.style.color"></span>
</ng-template>


<a class="bee-button" [href]="module.link" [ngClass]="getClass()" *ngIf="module.link.length > 0; else noLink" [target]="getTarget()">
  <span [innerHtml]="module.settings.content.text"
    [style.fontFamily]="module.settings.style.fontFamily"
    [style.fontSize]="module.settings.style.fontSize"
    [style.lineHeight]="module.settings.style.lineHeight"
    [style.fontWeight]="module.settings.style.fontWeight"
    [style.textAlign]="module.settings.style.textAlign"
    [style.color]="module.settings.style.color"></span>
</a>