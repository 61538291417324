<mat-form-field class="margin-top">
  <mat-label>Template</mat-label>
  <mat-select [(value)]="module.settings.content.template">
    <mat-option value="grid">Grid</mat-option>
    <mat-option value="list">List</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="margin-top">
  <mat-label>Select media type to show</mat-label>
  <mat-select [(value)]="module.settings.content.mediaType">
    <mat-option value="-1">All</mat-option>
    <mat-option value="1">Image</mat-option>
    <mat-option value="2">Video</mat-option>
    <mat-option value="3">Audio</mat-option>
    <mat-option value="4">PDF</mat-option>
  </mat-select>
</mat-form-field>


<mat-form-field class="margin-top">
  <mat-label>Filter on tag</mat-label>
  <mat-select [(value)]="module.settings.content.tag" (selectionChange)="loadItems($event)">
    <mat-option value="">Don't filter</mat-option>
    <mat-option *ngFor="let tag of tags" [value]="tag.urlRewrite">{{tag.name}}</mat-option>
  </mat-select>
</mat-form-field>

<div class="margin-top">
  <mat-checkbox [(ngModel)]="module.settings.content.selectItemsToShow" color="primary">Select items to show</mat-checkbox>
</div>

<div class="margin-top flex column" *ngIf="module.settings.content.selectItemsToShow">
  <mat-checkbox *ngFor="let file of files" color="primary" (change)="toggleFile($event, file)" [checked]="isFileChecked(file)">{{file.name}}</mat-checkbox>
</div>