import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileStatus, MediabankService } from '../../admin/mediabank/mediabank.service';
import { MediabankFile } from '../../admin/mediabank/mediabank-models';
import { SiteCulture } from 'src/app/authorize/models/user-session';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GuidService } from 'src/app/helpers/services/guid.service';

@Component({
  selector: 'mediabank-file-handler',
  templateUrl: './file-handler.component.html',
  styleUrls: ['./file-handler.component.scss']
})
export class FileHandlerComponent implements OnInit {
  @Input() realtedId?: string;
  @Input() useCultures: boolean = false;
  @Input() returnType: string = "";

  @Output() callback = new EventEmitter();


  mediaFileValue: any;

  @Output()
  mediaFileChange = new EventEmitter<any>();

  @Input()
  get mediaFile() {
    return this.mediaFileValue;
  }

  @Input() fileType: number;

  set mediaFile(val) {
    this.mediaFileValue = val;
    this.mediaFileChange.emit(this.mediaFileValue);
  }

  constructor(public dialog: MatDialog) {
    if(!this.mediaFile) this.mediaFile = {};
  }

  getTemplate(): string {
    if(!this.realtedId) {
      return "default";
    }
    else {
      return "add";
    }
  }

  ngOnInit() {
  }

  clear() {
    if(typeof this.mediaFile == "string" || this.mediaFile == null) {
      this.mediaFile = "";
    }
    else {
      this.mediaFile = {};
    }
  }

  openMediabank(type?: string) {
    const dialogRef = this.dialog.open(FileHandlerDialogComponent, {
      data: {
        mediaFile: this.mediaFile,
        fileType: this.fileType,
        type: type,
        realtedId: this.realtedId,
        useCultures: this.useCultures
      }
    });

    dialogRef.afterClosed().subscribe(file => {
      if(this.returnType == "string") {
        this.mediaFile = file.fileUrl;
        this.callback.emit(file.fileUrl);
      }
      else {
        this.mediaFile = {
          imgUrl: file.fileUrl,
          fileUrl: file.fileUrl,
          mediabankFileId: file.id,
          thumbnail: file.thumbnail,
          durationInSec: file.durationInSec,
          minutes: file.minutes,
          seconds: file.seconds,
          size: file.size,
          sizeInKb: file.sizeInKb,
          sizeInMb: file.sizeInMb,
          urlRewrite: file.urlRewrite,
          name: file.name,
          description: file.description,
          sizes: file.sizes,
          id: file.id,
          ...file
        };
  
        this.callback.emit(file);
      }
    });
  }
}


@Component({
  selector: 'mediabank-file-handler-dialog',
  templateUrl: './file-handler-dialog.component.html',
  styleUrls: ['./file-handler.component.scss']
})
export class FileHandlerDialogComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];

  public selectedCulture: SiteCulture;
  fileData: File = null;
  previewUrl: any = null;
  selectedFile: any = { id: -1 };
  fileType: number;

  mediabankFiles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public isLoading: boolean = true;
  public searchTerm: string = "";

  public pageSize: number = 40;
  public totalCount: number = 0;
  uploadProgress: Observable<FileStatus[]>;

  constructor(private snackbar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<FileHandlerDialogComponent>, public mediabankService: MediabankService, 
    private guidService: GuidService) {

      if(this.data.fileType) this.fileType = this.data.fileType;
      else this.fileType = this.mediabankService.TYPES.IMAGE;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
 
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
         var mediabankFile: MediabankFile = new MediabankFile({file: file, isPublic: false, relatedToId: this.data.realtedId});
         mediabankFile.name = file.name;
         mediabankFile.type = this.fileType;
         mediabankFile.urlRewrite = this.guidService.uuidv4();

         if(this.data.useCultures) {
          mediabankFile.iso2Culture = this.selectedCulture.iso2;
          mediabankFile.iso4Culture = this.selectedCulture.iso4;
         }

         if(this.fileType == 2 || this.fileType == 10) {
          this.mediabankService.SaveTus(mediabankFile, file, (upload) => {
            this.mediabankService.resetUploadStatus();
            if(this.data.type == 'add') {
              this.mediabankService.GetMediabankFileByUrlRewrite(mediabankFile.urlRewrite).subscribe(m => {
                this.mediabankFiles$.next([m, ...this.mediabankFiles$.value]);
                this.selectedFile = m;
              });
            }
            else {
              setTimeout(() => {
                this.getMediabankFiles(this.pageSize, 0);
              }, 1000);
            }
           }, (error) => {
  
           });
         }
         else {
          this.mediabankService.Save(mediabankFile)
          .subscribe(res => {
            var config: MatSnackBarConfig = new MatSnackBarConfig();
            config.duration = 1000;
            this.snackbar.open("File uploaded!", null, config);
            this.mediabankFiles$.next([res, ...this.mediabankFiles$.value]);
            this.selectedFile = this.mediabankFiles$.value[0];
          });
         }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event){
    console.log(event);
  }
 
  public fileLeave(event){
    console.log(event);
  }

  ngOnInit() { 
    this.getMediabankFiles(this.pageSize, 0);
    this.uploadProgress = this.mediabankService.uploadProgress;
  }

  pageChange(event: PageEvent) {
    this.getMediabankFiles(event.pageSize, event.pageIndex);
  }

  getMediabankFiles(pageSize: number, pageIndex: number) {
    this.isLoading = true;
    this.mediabankService.GetAllPaged(pageSize, pageIndex, this.fileType).subscribe((response: PaginationReturnModel<MediabankFile[]>) => {
      this.mediabankFiles$.next([]);

      if(response.isSuccessful) {
        this.totalCount = response.totalCount;
        let files = [];
        for(let i = 0; i < response.data.length; i++) {
          if(response.data[i].type == this.fileType) {
            files.push(new MediabankFile(response.data[i]));
          }
        }

        this.mediabankFiles$.next(files);
      }
      this.isLoading = false;
    });
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  ok() {
    this.close(this.selectedFile);
  }

  selectFile(file: any) {
    this.selectedFile = file;
  }

  public changeCulture(culture: SiteCulture) {
    this.selectedCulture = culture;
  }
}