<ng-container *ngTemplateOutlet="{'standard': standard, 'icon': icon }[module.settings.content.template]"></ng-container>

<ng-template #icon>
  <bee-search [settings]="module.settings.content"></bee-search>
</ng-template>

<ng-template #standard>
  <bee-animated-icon class="md-36"
    class="mobile-toggle"
    start="search" 
    end="close" 
    colorStart="none" 
    colorEnd="none" 
    [animate]="searchOpen"
    [animateFromParent]="true"
    (click)="searchOpen = !searchOpen; query = '';"></bee-animated-icon>

  <div class="container-search-wrapper" [class.open]="searchOpen">
    <mat-form-field>
      <mat-label>{{'SEARCH.SEARCH' | translate}}</mat-label>
      <input matInput [(ngModel)]="query" type="search" (keyup.enter)="searchRoute()">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
</ng-template>