import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CourseEntity } from "src/app/admin/academy/models/course-entity";
import { PaginationReturnModel } from "src/app/domain/core/help-models/pagination-return-model";

@Injectable()
export abstract class ICourseService {
  abstract getAll(deepload?: boolean, pageSize?: number, pageIndex?: number): Observable<PaginationReturnModel<CourseEntity[]>>;
  abstract get(id: string): Observable<CourseEntity>;
  abstract getMyCompletedEducationModules(): Observable<string[]>;
  abstract getCompletedCourseIds(): Observable<string[]>;
  abstract getMyCompletedEducationModulesWithDate(): Observable<any[]>;
}
