import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bee-landscape-image',
  templateUrl: './bee-landscape-image.component.html',
  styleUrls: ['./bee-landscape-image.component.scss']
})
export class BeeLandscapeImageComponent implements OnInit {
  @Input() image: string;
  @Input() alt?: string = "";

  private _scaling: string = 'default';

  @Input()
  set scaling(scalingVal: string) {
    if(scalingVal) this._scaling = scalingVal;
  }
  get scaling() { return this._scaling; }

  constructor() { 
    if(!this.alt) { 
      this.alt = "";
    }
  }

  ngOnInit() {
    if(!this.alt || this.alt == undefined) {
      this.alt = "";
    }
  }

}
