import { PhysicalAddressEntity } from "./physical-address-entity";

export class BeeUnit {
  id: string;
  name: string;
  isDeleted: boolean;
  isActive: boolean;
  createdByUserId: string;
  createdTimeUtc: Date;
  lastEditedByUserId: string;
  lastEditedTimeUtc: Date;
  logoUrl: string;
  parentUnit: BeeUnit;
  parentUnitId: string;
  parentUnitIds: string[];
  physicalAddress: PhysicalAddressEntity = new PhysicalAddressEntity();
}
