import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SaveReturnModel } from "src/app/domain/core/help-models/save-return-model";
import { BeeUser } from "src/app/domain/core/models/bee-user";

@Injectable()
export abstract class IMyProfileService {
  abstract getMyProfile(): Observable<BeeUser>;
  abstract saveMyProfile(user: BeeUser): Observable<SaveReturnModel<BeeUser>>;
}
