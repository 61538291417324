import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-bee-form-button-select',
  templateUrl: './bee-form-button-select.component.html',
  styleUrls: ['./bee-form-button-select.component.scss']
})
export class BeeFormButtonSelectComponent extends FieldType implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit() {
  }

  selectOption(option) {
    this.field.templateOptions.options.forEach((o: any) => {
      o.selected = false;
    });

    option.selected = true;

    this.formControl.setValue(option.label);
  }
}
