import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], args: any[]): any[] { 
    if (!items) return [];
    if (!args) return items;
    
    let prop = args[0];
    let val = args[1];
    
    return items.filter(it => {
      return it[prop] === val;
    });
  }

}
