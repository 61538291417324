import { Pipe, PipeTransform } from '@angular/core';
import { CMSModuleStyle } from '../cms-models';

@Pipe({
  name: 'mediaQuery',
  pure: false
})
export class MediaQueryPipe implements PipeTransform {

  transform(value: any, data: any): any {
    let mobileStyle = undefined;

    if(data) {
      if(data["mobile"]) {
        mobileStyle = data["mobile"];
      }
    }

    //We want mobile style...
    if((mobileStyle && window.innerWidth <= 767) || data["deviceEmulation"] && data["deviceEmulation"] == "mobile") {
      if(typeof mobileStyle === "string") {
        return mobileStyle;
      }
      else {
        //We need to handle the style...
        let originalStyle: CMSModuleStyle = new CMSModuleStyle(value);
        let styleToReturn: CMSModuleStyle = new CMSModuleStyle(mobileStyle);

        return this.handleStyles(styleToReturn, originalStyle);
      }
    }

    return value;
  }

  handleStyles(mainStyle: CMSModuleStyle, fallbackStyle: CMSModuleStyle) {
    if(mainStyle.fontWeight === 'inherit') mainStyle.fontWeight = fallbackStyle.fontWeight;
    if(mainStyle.fontSize === '1em') mainStyle.fontSize = fallbackStyle.fontSize;
    if(mainStyle.fontFamily === 'inherit') mainStyle.fontFamily = fallbackStyle.fontFamily;
    // if(mainStyle.textAlign === 'left') mainStyle.textAlign = fallbackStyle.textAlign;
    if(mainStyle.lineHeight === null) mainStyle.lineHeight = fallbackStyle.lineHeight;
    if(mainStyle.color === 'inherit') mainStyle.color = fallbackStyle.color;
    if(mainStyle.backgroundColor === 'transparent') mainStyle.backgroundColor = fallbackStyle.backgroundColor;
    if(mainStyle.backgroundImage === null) mainStyle.backgroundImage = fallbackStyle.backgroundImage;
    if(mainStyle.backgroundSize === 'cover') mainStyle.backgroundSize = fallbackStyle.backgroundSize;
    if(mainStyle.backgroundPosition === 'center') mainStyle.backgroundPosition = fallbackStyle.backgroundPosition;
    if(mainStyle.paddingTop === null) mainStyle.paddingTop = fallbackStyle.paddingTop;
    if(mainStyle.paddingRight === null) mainStyle.paddingRight = fallbackStyle.paddingRight;
    if(mainStyle.paddingBottom === null) mainStyle.paddingBottom = fallbackStyle.paddingBottom;
    if(mainStyle.paddingLeft === null) mainStyle.paddingLeft = fallbackStyle.paddingLeft;
    if(mainStyle.marginTop === null) mainStyle.marginTop = fallbackStyle.marginTop;
    if(mainStyle.marginRight === null) mainStyle.marginRight = fallbackStyle.marginRight;
    if(mainStyle.marginBottom === null) mainStyle.marginBottom = fallbackStyle.marginBottom;
    if(mainStyle.marginLeft === null) mainStyle.marginLeft = fallbackStyle.marginLeft;
    if(mainStyle.justifyContent === null) mainStyle.justifyContent = fallbackStyle.justifyContent;
    if(mainStyle.alignItems === 'flex-start') mainStyle.alignItems = fallbackStyle.alignItems;
    // if(mainStyle.width === '100%') mainStyle.width = fallbackStyle.width; 
    if(mainStyle.maxWidth === null) mainStyle.maxWidth = fallbackStyle.maxWidth;
    if(mainStyle.height === null) mainStyle.height = fallbackStyle.height;
    if(mainStyle.maxHeight === null) mainStyle.maxHeight = fallbackStyle.maxHeight;
    if(mainStyle.borderRadius === null) mainStyle.borderRadius = fallbackStyle.borderRadius;
    if(mainStyle.borderWidth === null) mainStyle.borderWidth = fallbackStyle.borderWidth;
    if(mainStyle.borderStyle === null) mainStyle.borderStyle = fallbackStyle.borderStyle;
    if(mainStyle.borderStyle === null) mainStyle.borderStyle = fallbackStyle.borderStyle;
    if(mainStyle.borderColor === null) mainStyle.borderColor = fallbackStyle.borderColor;
    if(mainStyle.clipPath === null) mainStyle.clipPath = fallbackStyle.clipPath;
    if(mainStyle.zIndex === null) mainStyle.zIndex = fallbackStyle.zIndex;

    return mainStyle;
  }

}
