<form class="settings-wrapper">

    <mat-form-field class="margin-top">
      <mat-label>Select Template</mat-label>
      <mat-select [(value)]="moi.settings.content.template">
        <mat-option value="default">Default</mat-option>
        <mat-option value="icon">Icon</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngTemplateOutlet="{'default': default, 'icon': icon }[moi.settings.content.template]"></ng-container>
  </form>

  <ng-template #default>
    <mat-form-field>
      <input matInput placeholder="Login text" name="loginText" [(ngModel)]="moi.settings.content.loginText">
    </mat-form-field>
  
    <mat-form-field>
      <input matInput placeholder="Logout text" name="logoutText" [(ngModel)]="moi.settings.content.logoutText">
    </mat-form-field>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="moi.settings.content.showExpandIcon" [ngModelOptions]="{standalone: true}" color="primary">Show expand icon</mat-checkbox>
    </div>
  </ng-template>

  <ng-template #icon>
    <mat-form-field class="margin-top">
      <input matInput placeholder="Font set (material-icons / material-icons-outlined)" name="fontSet" [(ngModel)]="moi.settings.content.fontSet">
    </mat-form-field>

    <mat-form-field class="margin-top">
      <input matInput placeholder="Icon size" name="fontSet" [(ngModel)]="moi.settings.content.iconSize">
    </mat-form-field>

    <div class="margin-top">
      <mat-checkbox [(ngModel)]="moi.settings.content.profileLinkEnabled" [ngModelOptions]="{standalone: true}" color="primary">
        Override profile link
      </mat-checkbox>
    </div>

    <mat-form-field *ngIf="moi.settings.content.profileLinkEnabled">
      <input matInput placeholder="Profile link (dialog:{id} for using dialog)" name="profileLink" [(ngModel)]="moi.settings.content.profileLink">
    </mat-form-field>

    <div class="margin-top">
      <p>Links</p>
      <mat-expansion-panel *ngFor="let link of moi.settings.content?.links">
        <mat-expansion-panel-header>{{link.name}}</mat-expansion-panel-header>
        <mat-form-field class="margin-top">
          <input matInput placeholder="Name" name="name" [(ngModel)]="link.name">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Url" name="url" [(ngModel)]="link.url">
        </mat-form-field>
        <button mat-raised-button color="warn" (click)="removeLink(link)">Remove link</button>
      </mat-expansion-panel>

      <button mat-raised-button color="primary" class="margin-top" (click)="addLink()">Add link</button>
    </div>
  </ng-template>