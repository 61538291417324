import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdnPipe } from './cdn/cdn.pipe';
import { TagNamesPipe } from './tag-names/tag-names.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { SafeHtml, SafePipe, StripHtmlPipe } from './safe/safe.pipe';
import { LimitToPipe } from './limit/limit-to.pipe';
import { BeeImagePipe } from './bee-image/bee-image.pipe';
import { BeeDatePipe } from './bee-date/bee-date.pipe';
import { BeePlaceholdersPipe } from './bee-placeholders/bee-placeholders.pipe';
import { BeeNamesPipe } from './bee-names/bee-names.pipe';
import { BeeFromNowPipe } from './bee-from-now/bee-from-now.pipe';
import { SearchFilterPipe } from './search-filter/search-filter.pipe';
import { BeeStripHtmlPipe } from './bee-strip-html/bee-strip-html.pipe';



@NgModule({
  declarations: [
    CdnPipe,
    TagNamesPipe,
    FilterPipe,
    SafePipe,
    SafeHtml,
    LimitToPipe,
    StripHtmlPipe,
    BeeImagePipe,
    BeeDatePipe,
    BeePlaceholdersPipe,
    BeeNamesPipe,
    BeeFromNowPipe,
    SearchFilterPipe,
    BeeStripHtmlPipe
  ],
  exports: [
    CdnPipe,
    TagNamesPipe,
    FilterPipe,
    SafePipe,
    SafeHtml,
    LimitToPipe,
    StripHtmlPipe,
    BeeImagePipe,
    BeeDatePipe,
    BeePlaceholdersPipe,
    BeeNamesPipe,
    BeeFromNowPipe,
    SearchFilterPipe,
    BeeStripHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    CdnPipe
  ]
})
export class BeePipesModule { }
