import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  public backsidePreview: boolean = false;

  public toggle(): void {
    this.backsidePreview = !this.backsidePreview;
  }
  constructor() {

  }
}
