import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Pipe({
  name: 'beeSiteStyle'
})
export class SiteStylePipe implements PipeTransform {

  /**
   *
   */
  constructor(private authService: AuthenticationService) {
    
  }

  transform(value: any, fallback: string): any {
    var color = this.authService.currentUserValue.siteSettings.siteStyle[value];
    if(color) return color;
    else return fallback;
  }

}
