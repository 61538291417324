import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CountryService } from 'src/app/helpers/services/country.service';
import { IBeeSelectItem } from 'src/app/bee-components/models/bee-select-item';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { CountryModel } from 'src/app/helpers/models/country-model';

@Component({
  selector: 'app-bee-input-phone',
  templateUrl: './bee-input-phone.component.html',
  styleUrls: ['./bee-input-phone.component.scss']
})
export class BeeInputPhoneComponent extends FieldType implements OnInit {
  public countries: IBeeSelectItem[] = [];
  private selectedCountryValue: IBeeSelectItem;

  public set selectedCountry(val: IBeeSelectItem) {
    this.selectedCountryValue = val;
    if(this.selectedCountry && this.selectedCountry.value) {
      this.formControl.setValue(this.selectedCountry.value + " ");
    }
  }

  public get selectedCountry(): IBeeSelectItem {
    return this.selectedCountryValue;
  }

  constructor(private counrtyService: CountryService, private authService: AuthenticationService) { 
    super();
  }

  ngOnInit() {
    this.countries = [];
    
    if(this.field.templateOptions.options) {
      this.field.templateOptions.options.forEach((o: any) => {
        let country = this.authService.getCultureFromIso4(o.value);

        if(country) {
          this.countries.push({
            name: country.name,
            shortName: country.iso2.toUpperCase(),
            value: country.phonePrefix,
            imageUrl: country.flagIcon || `cdn/${country.iso2}.png`
          });
        }
      });
    }
    else {
      this.counrtyService.getAllCountries().subscribe((data: CountryModel[]) => {
        data.forEach(c => {
          this.countries.push({
            name: `${c.name}`,
            shortName: c.countryCode.toUpperCase(),
            value: c.phonePrefix,
            imageUrl: `cdn/${c.countryCode}.png`
          });
        });

        this.selectedCountry = this.countries.filter(obj => { return obj.value === this.authService.getCurrentCulture().phonePrefix; })[0];
      });
    }

    //Select the first one as default.
    this.selectedCountry = this.countries[0];
  }

}
