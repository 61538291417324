import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { IMediabankService } from 'src/app/container/interfaces/mediabank.service.interface';

@Injectable({
  providedIn: 'root'
})
export class MediabankService implements IMediabankService {
  public TYPES = {
    UNDEFINED: -1,
    IMAGE: 1,
    VIDEO: 2,
    AUDIO: 3,
    PDF: 4,
    WORD: 5,
    EXCEL: 6,
    POWERPOINT: 7,
    SUBTITLE: 8
   };

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getTypeName(type: number): string {
    if(type == this.TYPES.IMAGE) return "Image";
    else if(type == this.TYPES.VIDEO) return "Video";
    else if(type == this.TYPES.AUDIO) return "Audio";
    else if(type == this.TYPES.PDF) return "Pdf";
    else if(type == this.TYPES.WORD) return "Word";
    else if(type == this.TYPES.EXCEL) return "Excel";
    else if(type == this.TYPES.POWERPOINT) return "PowerPoint";
    else if(type == this.TYPES.SUBTITLE) return "Subtitle";
    else return "Undefined";
  }
  
  getMediaTypes() {
    return this.TYPES;
  }
  
  getAllByTypes(types: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getAllByTypes?types=' + types);  
  }
  getAllByType(type: number) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetAllByType?type=' + type);  
  }
  getAllPublic() {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getAllPublic');  
  }
  getAllNonPublic() {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getAllNonPublic');  
  }
  getAll() {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getAll');  
  }
  getByUrl(urlRewrite: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getByUrl?urlRewrite=' + urlRewrite);  
  }
  get(id: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/get?id=' + id);  
  }
  getMediaFilesByTag(tagUrlRewrite: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getMediaFilesByTag?tagUrlRewrite=' + tagUrlRewrite);  
  }
  getMediaFilesByTagAndType(tagUrlRewrite: string, type: number) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetMediaFilesByTagAndType?type=' + type + "&tagUrlRewrite=" + tagUrlRewrite);  
  }

  getMediaFilesByTagAndTypes(tagUrlRewrite: string, types: number[]) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetMediaFilesByTagAndTypes?types=' + types + "&tagUrlRewrite=" + tagUrlRewrite);  
  }
}
