import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CourseEntity } from "src/app/admin/academy/models/course-entity";
import { PaginationReturnModel } from "src/app/domain/core/help-models/pagination-return-model";

@Injectable()
export abstract class IComponentsCourseService {
  abstract getAll(deepload?: boolean): Observable<PaginationReturnModel<CourseEntity[]>>;
  abstract getMyCompletedEducationModules(): Observable<string[]>;
  abstract getCertificate(certificateId: string, courseId: string);
}
