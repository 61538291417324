import dayjs from 'dayjs'
import { CMSContainer } from 'src/app/container/cms-models';
import { BeeUser } from 'src/app/domain/core/models/bee-user';

export class GenericContainer {
  id: string;
  name: string;
  description: string;
  createdTimeUtc: Date;
  isDeleted: boolean;
  objectType: string;
  urlRewrite: string;
  iso2Culture: string;
  iso4Culture: string;
  imgUrl: string;
  mediabankFileId: string;
  container: CMSContainer;
  tags: any[];
  startTimeUtc: Date;
  endTimeUtc: Date;
  properties: any = {};

  user: BeeUser;

  image: any;

  constructor(data?: any) {
    if(!data) data = {};

    this.id = data.id || "";
    this.name = data.name || "";
    this.description = data.description || "";
    this.createdTimeUtc = data.createdTimeUtc || new Date();
    this.isDeleted = data.isDeleted || false;
    this.objectType = data.objectType || "";
    this.urlRewrite = data.urlRewrite || "";
    this.iso2Culture = data.iso2Culture || "sv";
    this.iso4Culture = data.iso4Culture || "sv-SE";
    this.imgUrl = data.imgUrl || "";
    this.mediabankFileId = data.mediabankFileId || "";
    this.tags = data.tags || [];
    this.container = new CMSContainer(data.container);
    this.startTimeUtc = data.startTimeUtc || new Date(dayjs().startOf("day").add(1, "hour").toDate());
    this.endTimeUtc = data.endTimeUtc || null;
    this.image = data.image || {};
    this.properties = data.properties || {};
    this.user = data.user;
  }
}

export class GenericContainerType {
  id: string;
  name: string;
  icon: string;
  description: string;
  color: string;
  containerType: string;
  properties: any;

  /**
   *
   */
  constructor(data?: any) {
    if(!data) data = {};

    this.id = data.id || "";
    this.name = data.name || "";
    this.icon = data.icon || "";
    this.description = data.description || "";
    this.color = data.color || "";
    this.containerType = data.containerType || "";
    this.properties = data.properties || {};
  }
}
