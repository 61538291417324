import { Injectable, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, fromEventPattern } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ɵgetDOM as getDOM } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FaviconService implements OnDestroy {

  constructor(@Inject(DOCUMENT) private _doc: HTMLDocument) {}

  private darkScheme = '(prefers-color-scheme:dark)';
  private subscriptionToColorScheme: Subscription;
  private prefersColorScheme$ = fromEventPattern(handler => window.matchMedia(this.darkScheme).addListener(handler as EventListener)).pipe(
    pluck('matches')
  );

  /**
   * Get the favicon of the current HTML document.
   */
  getFavicon() {
    return this._doc.querySelector("link[rel*='icon']");
  }

  /**
   * Set the title of the current HTML document.
   * @param iconURL - Default favicon URL
   * @param altIconURL - Optional, dark theme favicon URL
   */
  setFavicon(iconURL: string, altIconURL?: string) {
    this._doc.getElementById('appFavicon').setAttribute('href', iconURL);
  }

  /**
   * Subscribe to the theme color changes in browser/OS and apply the appropiate favicon
   * @param link - DOM element
   * @param iconURL - Default favicon URL
   * @param altIconURL - Optional, dark theme favicon URL
   */
  private subscribeToChangesInTheme(link: any, iconURL: string, altIconURL: string) {
    this.subscriptionToColorScheme = this.prefersColorScheme$.subscribe(isDarkTheme => {
      if (isDarkTheme) {
        this.appendLinkTag(link, altIconURL);
      } else {
        this.appendLinkTag(link, iconURL);
      }
    });
  }

  /**
   * Append new link to HEAD
   * @param link - DOM element
   * @param iconURL - favicon URL
   */
  private appendLinkTag(link: any, iconURL: string) {
    // link.type = 'image/x-icon';
    // link.rel = 'shortcut icon';
    // link.href = iconURL;
    // getDOM()
    //   .getElementsByTagName(this._doc, 'head')[0]
    //   .appendChild(link);
  }

  ngOnDestroy() {
    this.subscriptionToColorScheme.unsubscribe();
  }
}
