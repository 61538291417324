import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMyProfileService } from 'src/app/bee-components/interfaces/i-my-profile-service';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';
import { BeeUser } from 'src/app/domain/core/models/bee-user';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService extends IMyProfileService {
  constructor(private http: HttpClient, private config: AppConfigService) {
    super();
  }

  getMyProfile(): Observable<BeeUser> {
    return this.http.get<BeeUser>(this.config.apiEndpoint + "api-site/MyProfile/Get");
  }

  saveMyProfile(user: BeeUser): Observable<SaveReturnModel<BeeUser>> {
    return this.http.patch<SaveReturnModel<BeeUser>>(this.config.apiEndpoint + "api-site/MyProfile/Save", user);
  }
}
