<i class="material-icons icon-15x" (click)="showShareMenu = !showShareMenu" [style.color]="settings.mainColor">
  {{getIcon()}}
</i>


<div class="bee-share-menu" *ngIf="showShareMenu" [style.backgroundColor]="settings.mainColor">
  <div class="bee-share-menu-item" (click)="shareLinkedIn()">
    <img src="/assets/images/Linkedin_white.svg" class="tpl-icon" alt="LinkedIn share">
    <span [style.color]="settings.fontColor">
      Dela via LinkedIn
    </span>
  </div>

  <a class="bee-share-menu-item" href="mailto:?subject={{title}}&amp;body=Titta på detta jag hittade: {{url}}">
    <img src="/assets/images/Email_white.svg" class="tpl-icon" alt="LinkedIn share">
    <span [style.color]="settings.fontColor">
      Dela via e-post
    </span>
  </a>

  <div class="bee-share-menu-item" (click)="copy()">
    <img src="/assets/images/Link_white.svg" class="tpl-icon" alt="LinkedIn share">
    <span [style.color]="settings.fontColor">
      Kopiera länk
    </span>
  </div>
</div>