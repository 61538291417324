import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { IPermissionService } from 'src/app/container/interfaces/permission.service';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { GenericItemPermission } from 'src/app/domain/core/models/generic-item-permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements IPermissionService {

  constructor(private authService: AuthenticationService) { }

  hasPermission(permission: GenericItemPermission, user?: BeeUser): boolean {
    if(!user) {
      user = this.authService.currentUserValue.user;
    }

    if(!permission || permission.isPublic) {
      return true;
    }

    if(permission && !permission.isPublic) {
      //CULTURES
      if(permission.iso2Cultures && permission.iso2Cultures.length > 0) {
        if(!permission.iso2Cultures.includes(user.defaultCultureIso2)) {
          return false;
        }
      }

      //ROLES
      if(permission.roleIds && permission.roleIds.length > 0) {

        if(user && user.userRoles && user.userRoles.length > 0) {
          const hasRole = (element) => { 
            var ids = [];
  
            user.userRoles.forEach(r => ids.push(r.id));
  
            return ids.includes(element);
          };
          if(!permission.roleIds.some(hasRole)) {
            return false;
          }
        }
        else {
          return false;
        }
      }

      //UNITS
      if(permission.unitIds && permission.unitIds.length > 0) {
        if(user && user.unitId) {
          if(!permission.unitIds.includes(user.unitId)) {
            return false;
          }
        }
        else {
          return false;
        }
      }

      //USERS
      if(permission.userIds && permission.userIds.length > 0) {
        if(!permission.userIds.includes(user.id)) {
          return false;
        }
      }
    }

    return true;
  }
}
