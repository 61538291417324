<div class="unit-info-wrapper">
  <p class="header" [innerHtml]="'UNIT.NAME' | translate"></p>
  <span [innerHtml]="unit?.name"></span>
  <span [innerHtml]="unit?.physicalAddress?.address"></span>
  <span>
    <span [innerHtml]="unit?.physicalAddress?.zip"></span>
    <span> </span>
    <span [innerHtml]="unit?.physicalAddress?.city"></span>
  </span>
</div>

<div class="unit-admins-wrapper">
  <div class="unit-admin" *ngFor="let user of unitAdministrators">
    <p class="header" [innerHtml]="'UNIT.ADMIN' | translate"></p>
    <span [innerHtml]="user.fullName"></span>
    <span>
      <span>Telefon: </span>
      <a [innerHtml]="user.phone" href="tel:{{user.phone}}"></a>
    </span>
    <span>
      <span>Mailadress: </span>
      <a [innerHtml]="user.email" href="mailto:{{user.email}}"></a>
    </span>
  </div>
</div>