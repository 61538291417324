import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagNames'
})
export class TagNamesPipe implements PipeTransform {

  transform(tags: any[], ...args: any[]): string {
    var names = "";
    if(tags && tags.length > 0) {
      tags.forEach(t => {
        if(!t.isDeleted) {
          if(t.color) {
            names += (`<span style='color: ${t.color}; font-size: inherit;'>${t.tagName}</span>, `);
          }
          else { 
            names += (t.tagName + ", ");
          }
        }
      });
    }

    return names.substring(0, names.length - 2);
  }

}
