import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormsModel } from 'src/app/admin/forms/forms-model';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { FormSubmissionResponseModel } from '../../models/form-submission-response-model';

@Component({
  selector: 'bee-form-result',
  templateUrl: './form-result.component.html',
  styleUrls: ['./form-result.component.scss']
})
export class FormResultComponent implements OnInit {
  @Input() result: FormSubmissionResponseModel;
  @Input() form: FormsModel;
  @Output() reset = new EventEmitter();

  constructor(private cdnPipe: CdnPipe) { }

  ngOnInit(): void {
  }

  callAction(action: any): void {
    switch(action.action) {
      case "FORM_ACTION_BACK":
        this.reset.emit();
        break;
      case "FORM_ACTION_SAVE_PDF":
        this.saveAsPdf(action);
        break;
      case "FORM_ACTION_MAIL_RESULT":
        this.sendAsMail(action);
        break;
    }
  }

  sendAsMail(action: any) {
    console.log("Send form result as mail");
  }

  saveAsPdf(action: any) {
    console.log("Save form result as PDF");

    var link = document.createElement('a');
    link.href = this.cdnPipe.transform(action.data);
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));

  }
}
