<ng-container *ngTemplateOutlet="{'single': single, 'normal': normal, 'none': none}[template]"></ng-container>

<ng-template #single>

  <ng-container *ngIf="settings.showOnlyJourney; else showAll">


    <bee-journey *ngIf="journeyItems" [items]="journeyItems" template="slim" id="bee-journey"></bee-journey>

  </ng-container>
</ng-template>
<ng-template #normal>
  <div class="courses-wrapper">
    <div *ngFor="let course of getCoursesInOrder()" class="course">
      <div class="course-thumbnail" [style.backgroundImage]="'url(' + (course.imgUrl | cdn) + ')'">
        
      </div>
      <div class="course-information">
        <h4 [innerHtml]="course.name"></h4>
        <span class="status" [class.started]="course.userStatus.status == 'started'">{{getStatusText(course)}}</span>
        <div [innerHtml]="course.description"></div>

        <div>
          <div class="bee-button" (click)="goToCourse(course)">
            <div>
              <span [innerHtml]="'CORE.TO' | translate"></span> 
              <span [innerHtml]="course.name"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #none></ng-template>


<ng-template #showAll>

  <div class="single-course-wrapper" *ngIf="firstCourse">
    <h2 class="uppercase">
      <span [innerHtml]="'ACADEMY.INTRO' | translate"></span>
    </h2>

    <!-- <p [innerHtml]="firstCourse.description" class="margin-top lg"></p> -->

    <div class="intruduction-video margin-top lg text-left" [hidden]="!firstCourse.introductionVideo">
      <bee-video [video]="firstCourse.introductionVideo"></bee-video>
      <h4 class="margin-top text-left">Intruduktionsfilm</h4>
      <p>Se en film som sammanfattar innehållet i utbildningen.</p>
    </div>

    <div class="margin-top margin-bottom lg hr white"></div>

    <div class="chapters-wrapper margin-top lg">
      <h2 class="uppercase">
        <!-- <span [innerHtml]="firstCourse.name"></span>
        <span> | </span> -->
        <span [innerHtml]="'ACADEMY.CHAPTERS' | translate"></span>
        <!-- Ämnesområden -->
      </h2>

      <!-- <p>
        Utbildningen {{firstCourse.name}} består av nedan ämnesområden.
      </p> -->

      <div class="chapters margin-top lg">
        <div class="chapter-wrapper" *ngFor="let chapter of firstCourse.chapters">
          <div class="chapter"
            [style.backgroundImage]="'url(' + (chapter.image | beeImage | cdn) + ')'"
            (click)="goToChapter(chapter)">
            <div class="image-16-9"></div>
            <mat-icon *ngIf="checkIfChapterIsDone(chapter)" class="chapter-finish-icon">star</mat-icon>
          </div>
          <h5 [innerHtml]="chapter.name"></h5>
        </div>
      </div>
    </div>
  </div>

  <div class="margin-top margin-bottom lg hr white"></div>

  <h2 class="margin-top lg center uppercase">
    <span [innerHtml]="'ACADEMY.MY_JOURNEY' | translate"></span>
    <!-- Ämnesområden -->
  </h2>

  <div class="flex center">
    <p class="course-small-journey-text" [innerHtml]="settings.courseSmallJourneyText"></p>
  </div>

  <bee-journey *ngIf="journeyItems" [items]="journeyItems" template="vertical" id="bee-journey"></bee-journey>

</ng-template>