import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { ICourseLogService } from 'src/app/container/interfaces/course-log.service.interface';
import { CourseLogEntity } from 'src/app/domain/academy/models/course-log-entity';
import { CourseStatusType } from 'src/app/domain/academy/models/course-status-type';

@Injectable({
  providedIn: 'root'
})
export class CourseLogService implements ICourseLogService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getMyCoursesWithStatus(status: CourseStatusType): Observable<CourseLogEntity[]> {
    return this.http.get<CourseLogEntity[]>(this.config.apiEndpoint + "api-academy/courselog/GetMyCoursesWithStatus?status=" + status);
  }
}
