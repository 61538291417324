import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ShowFormComponent } from './show-form/show-form.component';

const routes: Routes = [
  { path: "show/:id", component: ShowFormComponent }
]



@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class BeeFormsRoutingModule { }
