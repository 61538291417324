<ng-container *ngIf="container$ | async as c">
  <div *ngFor="let moduleInstance of c.moduleInstances | sort:'order' | filter:['isDeleted', false] 
    | sortHasPermission"
    id="{{moduleInstance.name_id  ? moduleInstance.name_id: moduleInstance.instanceId}}" 
    class="module-instance {{getExtraClass(moduleInstance)}}"
    [class.videoBackground]="moduleInstance.settings.style.backgroundVideo"
    [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle}"
    [style.background-image]="'url(' + ((moduleInstance.settings.style.backgroundImage | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle.backgroundImage}) || '' | cdn) + ')'">


    <video *ngIf="moduleInstance.settings.style.backgroundVideo" autoplay [muted]="'muted'" loop class="moi-bgVideo" id="bgVideo-{{moduleInstance.instanceId}}">
      <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
    </video>

    <app-cms-module-wrapper [moi]="moduleInstance"></app-cms-module-wrapper>
  </div>
</ng-container>
