import { Component, OnInit, Input } from '@angular/core';
import { copyToClipboard } from 'src/app/helpers/copy-to-clipboard/copy-to-clipboard';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'bee-share',
  templateUrl: './bee-share.component.html',
  styleUrls: ['./bee-share.component.scss']
})
export class BeeShareComponent implements OnInit {
  public showShareMenu: boolean;

  @Input() url: String;
  @Input() title: String;
  @Input() settings: BeeShareSettingsModel;

  @Input()
  set isOpen(open: boolean) {
    if(open) this.showShareMenu = open;
  }
  get isOpen() { return this.showShareMenu; }

  public body: String;

  constructor(private snackbar: MatSnackBar) { 
    this.showShareMenu = false;

    if(!this.url || this.url == "") {
      this.url = window.location.href;
    }

    if(!this.title || this.title == "") {
      this.title = "PostNord TPL";
    }

    this.body = "";

    if(!this.settings) {
      this.settings = new BeeShareSettingsModel();
    }
  }

  ngOnInit() {
  }

  shareLinkedIn() {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${this.title}&summary=${this.body}&source=LinkedIn`, '_blank');
    this.showShareMenu = false;
  }

  copy() {
    copyToClipboard(this.url);
    this.showShareMenu = false;

    var config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = 1000;
    this.snackbar.open("Länk kopierad", null, config);

    // if (navigator["share"]) {
    //   var s = navigator["share"];
    //   s({
    //     title: this.title as string,
    //     text: this.body as string,
    //     url: this.url as string,
    //   })
    //     .then(() => console.log('Successful share'))
    //     .catch((error) => console.log('Error sharing', error));
    // }
    // else {
      
    // }
  }

  getIcon() {
    return this.settings.icon || "more_vert";
  }

}


export class BeeShareSettingsModel {
  public mainColor: string;
  public fontColor: string;
  public icon: string;
}