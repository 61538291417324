import { Component, OnInit, Input } from '@angular/core';
import { CMSModuleStyle } from '../../cms-models';

@Component({
  selector: 'cms-style-edit',
  templateUrl: './cms-style-edit.component.html',
  styleUrls: ['./cms-style-edit.component.scss']
})
export class CmsStyleEditComponent implements OnInit {
  @Input() style: CMSModuleStyle;

  topValue: number;
  leftValue: number;
  bottomValue: number;
  rightValue: number;

  constructor() { 
  }

  ngOnInit() {
  }

  changeOverflow(e) {
    if(e.checked === true) {
      this.style.overflow = "hidden";
    }
    else this.style.overflow = undefined;
  }

  ngOnChanges() {
    if(this.style.top) {
      this.topValue = ~~this.style.top.substring(0, this.style.top.length - 1);
    }
    if(this.style.bottom) {
      this.bottomValue = ~~this.style.bottom.substring(0, this.style.bottom.length - 1);
    }
    if(this.style.left) {
      this.leftValue = ~~this.style.left.substring(0, this.style.left.length - 1);
    }
    if(this.style.right) {
      this.rightValue = ~~this.style.right.substring(0, this.style.right.length - 1);
    }
  }

  changeTopVal(e) {
    this.style.top = e.value + "%";

    if(e.value === 0) this.style.top = undefined;
  }

  changeBottomVal(e) {
    this.style.bottom = e.value + "%";

    if(e.value === 0) this.style.bottom = undefined;
  }

  changeLeftVal(e) {
    this.style.left = e.value + "%";

    if(e.value === 0) this.style.left = undefined;
  }

  changeRightVal(e) {
    this.style.right = e.value + "%";

    if(e.value === 0) this.style.right = undefined;
  }

}
