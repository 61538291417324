import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'bee-form-checkbox-select',
  templateUrl: './bee-form-checkbox-select.component.html',
  styleUrls: ['./bee-form-checkbox-select.component.scss']
})
export class BeeFormCheckboxSelectComponent extends FieldType implements OnInit {

  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.field.templateOptions.options.forEach((o: any) => {
      o.selected = this.formControl.value == o.label;
    });
  }

  // selectOption(option) {
  //   this.field.templateOptions.options.forEach((o: any) => {
  //     o.selected = false;
  //   });

  //   option.selected = true;

  //   this.formControl.setValue(option.label);phone
  change(e, option) {
    this.formControl.setValue(undefined);

    this.field.templateOptions.options[0].selected = false;

    this.field.templateOptions.options.forEach((o: any) => {
      o.selected = false;
    });

    option.selected = e.checked;

    if(e.checked) {
      this.formControl.setValue(option.label);
    }
  }

}
