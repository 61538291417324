import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  public TYPES = {
    UNDEFINED: -1,
    IMAGE: 1,
    VIDEO: 2,
    AUDIO: 3,
    EXTERNAL_VIDEO: 9
  };

  constructor(private http: HttpClient, private config: AppConfigService) { }

  getTypeName(type) {
    switch(type) {
      case 1:
        return "Bild"
      case 2: 
        return "Video"
      case 3:
        return "Podd"
      case 9:
        return "Video"
      default:
        return "Undefined"
    }
  }

  /**
   * GetTypeName
    type: number   */
    public GetTypeName(type: number) {
      if(type == this.TYPES.IMAGE) return "Image";
      else if(type == this.TYPES.VIDEO) return "Video";
      else if(type == this.TYPES.AUDIO) return "Audio";
      else if(type == this.TYPES.EXTERNAL_VIDEO) return "Video";
      else return "Undefined";
    }

  youtube_parser(url: string){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

    /**
   * GetMediabankFiles
   */
  public GetMediabankFiles(types: number[]) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetAllByTypes?types=' + types.join(",")); 
  }

  /**
   * GetAllMediabankFiles
   */
  public GetAllMediabankFiles() {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetAll');
  }

  /**
   * GetByUrl
urlRewrite: string   */
  public GetByUrl(urlRewrite: String) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetByUrl?urlRewrite=' + urlRewrite);
  }

  /**
   * GetRelated
   */
  public GetRelated(urlRewrite) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/getrelated?urlRewrite=' + urlRewrite);
  }

  /**
   * GetMediaFilesByTag
urlRewrite   */
  public GetMediaFilesByTag(tagUrlRewrite) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetMediaFilesByTag?tagUrlRewrite=' + tagUrlRewrite);
  }

  /**
   * LogEvent
   */
  public LogEvent(mediabankFileEvent) {
    return this.http.post(this.config.apiEndpoint + 'api-mediabank/MediabankFileEvent/LogEvent', mediabankFileEvent);
  }

  public getPhysicalFile(id: string) {
    return this.http.get(this.config.apiEndpoint + 'api-mediabank/mediabank/GetPhysicalFile?id=' + id);
  }

  /**
   * getCdnPath
   */
  public getCdnPath(url: string) {
    return `${this.config.apiEndpoint}cdn/${url}`;
  }
}
