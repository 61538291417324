<img [src]="customerLogo | cdn" *ngIf="customerLogo" class="logo hide-mobile">


<div class="login-wrapper">


  <h3>{{ (loginSettings$ | async).loginTitle || 'Logga in'  }}</h3>

  <!-- <p class="margin-top">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim adman.</p> -->


  <form action="#" (ngSubmit)="login(myForm);" class="login-form" #myForm="ngForm">

    <div class="login-form-inner-wrapper {{(loginSettings$ | async).theme}}">

      
      <ng-container *ngTemplateOutlet="{'default': defaultTheme, 'md': mdTheme}[(loginSettings$ | async).theme]"></ng-container>

      <ng-template #defaultTheme>
        <label for="username" class="margin-top lg">ANVÄNDARNAMN</label>
        <input required type="text" id="username" [(ngModel)]="userName" name="username" class="margin-bottom">
        
        <label for="password">LÖSENORD</label>
        <input required type="password" id="password" [(ngModel)]="password" name="password" class="margin-bottom">

        <div class="margin-top margin-bottom flex">
          <input required type="checkbox" id="gdprConsent" #gdprConsentInput="ngModel" name="gdprConsent" [(ngModel)]="gdprConsent">
          <label for="gdprConsent" class="gdprConsent">Godkänn <a [href]="authService.currentUserValue?.siteSettings?.loginSettings?.gdprConsentLink">behandling av personuppgifter</a></label>
        </div>
        <p *ngIf="submitted && gdprConsentInput.errors?.['required']" style="text-align: left; color: red;">GDPR consent it required.</p>
      </ng-template>

      <ng-template #mdTheme>
        <mat-form-field class="w-full mt-16" appearance="outline">
          <mat-label>Användarnamn</mat-label>
          <input required type="text" id="username" matInput placeholder="Användarnamn" [(ngModel)]="userName" name="username">
        </mat-form-field>

        <mat-form-field class="w-full mt-4" appearance="outline">
          <mat-label>Lösenord</mat-label>
          <input required type="password" id="password" matInput placeholder="Lösenord" [(ngModel)]="password" name="password">
        </mat-form-field>

        <mat-checkbox color="primary" required id="gdprConsent" #gdprConsentInput="ngModel" name="gdprConsent" [(ngModel)]="gdprConsent">
          Godkänn <a style="display: inline;" [href]="authService.currentUserValue?.siteSettings?.loginSettings?.gdprConsentLink">behandling av personuppgifter</a>
        </mat-checkbox>
        <p *ngIf="submitted && gdprConsentInput.errors?.['required']" style="text-align: left; color: red;">GDPR consent it required.</p>
      </ng-template>
      
      
      <p *ngIf="showErrorMessage" style="text-align: left; color: red;">Du har angett fel inloggningsuppgifter, prova igen</p>
    </div>
    
    <div class="margin-top flex column center login-form-inner-wrapper">
      <button class="bee-button">Logga in</button>
      <div class="margin-top lg">
        <span class="action" (click)="openForgotPassword();" 
          *ngIf="authService.currentUserValue?.siteSettings?.loginSettings?.showForgotPassword">Glömt lösenord?</span>
        <span class="action" (click)="openChangePassword();"
          *ngIf="authService.currentUserValue?.siteSettings?.loginSettings?.showChangePassword">Byt lösenord</span>
      </div>
    </div>
  </form>
</div>