import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bee-social-share-default',
  templateUrl: './bee-social-share-default.component.html',
  styleUrls: ['./bee-social-share-default.component.scss']
})
export class BeeSocialShareDefaultComponent implements OnInit {

  private url: string;
  private title: string;

  constructor() { 
    this.url = window.location.href;
    this.title = document.title;
  }

  ngOnInit(): void {
  }

  shareLinkedIn() {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${this.title}`, '_blank');
  }

  shareTwitter() {
    window.open(`http://twitter.com/share?text=${this.title}&url=${this.url}`);
  }

  shareFacebook() {
    window.open(`https://www.facebook.com/sharer/sharer.php?href=${this.url}`);
  }

}
