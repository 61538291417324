<ng-container *ngTemplateOutlet="{'loggedin': loggedin, 'notloggedin': notloggedin }[template]"></ng-container>

<ng-template #loggedin>
    <ng-container *ngTemplateOutlet="{'default': default, 'icon': icon }[moi.settings.content.template]"></ng-container>
</ng-template>
<ng-template #notloggedin>
    <div class="not-logged-in-container" [style.justifyContent]="moi.settings.style.justifyContent"> 
        <mat-icon>person</mat-icon>
        <a href="/login">
            {{moi.settings.content.loginText || 'CORE.LOGIN' | translate}}
        </a>
    </div>
</ng-template>

<ng-template #default>
    <div class="logged-in-container" [style.height]="moi.settings.style.height" [style.justifyContent]="moi.settings.style.justifyContent"> 
        <div class="unit-image hide-mobile" (click)="toggleUserMenu()">
            <img *ngIf="user.unit && user.unit.logoUrl" [src]="user.unit.logoUrl | cdn" alt="">
        </div>
        <div class="user-info-wrapper" (click)="toggleUserMenu()">
            <p class="name">
                <span [innerHtml]="user.fullName" class="hide-mobile"></span>
                <span [innerHtml]="user.firstName" class="hide-desktop"></span>
            </p>
            <p *ngIf="user.unit" [innerHtml]="user.unit.name" class="unit hide-mobile"></p>
        </div>
        <div (click)="toggleUserMenu()" *ngIf="moi.settings.content.showExpandIcon">
            <mat-icon>expand_more</mat-icon>
        </div>


        <div class="slide-from-right" [class.show]="showUserMenu">
            <div class="header">
                <span class="uppercase" [innerHtml]="'CORE.USER' | translate"></span>
                <mat-icon (click)="showUserMenu = false;">close</mat-icon>
            </div>
        
            <div class="notifications-wrapper">

                <div class="flex align-center padding" *ngIf="isAdmin()" (click)="goTo('/admin')">
                    <div class="circle white-background">
                        <mat-icon class="icon-2x logout-icon">admin_panel_settings</mat-icon>
                    </div>

                    <p [innerHtml]="'CORE.ADMIN' | translate"></p>
                </div>

                <div class="flex align-center padding" (click)="logout()">
                    <div class="circle white-background">
                        <mat-icon class="icon-2x logout-icon">logout</mat-icon>
                    </div>

                    <p [innerHtml]="moi.settings.content.logoutText || 'CORE.LOGOUT' | translate"></p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #icon>
    <mat-icon [style.fontSize]="moi.settings.content.iconSize" 
        [style.height]="moi.settings.content.iconSize" 
        [style.width]="moi.settings.content.iconSize" 
        [fontSet]="moi.settings.content.fontSet" (click)="show = !show">account_circle</mat-icon>

    <div class="profile-menu" 
        [class]="authService.currentUserValue.siteSettings.defaultAppearance" [hidden]="!show">
    <h4 class="margin-left margin-top margin-bottom">Profil</h4>

    <div class="profile-menu-item" *ngFor="let link of moi.settings.content?.links">
        <span [innerHtml]="link.name" (click)="goTo(link.url)"></span>
    </div>

    <div *ngIf="moi.settings.content?.links" class="divider margin-left margin-right sm"></div>

    <div class="profile-menu-item" (click)="openProfile()">
        <span>Mitt konto</span>
    </div>

    <div class="profile-menu-item" *ngIf="isAdmin()" (click)="goTo('/admin')">
        <span [innerHtml]="'CORE.ADMIN' | translate"></span>
    </div>

    <div class="profile-menu-item" (click)="logout()">
        <span [innerHtml]="moi.settings.content.logoutText || 'CORE.LOGOUT' | translate"></span>
    </div>
    
  </div>
</ng-template>