import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericContainerService } from 'src/app/frontend/services/generic-container.service';

@Component({
  selector: 'bee-generic-container-list',
  templateUrl: './generic-container-list.component.html',
  styleUrls: ['./generic-container-list.component.scss']
})
export class GenericContainerListComponent implements OnInit {
  sub: any;
  objectType: string;
  genericContainers: any[];
  containerType: any = {};

  constructor(private genericContainerService: GenericContainerService, private route: ActivatedRoute, private router: Router) { 

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.objectType = params["objectType"];

      this.GetContainerType();

      this.GetGenericContainers();
    });
  }

  GetContainerType() {
    this.genericContainerService.GetContainerType(this.objectType).subscribe(containerType => {
      this.containerType = containerType;
    });
  }

  GetGenericContainers() {
    this.genericContainerService.GetList(this.objectType).subscribe((data: any[]) => {
      this.genericContainers = data;
    });
  }

  goTo(genericContainer) {
    this.router.navigate(["modules/generic", this.objectType, genericContainer.urlRewrite]);
  }

  selectTag(tag) {
    
  }

}
