import { Component, ComponentFactoryResolver, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GenericContainer, GenericContainerType } from 'src/app/admin/generic-container/generic-container';
import { GenericContainerService } from 'src/app/admin/generic-container/generic-container.service';
import { BeeComponentService } from 'src/app/bee-services/bee-component.service';
import { CMSModuleInstance } from '../../cms-models';

@Component({
  selector: 'bee-cms-module-generic-container-list',
  templateUrl: './cms-module-generic-container-list.component.html',
  styleUrls: ['./cms-module-generic-container-list.component.scss']
})
export class CmsModuleGenericContainerListComponent implements OnInit {
  @ViewChild('customerContainer', { read: ViewContainerRef }) customerContainer: ViewContainerRef;

  public genericContainers: GenericContainer[];

  constructor(public moi: CMSModuleInstance, private genericContainerService: GenericContainerService, private componentService: BeeComponentService,
    private cfr: ComponentFactoryResolver,
    private injector: Injector) { 
    this.genericContainers = [];
  }

  ngOnInit() {
    if(this.moi.settings.content.genericContainerType) {
      this.GetGenericContainersForType();
    }

    if(!this.moi.settings.content.layout) {
      this.moi.settings.content.layout = 'default';
    }

    this.moi.eventHandler.subscribe((e) => {
      if(e.eventKey == 'CHANGE_CUSTOMER_TEMPLATE') {
        this.changeCustomerTemplate();
      }
    });
  }

  ngAfterViewInit(): void {
    this.changeCustomerTemplate();
  }

  changeCustomerTemplate() {
    if(this.moi.settings.content.template == 'customerSpecific' && this.moi.settings.content.customerTemplate) {
      if(this.customerContainer) {
        this.componentService.getComponentTemplateComponent("CmsModuleGenericContainerListComponent", this.moi.settings.content.customerTemplate).then(c => {
          const factory = this.cfr.resolveComponentFactory(c);
          const {instance} = this.customerContainer.createComponent(factory, null, this.injector);
        });
      }
    }
  }


  GetGenericContainersForType() {
    this.genericContainerService.GetList(this.moi.settings.content.genericContainerType).subscribe((data: GenericContainer[]) => {
      this.genericContainers = data;
    });
  }

}

@Component({
  selector: 'bee-cms-module-generic-container-list-settings',
  templateUrl: './cms-module-generic-container-list-settings.component.html',
  styleUrls: ['./cms-module-generic-container-list.component.scss']
})
export class CmsModuleGenericContainerListSettingsComponent implements OnInit {
  public templates: any[];
  public customerTemplates: any[];

  public genericContainerTypes: GenericContainerType[];

  constructor(public moi: CMSModuleInstance, private genericContainerService: GenericContainerService,
    private componentService: BeeComponentService) {
      this.customerTemplates = this.componentService.getComponentTemplates("CmsModuleGenericContainerListComponent");

      this.templates = [
        {
          name: "Default",
          key: "default"
        }
      ];

      if(this.customerTemplates.length > 0) {
        this.templates.push({
          name: "Customer specific",
          key: "customerSpecific"
        });
      }
  }

  ngOnInit() {
    this.GetGenericContainerTypes();
  }

  GetGenericContainerTypes() {
    this.genericContainerService.GetGenericContainerTypes().subscribe((data: GenericContainerType[]) => {
      this.genericContainerTypes = data;
      console.log('genericContainers', this.genericContainerTypes);
    });
  }

  changeCustomerTemplate() {
    if(this.moi.settings.content.template == 'default') {
      this.moi.settings.content.customerTemplate = '';
    }

    this.moi.eventHandler.emit({eventKey: "CHANGE_CUSTOMER_TEMPLATE"});
  }
}