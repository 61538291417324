export interface ICountryModel {
  name: string;
  countryCode: string;
  countryCodeLong: string;
  flagImgUrl: string;
  phonePrefix: string;
}

export class CountryModel {
  name: string;
  countryCode: string;
  countryCodeLong: string;
  flagImgUrl: string;
  phonePrefix: string;

  constructor(data?: ICountryModel) {
    if(data) {
      this.name = data.name;
      this.countryCode = data.countryCode;
      this.countryCodeLong = data.countryCodeLong;
      this.flagImgUrl = data.flagImgUrl;
      this.phonePrefix = data.phonePrefix;
    }
  }
}
