<div *ngIf="!isAdmin && initialized" id="{{moi.instanceId}}" 
  class="module-wrapper {{moi.instanceId}} {{extraClass}} {{moi.settings.cssClass}}" 
  [style.justifyContent]="moi.settings.style.justifyContent | mediaQuery:{'mobile': moi.settings.mobileStyle.justifyContent, 'deviceEmulation': deviceEmulation }" 
  [style.alignItems]="moi.settings.style.alignItems | mediaQuery:{'mobile': moi.settings.mobileStyle.alignItems, 'deviceEmulation': deviceEmulation }">
    <ng-container *ngComponentOutlet="moi.component; injector: moi.injectSettings;">

    </ng-container>
</div>

<div *ngIf="isAdmin && initialized" id="{{moi.instanceId}}" 
  class="module-wrapper {{moi.instanceId}} {{extraClass}} {{moi.settings.cssClass}}" 
  [style.justifyContent]="moi.settings.style.justifyContent | mediaQuery:{'mobile': moi.settings.mobileStyle.justifyContent, 'deviceEmulation': deviceEmulation }" 
  [style.alignItems]="moi.settings.style.alignItems | mediaQuery:{'mobile': moi.settings.mobileStyle.alignItems, 'deviceEmulation': deviceEmulation }" >
    <ng-container *ngComponentOutlet="moi.adminComponent; injector: moi.injectSettings;">

    </ng-container>
</div> 
