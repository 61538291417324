<h1 mat-dialog-title>Create module template</h1>

<div mat-dialog-content>
  <div class="content-item">
    <mat-form-field>
      <input matInput placeholder="Name" name="name" [(ngModel)]="data.name">
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Description" name="description" [(ngModel)]="data.description"></textarea>
    </mat-form-field>

    <mat-checkbox color="primary" [(ngModel)]="data.isPublic">Is public</mat-checkbox> 
  </div>
</div>

<div class="actions margin-top">
  <span class="bee-button" (click)="cancel();">Cancel</span>
  <span class="bee-button" (click)="ok();">Ok</span>
</div>