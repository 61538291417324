<ng-container *ngTemplateOutlet="{'default': default,'vertical': vertical, 'slim': slim}[template]"></ng-container>

<ng-template #default>
  <div class="journey-wrapper" *ngIf="hasHandeledItems">
    <div class="journey-item"
    *ngFor="let item of itemsToShow; let i = index;" 
    [class.chapter]="item.itemType == 'ACADEMY_CHAPTER'"
    [class.lg]="item.itemType == 'ACADEMY_CHAPTER'" 
    [class.medium]="item.itemType == 'ACADEMY_EVALUATION'"
    (click)="openItem(item);"
    [matTooltip]="getTooltip(item)">
      <div class="journey-item-icon">
        <mat-icon>{{getIcon(item)}}</mat-icon>
        <div class="overlay-item" *ngIf="hasOverlayItem(item)">
          <div class="last-overlay-item" *ngIf="isLastInOverlayItems(i)">
            <mat-icon *ngIf="item.isCompleted">done</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #vertical>
  <div class="journey-wrapper">

    <div class="person-wrapper">
      <mat-icon class="journey-person">person</mat-icon>
    </div>

    <div class="vertical-wrapper" *ngFor="let chapter of itemsToShowVertical; let ci = index;">
      
      <div class="journey-item"
        *ngFor="let item of chapter; let i = index;" 
        [class.finished]="item.isCompleted"
        [class.chapter]="item.itemType == 'ACADEMY_CHAPTER'"
        [class.lg]="item.itemType == 'ACADEMY_CHAPTER'" 
        [class.medium]="item.itemType == 'ACADEMY_EVALUATION'"
        (click)="openItem(item);"
        [matTooltip]="getTooltip(item)">
      <div class="journey-item-icon">
        <mat-icon>{{getIcon(item)}}</mat-icon>
        <span *ngIf="i == 0">{{ci + 1}}</span>
        <div class="overlay-item" *ngIf="hasOverlayItem(item)">
          <div class="last-overlay-item" *ngIf="isLastInOverlayItems(i)">
            <mat-icon *ngIf="item.isCompleted">done</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="journey-item medium finish-icon" [class.finished]="isChapterFinished(chapter)">
      <div class="journey-item-icon">
        <mat-icon>{{getChapterIsFinishedIcon(chapter)}}</mat-icon>
      </div>
    </div>

    </div>
  </div>
</ng-template>

<ng-template #slim>
  <div class="slim-journey-wrapper">
    <h3 class="uppercase" [innerHtml]="'ACADEMY.MY_JOURNEY' | translate"></h3>

    <div class="inner-content center margin-top content-padding">
      <p>
        Följ dina framsteg i utbildningen. Kursen är uppdelad i ämnesområden som är indelade i ett antal lärmoduler. Öppna ett ämnesområde för att välja lärmodul.
        <br>
        <i>(Extramaterial är valfritt.)</i>
      </p>
    </div>


    <div class="journey-wrapper">

      <div class="person-wrapper">
        <mat-icon class="journey-person">person</mat-icon>
      </div>

      <div class="slim-wrapper">
      
        <div class="slim-chapter" *ngFor="let chapter of itemsToShowVertical; let ci = index;"
          [class.finished]="isChapterFinished(chapter)"
          [class.expanded]="chapter.isExpanded"
          [class.optional]="chapter[0].optional"
          [class.hideConnector]="!chapter.isExpanded && itemsToShowVertical[ci + 1] && itemsToShowVertical[ci + 1][0] && itemsToShowVertical[ci + 1][0].optional"
          [class.hideChildConnector]="itemsToShowVertical[ci + 1] && itemsToShowVertical[ci + 1][0] && itemsToShowVertical[ci + 1][0].optional">

          <div class="slim-chapter-inner">

            <div class="finish-marker">
              <mat-icon>done</mat-icon>
            </div>

            <div class="chapter-status"></div>
            <div class="chapter-number">
              <span>{{ci}}</span>
            </div>

            <p [innerHtml]="chapter[0].name"></p>

            <div class="optional-item-status">
              <mat-icon *ngIf="isChapterFinished(chapter)">check</mat-icon>
            </div>

            <span class="margin-left intro-play-icon" *ngIf="chapter[0].itemType == 'COURSE_INTRO'" (click)="openItem(chapter[0]);">
              <mat-icon>play_circle_outline</mat-icon>
            </span>

            <div class="expander" *ngIf="chapter.length > 1">
              <bee-animated-icon class="md-36"
                start="keyboard_arrow_down" 
                end="keyboard_arrow_left" 
                [(animate)]="chapter.isExpanded" (click)="toggleChapter(chapter); $event.stopPropagation();"></bee-animated-icon> 
            </div>
          </div>

          <div class="slim-item" 
            [class.completed]="item.isCompleted" 
            [class.optional]="item.optional"
            (click)="openItem(item);"
            *ngFor="let item of chapter; let i = index;" [hidden]="i == 0 || !chapter.isExpanded">
            
            <div class="item-status" [class.star]="item.itemType == 'ACADEMY_EVALUATION'">
              <mat-icon *ngIf="item.isCompleted">check</mat-icon>
            </div>
            
            <div class="slim-item-info">
              <span class="slim-item-icon">
                <mat-icon *ngIf="item.itemType == 'ACADEMY_VIDEO'">play_circle_outline</mat-icon>
              </span>
  
              <p [innerHtml]="item.name"></p>

              <div class="optional-item-status">
                <mat-icon *ngIf="item.isCompleted">check</mat-icon>
              </div>
            </div>

          </div>

        </div>
  
      </div>

    </div>
  </div>
</ng-template>

