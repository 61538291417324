import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { CMSModuleInstance, CMSModuleAbstract } from '../../cms-models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CmsEventService } from '../../cms-editor/cms-event-service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CmsModuleService } from '../../cms-services/cms-module-service.service';

@Component({
  selector: 'app-cms-module-slider',
  templateUrl: './cms-module-slider.component.html',
  styleUrls: ['./cms-module-slider.component.scss']
})
export class CmsModuleSliderComponent implements OnInit {
  sliderOptions: OwlOptions;

  constructor(public module: CMSModuleInstance) { 
      if(!this.module.settings.content.sliderSettings) {
        this.module.settings.content.sliderSettings = {
          dots: true,
          items: 1,
          center: false
        }
      }
  }

  ngOnInit() {
    this.sliderOptions = {
      center: this.module.settings.content.sliderSettings.center,
      dots: this.module.settings.content.sliderSettings.dots,
      autoWidth: false,
      autoplay: true,
      loop: true,
      autoplayHoverPause: true,
      animateOut: this.module.settings.content.sliderSettings.animateType,
      responsive: {
        0: {
          items: 1,
          nav: false
        },
        400: {
          items: this.module.settings.content.sliderSettings.items,
          nav: this.module.settings.content.sliderSettings.dots
        }
      }
    };
  }

}

@Component({
  selector: 'app-cms-module-slider-settings',
  templateUrl: './cms-module-slider-settings.component.html',
  styleUrls: ['./cms-module-slider.component.scss']
})
export class CmsModuleSliderSettingsComponent implements OnInit {
  hasCopiedMoi: boolean;

  constructor(public module: CMSModuleInstance, public dialog: MatDialog, private eventService: CmsEventService) { }

  ngOnInit() {
    if(sessionStorage.getItem("COPY_CMS_MOI")) {
      this.hasCopiedMoi = true;
    }
    else {
      this.hasCopiedMoi = false;
    }
  }

  selectModule(moi) {
    this.eventService.setSelectedModuleInstance.emit(moi)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.module.moduleInstances, event.previousIndex, event.currentIndex);

    //Give all items new order...
    for(let i = 0; i < this.module.moduleInstances.length; i++) {
      this.module.moduleInstances[i].order = i;
    }
  }

  addModule() {
    const dialogRef = this.dialog.open(CmsModuleSliderSettingsAddDialogComponent, {
      data: this.module
    });

    dialogRef.afterClosed().subscribe(moi => {
      //this.eventService.setSelectedModuleInstance.emit(moi);
    });
  }

  addCopiedModule() {
    let newMoi = new CMSModuleInstance(JSON.parse(sessionStorage.getItem("COPY_CMS_MOI")));
    newMoi.parentId = this.module.id;
    this.module.moduleInstances.push(newMoi); 
  }

  deleteMoi(moi: CMSModuleInstance) {
    moi.isDeleted = true;
  }

  copyMoi(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    
    this.module.moduleInstances.push(newMoi);
  }

  saveCopy(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    sessionStorage.setItem("COPY_CMS_MOI", JSON.stringify(newMoi));
    this.hasCopiedMoi = true;
  }

}

@Component({
  selector: 'app-cms-module-slider-settings-add-module-dialog',
  templateUrl: 'app-cms-module-slider-settings-add-module-dialog.html',
  styleUrls: ['./app-cms-module-slider-settings-add-module-dialog.component.scss']
})
export class CmsModuleSliderSettingsAddDialogComponent {
  moduleAbstracts: Array<CMSModuleAbstract>;

  constructor(public dialogRef: MatDialogRef<CmsModuleSliderSettingsAddDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CMSModuleInstance, private cmsModuleService: CmsModuleService) {
    this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
  }

  close(moi: CMSModuleInstance): void {
    this.dialogRef.close(moi);
  }

  addModuleInstance(moduleAbstract: CMSModuleAbstract) {
    var moi = moduleAbstract.createModuleInstance();
    this.data.moduleInstances.push(moi);
    this.close(moi);
  }
}
