<div class="bee-media-list-wrapper">

  <bee-tag-cloud 
    *ngIf="mediabankSiteSettings.showTagCloud && mediabankSiteSettings.showTagCloud.show == true && mediabankSiteSettings.showTagCloud.template == 'fab'"
    (tagSelect)="selectTag($event)">
  </bee-tag-cloud>

  <div class="media-list-header">
    <h1>TPLay</h1>
    <p>Här kan du se rörligt material och lyssna på poddar. Filtrera på video, poddar och med taggar.</p>
    <div *ngIf="!isSingleTypeMode" class="media-types">
      <mat-checkbox *ngFor="let type of avalibleMediaTypes" [ngClass]="type.name" class="margin-right x-lg md-24" color="primary" [checked]="type.checked" (change)="typeChange(type)">
        <h4 [innerHtml]="type.name"></h4>
      </mat-checkbox>
    </div>
  </div>

  <div class="media-list">
    <div class="header">
      <h3 (click)="toggleLatestLimit()" class="hide-mobile">Senaste</h3>
      <h2 (click)="toggleLatestLimit()" class="hide-desktop mobile-medium">Senaste</h2>
      <bee-animated-icon class="md-36"
        start="keyboard_arrow_down" 
        end="keyboard_arrow_left" 
        [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
        [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
        [(animate)]="latestExpanded" (click)="toggleLatestLimit(); $event.stopPropagation();"></bee-animated-icon> 
    </div>

    <div class="media-list-item" *ngFor="let media of mediaItemsLatest | limitTo:latestLimit" (click)="goToMedia(media)">
      <div class="media-list-image">
        <img [src]="getImage(media) | cdn" [alt]="media.name" class="media-image">
        <div class="play-overlay">
          <!-- <i class="material-icons">play_arrow</i> -->
          <bee-play-icon class=""></bee-play-icon>
        </div>
      </div>
      <div class="media-info">
        <h6 [innerHtml]="media.name" class="margin-top"></h6>
        <span class="media-type-indicator" [ngClass]="getTypeName(media.type)" [innerHtml]="getTypeName(media.type)"></span>
      </div>
    </div>
  </div>

  <div class="media-list">
    <div class="header">
      <h3 (click)="toggleMostViewedLimit()" class="hide-mobile">Mest sedda</h3>
      <h3 (click)="toggleMostViewedLimit()" class="hide-desktop mobile-medium">Mest sedda</h3>
      <bee-animated-icon class="md-36"
        start="keyboard_arrow_down" 
        end="keyboard_arrow_left" 
        [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
        [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
        [(animate)]="mostViewedExpanded" (click)="toggleMostViewedLimit(); $event.stopPropagation();"></bee-animated-icon> 
    </div>

    <div class="media-list-item" *ngFor="let media of mediaItemsMostViewed | limitTo:mostViewedtLimit" (click)="goToMedia(media)">
      <div class="media-list-image">
        <img [src]="getImage(media) | cdn" [alt]="media.name" class="media-image">
        <div class="play-overlay">
          <!-- <i class="material-icons">play_arrow</i> -->
          <bee-play-icon class=""></bee-play-icon>
        </div>
      </div>
      <div class="media-info">
        <h6 [innerHtml]="media.name" class="margin-top"></h6>
        <span class="media-type-indicator" [ngClass]="getTypeName(media.type)" [innerHtml]="getTypeName(media.type)"></span>
      </div>
    </div>
  </div>
</div>


