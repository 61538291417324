import { PreviewService } from './services/preview.service';
import { Component, OnInit, Input } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CmsEventService} from 'src/app/container/cms-editor/cms-event-service';
import { CmsModuleCardSettingsAddDialogComponent } from 'src/app/container/cms-modules/cms-module-card/cms-module-card-dialog.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-cms-module-card',
  templateUrl: './cms-module-card.component.html',
  styleUrls: ['./cms-module-card.component.scss'],
})
export class CmsModuleCardComponent implements OnInit {
  readonly FRONTSIDE_ORDER_LIMIT = 1000;
  backsidePreview: PreviewService = this.previewService;
  @Input() animationData: Object;

  constructor(public module: CMSModuleInstance, private eventService: CmsEventService, private previewService: PreviewService) {

  }

  ngOnInit() {
    // XpAnimations[this.animationData['hover']] = XpAnimations['card_flip'];
  }

  selectModuleInstance(moi: CMSModuleInstance, e) {
    this.eventService.setSelectedModuleInstance.emit(moi);
    e.stopPropagation();
  }

  displayBackSidePreview($event){

    this.backsidePreview = $event;
    console.log($event);
  }

  // frontSideModules(): CMSModuleInstance[] {
  //   let moduleInstances = [];

  //   this.module.moduleInstances.forEach(m => {
  //     if(m.order <= this.FRONTSIDE_ORDER_LIMIT) {
  //       moduleInstances.push(m);
  //     }
  //   });

  //   return moduleInstances;
  // }

  // backSideModules(): CMSModuleInstance[] {
  //   let moduleInstances = [];

  //   this.module.moduleInstances.forEach(m => {
  //     if(m.order > this.FRONTSIDE_ORDER_LIMIT) {
  //       moduleInstances.push(m);
  //     }
  //   });

  //   return moduleInstances;
  // }

  backSideSettings(): CMSModuleInstance[] {
    let moduleInstances = [];

    moduleInstances = this.module.moduleInstances.filter(function (mois) {
      return mois.order >= 1001;
    });
    return moduleInstances;
  }

  frontSideSettings(): CMSModuleInstance[] {
    let moduleInstances = [];

    moduleInstances = this.module.moduleInstances.filter(function (mois) {
      return mois.order < 1001;
    });
    return moduleInstances;
  }

}

@Component({
  selector: 'app-cms-module-card-settings',
  templateUrl: './cms-module-card-settings.component.html',
  styleUrls: ['./cms-module-card.component.scss']
})
export class CmsModuleCardSettingsComponent implements OnInit {
  hasCopiedMoi: boolean;
  readonly FRONTSIDE_ORDER_LIMIT = 1000;
  frontSide = [...this.frontSideSettings()];
  backSide = [...this.backSideSettings()];

  constructor(public module: CMSModuleInstance, public dialog: MatDialog, private eventService: CmsEventService, private previewService: PreviewService) { }

  ngOnInit() {
    if(sessionStorage.getItem("COPY_CMS_MOI")) {
      this.hasCopiedMoi = true;
    }
    else {
      this.hasCopiedMoi = false;
    }
  }

  selectModule(moi) {
    this.eventService.setSelectedModuleInstance.emit(moi)
  }

  drop(event: CdkDragDrop<any>) {

    if (event.previousContainer === event.container && event.container.id === "cdk-drop-list-3"){
      moveItemInArray(this.frontSide, event.previousIndex, event.currentIndex);
      for(let i = 0; i <this.frontSide.length; i++) {
          this.frontSide[i].order = i;
          // console.log(this.frontSide[i].name + ' ' + this.frontSide[i].order + ' FRONTSIDE');
      }
    }

    if (event.previousContainer === event.container && event.container.id === "cdk-drop-list-4"){
      moveItemInArray(this.backSide, event.previousIndex, event.currentIndex);
      for(let i = 0; i < this.backSide.length; i++) {
          this.backSide[i].order = 1001 + i;
          // console.log(this.backSide[i].name + ' ' + this.backSide[i].order + ' BACKSIDE');
      }
    }
  }

  addModule(backSide) {
    const dialogRef = this.dialog.open(CmsModuleCardSettingsAddDialogComponent, {
      data: this.module
    });
    // console.log(this.module);
    dialogRef.afterClosed().subscribe(moi => {
      //console.log(moi);
      if(backSide){
        moi.order += 1001;
      }
      this.repopulateModules();
    });
  }
  repopulateModules(){
    this.frontSide = [...this.frontSideSettings()];
    this.backSide = [...this.backSideSettings()];
  }
  deleteMoi(moi: CMSModuleInstance) {
    moi.isDeleted = true;
  }

  copyMoi(moi: CMSModuleInstance) {
    let newMoi = moi.copy();

    this.module.moduleInstances.push(newMoi);
  }

  addCopiedModule() {
    let newMoi = new CMSModuleInstance(JSON.parse(sessionStorage.getItem("COPY_CMS_MOI")));
    newMoi.parentId = this.module.id;
    this.module.moduleInstances.push(newMoi);
  }

  saveCopy(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    sessionStorage.setItem("COPY_CMS_MOI", JSON.stringify(newMoi));
    this.hasCopiedMoi = true;
  }

  frontSideModules(): CMSModuleInstance[] {
    let moduleInstances = [];

    this.module.moduleInstances.forEach(m => {
      if(m.order <= this.FRONTSIDE_ORDER_LIMIT) {
        moduleInstances.push(m);
      }
    });

    return moduleInstances;
  }

  backSideModules(): CMSModuleInstance[] {
    let moduleInstances = [];

    this.module.moduleInstances.forEach(m => {
      if(m.order > this.FRONTSIDE_ORDER_LIMIT) {
        moduleInstances.push(m);
      }
    });

    return moduleInstances;
  }


  backSideSettings(): CMSModuleInstance[] {
    let moduleInstances = [];

    moduleInstances = this.module.moduleInstances.filter(function (mois) {
      return mois.order >= 1001;
    });
    return moduleInstances;
  }

  frontSideSettings(): CMSModuleInstance[] {
    let moduleInstances = [];

    moduleInstances = this.module.moduleInstances.filter(function (mois) {
      return mois.order < 1001;
    });
    return moduleInstances;
  }

  previewBacksideToggler(){
    //toggles the preview
    this.previewService.toggle();
    console.log(this.previewService);
  }
}
