import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseChapter, getJourneyItemsFromChapters, checkIfChapterIsDone } from 'src/app/admin/academy/models/chapter';
import { CourseEntity } from 'src/app/admin/academy/models/course-entity';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { CourseLogEntity } from 'src/app/domain/academy/models/course-log-entity';
import { CourseStatusType } from 'src/app/domain/academy/models/course-status-type';
import { SignalrService } from 'src/app/frontend/services/signalr.service';
import { JourneyItem } from '../journey/journey.component';

import dayjs from 'dayjs'

@Component({
  selector: 'bee-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {
  sub: any;
  _courses: CourseEntity[];

  @Input() public settings: CourseListSettings = new CourseListSettings();
  @Input() completedItems: string[];
  @Input() courseStatuses: CourseLogEntity[] = [];

  public firstCourse: CourseEntity;

  public journeyItems;

  get courses(): CourseEntity[] {
    return this._courses;
  }

  @Input() set courses(val: CourseEntity[]) {
    this._courses = val;
    this.setTemplate();
    if(val && val.length > 0) {
      this.firstCourse = val[0];
      this.journeyItems = this.getJourneyItems();
    }
  }

  public template: string = "none";

  private approvedCourses: CourseEntity[] = [];
  private completedCourses: CourseEntity[] = [];
  private startedCourses: CourseEntity[] = [];
  private require_approvalCourses: CourseEntity[] = [];

  getCoursesInOrder(): CourseEntity[] {
    let courses: CourseEntity[] = [];
    let unknown: CourseEntity[] = [];

    this.approvedCourses = [];
    this.completedCourses = [];
    this.startedCourses = [];
    this.require_approvalCourses = [];

    if(this.courses && this.courses.length > 0) {
      this.courses.forEach(c => {
        let status = this.getCourseStatus(c);
  
        if(status) {
          c.userStatus = status;

          switch(status.status) {
            case "approved": 
              this.approvedCourses.push(c);
            break;
            case "require_approval":
              this.require_approvalCourses.push(c);
            break;
            case "completed":
              this.completedCourses.push(c);
            break;
            case "started":
              this.startedCourses.push(c);
            break;
          }
        }
        else {
          c.userStatus = new CourseLogEntity();
          unknown.push(c);
        }
      });
  
      courses.push(...this.startedCourses);
      courses.push(...unknown);
      courses.push(...this.completedCourses);
      courses.push(...this.approvedCourses);
      courses.push(...this.require_approvalCourses);
    }

    return courses;
  }

  getStatusText(course: CourseEntity): string {
    let text: string = "";
    let logEntity: CourseLogEntity = this.getCourseStatus(course);

    if(logEntity) {
      switch(logEntity.status as CourseStatusType) {
        case "approved": 
          text = `Avslutad och Godkänd ` + this.getApprovedDate(logEntity.courseId);
        break;
        case "require_approval":
          text = `Avslutad och inväntar godkännande ` + this.getRequireApprovalDate(logEntity.courseId);
        break;
        case "completed":
          text = `Avslutad ` + this.getCompletedDate(logEntity.courseId);
        break;
        case "started":
          text = `Pågående`;
        break;
        default:
          text = `Ej påbörjad`;
        break;
      }
    }
    else {
      text = `Ej påbörjad`;
    }

    return text;
  }

  getRequireApprovalDate(courseId: string) {
    var date = new Date();

    for(let i = 0; i < this.require_approvalCourses.length; i++) {
      if(this.require_approvalCourses[i].id === courseId) {
        date = this.require_approvalCourses[i].createdTimeUtc;
        break;
      }
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  getCompletedDate(courseId: string) {
    var date = new Date();

    for(let i = 0; i < this.completedCourses.length; i++) {
      if(this.completedCourses[i].id === courseId) {
        date = this.completedCourses[i].createdTimeUtc;
        break;
      }
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  getApprovedDate(courseId: string): string {
    var date = new Date();

    for(let i = 0; i < this.approvedCourses.length; i++) {
      if(this.approvedCourses[i].id === courseId) {
        date = this.approvedCourses[i].createdTimeUtc;
        break;
      }
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  getCourseStatus(course: CourseEntity): CourseLogEntity {
    let status: CourseLogEntity = null;

    if(this.courseStatuses && this.courseStatuses.length > 0) {

      for(let i = 0; i < this.courseStatuses.length; i++) {
        if(this.courseStatuses[i].courseId == course.id) {
          status = this.courseStatuses[i];
          break;
        }
      }
    }

    return status;
  }

  constructor(private router: Router, private route: ActivatedRoute, private signalrService: SignalrService,
    private authService: AuthenticationService) {       
      if(!this.settings) {
        this.settings = new CourseListSettings();
      }

      this.initSignalR();
  }

  goToCourse(course: CourseEntity) {
    this.router.navigate([], { fragment: course.id });
  }

  setSelectedCourse(course: CourseEntity) {
    console.log('SETTINGS COURSE', course);
    this.firstCourse = course;
    this.journeyItems = this.getJourneyItems();
    this.setTemplate();
  }

  ngOnInit(): void {
    this.sub = this.route.fragment.subscribe(fragment => {
      if(fragment) {
        this.courses.forEach(course => {
          if(course.id == fragment) {
            this.setSelectedCourse(course);
          }
        });

      }
      else {
        if(this.courses && this.courses.length === 1) {
          this.firstCourse = this.courses[0];
        }
        else {
          this.firstCourse = null;
        }

        console.log(this.firstCourse);
        
        this.setTemplate();
      }
   });

   this.journeyItems = this.getJourneyItems();
    
  }

  initSignalR() {

    this.signalrService.subscribeToEvent(this.authService.currentUserValue.user.id, "UPDATE_EDUCATION_STATUS", (message) => {
      var educationStatus = JSON.parse(message);      
  
      if(educationStatus.status == "passed") {
        this.completedItems.push(educationStatus.educationId);

        this.setJourneyItemStatusToCompleted(educationStatus.educationId);

        // this.journeyItems = undefined;

        // setTimeout(() => {
        //   this.journeyItems = this.getJourneyItems();
        // }, 250);
      }

      console.log("UPDATE_EDUCATION_STATUS", educationStatus);
    });

    // if(this.signalrService.connection) {
    //   this.signalrService.connection.on("UPDATE_EDUCATION_STATUS", (message) => {
    //     var educationStatus = JSON.parse(message);      
  
    //     if(educationStatus.status == "passed") {
    //       this.completedItems.push(educationStatus.educationId);
  
    //       this.journeyItems = undefined;
  
    //       setTimeout(() => {
    //         this.journeyItems = this.getJourneyItems();
    //       }, 250);
    //     }
  
    //     console.log("UPDATE_EDUCATION_STATUS", educationStatus);
    //   });
    // }
  }

  setJourneyItemStatusToCompleted(educationId: any) {
    console.log("JOURNEY ITEMS: ", this.journeyItems);

    console.log("UPDATE EDUCATION ID: ", educationId);

    this.journeyItems.forEach(j => {
      if(j.itemId == educationId) {
        j.isCompleted = true;
      }
    });
  }

  goToChapter(chapter: CourseChapter) {
    this.router.navigate([this.settings.chapterUrl, chapter.id])
  }

  setTemplate() {
    console.log(this.courses.length);
    console.log(this.firstCourse);
    if(!this.courses || this.courses.length <= 0) {
      this.template = "none";
    }
    else if(this.courses.length === 1 || this.firstCourse) {
      this.template = "single";
    }
    else {
      this.template = "normal";
    }
  }

  getJourneyItems() {
    if(this.firstCourse && this.firstCourse.chapters) {
      let items = this.getJourneyItemsFromChapters(this.firstCourse.chapters);

      if(this.firstCourse.introductionVideo) {

        let item = new JourneyItem({
          name: "Introduktion " + this.firstCourse.name,
          description: "",
          image: "",
          isCompleted: this.firstCourse.hasUserSeenIntroductionVideo,
          itemType: "COURSE_INTRO",
          itemId: this.firstCourse.id,
          isExpanded: false,
        });

        items.unshift(item)
      }

      return items;
    }
  }

  getJourneyItemsFromChapters(chapters: CourseChapter[]): JourneyItem[] {
    if(this.completedItems) {
      return getJourneyItemsFromChapters(chapters, this.completedItems);
    }

    return [];
  }

  checkIfChapterIsDone(c: CourseChapter): boolean {
    return checkIfChapterIsDone(c, this.completedItems);
  }

  ngOnDestroy(): void {
    this.signalrService.unsubscribeToEvent(this.authService.currentUserValue.user.id, "update-education-status");
  }
}

export class CourseListSettings {
  chapterUrl: string = "modules/academy/course/chapter/";
  showOnlyJourney: boolean = false;
  courseSmallJourneyText: string = "";
}