import { Component, OnInit, Inject } from '@angular/core';
import { CMSModuleInstance } from 'src/app/container/cms-models';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CmsEventService} from 'src/app/container/cms-editor/cms-event-service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AddModuleDialogComponent } from '../../cms-editor/add-module-dialog/add-module-dialog.component';
import { IPermissionService } from '../../interfaces/permission.service';
import { DEVICE_EMULATION } from '../../cms-editor/cms-editor.component';

@Component({
  selector: 'app-cms-module-segment',
  templateUrl: './cms-module-segment.component.html',
  styleUrls: ['./cms-module-segment.component.scss']
})
export class CmsModuleSegmentComponent implements OnInit {
  public deviceEmulation: string;

  constructor(public module: CMSModuleInstance, private eventService: CmsEventService, private permissionService: IPermissionService) { 
  }

  ngOnInit() {
    this.eventService.setDeviceEmulation.subscribe(emu => {
      this.deviceEmulation = emu;
    });
  }

  selectModuleInstance(moi: CMSModuleInstance, e) {
    this.eventService.setSelectedModuleInstance.emit(moi);
    e.stopPropagation();
  }

}


@Component({
  selector: 'app-cms-module-segment-settings',
  templateUrl: './cms-module-segment-settings.component.html',
  styleUrls: ['./cms-module-segment.component.scss']
})
export class CmsModuleSegmentSettingsComponent implements OnInit {
  hasCopiedMoi: boolean;

  constructor(public module: CMSModuleInstance, public dialog: MatDialog, private eventService: CmsEventService) { }

  ngOnInit() {
    if(sessionStorage.getItem("COPY_CMS_MOI")) {
      this.hasCopiedMoi = true;
    }
    else {
      this.hasCopiedMoi = false;
    }
  }

  getName(moi: CMSModuleInstance): string {
    if(moi.name) {
      return ", " + moi.name;
    }

    return "";
  }

  selectModule(moi) {
    this.eventService.setSelectedModuleInstance.emit(moi)
  }

  drop(event: CdkDragDrop<string[]>) {
    debugger;
    moveItemInArray(this.module.moduleInstances, event.previousIndex, event.currentIndex);

    //Give all items new order...
    for(let i = 0; i < this.module.moduleInstances.length; i++) {
      this.module.moduleInstances[i].order = i;
    }
  }

  addModule() {
    const dialogRef = this.dialog.open(AddModuleDialogComponent, {});

    dialogRef.afterClosed().subscribe(moi => { 
      this.module.moduleInstances.push(moi);
    });
  }

  deleteMoi(moi: CMSModuleInstance) {
    moi.isDeleted = true;
  }

  copyMoi(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    
    this.module.moduleInstances.push(newMoi);
  }

  addCopiedModule() {
    let newMoi = new CMSModuleInstance(JSON.parse(sessionStorage.getItem("COPY_CMS_MOI")));
    newMoi.parentId = this.module.id;
    this.module.moduleInstances.push(newMoi); 
  }

  saveCopy(moi: CMSModuleInstance) {
    let newMoi = moi.copy();
    sessionStorage.setItem("COPY_CMS_MOI", JSON.stringify(newMoi));
    this.hasCopiedMoi = true;
  }
}
