import VerificationFlowStep from "./verification-flow-step.model";

export class VerificationFlowModel {
    steps: VerificationFlowStep[] = [];

    constructor(data?: VerificationFlowModel) {
        if(data) {
            Object.assign(this, data);
            this.steps = data.steps.map((s) => {
                return new VerificationFlowStep(s);
            });
        }
    }
}