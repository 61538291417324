import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { PasswordChangeService } from 'src/app/authorize/services/password-change.service';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { IMyProfileService } from '../interfaces/i-my-profile-service';
import { IBeeSelectItem } from '../models/bee-select-item';

@Component({
  selector: 'bee-my-profile-small',
  templateUrl: './bee-my-profile-small.component.html',
  styleUrls: ['./bee-my-profile-small.component.scss']
})
export class BeeMyProfileSmallComponent implements OnInit {

  @Input() beeStyle?: any;
  @Input() template: string = "default";

  public saveMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public isEditing = false;
  public subId: string;
  public isSubscribedToPush: boolean = false;
  public user: BeeUser = new BeeUser();
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public selectedUnit: IBeeSelectItem;
  public units$: BehaviorSubject<IBeeSelectItem[]> = new BehaviorSubject<IBeeSelectItem[]>([]);

  private originalUserData: BeeUser;

  constructor(private myProfileService: IMyProfileService,
    public authService: AuthenticationService,
    private passwordChangeService: PasswordChangeService) { }

  
  isNotOptedOut(type: string): boolean {
    return this.user.notificationOptOut?.indexOf(type) == -1;
  }

  setOptOut(e, type: string) {
    if(this.user.notificationOptOut == null) {
      this.user.notificationOptOut = [];
    }

    if(e.checked) {
      this.user.notificationOptOut = [...this.user.notificationOptOut.filter(x => x != type)];
    }
    else {
      this.user.notificationOptOut.push(type);
    }
  }

  ngOnInit(): void {
    this.getUser();
  }

  ngAfterViewInit(): void {
    // this.pushNotificationService.checkIfRegistered().subscribe(sub => {
    //   if(sub) {
    //     this.subId = sub;
    //     this.isSubscribedToPush = true;
    //   }
    // })
  }

  openChangePassword(): void {
    this.passwordChangeService.openResetPasswordDialog();
  }

  getUser() {
    this.myProfileService.getMyProfile().subscribe(response => {
      this.user = response;
      this.units$ = new BehaviorSubject<IBeeSelectItem[]>(response.units.map(x => {
        return  {
          name: x.name,
          shortName: x.name,
          value: x.id
        } as IBeeSelectItem
      }));

      this.selectedUnit = this.units$.value.find(x => x.value == this.user.unitId);

      if(this.user.notificationOptOut == null) {
        this.user.notificationOptOut = [];
      }
      this.isLoading$.next(false);
    });
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;

    if(this.isEditing == true) {
      this.originalUserData = Object.assign({}, this.user);
    }
  }

  cancel(): void {
    if(this.originalUserData != undefined && this.originalUserData != null) {
      this.user = Object.assign({}, this.originalUserData);
    }

    this.toggleEdit();
  }

  saveProfile(form: NgForm): void {
    if(form.valid) {
      this.user.password = "";
      this.user.unitId = this.selectedUnit?.value;
      this.user.unit = this.user.units.find(x => x.id == this.selectedUnit?.value);
      this.myProfileService.saveMyProfile(this.user).subscribe(response => {
        if(response.isSuccessful) {
          this.user = response.data;
          this.authService.setUser(this.user);
          this.authService.refreshToken();
        }

        this.saveMessage$.next(response.errorMessage || "Profile saved.");

        this.isEditing = false;
      });
    }
  }

  // togglePush(on: boolean): void {
  //   if(on) {
  //     console.log('SUBSCRIBE');
  //     this.registerToPush();
  //   }
  //   else {
  //     this.unregisterToPush();
  //   }
  // }

  // unregisterToPush() {
  //   this.pushNotificationService.unregisterToPush(this.subId).subscribe(sub => {
  //     this.isSubscribedToPush = false;
  //   });
  // }

  // registerToPush() {
  //   this.pushNotificationService.registerToPush().subscribe(sub => {
  //     if(sub) {
  //       if(sub.isSuccessful) {
  //         this.subId = sub.data.sub;
  //         this.isSubscribedToPush = true;
  //       }
  //       else if(sub.errorMessage == "Already registered...") {
  //         this.isSubscribedToPush = true;
  //       }
  //     }
  //   });
  // }

}
