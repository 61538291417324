import { EventEmitter, Injector } from '@angular/core';
import { AppInjector } from './container.module';
import { CmsModuleService } from './cms-services/cms-module-service.service';
import { GenericItemPermission } from '../domain/core/models/generic-item-permission';
import { BehaviorSubject } from 'rxjs';



function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export class CMSContainer {
  id: string;
  templateId: string;
  template: CMSContainer;
  dynamicData: any;
  objectKey: String;
  objectType: String;
  moduleInstances: Array<CMSModuleInstance> = [];
  iso2Culture: String;
  iso4Culture: String;
  animationData: any[];

  constructor(data?: any) {
    if(!data) data = {};
    this.id = data.id;
    this.dynamicData = data.dynamicData || {};
    this.objectKey = data.objectKey || "";
    this.objectType = data.objectType || "";
    this.iso2Culture = data.iso2Culture;
    this.iso4Culture = data.iso4Culture;
    this.animationData = data.animationData || [];
    this.moduleInstances = [];

    //TEMPLATE HANDLING
    this.templateId = data.templateId;
    if(data.template) {
      this.template = new CMSContainer(data.template.container);
      this.animationData = this.template.animationData;

      this.template.moduleInstances.forEach(moi => {
        this.moduleInstances.push(moi);
      });

      this.handleTemplateModuleInstances(data.moduleInstances);
    }
    else {
      if(data.moduleInstances && data.moduleInstances.length > 0) {
        for(let i = 0; i < data.moduleInstances.length; i++) {
          this.moduleInstances.push(new CMSModuleInstance(data.moduleInstances[i], this.template));
        }
      }
    }
  }

  handleTemplateModuleInstances(moduleInstances: any[]) {
    moduleInstances.forEach((moi: CMSModuleInstance) => {
      let m = this.getModuleInstanceByInstanceId(moi.instanceId);

      if(m == null) {

        //The module was removed from the template...
        //this.moduleInstances.push(new CMSModuleInstance(moi));
      }
      else {
        if(m.settings.content != moi.settings.content && moi.settings.content) {
          m.settings.content = moi.settings.content;
        }
        
        //Links...
        if(m.link != moi.link) {
          m.link = moi.link;
        }

        //Desktop style
        if(m.settings.style.backgroundImage != moi.settings.style.backgroundImage) {
          m.settings.style.backgroundImage = moi.settings.style.backgroundImage;
        }
        if(m.settings.style.backgroundVideo != moi.settings.style.backgroundVideo) {
          m.settings.style.backgroundVideo = moi.settings.style.backgroundVideo;
        }
        if(m.settings.style.display != moi.settings.style.display) {
          m.settings.style.display = moi.settings.style.display;
        }
        //Mobile style
        if(m.settings.mobileStyle.backgroundImage != moi.settings.mobileStyle.backgroundImage) {
          m.settings.mobileStyle.backgroundImage = m.settings.style.backgroundImage;
        }
        if(m.settings.mobileStyle.backgroundVideo != moi.settings.mobileStyle.backgroundVideo) {
          m.settings.mobileStyle.backgroundVideo = m.settings.style.backgroundVideo;
        }
        if(m.settings.mobileStyle.display != moi.settings.mobileStyle.display) {
          m.settings.mobileStyle.display = m.settings.style.display;
        }
      }

      if(moi.moduleInstances && moi.moduleInstances.length > 0) {
        this.handleTemplateModuleInstances(moi.moduleInstances);
      }
    });
  }

  getModuleInstanceByInstanceId(instanceId: string) : CMSModuleInstance {
    let moi: CMSModuleInstance;

    for(let i = 0; i < this.moduleInstances.length; i++) {

      if(this.moduleInstances[i].instanceId == instanceId) {
        moi = this.moduleInstances[i];
        break;
      }
      else if(!moi && this.moduleInstances[i].moduleInstances.length > 0) {
        moi = this.findModuleInstanceByInstanceId(instanceId, this.moduleInstances[i].moduleInstances);
      }

      //moi = this.moduleInstances[i].getModuleInstanceByInstanceId(instanceId);

      if(moi != null) break;
    }

    return moi;
  }

  findModuleInstanceByInstanceId(instanceId: string, moduleInstances: CMSModuleInstance[]): CMSModuleInstance {
    let moi: CMSModuleInstance;

    for(let i = 0; i < moduleInstances.length; i++) {
      if(moduleInstances[i].instanceId == instanceId) {
        moi = moduleInstances[i];
        break;
      }
      else if(!moi && moduleInstances[i].moduleInstances.length > 0) {
        moi = this.findModuleInstanceByInstanceId(instanceId, moduleInstances[i].moduleInstances);
      }
    }

    return moi;
  };

  getModuleInstance(id: string) : CMSModuleInstance {
    let moi: CMSModuleInstance;

    for(let i = 0; i < this.moduleInstances.length; i++) {
      moi = this.moduleInstances[i].getModuleInstanceById(id);

      if(moi != null) break;
    }

    return moi;
  }
}

export class CMSModuleAbstract {
  public adminComponent;
  public adminSettingsComponent;
  public component;
  public key: string;
  public icon: string;
  public description: string;
  public id: string;
  public abstractName: string;

  public componentName: string;
  public adminComponentName: string;
  public adminSettingsComponentName: string;

  getComponent: Function;

  constructor(data: any) {
    if(!data) console.error('CMSModuleAbstract: Requires data to be initialized with!');
    if(!data.adminComponent || !data.adminSettingsComponent || !data.component) {
      let cmsModuleService = AppInjector.get(CmsModuleService);
      cmsModuleService.populateComponents(data);
    }

    this.adminComponent = data.adminComponent;
    this.adminSettingsComponent = data.adminSettingsComponent;
    this.component = data.component;
    this.abstractName = data.abstractName || "";
    this.icon = data.icon || "";
    this.description = data.description || "";
    this.id = data.id || "";
    this.key = data.key || "";

    this.componentName = data.componentName;
    this.adminComponentName = data.adminComponentName;
    this.adminSettingsComponentName = data.adminSettingsComponentName;

    this.getComponent = data.getComponent;
  }

  createModuleInstance() : CMSModuleInstance {
    return new CMSModuleInstance(this);
  }
}

// @Injectable()
export class CMSModuleInstance extends CMSModuleAbstract {
  public moiId: string;
  public instanceId: string = uuidv4();
  public abstractId: string;
  public abstractKey: string;
  public settings: CMSModuleSettings;
  public settings$: BehaviorSubject<CMSModuleSettings>;
  public moduleInstances: Array<CMSModuleInstance> = [];
  public order: number;
  public isDeleted: boolean;
  public parentId: string;

  public link: string;
  public name: string;
  public name_id: string;
  public showDialog: boolean;
  public dialogData: any;

  public permission: GenericItemPermission;

  //Javascript specific for angular
  public injectSettings: Injector;
  public eventHandler = new EventEmitter();

  //Editor variables
  public hasAdminAccess: boolean;

  constructor(data: any, template?: CMSContainer) {
    super(data);

    this.moiId = data.moiId || "";
    this.abstractId = data.abstractId || this.id;
    this.abstractKey = data.abstractKey || this.key;
    this.parentId = data.cmsModuleInstancemoiId || "";
    this.link = data.link || "";
    this.name = data.name || "";
    this.name_id = data.name_id || "";
    this.showDialog = data.showDialog || false;
    this.dialogData = data.dialogData || { icon: "" };

    this.instanceId = data.instanceId || this.instanceId;
    this.settings = new CMSModuleSettings(data.settings);
    this.settings$ = new BehaviorSubject<CMSModuleSettings>(this.settings);
    this.moduleInstances = [];

    this.permission = data.permission || new GenericItemPermission();
    
    if(data.moduleInstances && data.moduleInstances.length > 0) {
      for(let i = 0; i < data.moduleInstances.length; i++) {
        let moi = new CMSModuleInstance(data.moduleInstances[i], template);
        this.moduleInstances.push(moi);
      }
    }

    this.order = data.order || 0;
    this.isDeleted = data.isDeleted || false;
    
    //Get the service...
    let cmsModuleService = AppInjector.get(CmsModuleService);

    let mod = cmsModuleService.GetModuleAbstract(this.abstractId);
    if(this.abstractName == "") this.abstractName = mod.abstractName;
    if(!this.getComponent) this.getComponent = mod.getComponent;
    if(!this.componentName) this.componentName = mod.componentName;
    if(!this.adminComponentName) this.adminComponentName = mod.adminComponentName;
    if(!this.adminSettingsComponentName) this.adminSettingsComponentName = mod.adminSettingsComponentName;
    if(!this.icon) this.icon = mod.icon;
  }

  getModuleInstanceById(id: string) : CMSModuleInstance {
    let moi: CMSModuleInstance = null;

    if(this.moiId == id) moi = this;
    if(moi == null) {
      for(let i = 0; i < this.moduleInstances.length; i++) {
        if(this.moduleInstances[i].moiId == id) {
          moi = this.moduleInstances[i];
        }
        else {
          moi = this.moduleInstances[i].getModuleInstanceById(id);
        }
      }
    }

    return moi;
  }

  getModuleInstanceByInstanceId(instanceId: string): CMSModuleInstance {
    let moi: CMSModuleInstance = null;

    if(this.instanceId == instanceId) moi = this;
    if(moi == null) {
      for(let i = 0; i < this.moduleInstances.length; i++) {
        if(this.moduleInstances[i].instanceId == instanceId) {
          moi = this.moduleInstances[i];
        }
        else if(this.moduleInstances[i].moduleInstances && this.moduleInstances[i].moduleInstances.length > 0) {
          moi = this.moduleInstances[i].getModuleInstanceByInstanceId(instanceId);
        }
      }
    }

    return moi;
  }

  replacer(key,value) {
    if(key == "injectSettings") return undefined;
    if(key == "eventHandler") return undefined;
    if(key == "hasAdminAccess") return undefined;
    if(key == "settings$") return undefined;

    return value;
  }


  copy() : CMSModuleInstance {
    let moi: CMSModuleInstance = new CMSModuleInstance(JSON.parse(JSON.stringify(this, this.replacer)));
    moi.reset();
    return moi;
  }

  reset() {
    this.moiId = "";
    this.parentId = "";
    this.instanceId = uuidv4();

    this.settings.id = 0;
    this.settings.style.id = 0;
    this.settings.mobileStyle.id = 0;

    for(let i = 0; i < this.moduleInstances.length; i++) {
      this.moduleInstances[i].reset();
    }
  }
}

export class CMSModuleStyle {
  public id: number;

  public fontWeight: string = "inherit";
  public fontSize: string = "1em";
  public fontFamily: string = "inherit";
  public textAlign: string;
  public lineHeight: string;

  public color: string = "inherit";
  public backgroundColor: string = "transparent";

  public backgroundImage: string = "";
  public backgroundVideo: string = undefined;
  public backgroundSize: string = "cover";
  public backgroundPosition: string = "center";

  public paddingTop: string;
  public paddingRight: string;
  public paddingBottom: string;
  public paddingLeft: string;
  public marginTop: string;
  public marginRight: string;
  public marginBottom: string;
  public marginLeft: string;

  public justifyContent: string;
  public alignItems: string = "flex-start";

  public width: string = "100%";
  public maxWidth: string;
  public height: string;
  public maxHeight: string;
  public aspectRatio: string;

  public borderRadius: string;
  public borderWidth: string;
  public borderStyle: string;
  public borderColor: string;

  public borderTopColor: string;
  public borderRightColor: string;
  public borderBottomColor: string;
  public borderLeftColor: string;

  public clipPath: string;
  public transform: string;
  public zIndex: string;

  public display: string;
  public position: string;
  public left: string;
  public right: string;
  public top: string;
  public bottom: string;

  public overflow: string;
  public boxShadow: string;
  public boxSizing: string;

  constructor(data: any, fallbackData: any = {}) {
    if(data) {
      this.id = data.id || 0;

      this.fontWeight = data.fontWeight || fallbackData.fontWeight || "inherit";
      this.fontSize = data.fontSize || fallbackData.fontSize || "1em";
      this.fontFamily = data.fontFamily || fallbackData.fontFamily || "inherit";
      this.textAlign = data.textAlign || fallbackData.textAlign;
      this.lineHeight = data.lineHeight || fallbackData.lineHeight;

      this.color = data.color || fallbackData.color || "inherit";
      this.backgroundColor = data.backgroundColor || fallbackData.backgroundColor || "transparent";

      this.backgroundImage = data.backgroundImage || fallbackData.backgroundImage || "";

      this.backgroundVideo = data.backgroundVideo || fallbackData.backgroundVideo || "";
      this.backgroundSize = data.backgroundSize || fallbackData.backgroundSize || "cover";
      this.backgroundPosition = data.backgroundPosition || fallbackData.backgroundSize || "center";

      this.paddingTop = data.paddingTop || fallbackData.paddingTop;
      this.paddingRight = data.paddingRight || fallbackData.paddingRight;
      this.paddingBottom = data.paddingBottom || fallbackData.paddingBottom;
      this.paddingLeft = data.paddingLeft || fallbackData.paddingLeft;
      this.marginTop = data.marginTop || fallbackData.marginTop;
      this.marginRight = data.marginRight || fallbackData.marginRight;
      this.marginBottom = data.marginBottom || fallbackData.marginBottom;
      this.marginLeft = data.marginLeft || fallbackData.marginLeft;

      this.justifyContent = data.justifyContent || fallbackData.justifyContent;
      this.alignItems = data.alignItems || fallbackData.alignItems || "flex-start";

      this.width = data.width || "100%";
      this.maxWidth = data.maxWidth || "100%";
      this.height = data.height;
      this.maxHeight = data.maxHeight;
      this.aspectRatio = data.aspectRatio;

      this.borderRadius = data.borderRadius || fallbackData.borderRadius;
      this.borderWidth = data.borderWidth || fallbackData.borderWidth;
      this.borderStyle = data.borderStyle || fallbackData.borderStyle;
      this.borderColor = data.borderColor || fallbackData.borderColor;

      if(data.borderTopColor) {
        this.borderTopColor = data.borderTopColor || fallbackData.borderTopColor;
      }
      if(data.borderRightColor) {
        this.borderRightColor = data.borderRightColor || fallbackData.borderRightColor;
      }
      if(data.borderBottomColor) {
        this.borderBottomColor = data.borderBottomColor || fallbackData.borderBottomColor;
      }
      if(data.borderLeftColor) {
        this.borderLeftColor = data.borderLeftColor || fallbackData.borderLeftColor;
      }

      this.clipPath = data.clipPath || fallbackData.clipPath;
      this.transform = data.transform || fallbackData.transform;
      this.zIndex = data.zIndex || fallbackData.zIndex;

      this.display = data.display || "";

      this.position = data.position || "relative";
      this.left = data.left;
      this.top = data.top;
      this.bottom = data.bottom;
      this.right = data.right;

      this.overflow = data.overflow || fallbackData.overflow;
      this.boxShadow = data.boxShadow || fallbackData.boxShadow;
      this.boxSizing = data.boxSizing || fallbackData.boxSizing || "border-box";
    }
  }
}

export class CMSModuleSettings {
  public id: number;
  public content: any = {};
  public style: CMSModuleStyle = new CMSModuleStyle({});
  public mobileStyle: CMSModuleStyle = new CMSModuleStyle({});
  public animationData: any = {};
  public cssClass: string;
  public scrollAnimation: any;

  constructor(data: any) {
    if(data) {
      this.id = data.id || 0;
      this.content = data.content || {};
      this.style= new CMSModuleStyle(data.style);
      this.mobileStyle= new CMSModuleStyle(data.mobileStyle, data.style);
      this.animationData = data.animationData || {};
      this.cssClass = data.cssClass;
      this.scrollAnimation = data.scrollAnimation || { isActive: false };
    }
  }
}


export class ModuleInstanceTemplate {
  public id: string;
  public name: string;
  public description: string;
  public isPublic: boolean;
  public icon: string;
  public moduleInstance: CMSModuleInstance;
  public createdByUserId: string;
  public createdTimeUtc: Date;
  public lastEditedByUserId: string;
  public lastEditedTimeUtc: Date;

  constructor(moi?: CMSModuleInstance) {
    if(moi) {
      this.moduleInstance = moi.copy();
      this.moduleInstance.order = undefined;
    }
  }
}
