import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { CmsModuleGenericContainerListComponent } from 'src/app/customers/vo-college/components/cms-module-templates/success-stories/vo-college-success-stories-template.component';
import { CMSModuleAbstract } from '../cms-models';
import { CmsModuleBlogListComponent, CmsModuleBlogListSettingsComponent } from '../cms-modules/cms-module-blog-list/cms-module-blog-list.component';
import { CmsModuleButtonComponent, CmsModuleButtonSettingsComponent } from '../cms-modules/cms-module-button/cms-module-button.component';
import { CmsModuleCalendarComponent, CmsModuleCalendarSettingsComponent } from '../cms-modules/cms-module-calendar/cms-module-calendar.component';
import { CmsModuleCardComponent, CmsModuleCardSettingsComponent } from '../cms-modules/cms-module-card/cms-module-card.component';
import { CmsModuleCourseChapterTableInfoComponent, CmsModuleCourseChapterTableInfoSettingsComponent } from '../cms-modules/cms-module-course-chapter-table-info/cms-module-course-chapter-table-info.component';
import { CmsModuleCourseListComponent, CmsModuleCourseListSettingsComponent } from '../cms-modules/cms-module-course-list/cms-module-course-list.component';
import { CmsModuleFormsComponent, CmsModuleFormsSettingsComponent } from '../cms-modules/cms-module-forms/cms-module-forms.component';
import { CmsModuleGenericContainerListSettingsComponent } from '../cms-modules/cms-module-generic-container-list/cms-module-generic-container-list.component';
import { CmsModuleHtmlInputComponent, CmsModuleHtmlInputSettingsComponent } from '../cms-modules/cms-module-html-input/cms-module-html-input.component';
import { CmsModuleImageComponent, CmsModuleImageSettingsComponent } from '../cms-modules/cms-module-image/cms-module-image.component';
import { CmsModuleLoggedInMiniComponent, CmsModuleLoggedInMiniSettingsComponent } from '../cms-modules/cms-module-logged-in-mini/cms-module-logged-in-mini.component';
import { CmsModuleMediaListComponent, CmsModuleMediaListSettingsComponent } from '../cms-modules/cms-module-media-list/cms-module-media-list.component';
import { CmsModuleMyProfileComponent, CmsModuleMyProfileSettingsComponent } from '../cms-modules/cms-module-my-profile/cms-module-my-profile.component';
import { CmsModuleNavigationComponent, CmsModuleNavigationSettingsComponent } from '../cms-modules/cms-module-navigation/cms-module-navigation.component';
import { CmsModulePageMenuAdminComponent, CmsModulePageMenuComponent, CmsModulePageMenuSettingsComponent } from '../cms-modules/cms-module-page-menu/cms-module-page-menu.component';
import { CmsModuleSearchComponent, CmsModuleSearchSettingsComponent } from '../cms-modules/cms-module-search/cms-module-search.component';
import { CmsModuleSegmentComponent, CmsModuleSegmentSettingsComponent } from '../cms-modules/cms-module-segment/cms-module-segment.component';
import { CmsModuleSliderComponent, CmsModuleSliderSettingsComponent } from '../cms-modules/cms-module-slider/cms-module-slider.component';
import { CmsModuleSocialFeedComponent, CmsModuleSocialFeedSettingsComponent } from '../cms-modules/cms-module-social-feed/cms-module-social-feed.component';
import { CmsModuleSocialShareComponent, CmsModuleSocialShareSettingsComponent } from '../cms-modules/cms-module-social-share/cms-module-social-share.component';
import { CmsModuleTabsComponent, CmsModuleTabsSettingsComponent } from '../cms-modules/cms-module-tabs/cms-module-tabs.component';
import { CmsModuleTextComponent, CmsModuleTextSettingsComponent } from '../cms-modules/cms-module-text/cms-module-text.component';
import { CmsModuleUnitInformationComponent, CmsModuleUnitInformationSettingsComponent } from '../cms-modules/cms-module-unit-information/cms-module-unit-information.component';
import { CmsModuleUserNotificationsComponent, CmsModuleUserNotificationsSettingsComponent } from '../cms-modules/cms-module-user-notifications/cms-module-user-notifications.component';
import { CmsModuleVideoListComponent, CmsModuleVideoListSettingsComponent } from '../cms-modules/cms-module-video-list/cms-module-video-list.component';
import { CmsModuleVideoComponent, CmsModuleVideoSettingsComponent } from '../cms-modules/cms-module-video/cms-module-video.component';
import { VO_COLLEGE_MODULES } from './customer-modules/vo-college-modules';
import { CmsModuleVerificationFlowSelectComponent, CmsModuleVerificationFlowSelectSettingsComponent } from '../cms-modules/cms-module-verification-flow-select/cms-module-verification-flow-select.component';
import { CmsModuleScrollAnimationComponent, CmsModuleScrollAnimationSettingsComponent } from '../cms-modules/cms-module-scroll-animation/cms-module-scroll-animation.component';
import { KCF_MODULES } from './customer-modules/kcf-modules';

@Injectable({
  providedIn: 'root'
})
export class CmsModuleService {
  CUSTOMER_ABSTRACTS: any;

  constructor(private authService: AuthenticationService) { 
  }

  getModuleAbstracts(): Array<any> {
    if(!this.CUSTOMER_ABSTRACTS) {
      this.CUSTOMER_ABSTRACTS = {
        'vo-college': VO_COLLEGE_MODULES,
        'vo-college-dev': VO_COLLEGE_MODULES,
        'kcf': KCF_MODULES,
        'kcf-dev': KCF_MODULES,
      };
    }

    const ModuleAbstracts: Array<any> = [
      new CMSModuleAbstract({
        adminComponent: CmsModuleTextComponent,
        adminSettingsComponent: CmsModuleTextSettingsComponent,
        component: CmsModuleTextComponent,
        key: "TEXT",
        abstractName: "Text",
        icon: "short_text",
        description: "This is a normal text module",
        id: 1,
        settings: {
          style: {
            fontWeight: 'bold',
          }
        }
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleImageComponent,
        adminSettingsComponent: CmsModuleImageSettingsComponent,
        component: CmsModuleImageComponent,
        key: "IMAGE",
        abstractName: "Image",
        icon: "add_photo_alternate",
        description: "This is a module to display image",
        id: 2
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleVideoComponent,
        adminSettingsComponent: CmsModuleVideoSettingsComponent,
        component: CmsModuleVideoComponent,
        key: "VIDEO",
        abstractName: "Video",
        icon: "movie",
        description: "This is a normal video module",
        id: 3
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleSegmentComponent,
        adminSettingsComponent: CmsModuleSegmentSettingsComponent,
        component: CmsModuleSegmentComponent,
        key: "SEGMENT",
        abstractName: "Segment",
        icon: "check_box_outline_blank",
        description: "This is a module that holds other modules",
        id: 4
      }),
      // new CMSModuleAbstract({
      //   adminComponent: CmsModuleGoogleMapsComponent,
      //   adminSettingsComponent: CmsModuleGoogleMapsSettingsComponent,
      //   component: CmsModuleGoogleMapsComponent,
      //   key: "GOOGLE_MAPS",
      //   abstractName: "Google maps",
      //   icon: "map",
      //   description: "This module will display a google maps",
      //   id: 5
      // }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleVideoListComponent,
        adminSettingsComponent: CmsModuleVideoListSettingsComponent,
        component: CmsModuleVideoListComponent,
        key: "VIDEO_LIST",
        abstractName: "Video list",
        icon: "ondemand_video",
        description: "This module can display a list of videos",
        id: 6
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleCalendarComponent,
        adminSettingsComponent: CmsModuleCalendarSettingsComponent,
        component: CmsModuleCalendarComponent,
        key: "CALENDAR",
        abstractName: "Calendar",
        icon: "event",
        description: "This module will show the upcomming events",
        id: 7
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleBlogListComponent,
        adminSettingsComponent: CmsModuleBlogListSettingsComponent,
        component: CmsModuleBlogListComponent,
        key: "BLOG_LIST",
        abstractName: "Blog list",
        icon: "ballot",
        description: "This module will show a list of blog entries",
        id: 8
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModulePageMenuAdminComponent,
        adminSettingsComponent: CmsModulePageMenuSettingsComponent,
        component: CmsModulePageMenuComponent,
        key: "PAGE_MENU",
        abstractName: "Page menu",
        icon: "view_column",
        description: "This module can generate a graphical menu to link to other CMS pages",
        id: 9
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleSliderComponent,
        adminSettingsComponent: CmsModuleSliderSettingsComponent,
        component: CmsModuleSliderComponent,
        key: "SLIDER",
        abstractName: "Slider",
        icon: "view_carousel",
        description: "Create a slider that contains modules for a slideshow.",
        id: 10
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleButtonComponent,
        adminSettingsComponent: CmsModuleButtonSettingsComponent,
        component: CmsModuleButtonComponent,
        key: "BUTTON",
        abstractName: "Button",
        icon: "touch_app",
        description: "Create a button.",
        id: 11
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleSocialShareComponent,
        adminSettingsComponent: CmsModuleSocialShareSettingsComponent,
        component: CmsModuleSocialShareComponent,
        key: "SOCIAL_SHARE",
        abstractName: "Social share",
        icon: "share",
        description: "Create a social share module.",
        id: 12
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleFormsComponent,
        adminSettingsComponent: CmsModuleFormsSettingsComponent,
        component: CmsModuleFormsComponent,
        key: "FORMS",
        abstractName: "Form",
        icon: "contact_mail",
        description: "Display a form",
        id: 13
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleCardComponent,
        adminSettingsComponent: CmsModuleCardSettingsComponent,
        component: CmsModuleCardComponent,
        key: "CARD",
        abstractName: "Card",
        icon: "picture_in_picture",
        description: "Create a card",
        id: 14
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleNavigationComponent,
        adminSettingsComponent: CmsModuleNavigationSettingsComponent,
        component: CmsModuleNavigationComponent,
        key: "NAV",
        abstractName: "Nav",
        icon: "menu",
        description: "Display menu",
        id: 15
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleSearchComponent,
        adminSettingsComponent: CmsModuleSearchSettingsComponent,
        component: CmsModuleSearchComponent,
        key: "SEARCH",
        abstractName: "Search",
        icon: "search",
        description: "Module for searching",
        id: 16
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleSocialFeedComponent,
        adminSettingsComponent: CmsModuleSocialFeedSettingsComponent,
        component: CmsModuleSocialFeedComponent,
        key: "SOCIAL_FEED",
        abstractName: "Social feed",
        icon: "facebook",
        description: "Show a social feed",
        id: 17
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleGenericContainerListComponent,
        adminSettingsComponent: CmsModuleGenericContainerListSettingsComponent,
        component: CmsModuleGenericContainerListComponent,
        key: "GENERIC_CONTAINER_LIST",
        abstractName: "Generic container list",
        icon: "ballot",
        description: "List any type of generic container",
        id: 18
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleTabsComponent,
        adminSettingsComponent: CmsModuleTabsSettingsComponent,
        component: CmsModuleTabsComponent,
        key: "TABS",
        abstractName: "Tabs",
        icon: "tab",
        description: "Make tabs",
        id: 19
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleMediaListComponent,
        adminSettingsComponent: CmsModuleMediaListSettingsComponent,
        component: CmsModuleMediaListComponent,
        key: "MEDIA_LIST",
        abstractName: "Media list",
        icon: "perm_media",
        description: "Makes a list of mediabank files",
        id: 20
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleCourseListComponent,
        adminSettingsComponent: CmsModuleCourseListSettingsComponent,
        component: CmsModuleCourseListComponent,
        key: "COURSE_LIST",
        abstractName: "Course list",
        icon: "school",
        description: "List all the courses that the user has access to.",
        id: 21
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleUserNotificationsComponent,
        adminSettingsComponent: CmsModuleUserNotificationsSettingsComponent,
        component: CmsModuleUserNotificationsComponent,
        key: "USER_NOTIFICATIONS",
        abstractName: "User notifications",
        icon: "bell",
        description: "Display logged in user notifications",
        id: 22
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleCourseChapterTableInfoComponent,
        adminSettingsComponent: CmsModuleCourseChapterTableInfoSettingsComponent,
        component: CmsModuleCourseChapterTableInfoComponent,
        key: "COURSE_CHAPTER_TABLE_INFO",
        abstractName: "Course chapters table info",
        icon: "school",
        description: "Display the information about users ongoing courses",
        id: 23
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleMyProfileComponent,
        adminSettingsComponent: CmsModuleMyProfileSettingsComponent,
        component: CmsModuleMyProfileComponent,
        key: "MY_PROFILE",
        abstractName: "My profile",
        icon: "manage_accounts",
        description: "Show and edit profile",
        id: 24
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleUnitInformationComponent,
        adminSettingsComponent: CmsModuleUnitInformationSettingsComponent,
        component: CmsModuleUnitInformationComponent,
        key: "UNIT_INFORMATION",
        abstractName: "Unit information",
        icon: "store",
        description: "Shows information about the logged in users unit",
        id: 25
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleLoggedInMiniComponent,
        adminSettingsComponent: CmsModuleLoggedInMiniSettingsComponent,
        component: CmsModuleLoggedInMiniComponent,
        key: "LOGGED_IN_MINI",
        abstractName: "Logged in mini",
        icon: "person",
        description: "Shows the logged in user or login button",
        id: 26
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleHtmlInputComponent,
        adminSettingsComponent: CmsModuleHtmlInputSettingsComponent,
        component: CmsModuleHtmlInputComponent,
        key: "HTML_INPUT",
        abstractName: "Html input",
        icon: "code",
        description: "Add HTML code",
        id: 27
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleVerificationFlowSelectComponent,
        adminSettingsComponent: CmsModuleVerificationFlowSelectSettingsComponent,
        component: CmsModuleVerificationFlowSelectComponent,
        key: "VERIFICATION_FLOW_SELECT",
        abstractName: "Verification flow select",
        icon: "code",
        description: "Add verification flow select",
        id: 28
      }),
      new CMSModuleAbstract({
        adminComponent: CmsModuleScrollAnimationComponent,
        adminSettingsComponent: CmsModuleScrollAnimationSettingsComponent,
        component: CmsModuleScrollAnimationComponent,
        key: "SCROLL_ANIMATION",
        abstractName: "Scroll animation",
        icon: "animation",
        description: "Add scroll animation",
        id: 29
      })
    ];

    return [...ModuleAbstracts, ...this.getCustomerModuleAbstracts()];
  }

  private getCustomerModuleAbstracts(): Array<any> {
    return this.CUSTOMER_ABSTRACTS[this.authService.currentUserValue.siteSettings.customer] || [];
  }

  GetModuleAbstract(id: string): CMSModuleAbstract {
    let mod: CMSModuleAbstract = null;
    const ModuleAbstracts = this.getModuleAbstracts();
  
    for(let i = 0; i < ModuleAbstracts.length; i++) {
      if(ModuleAbstracts[i].id == id) {
        mod = ModuleAbstracts[i]; 
        break;
      }
    }
  
    return mod;
  }

  populateComponents(mod: any) {
    if(mod.abstractId) {
      let m: CMSModuleAbstract = this.GetModuleAbstract(mod.abstractId);

      mod.adminComponent = m.adminComponent;
      mod.adminSettingsComponent = m.adminSettingsComponent;
      mod.component = m.component;
    }
  }
}
