import { Component, ComponentFactoryResolver, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GenericContainer } from 'src/app/admin/generic-container/generic-container';
import { GenericContainerSiteSettings } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BeeComponentService } from 'src/app/bee-services/bee-component.service';
import { GenericContainerService } from 'src/app/frontend/services/generic-container.service';
import { GenericContainerViewMoreComponent } from '../generic-container-view-more/generic-container-view-more.component';

@Component({
  selector: 'bee-generic-container-view',
  templateUrl: './generic-container-view.component.html',
  styleUrls: ['./generic-container-view.component.scss']
})
export class GenericContainerViewComponent implements OnInit {
  @ViewChild('moreContent', {read: ViewContainerRef}) moreContent: ViewContainerRef;
  
  sub: any;
  urlRewrite: string;
  genericContainer: GenericContainer;

  customer: string;

  public genericContainerSiteSettings: GenericContainerSiteSettings = new GenericContainerSiteSettings();

  constructor(private genericContainerService: GenericContainerService, private route: ActivatedRoute,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private compService: BeeComponentService,
    private authService: AuthenticationService) { 
      this.customer = this.authService.currentUserValue.siteSettings.customer;
      this.genericContainerSiteSettings = this.authService.currentUserValue.siteSettings.genericContainerSiteSettings;
    }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.urlRewrite = params["urlRewrite"];

      this.GetGenericCmsContainer();
    });
  }

  ngAfterViewInit(): void {
    this.GetMoreContent();
  }

  GetMoreContent() {
    this.compService.getComponent("GenericContainerViewMoreComponent", GenericContainerViewMoreComponent).then(t => {
      const factory = this.cfr.resolveComponentFactory(t);
      const {instance} = this.moreContent.createComponent(factory, null, this.injector);
    });
  }

  GetGenericCmsContainer() {
    this.genericContainerService.GetByUrlRewrite(this.urlRewrite).subscribe((data: any) => {
      this.genericContainer = new GenericContainer(data);
    });
  }

  selectTag(tag) {
    
  }

}
