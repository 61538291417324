<ng-container *ngTemplateOutlet="{ 'default': default, 'min-max': minMax }[field.templateOptions.extraData.template]"></ng-container>

<ng-template #default>
  <span class="required" *ngIf="field.templateOptions.required">*</span>

  <p class="text-2xl"><strong [innerHtml]="field.templateOptions.label"></strong></p>

  <div class="slider-wrapper">
    <mat-slider
    thumbLabel
    color="primary"
    [(ngModel)]="sliderVal"
    [formControl]="formControl" 
    [min]="field.templateOptions.attributes.min"
    [max]="field.templateOptions.attributes.max"></mat-slider>

    <input type="input" [(ngModel)]="sliderVal">

    <!-- <span [innerHtml]="getText()"></span> -->
  </div>
</ng-template>

<ng-template #minMax>
  <span class="required" *ngIf="field.templateOptions.required">*</span>

  <p class="text-2xl"><strong [innerHtml]="field.templateOptions.label"></strong></p>

  <div class="slider-wrapper min-max">
    <mat-slider
    thumbLabel="false"
    disableRipple="true"
    showTickMarks="true"
    color="primary"
    [(ngModel)]="sliderVal"
    [formControl]="formControl" 
    [min]="field.templateOptions.attributes.min"
    [max]="field.templateOptions.attributes.max"></mat-slider>
  </div>
  <div class="val-labels-wrapper flex space-between width-100 margin-top leading-6 font-bold">
    <div class="val-label" *ngFor="let label of field.templateOptions.extraData.valLabels">
      {{label}}
    </div>
  </div>
</ng-template>