<div>

    <mat-button-toggle-group [(ngModel)]="moi.settings.content.settings.showType">
        <mat-button-toggle value="dropdown" aria-label="Dropdown">
            Dropdown
        </mat-button-toggle>
        <mat-button-toggle value="slideFromRight" aria-label="Slide from right">
            Slide from right
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="margin-top">
        <input matInput placeholder="Font set (material-icons / material-icons-outlined)" name="fontSet" [(ngModel)]="moi.settings.content.settings.fontSet">
    </mat-form-field>

    <div class="margin-top">
        <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.settings.showNotificationIcon">Show notification icon</mat-checkbox>
    </div>

    <div class="margin-top">
        <mat-checkbox color="primary" [(ngModel)]="moi.settings.content.settings.showNotificationDate">Show notification date</mat-checkbox>
    </div>
</div>