import { ISearchItem } from './../../../search-item';
import { SearchService } from './../services/search.service';
import { SortPipe } from './../../pipes/sort.pipe';
import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  providers:[SortPipe]
})
export class SearchListComponent implements OnInit {

  public searchItem: ISearchItem;
  public query: string;

  constructor(private _searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router, private sortPipe: SortPipe) {}


  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.query = params.get('query');
    });
    this.getListItems('');
  }

  // filter array by categories from buttonclick on frontend.
  public buttonStatus: string;

  getListItems(filterValue) {

    if(filterValue === "BLOG"){
      this.buttonStatus = "BLOG";
    }

    else if(filterValue === "CMS_PAGE"){
      this.buttonStatus = "CMS_PAGE";
    }

    else if(filterValue === "MEDIABANKFILE"){
      this.buttonStatus = "MEDIABANKFILE";
    }

    else if(filterValue === ""){
      this.buttonStatus = "All"
    }

    //this.buttonStatus = !this.buttonStatus;
    if(filterValue === ""){
      this._searchService.getSearchItems(this.query) // to.lowercase solves matching issues
      .subscribe(data => this.searchItem = data );
    }
    else{
      this._searchService.getSearchItems(this.query) // to.lowercase solves matching issues
      .subscribe(data => { this.searchItem = data; this.filterListItems(filterValue)});
    }
  }

// needed for updating total resultcount after new search runs.
  recalculateTotalResultCount(indexes: any[]) {
    this.searchItem.totalResultCount = 0;

    indexes.forEach(i => {
      this.searchItem.totalResultCount += i.items.length;
    });
  }

  // filter array by categories from buttonclick on frontend.
  filterListItems(filterValue) {
    this.searchItem.indexes = this.searchItem.indexes.filter(function(index) {
        return index.indexType === filterValue;
    })

    this.recalculateTotalResultCount(this.searchItem.indexes);

    return this.searchItem.indexes;
  }

  // navigation on click.
  navigateOnItemClick(term, name) {
    if(term == "CMS_PAGE"){
      this.router.navigate(["/" , name.toLowerCase()]);
    }
    else if(term == "BLOG"){
      this.router.navigate(["/modules/blog/" , name.toLowerCase()]);
    }
    else if(term == "MEDIABANKFILE"){
      this.router.navigate(["/modules/media/" , name.toLowerCase()]);
    }
    else if(term == "COURSE") {
      this.router.navigate(["/modules/academy/"], { fragment: name.toLowerCase() });
    }
  }

  getItemUrl(item) {
    switch(item.objectType) {
      case "BLOG":
        return "modules/blog/" + item.objectKey.toLowerCase();
      case "CMS_PAGE":
        return item.objectKey;
      case "MEDIABANKFILE":
        return "modules/media/" + item.objectKey.toLowerCase();
      case "COURSE":
        return "modules/academy#" + item.objectKey.toLowerCase();
    }
  }

  // toggle for filteroptions.
  open: boolean = false;
  menuFunction(){
    this.open = !this.open;
  }

  // order the array by name or date.
  filterResults(val){
    if(val === "alphabetical"){
      this.searchItem.indexes.forEach(index => {
          return this.sortPipe.transform(index['items'], 'name');
      });
    }

    else{
      this.searchItem.indexes.forEach(index => {
        return this.sortPipe.transform(index['items'], 'updatedTimeUtc');
      });
    }
  }

}
