import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(private authenticationService: AuthenticationService, private config: AppConfigService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.siteSettings.useSiteLoader) {
      if(this.loadingMap.size === 0) {
        // this.spinner.show(undefined, {
        //   color: this.authenticationService.currentUserValue.siteSettings.siteStyle.primaryColor
        // });
  
        // setTimeout(() => {
        //   this.spinner.hide();
        // }, 2500);
      }
      if(request.responseType == 'json') {
        this.setLoading(true, request.url);
      } 
    }


    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (request.url.indexOf(this.config.apiEndpoint) >= 0 && currentUser && currentUser.token) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${currentUser.token}`,
                "Bee-Current-Culture": `${currentUser.currentISO2CountryCode}`,
                "Bee-Environment": `${environment.production == true ? 'production' : 'development'}`
            }
        });
    }

    return next.handle(request)
      .pipe(catchError((err) => {
        if(request.responseType == 'json') {
          this.setLoading(false, request.url);
        }
        return err;
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          if(request.responseType == 'json') {
            this.setLoading(false, request.url);
          }
        }
        return evt;
      }));
  }

  setLoading(loading: boolean, url: string): void {
    if(!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }

    if(loading === true) {
      this.loadingMap.set(url, loading);
    }
    else if(loading === false) {
      this.loadingMap.delete(url);
      console.log('Loading map size: ', this.loadingMap.size);
    }

    if(this.loadingMap.size === 0) {
      // setTimeout(() => {
      //   if(this.loadingMap.size === 0) {
      //     this.spinner.hide();
      //   }
      // }, 150);
    }
  }
}