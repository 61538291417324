<img [src]="customerLogo | cdn" *ngIf="customerLogo" class="logo">


<div class="login-wrapper" *ngIf="isValid; else notValid">

  <h2>NYTT LÖSENORD</h2>

  <p class="margin-top">Det bästa är att använda ett starkt lösenord som du inte använder någon annanstans.</p>


  <form action="#" (ngSubmit)="resetPassword(myForm);" class="login-form" #myForm="ngForm">

    <div class="login-form-inner-wrapper">

      <label for="password" class="margin-top lg">NYTT LÖSENORD</label>
      <input required type="password" id="password" [(ngModel)]="password" name="password" class="margin-bottom">

      <label for="repeatPassword">SKRIV DET NYA IGEN</label>
      <input required type="password" id="repeatPassword" [(ngModel)]="repeatPassword" name="repeatPassword" class="margin-bottom">
    </div>
    
    <div class="margin-top lg flex end login-form-inner-wrapper">
      <button class="bee-button secondary margin-right lg" (click)="cancel();">Avbryt</button>
      <button class="bee-button" [disabled]="!myForm.valid">Spara lösenord</button>
    </div>
  </form>
</div>

<ng-template #notValid>
    <div class="login-wrapper">
        <h2>EJ GILTIG</h2>
        <p>Din återställning av lösenords giltighetstid har gått ut. Vänligen begär en ny.</p>
    </div>
</ng-template>