<div *ngIf="field.templateOptions.attributes.type == 'images'; else default">
  <span class="required" *ngIf="field.templateOptions.required">*</span>
  <p><strong [innerHtml]="field.templateOptions.label"></strong></p>

  <div class="checkbox-images-wrapper">
    <div *ngFor="let option of field.templateOptions.options">

      <img [src]="option.imgUrl" alt="">

      <mat-checkbox (change)="checkboxChange()" [(ngModel)]="option.checked"
        color="primary" class="margin-top">
        <span *ngIf="!field.templateOptions.attributes.hideLabels" 
          [innerHtml]="option.label"></span>
      </mat-checkbox>
    </div>
  </div>
</div>

<ng-template #default>
  <div>
    <span class="required" *ngIf="field.templateOptions.required">*</span>
    <p><strong [innerHtml]="field.templateOptions.label"></strong></p>

    <div class="checkbox-wrapper">
      <div *ngFor="let option of field.templateOptions.options" class="checkbox-wrapper">
        <mat-checkbox (change)="checkboxChange()" [(ngModel)]="option.checked"
          color="primary">
          <span [innerHtml]="option.label"></span>
        </mat-checkbox>
      </div>
    </div>
  </div>
</ng-template>
