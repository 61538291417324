import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/configuration/app-config.service';
import { PaginationModel } from 'src/app/domain/core/help-models/pagination-model';
import { PaginationReturnModel } from 'src/app/domain/core/help-models/pagination-return-model';
import { SaveReturnModel } from 'src/app/domain/core/help-models/save-return-model';
import { IUserNotificationService } from 'src/app/domain/notifications/interfaces/i-user-notification-service';
import { UserNotification } from 'src/app/domain/notifications/models/user-notification';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService implements IUserNotificationService{

  constructor(private http: HttpClient, private config: AppConfigService) { }

  unsubscribePushNotification(sub: string): Observable<SaveReturnModel<any>> {
    return this.http.patch<SaveReturnModel<any>>(this.config.apiEndpoint + "api-notifications/PushNotification/unregister", { sub: sub });
  }

  addPushSubscriber(sub: string): Observable<SaveReturnModel<any>> {
    return this.http.put<SaveReturnModel<any>>(this.config.apiEndpoint + "api-notifications/PushNotification/register", { sub: sub });
  }

  markAsRead(notification: UserNotification): Observable<SaveReturnModel<string>> {
    return this.http.patch<SaveReturnModel<string>>(this.config.apiEndpoint + "api-notifications/UserNotifications/markAsRead", notification);
  }

  get(pagination: PaginationModel): Observable<PaginationReturnModel<UserNotification[]>> {
    return this.http.get<PaginationReturnModel<UserNotification[]>>(this.config.apiEndpoint + "api-notifications/UserNotifications/get?pageSize=" + pagination.pageSize + "&pageIndex=" + pagination.pageIndex);
  }
}
