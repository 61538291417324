<div [id]="id">
    <ng-container *ngTemplateOutlet="{ 'sprite': sprite, 'container': container }[template]"></ng-container>
</div>

<ng-template #sprite>
    <div class="main-wrapper">
        <section class="scroll-section">
            <div class="box box{{i}}" *ngFor="let item of items; let i = index">
                <img [src]="item | cdn" alt="">
            </div>
        </section>
    </div>
</ng-template>

<ng-template #container>
    <div class="main-wrapper">
        <section class="scroll-section-container" id="section-{{i}}" *ngFor="let item of items; let i = index" 
            [style.backgroundImage]="'url(' + (item | cdn) + ')'">
            
            <div class="anim-from-left-{{i}}">
                <h1>HEADER</h1>
                <p>LOREM IPSUM!</p>
            </div>
        </section>
    </div>
</ng-template>