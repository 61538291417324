<div class="info-text">
    <h2>GLÖMT LÖSENORD</h2>

    <p class="margin-top">Fyll i ditt användarnamn så skickar vi ett mail med en länk för återställning av lösenord.</p>
</div>

<div class="forgot-password-wrapper">
    <form action="#" (ngSubmit)="createChangePasswordRequest(myForm);" class="forgot-password-form" #myForm="ngForm">
        <label for="username">ANVÄNDARNAMN</label>
        <input required type="username" id="username" [(ngModel)]="username" name="username" class="margin-bottom">


        <div class="margin-top lg flex end">
            <button class="bee-button secondary margin-right lg" (click)="close()">Avbryt</button>
            <button class="bee-button">Skicka</button>
          </div>
    </form>
</div>