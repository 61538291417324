import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Router } from '@angular/router';
import { CMSModuleInstance } from '../../cms-models';
import { IMediabankService } from '../../interfaces/mediabank.service.interface';
import { ITagInterface } from '../../interfaces/tag.service.interface';
import { MediabankFile } from 'src/app/admin/mediabank/mediabank-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { CdnPipe } from 'src/app/bee-pipes/cdn/cdn.pipe';
import { MediaService } from '../services/media.service';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'bee-cms-module-media-list',
  templateUrl: './cms-module-media-list.component.html',
  styleUrls: ['./cms-module-media-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CmsModuleMediaListComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public files = [];
  public files$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public selectedFile: MediabankFile;
  public selectedFileUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedFileVerified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public destroy$: Subject<void> = new Subject<void>();
  @ViewChild('pdfObject', {static: false}) pdfObject: ElementRef;

  constructor(public module: CMSModuleInstance, private mediabankService: IMediabankService, private router: Router,
    private cdnPipe: CdnPipe, private mediaService: MediaService, public authService: AuthenticationService) { }

  navigateToFile(file: any): void {
    this.router.navigate(['modules', 'media', file.urlRewrite]);
  }

  selectFile(file: MediabankFile): void {
    this.selectedFile = file;
    this.selectedFileUrl$.next(null);
    setTimeout(() => {
      this.selectedFileUrl$.next("https://docs.google.com/gview?embedded=true&url=" + this.cdnPipe.transform(file.fileUrl));
    }, 10);
    this.getEvents();
    this.mediaService.LogEvent({ mediabankFileId: this.selectedFile.id, eventType: 1 }).pipe(
      tap(() => {
        this.mediaService.LogEvent({ mediabankFileId: this.selectedFile.id, eventType: 2 }).pipe(
          takeUntil(this.destroy$)
        ).subscribe((data: any) => {
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe((data: any) => {
    });
  }

  getEvents(): void {
    this.mediaService.GetEvents(this.selectedFile.id, 3).subscribe((data: any[]) => {
      if(data.length > 0) {
        this.selectedFileVerified$.next(data.filter(f => f.userId == this.authService.currentUserValue.user?.id).length > 0);
      }
    });
  }

  checkVerified(e): void {
    this.mediaService.LogEvent({ mediabankFileId: this.selectedFile.id, eventType: 3 }).subscribe((data: any) => {
      this.selectedFileVerified$.next(true);
    });
  }

  ngOnInit(): void {
    if(!this.module.settings.content.mediaType) {
      this.module.settings.content.mediaType = -1;
    }

    if(!this.module.settings.content.template) {
      this.module.settings.content.template = "grid";
    }

    if(!this.module.settings.content.tag) {
      this.module.settings.content.tag = "";
    }

    this.loadData();
  }

  loadData() {
    this.isLoading$.next(true);
    if(this.module.settings.content.tag && this.module.settings.content.tag.length > 0) {
      if(this.module.settings.content.mediaType == -1 || this.module.settings.content.mediaType == "-1") {
        this.mediabankService.getMediaFilesByTag(this.module.settings.content.tag).subscribe((data: any[]) => {
          this.files = data;
          this.files$.next(this.getFiles());
          this.isLoading$.next(false);
        });
      }
      else {
        this.mediabankService.getMediaFilesByTagAndType(this.module.settings.content.tag, this.module.settings.content.mediaType).subscribe((data: any[]) => {
          this.files = data;
          this.files$.next(this.getFiles());
          this.isLoading$.next(false);
        });
      }
    }
    else {
      this.mediabankService.getAllByType(this.module.settings.content.mediaType).subscribe((data: any[]) => {
        this.files = data;
        this.files$.next(this.getFiles());
        this.isLoading$.next(false);
      });
    }
  }

  getFiles(): any[] {
    if(this.module.settings.content.selectItemsToShow) {
      let f = [];

      for(let i = 0; i < this.files.length; i++) {
        if(this.module.settings.content.selectedFiles && this.module.settings.content.selectedFiles.indexOf(this.files[i].id) >= 0) {
          f.push(this.files[i]);
        }
      }

      return f;
    }
    else {
      return this.files;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@Component({
  selector: 'bee-cms-module-media-list-settings',
  templateUrl: './cms-module-media-list-settings.component.html',
  styleUrls: ['./cms-module-media-list.component.scss']
})
export class CmsModuleMediaListSettingsComponent implements OnInit {
  public tags = [];
  public files = [];
  

  constructor(public module: CMSModuleInstance, private mediabankService: IMediabankService, private tagService: ITagInterface) { 
    
  }

  ngOnInit(): void {
    this.tagService.getTags().subscribe(t => {
      this.tags = t;
    });

    this.loadItems({value: this.module.settings.content.tag});
  }

  getTypeName(type: number): string {
    return this.mediabankService.getTypeName(type);
  }

  loadItems(e) {
    if(e.value && e.value.length > 0) {
      this.mediabankService.getMediaFilesByTagAndType(e.value, this.module.settings.content.mediaType).subscribe((data: any[]) => {
        this.files = data;
      });
    }
    else {
      this.mediabankService.getAllByType(this.module.settings.content.mediaType).subscribe((data: any[]) => {
        this.files = data;
      });
    }
  }

  toggleFile(e: MatCheckboxChange, file) {
    if(!this.module.settings.content.selectedFiles) {
      this.module.settings.content.selectedFiles = [];
    }

    if(e.checked && !this.isFileChecked(file)) {
      this.module.settings.content.selectedFiles.push(file.id);
    }
    else if(!e.checked && this.isFileChecked(file)) {
      this.module.settings.content.selectedFiles.splice(this.module.settings.content.selectedFiles.indexOf(file.id), 1);
    }
  }

  isFileChecked(file: any): boolean {
    if(this.module.settings.content.selectedFiles && this.module.settings.content.selectedFiles.length > 0) {
      for(let i = 0; i < this.module.settings.content.selectedFiles.length; i++) {
        if(this.module.settings.content.selectedFiles[i] == file.id) {
          return true;
        }
      }
    }

    return false;
  }

}
