<ng-container *ngTemplateOutlet="{'DEFAULT': default, 'MOBILE': mobile, 'BIG': big }[module.settings.content.template]"></ng-container>


<ng-template #default>
  <nav class="navbar default-wrapper" [class.open]="menuOpen"
    [ngStyle]="{'justify-content': module.settings.content.navItemFlexPosition}">

    <bee-animated-icon class="md-36" class="burger" start="menu" end="close" colorStart="none" colorEnd="none"
      [animate]="menuOpen" [animateFromParent]="true" (click)="menuOpen = !menuOpen"></bee-animated-icon>

    <ul class="default" [class.open]="menuOpen">
      <li *ngFor="let navItem of nav$ | async" [ngClass]="{'active': router.url == navItem.url}"
        class="nav-item {{navItem.cssClass}}" [style.margin-right]="module.settings.content.marginRight"
        [style.margin-left]="module.settings.content.marginLeft">
        <p [style.font-size]="module.settings.content.navFontSize">
          <a [routerLink]="navItem.url" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="navItem.linkActiveOptions" [style.font-weight]="module.settings.style.fontWeight"
            [style.color]="module.settings.content.navFontColor">{{navItem.text}}</a>
          <span *ngIf="module.settings.content.showIndicator" class="indicator"></span>
        </p>
      </li>
    </ul>
  </nav>
</ng-template>

<ng-template #mobile>
  <nav class="navbar">
    <ul class="responsive" *ngIf="expandedMenu == true" [ngClass]="{'burger-expanded': expandedMenu == true}"
      [ngStyle]="{'align-items': module.settings.content.navItemFlexPosition}"
      [style.background-color]="module.settings.content.navBgOpacity"
      [style.paddingTop]="module.settings.content.navTopPadding">
      <li *ngFor="let navItem of nav$ | async" class="nav-item"
        [style.marginBottom]="module.settings.content.navItemsMarginBottom">
        <p *ngIf="navItem.children.length == 0" [style.font-size]="module.settings.content.navFontSize">
          <a [routerLink]="navItem.url" [style.color]="module.settings.content.navFontColor"
            (click)="expandedMenu = false">{{navItem.text}}</a>
        </p>
        <div class="expand-container">
          <div class="text-container">
            <p *ngIf="navItem.children.length > 0" [style.font-size]="module.settings.content.navFontSize"
              (click)="dropdownChildren()" [style.color]="module.settings.content.navFontColor">
              {{navItem.text}}
            </p>
          </div>
          <div *ngIf="navItem.children.length > 0" class="arrow-container">
            <div class="expand-arrow" [ngClass]="containsChildren ? 'expanded' : 'closed'"></div>
          </div>
        </div>
        <div *ngIf="navItem.children && containsChildren">
          <ul class="sub-items">
            <li *ngFor="let child of navItem.children">
              <p><a (click)="expandedMenu = false" [routerLink]="child.url"
                  [style.color]="module.settings.content.navFontColor">{{child.text}}</a></p>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <button *ngIf="!expandedMenu" (click)="burgerToggle();" mat-icon-button class="menu-button" type="button">
      <mat-icon>menu</mat-icon>
    </button>
    <button *ngIf="expandedMenu" (click)="burgerToggle();" mat-icon-button class="menu-button" type="button">
      <mat-icon class="mat-icon-clicked">clear</mat-icon>
    </button>
  </nav>
</ng-template>

<ng-template #big>
  <bee-animated-icon class="md-36" class="burger" start="menu" end="close" colorStart="none" colorEnd="none"
    [animate]="menuOpen" [animateFromParent]="true" (click)="menuOpen = !menuOpen"></bee-animated-icon>


  <div *ngIf="menuOpen" class="big-wrapper">

    <div class="big-header">
      <p>MENY</p>
    </div>
    
    <div class="big-inner-wrapper">
      
      <div class="menu-item" *ngFor="let menuItem of nav$ | async">

        <h5 [innerHtml]="menuItem.text"></h5>

        <div class="sub-item" *ngFor="let subItem of menuItem.children" [ngClass]="subItem.cssClass" (click)="navigate(subItem.url);">

          <p [innerHtml]="subItem.text"></p>

        </div>

      </div>


    </div>
  </div>

</ng-template>