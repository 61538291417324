import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteCulture } from 'src/app/authorize/models/user-session';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';

@Component({
  selector: 'bee-flag-icon',
  templateUrl: './bee-flag-icon.component.html',
  styleUrls: ['./bee-flag-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeFlagIconComponent implements OnInit {
  public culture$: BehaviorSubject<SiteCulture> = new BehaviorSubject<SiteCulture>(null);
  private _iso2: string;
  get iso2() {return this._iso2};
  @Input()set iso2(value: string) {
    this._iso2 = value;
    if(value !== undefined && value.length > 0) {
      const c = this.authService.getCultureFromIso2(this._iso2);
      if(c) {
        this.culture$.next(c);
      }
    }
  }

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.culture$ = null;
  }
}
