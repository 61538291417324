import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { BeeUnit } from 'src/app/domain/core/models/bee-unit';
import { BeeUser } from 'src/app/domain/core/models/bee-user';
import { IUnitService } from '../../interfaces/unit.service.interface';

@Component({
  selector: 'bee-cms-module-unit-information',
  templateUrl: './cms-module-unit-information.component.html',
  styleUrls: ['./cms-module-unit-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsModuleUnitInformationComponent implements OnInit {
  public unit$: Observable<BeeUnit>;
  public adminUsers$: Observable<BeeUser[]>;

  private user: BeeUser;

  constructor(private unitService: IUnitService, private authService: AuthenticationService) { 
    this.user = this.authService.currentUserValue.user;
  }

  ngOnInit(): void {
    this.unit$ = this.unitService.get(this.user.unitId);
    this.adminUsers$ = this.unit$.pipe(
      switchMap(unit => this.unitService.getUnitAdministrators()),
      map(admins => admins)
    );
  }

}

@Component({
  selector: 'bee-cms-module-unit-information-settings',
  templateUrl: './cms-module-unit-information.component-settings.html',
  styleUrls: ['./cms-module-unit-information.component.scss']
})
export class CmsModuleUnitInformationSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}