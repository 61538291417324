<ng-container #fieldComponent></ng-container>

<div *ngIf="(showCommentWrapper$ | async) && !field.fieldGroup" class="comment-outer-wrapper" [style]="variablesAsStyle" [id]="field.key">
    <div class="divider margin-top lg" *ngIf="(comments$ | async).length"></div>
    <div *ngIf="!field.templateOptions.disabled">
        <div>
            <div *ngFor="let comment of comments$ | async">
                <div class="write-comment-wrapper margin-top">
                    <div class="write-comment-profile">
                        <ng-container *ngTemplateOutlet="profileInitials; context: { $implicit: comment.createdByUser }"></ng-container>
                    </div>
                    <div class="show-comment-wrapper">
                        <span [innerHTML]="comment.comment"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="field.templateOptions.disabled">
        <p class="bold">Kommentarer:</p>
        <div class="write-comment-wrapper">
            <div class="write-comment-profile">
                <mat-icon>account_circle</mat-icon>
            </div>
            <textarea placeholder="Skriv en kommentar..." [(ngModel)]="comment" (keyup.enter)="saveComment()"></textarea>
        </div>

        <div>
            <div *ngFor="let comment of comments$ | async" [id]="comment.id">
                <div class="write-comment-wrapper margin-top">
                    <div class="write-comment-profile">
                        <ng-container *ngTemplateOutlet="profileInitials; context: { $implicit: comment.createdByUser }"></ng-container>
                    </div>
                    <div *ngIf="!comment.isEditing" class="show-comment-wrapper">
                        <span [innerHTML]="comment.comment"></span>
                    </div>
                    <textarea *ngIf="comment.isEditing" placeholder="Skriv en kommentar..." [(ngModel)]="comment.comment"></textarea>
                </div>

                <div class="flex center align-left margin-top comment-edit-wrapper" [beeAllowedToEdit]="comment" remove="true">
                    <span *ngIf="!comment.isEditing" (click)="editComment(comment);">Redigera</span>
                    <span *ngIf="comment.isEditing" (click)="updateComment(comment);">Spara</span>
                    <mat-icon>circle</mat-icon>
                    <span (click)="deleteComment(comment);">Ta bort kommentar</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #profileInitials let-user>
    <div class="initials">
        {{user.initials}}
    </div>
</ng-template>
<ng-template #profileIcon>
    <mat-icon>account_circle</mat-icon>
</ng-template>