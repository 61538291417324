import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'bee-vo-college-shipping-calculator',
  templateUrl: './vo-college-shipping-calculator.component.html',
  styleUrls: ['./vo-college-shipping-calculator.component.scss']
})
export class VoCollegeShippingCalculatorComponent extends FieldType {

  constructor() { 
    super();
  }

  ngOnInit(): void {
  }

  getCalculatedValue() {
    let price: number = 0;

    const itemWeight: number = 410;

    let form: any = this.form;
    let fieldToCalcValue = form._formlyControls[this.field.templateOptions.extraData.fieldToCalc];

    let totalWeight = itemWeight * ~~fieldToCalcValue.value;

    if(totalWeight == 0) price = 0;
    else if(totalWeight < 250) price = 54;
    else if(totalWeight < 500) price = 90;
    else if(totalWeight < 1000) price = 108;
    else if(totalWeight < 2000) price = 90;
    else if(totalWeight < 5000) price = 108;
    else if(totalWeight < 10000) price = 140;
    else if(totalWeight < 20000) price = 204;
    else if(totalWeight < 35000) price = 251;
    else if(totalWeight < 50000) price = 355;
    else price = 415;

    this.formControl.setValue(price.toString());

    return price;
  }

}
