import { IAccessLevelOptOut } from "src/app/standalone/pipes/access-level-opt-out.pipe";
import { VerificationModuleBase } from "./verification-module-base.model";

export default class VerificationFlowStep implements IAccessLevelOptOut {
    uuid: string = "";
    title: string = "";
    reportTitle?: string = null;
    description?: string = "";
    text?: string = "";
    titleImageUrl?: string = "";
    hasPhysicalDate: boolean = false;
    showDownloadFileButton: boolean = false;
    moduleInstances: VerificationModuleBase[] = [];
    accessLevelOptOuts: number[] = [];

    index?: number = 0;

    constructor(data?: VerificationFlowStep) {
        if(data) {
            Object.assign(this, data);
            this.moduleInstances = data.moduleInstances.map((mi) => {
                return new VerificationModuleBase(mi);
            });
        }

        if(!this.reportTitle) {
            this.reportTitle = this.title;
        }
    }
}