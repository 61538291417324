<div class="segment-wrapper"
  [style.align-items]="module.settings.style.alignItems | mediaQuery:{'mobile': module.settings.mobileStyle.alignItems, 'deviceEmulation': deviceEmulation }"
  [style.justify-content]="module.settings.style.justifyContent | mediaQuery:{'mobile': module.settings.mobileStyle.justifyContent, 'deviceEmulation': deviceEmulation }"
  [ngClass]="{'reverse': module.settings.content.reverseContent == true}">
  <div *ngFor="let moduleInstance of module.moduleInstances | sort:'order' | filter:['isDeleted', false] | sortHasPermission" 
    id="{{moduleInstance.name_id  ? moduleInstance.name_id: moduleInstance.instanceId}}" 
    class="module-instance"
    [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle, 'deviceEmulation': deviceEmulation }"
    [class.videoBackground]="moduleInstance.settings.style.backgroundVideo"
    [style.background-image]="'url(' + ((moduleInstance.settings.style.backgroundImage | mediaQuery:{'deviceEmulation': deviceEmulation, 'mobile': moduleInstance.settings.mobileStyle.backgroundImage}) || '' | cdn) + ')'"
    (click)="selectModuleInstance(moduleInstance, $event)">

      <video *ngIf="moduleInstance.settings.style.backgroundVideo" autoplay [muted]="'muted'" loop class="moi-bgVideo" id="bgVideo-{{moduleInstance.instanceId}}">
        <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
      </video>

      <app-cms-module-wrapper [moi]="moduleInstance" [hasAdminAccess]="module.hasAdminAccess"></app-cms-module-wrapper>
  </div>
</div>
