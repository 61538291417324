import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'bee-form-calc-info',
  templateUrl: './bee-form-calc-info.component.html',
  styleUrls: ['./bee-form-calc-info.component.scss']
})
export class BeeFormCalcInfoComponent extends FieldType {
  public getCalcValue() {
    let val = 0;
    switch(this.field.templateOptions.extraData.calcType) {
      case "multiply":
        val = this.multiply();
        break;
      case "add":
        val = this.add();
        break;
      case "subtract":
        val = this.subtract();
        break;
    }

    this.formControl.setValue(val.toString());
    return val;
  }

  multiply() {
    let form: any = this.form;
    let calcValue = 0;
    if(typeof this.field.templateOptions.extraData.fieldToCalc === 'object') {
      for(let i = 0; i < this.field.templateOptions.extraData.fieldToCalc.length; i++) {
        if(i == 0) {
          calcValue = ~~form._formlyControls[this.field.templateOptions.extraData.fieldToCalc[i]].value;
        }
        else {
          calcValue *= ~~form._formlyControls[this.field.templateOptions.extraData.fieldToCalc[i]].value;
        }
      }
    }
    else {
      calcValue = ~~form._formlyControls[this.field.templateOptions.extraData.fieldToCalc].value;
    }

    if(calcValue) {
      return ~~calcValue * ~~this.field.templateOptions.extraData.calcWith;
    }
    else {
      return 0;
    }
  }

  add() {
    let form: any = this.form;
    let calcValue = 0;
    if(typeof this.field.templateOptions.extraData.fieldToCalc === 'object') {
      for(let i = 0; i < this.field.templateOptions.extraData.fieldToCalc.length; i++) {
        calcValue += ~~form._formlyControls[this.field.templateOptions.extraData.fieldToCalc[i]].value;
      }
    }
    else {
      calcValue = form._formlyControls[this.field.templateOptions.extraData.fieldToCalc];
    }

    if(calcValue) {
      return ~~calcValue + ~~this.field.templateOptions.extraData.calcWith;
    }
    else {
      return 0;
    }
  }

  subtract() {
    let form: any = this.form;
    let fieldToCalcValue = form._formlyControls[this.field.templateOptions.extraData.fieldToCalc];

    if(fieldToCalcValue.value) {
      return ~~fieldToCalcValue.value + ~~this.field.templateOptions.extraData.calcWith;
    }
    else {
      return 0;
    }
  }
}
