import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../authorize/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SignalrService } from './services/signalr.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements OnInit {

  constructor(public authService: AuthenticationService, 
    @Inject(DOCUMENT) private document: Document,) {    
  }

  ngOnInit() {
    var appWrapper = this.document.getElementsByClassName("app-wrapper")[0];
    appWrapper.className = "app-wrapper";
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

}
