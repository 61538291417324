import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bee-header',
  templateUrl: './bee-header.component.html',
  styleUrls: ['./bee-header.component.scss']
})
export class BeeHeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToStartPage() {
    this.router.navigate(['/']);
  }

}
