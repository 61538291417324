  <mat-form-field>
    <input matInput placeholder="Account" name="instagramAccountName" [(ngModel)]="moi.settings.content.instagramAccountName">
  </mat-form-field>

  <mat-checkbox
    [(ngModel)]="moi.settings.content.showDescription" color="primary">
    Show description from feed
  </mat-checkbox>

  <mat-checkbox class="margin-top"
    [(ngModel)]="moi.settings.content.useLandscape" color="primary">
    Force image in landscape 16:9 mode
  </mat-checkbox>

  <mat-form-field class="margin-top">
    <mat-label>Select number of columns</mat-label>
    <mat-select [(value)]="moi.settings.content.instagramColCount">
      <mat-option value=1>1 column</mat-option>
      <mat-option value=2>2 columns</mat-option>
      <mat-option value=3>3 columns</mat-option>
      <mat-option value=4>4 columns</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Select number of feed items</mat-label>
    <mat-select [(value)]="moi.settings.content.instagramTotalCount">
      <mat-option value=1>1 feed item</mat-option>
      <mat-option value=2>2 feed items</mat-option>
      <mat-option value=3>3 feed items</mat-option>
      <mat-option value=4>4 feed items</mat-option>
      <mat-option value=5>5 feed items</mat-option>
      <mat-option value=6>6 feed items</mat-option>
      <mat-option value=7>7 feed items</mat-option>
      <mat-option value=8>8 feed items</mat-option>
      <mat-option value=9>9 feed items</mat-option>
      <mat-option value=10>10 feed items</mat-option>
      <mat-option value=11>11 feed items</mat-option>
      <mat-option value=12>12 feed items</mat-option>
    </mat-select>
  </mat-form-field>
