<ng-template #loading>
  <div class="bee-loading-wrapper">
    <mat-progress-spinner mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-container *ngTemplateOutlet="{'default': default, 'dialog': dialog}[template]"></ng-container>

<ng-template #dialog>
  <div class="profile-wrapper" *ngIf="!(isLoading$ | async); else loading">
    <form action="#" (ngSubmit)="saveProfile(myForm);" #myForm="ngForm" novalidate>
      <h3 class="text-center flex center margin-top xlg" [innerHtml]="'CORE.MY_ACCOUNT' | translate"></h3>

      <mat-form-field appearance="outline" class="margin-top lg">
        <mat-label>Namn</mat-label>
        <input matInput placeholder="Namn" name="Namn" [(ngModel)]="user.firstName">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Efternamn</mat-label>
        <input matInput placeholder="Efternamn" name="LastName" [(ngModel)]="user.lastName">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" name="Email" [(ngModel)]="user.email">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mobilnummer</mat-label>
        <input matInput placeholder="Mobilnummer" name="PhoneNumber" [(ngModel)]="user.phone">
      </mat-form-field>

      <mat-label>Enhet</mat-label>
        <bee-select [items]="units$ | async" [(selectedItem)]="selectedUnit"></bee-select>  

      <p class="margin-top lg">Byta lösenord</p>
      <div class="flex space-between">
        <div class="bee-button secondary" (click)="openChangePassword()">
          NYTT LÖSENORD
        </div>
      </div>

      <p class="margin-top lg">Notifieringar</p>
      <div class="flex space-between">
        <span>Notiser via mejl</span>
        <mat-slide-toggle [checked]="isNotOptedOut('EMAIL')" (change)="setOptOut($event, 'EMAIL')"
          color="primary"></mat-slide-toggle>
      </div>

      <div class="flex space-between margin-top">
        <span>Notiser via SMS</span>
        <mat-slide-toggle [checked]="isNotOptedOut('SMS')" (change)="setOptOut($event, 'SMS')"
          color="primary"></mat-slide-toggle>
      </div>

      <div class="flex space-between margin-top margin-bottom xlg">
        <div>
          <span [innerHtml]="saveMessage$ | async"></span>
        </div>
        <button class="bee-button primary" type="submit">
          <span [innerHtml]="'CORE.SAVE' | translate"></span>
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #default>
  <div *ngIf="!(isLoading$ | async); else loading" class="profile-wrapper" [style]="beeStyle">
    <h3 class="text-center" [innerHtml]="'CORE.MY_INFORMATION' | translate"></h3>

    <form action="#" (ngSubmit)="saveProfile(myForm);" #myForm="ngForm" novalidate>
      <div class="profile-inner-wrapper margin-top xlg">

        <div class="image">
          <img [src]="user?.unit?.logoUrl | cdn" alt="">
        </div>

        <div class="information">
          <div>
            <div [hidden]="isEditing">
              <p [innerHtml]="'CORE.FIRST_NAME' | translate"></p>
              <span [innerHtml]="user.firstName || '-'"></span>
            </div>

            <div [hidden]="!isEditing">
              <mat-form-field>
                <input matInput required [placeholder]="'CORE.FIRST_NAME' | translate" name="name"
                  [(ngModel)]="user.firstName">
              </mat-form-field>
            </div>
          </div>

          <div>
            <div>
              <p [innerHtml]="'CORE.UNIT' | translate"></p>
              <span [innerHtml]="user?.unit?.name || '-'"></span>
            </div>
          </div>

          <div class="mobile-margin-top">
            <div [hidden]="isEditing">
              <p [innerHtml]="'CORE.EMAIL' | translate"></p>
              <span [innerHtml]="user.email || '-'"></span>
            </div>

            <div [hidden]="!isEditing">
              <mat-form-field>
                <input matInput required [placeholder]="'CORE.EMAIL' | translate" name="email" [(ngModel)]="user.email">
              </mat-form-field>
            </div>
          </div>

          <div>
            <div [hidden]="isEditing">
              <p [innerHtml]="'CORE.LAST_NAME' | translate"></p>
              <span [innerHtml]="user.lastName || '-'"></span>
            </div>

            <div [hidden]="!isEditing">
              <mat-form-field>
                <input matInput [placeholder]="'CORE.LAST_NAME' | translate" name="lastName"
                  [(ngModel)]="user.lastName">
              </mat-form-field>
            </div>
          </div>

          <!-- <div>
            <div [hidden]="isEditing">
              <p>
                {{'CORE.PUSH_NOTIFICATIONS' | translate}}
                <bee-info [label]="'CORE.PUSH_NOTIFICATIONS_INFO' | translate"></bee-info>
              </p>
              <span *ngIf="isSubscribedToPush" [innerHtml]="'CORE.ON' | translate"></span>
              <span *ngIf="!isSubscribedToPush" [innerHtml]="'CORE.OFF' | translate"></span>
            </div>

            <div [hidden]="!isEditing">
              <p [innerHtml]="'CORE.PUSH_NOTIFICATIONS' | translate"></p>

              <div class="flex row">
                <div class="bee-select-button secondary small margin-right" [class.selected]="isSubscribedToPush"
                  (click)="togglePush(true)">
                  <mat-icon>notifications</mat-icon>
                  <span [innerHtml]="'CORE.ON' | translate"></span>
                </div>
                <div class="bee-select-button secondary small" [class.selected]="!isSubscribedToPush"
                  (click)="togglePush(false)">
                  <mat-icon>notifications_off</mat-icon>
                  <span [innerHtml]="'CORE.OFF' | translate"></span>
                </div>
              </div>
            </div>
          </div> -->

          <div style="width: 100%; margin: 0;"></div>

          <div class="mobile-margin-top">
            <div>
              <p [innerHtml]="'CORE.USERNAME' | translate"></p>
              <span [innerHtml]="user.userName || '-'"></span>
            </div>

            <!-- <div [hidden]="!isEditing">
              <mat-form-field>
                <input matInput required [placeholder]="'CORE.USERNAME' | translate" name="username" [(ngModel)]="user.userName">
              </mat-form-field>
            </div> -->
          </div>

          <div>
            <div [hidden]="isEditing">
              <p [innerHtml]="'CORE.PASSWORD' | translate"></p>
              <span>*****</span>
            </div>

            <div [hidden]="!isEditing">
              <p [innerHtml]="'CORE.PASSWORD' | translate" class="margin-bottom"></p>
              <a class="medium" (click)="openChangePassword();" [innerHtml]="'CORE.CHANGE_PASSWORD' | translate"></a>
            </div>
          </div>

          <div class="mobile-margin-top"
            *ngIf="(authService.currentUser | async).siteSettings.notificationsSettings.isSMSNotificationActivated">
            <div>
              <mat-checkbox [checked]="isNotOptedOut('SMS')" (change)="setOptOut($event, 'SMS')"
                [disabled]="!isEditing">
                <span [innerHtml]="'CORE.SMS' | translate"></span>
              </mat-checkbox>
              <!-- <input matInput required [placeholder]="'CORE.SMS' | translate" name="email" [(ngModel)]="user.email"> -->
            </div>
          </div>
        </div>

      </div>

      <div class="actions mobile-margin-top">
        <div [hidden]="isEditing">
          <button type="button" class="bee-button secondary" (click)="toggleEdit()">
            <mat-icon class="margin-right sm">edit</mat-icon>
            <span [innerHtml]="'CORE.EDIT_PROFILE' | translate"></span>
          </button>
        </div>

        <div [hidden]="!isEditing">
          <button class="bee-button primary" type="submit">
            <mat-icon class="margin-right sm">edit</mat-icon>
            <span [innerHtml]="'CORE.SAVE_PROFILE' | translate"></span>
          </button>
        </div>

        <div [hidden]="!isEditing" class="margin-right">
          <button type="button" class="bee-button secondary" (click)="cancel()">
            <span [innerHtml]="'CORE.CANCEL' | translate"></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>