import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { PasswordChangeService } from '../services/password-change.service';

@Component({
  selector: 'bee-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private sub: any;

  public customerName: string;
  public customerLogo: string;

  public password: string;
  public repeatPassword: string;

  private requestId: string;

  public isValid: boolean = true;

  constructor(private passwordChangeService: PasswordChangeService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { 

      this.customerName = this.authService.currentUserValue.siteSettings.siteTitle;
      this.customerLogo = this.authService.currentUserValue.siteSettings.siteImage;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.requestId = params['requestId'];

      this.passwordChangeService.checkIfChangePasswordRequestIsValid(this.requestId).subscribe(response => {
        this.isValid = response;
      });
   });

  }

  resetPassword(form: NgForm): void {
    if(this.password && this.password == this.repeatPassword) {
      this.passwordChangeService.updatePasswordFromRequest(this.requestId, this.password).subscribe(response => {
        this.router.navigate(["login"]);
      });
    }
  }
  
  cancel(): void {
    this.router.navigate(["login"]);
  }

}
