<mat-checkbox class="margin-top"
  [(ngModel)]="moi.settings.content.useLandscape" color="primary">
  Force image in landscape 16:9 mode
</mat-checkbox>

<mat-form-field class="margin-top" *ngIf="customerTemplates.length > 0">
  <mat-label>Select Template</mat-label>
  <mat-select [(value)]="moi.settings.content.template" (selectionChange)="changeCustomerTemplate()">
    <mat-option *ngFor="let template of templates" [value]="template.key">{{template.name}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="margin-top" *ngIf="moi.settings.content.template == 'customerSpecific'">
  <mat-label>Select customer specific template</mat-label>
  <mat-select [(value)]="moi.settings.content.customerTemplate" (selectionChange)="changeCustomerTemplate()">
    <mat-option *ngFor="let template of customerTemplates" [value]="template.key">{{template.name}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="margin-top">
  <mat-label>Select generic container type</mat-label>
  <mat-select [(value)]="moi.settings.content.genericContainerType">
    <mat-option *ngFor="let type of genericContainerTypes" [value]="type.containerType">{{type.name}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="margin-top">
  <mat-label>Select number of columns</mat-label>
  <mat-select [(value)]="moi.settings.content.colCount">
    <mat-option value=1>1 column</mat-option>
    <mat-option value=2>2 columns</mat-option>
    <mat-option value=3>3 columns</mat-option>
    <mat-option value=4>4 columns</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-label>Select number of generic containers to show</mat-label>
  <mat-select [(value)]="moi.settings.content.totalCount">
    <mat-option value=1>1 item</mat-option>
    <mat-option value=2>2 items</mat-option>
    <mat-option value=3>3 items</mat-option>
    <mat-option value=4>4 items</mat-option>
    <mat-option value=5>5 item</mat-option>
    <mat-option value=6>6 items</mat-option>
    <mat-option value=7>7 items</mat-option>
    <mat-option value=8>8 items</mat-option>
    <mat-option value=9>9 item</mat-option>
    <mat-option value=10>10 items</mat-option>
    <mat-option value=11>11 items</mat-option>
    <mat-option value=12>12 items</mat-option>
  </mat-select>
</mat-form-field>

<mat-checkbox class="margin-top"
  [(ngModel)]="moi.settings.content.showMore" color="primary">
  
  Show the "show more" button
</mat-checkbox>