<div class="content-item margin-top">
  <mat-checkbox
    [(ngModel)]="moi.settings.content.isOpen" color="primary">
    Is open by default
  </mat-checkbox>

  <mat-form-field>
    <input matInput placeholder="Main color" name="mainColor" [(ngModel)]="moi.settings.content.shareSettings.mainColor">
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="Font color" name="fontColor" [(ngModel)]="moi.settings.content.shareSettings.fontColor">
  </mat-form-field>

  <div class="settings-icons">
    <div class="settings-icon" [class.active]="moi.settings.content.shareSettings.icon == 'more_vert'">
      <i class="material-icons icon-15x" (click)="moi.settings.content.shareSettings.icon = 'more_vert'">more_vert</i>
    </div>

    <div class="settings-icon" [class.active]="moi.settings.content.shareSettings.icon == 'share'">
      <i class="material-icons icon-15x" (click)="moi.settings.content.shareSettings.icon = 'share'">share</i>
    </div>

    <div class="settings-icon" [class.active]="moi.settings.content.shareSettings.icon == 'favorite'">
      <i class="material-icons icon-15x" (click)="moi.settings.content.shareSettings.icon = 'favorite'">favorite</i>
    </div>

    <div class="settings-icon" [class.active]="moi.settings.content.shareSettings.icon == 'group_work'">
      <i class="material-icons icon-15x" (click)="moi.settings.content.shareSettings.icon = 'group_work'">group_work</i>
    </div>
  </div>
</div> 