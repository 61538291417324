import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FrontendComponent } from './frontend.component';
import { CmsPageComponent } from './cms-page/cms-page.component';
import { FrontendRoutingModule } from './frontend-routing.module';
import { ContainerModule } from '../container/container.module';
import { LayoutComponent } from './layout/layout.component';
import { BeeHeaderComponent } from './bee-header/bee-header.component';
import { BeeMenuComponent } from './bee-menu/bee-menu.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { BlogListComponent } from './modules/blog-list/blog-list.component';
import { BlogViewComponent } from './modules/blog-view/blog-view.component';
import { MediaListComponent } from './modules/media-list/media-list.component';
import { MediaViewComponent } from './modules/media-view/media-view.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { ConfigurationModule } from '../configuration/configuration.module';
import { BeeItemTagsComponent } from './bee-item-tags/bee-item-tags.component';
import { BeeTagCloudComponent } from './bee-tag-cloud/bee-tag-cloud.component';
import { MediaTagListComponent } from './modules/media-tag-list/media-tag-list.component';
import { MediaTagViewComponent } from './modules/media-tag-view/media-tag-view.component';
import { SearchViewComponent } from './modules/search-view/search-view.component';
import { BlogTagListComponent } from './modules/blog-tag-list/blog-tag-list.component';
import { SearchPipe } from './pipes/search.pipe';
import { SearchListComponent } from './modules/search-list/search-list.component';
import { DomService } from '../helpers/dom-service/dom.service';
import { DatePipe } from './pipes/date.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SiteStylePipe } from './pipes/site-style.pipe';
import { ThermoeyesHeaderComponent } from './customers/thermoeyes/thermoeyes-header/thermoeyes-header.component';
import { ThermoeyesMenuComponent } from './customers/thermoeyes/thermoeyes-menu/thermoeyes-menu.component';
import { BeePipesModule } from '../bee-pipes/bee-pipes.module';
import { ISearchService } from '../container/interfaces/search.service.interface';
import { SearchService } from './services/container/search.service';
import { TranslateModule } from '@ngx-translate/core';
import { GenericContainerListComponent } from './modules/generic-container/generic-container-list/generic-container-list.component';
import { ItemListFeaturedComponent } from './components/item-list-featured/item-list-featured.component';
import { GenericContainerViewComponent } from './modules/generic-container/generic-container-view/generic-container-view.component';
import { GenericContainerViewMoreComponent } from './modules/generic-container/generic-container-view-more/generic-container-view-more.component';
import { IMediabankService } from '../container/interfaces/mediabank.service.interface';
import { MediabankService } from './services/container/mediabank.service';
import { ITagInterface } from '../container/interfaces/tag.service.interface';
import { TagService } from './services/container/tag.service';
import { ICourseService } from '../container/interfaces/course.service.interface';
import { CourseService } from './services/container/course.service';
import { BeeComponentsModule } from '../bee-components/bee-components.module';
import { OpenurlComponent } from './openurl/openurl.component';
import { IUserNotification } from '../container/interfaces/user-notification.interface';
import { UserNotificationService } from './services/user-notification.service';
import { IUserNotificationService } from '../domain/notifications/interfaces/i-user-notification-service';
import { IMyProfileService } from '../bee-components/interfaces/i-my-profile-service';
import { MyProfileService } from './services/my-profile.service';
import { IComponentsCourseService } from '../bee-components/interfaces/i-course-service';
import { IUnitService } from '../container/interfaces/unit.service.interface';
import { UnitService } from './services/container/unit.service';
import { ICourseLogService } from '../container/interfaces/course-log.service.interface';
import { CourseLogService } from './services/container/course-log.service';
import { IPermissionService } from '../container/interfaces/permission.service';
import { PermissionService } from './services/container/permission.service';
import { ICourseAdminLogService } from '../domain/academy/interfaces/i-course-admin-log-service';
import { CourseAdminLogService } from '../modules/bee-academy/services/course-admin-log.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ICalendarService } from '../container/interfaces/calendar.service.interface';
import { CalendarService } from './services/container/calendar.service';
import { ScrollAnimationDirective } from 'src/app/standalone/directives/scroll-animation.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule, MatFabButton } from '@angular/material/button';


@NgModule({
    declarations: [
        FrontendComponent,
        CmsPageComponent,
        LayoutComponent,
        BeeHeaderComponent,
        BeeMenuComponent,
        BlogListComponent,
        BlogViewComponent,
        MediaListComponent,
        MediaViewComponent,
        BeeItemTagsComponent,
        BeeTagCloudComponent,
        MediaTagListComponent,
        MediaTagViewComponent,
        SearchViewComponent,
        BlogTagListComponent,
        SearchPipe,
        SearchListComponent,
        DatePipe,
        SortPipe,
        SiteStylePipe,
        ThermoeyesHeaderComponent,
        ThermoeyesMenuComponent,
        GenericContainerListComponent,
        ItemListFeaturedComponent,
        GenericContainerViewComponent,
        GenericContainerViewMoreComponent,
        OpenurlComponent,
    ],
    imports: [
        CommonModule,
        FrontendRoutingModule,
        ContainerModule,
        ConfigurationModule,
        BeePipesModule,
        BeeComponentsModule,
        //Material
        MatMenuModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        ScrollAnimationDirective,
        MatIconModule,
        MatButtonModule,
        TranslateModule.forRoot()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DomService,
        //{ provide: OverlayContainer, useClass: InAppWrapperOverlayContainer }
        { provide: ISearchService, useClass: SearchService },
        { provide: IMediabankService, useClass: MediabankService },
        { provide: ITagInterface, useClass: TagService },
        { provide: ICourseService, useClass: CourseService },
        { provide: IComponentsCourseService, useClass: CourseService },
        { provide: IUserNotification, useClass: UserNotificationService },
        { provide: IUserNotificationService, useClass: UserNotificationService },
        { provide: IMyProfileService, useClass: MyProfileService },
        { provide: IUnitService, useClass: UnitService },
        { provide: ICourseLogService, useClass: CourseLogService },
        { provide: IPermissionService, useClass: PermissionService },
        { provide: ICourseAdminLogService, useClass: CourseAdminLogService },
        { provide: ICalendarService, useClass: CalendarService },
    ]
})
export class FrontendModule { }
