<p>
  <strong [innerHtml]="field.templateOptions.label"></strong>
  <span class="required" *ngIf="field.templateOptions.required">*</span>

  <bee-info *ngIf="field.templateOptions.description" [label]="field.templateOptions.description">
    <!-- <p [innerHtml]="field.templateOptions.description"></p> -->
  </bee-info>
</p>

<div class="checkboxes-wrapper">
  <mat-checkbox *ngFor="let option of field.templateOptions.options" (change)="change($event, option)" 
    [checked]="option.selected" [name]="option.label" color="primary" [disableRipple]="true">

    <span [innerHtml]="option.label"></span>
  </mat-checkbox>
</div>