import { Component, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CmsEventService } from '../../cms-editor/cms-event-service';
import { CMSModuleAbstract, CMSModuleInstance } from '../../cms-models';
import { CmsModuleService } from '../../cms-services/cms-module-service.service';

@Component({
  selector: 'bee-cms-module-tabs',
  templateUrl: './cms-module-tabs.component.html',
  styleUrls: ['./cms-module-tabs.component.scss']
})
export class CmsModuleTabsComponent implements OnInit {
  public selectedTab: CMSModuleInstance;

  constructor(public moi: CMSModuleInstance, public router: Router, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if(!this.selectedTab && this.moi.moduleInstances.length > 0) {
      this.selectedTab = this.moi.moduleInstances[0];
    }

    this.router.events.subscribe((evt) => {
      if(evt instanceof NavigationEnd) {
        this.activatedRoute.fragment.subscribe(f => {
          this.handleFragment(f);
        });
      }
    });

    this.activatedRoute.fragment.subscribe(f => {
      this.handleFragment(f);
    });

    if(this.moi.settings.content.layout == 'advancedList') {
      this.initializeSubTabs();
    }
  }

  initializeSubTabs() {
    this.moi.moduleInstances.forEach(m => {
      if(m.settings.content.subTabs) {
        for(let i = 0; i < m.settings.content.subTabs.length; i++) {
          m.settings.content.subTabs[i] = new CMSModuleInstance(m.settings.content.subTabs[i]);
        }
      }
    });
  }

  handleFragment(f) {
    if(!f || f === "") {
      this.selectedTab = this.moi.moduleInstances[0];
    }
    else {
      this.moi.moduleInstances.forEach(m => {
        if(m.settings.content.tabName == f) {
          this.selectedTab = m;
        }
      })
    }
  }

  selectTab(tab: any, isSubtab: boolean = false): void {
    this.selectedTab = tab;

    if(!isSubtab) {
      this.getTabs().forEach((t: any) => {
        t.isOpen = false;
      });
  
      tab.isOpen = !tab.isOpen;
    }

    this.router.navigate([], { fragment: tab.settings.content.tabName, state: { ignoreScroll: true } });
  }

  getTabs() {
    if(this.moi.settings.content.unselectedTabEnabled) {
      const [, ...tabs] = this.moi.moduleInstances;
      return tabs;
    }
    else {
      const [...tabs] = this.moi.moduleInstances;
      return tabs;
    }
  }

  setMyStyles() {
    return `--data-color: ${this.moi.settings.content.selectedColor};`;
  }

  getAllModuleInstances() {
    let mois = [];

    this.moi.moduleInstances.forEach(m => {
      this.addMoiAndSubMois(m, mois);
    });

    return mois;
  }
  
  addMoiAndSubMois(m: CMSModuleInstance, mois: any[]) {
    mois.push(m);
    if(m.settings.content.subTabs) {
      m.settings.content.subTabs.forEach(moi => {
        this.addMoiAndSubMois(moi, mois);
      });
    }
  }

}


@Component({
  selector: 'bee-cms-module-tabs-settings',
  templateUrl: './cms-module-tabs-settings.component.html',
  styleUrls: ['./cms-module-tabs.component.scss']
})
export class CmsModuleTabsSettingsComponent implements OnInit {
  moduleAbstracts: Array<CMSModuleAbstract>;

  constructor(public moi: CMSModuleInstance, private eventService: CmsEventService, private cmsModuleService: CmsModuleService) {
    this.moduleAbstracts = this.cmsModuleService.getModuleAbstracts();
  }

  ngOnInit() {
    if(!this.moi.settings.content.layout) {
      this.moi.settings.content.layout = 'atumn'; 
    }
  }

  unselectedTabEnabledChange(event: MatCheckboxChange) {
    if(event.checked) {
      let m = this.moduleAbstracts[3].createModuleInstance();
      m.parentId = this.moi.id,
      m.order = -1;
      this.moi.moduleInstances.unshift(m);
    }
    else {
      this.moi.moduleInstances.splice(0, 1);
    }
  }

  addTab(tab?: CMSModuleInstance): void {
    let m = this.moduleAbstracts[3].createModuleInstance();
    m.parentId = this.moi.id,
    m.order = this.moi.moduleInstances.length

    if(tab) {
      if(!tab.settings.content.subTabs) {
        tab.settings.content.subTabs = [];
      }

      m.order = tab.settings.content.subTabs.length;
      tab.settings.content.subTabs.push(m);
    }
    else {
      this.moi.moduleInstances.push(m);
    }
  }

  deleteTab(tab: CMSModuleInstance): void {
    this.moi.moduleInstances.splice(this.moi.moduleInstances.indexOf(tab), 1);
  }

  deleteSubTab(parentTab: CMSModuleInstance, tab: CMSModuleInstance): void {
    debugger;
    var parentTab = this.moi.moduleInstances[this.moi.moduleInstances.indexOf(parentTab)];

    parentTab.settings.content.subTabs.splice(parentTab.settings.content.subTabs.indexOf(tab), 1);
  }

  selectMoi(moi: CMSModuleInstance): void {
    this.eventService.setSelectedModuleInstance.emit(moi)
  }

  getTabs() {
    if(this.moi.settings.content.unselectedTabEnabled) {
      const [, ...tabs] = this.moi.moduleInstances;
      return tabs;
    }
    else {
      const [...tabs] = this.moi.moduleInstances;
      return tabs;
    }
  }

}
