import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ShowFormComponent } from './show-form/show-form.component';
import { BeeFormsRoutingModule } from './bee-forms-routing.module';
import { BeeShowFormComponent } from './components/bee-show-form/bee-show-form.component';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { BeeFormInputComponent } from './fields/bee-form-input/bee-form-input.component';
import { BeeFormLineComponent } from './fields/bee-form-line/bee-form-line.component';
import { BeeFormTextComponent } from './fields/bee-form-text/bee-form-text.component';
import { BeeFormMulticheckComponent } from './fields/bee-form-multicheck/bee-form-multicheck.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { BeeFormCheckboxComponent } from './fields/bee-form-checkbox/bee-form-checkbox.component';
import {MatStepperModule} from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { BeeFormSliderComponent } from './fields/bee-form-slider/bee-form-slider.component';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { BeeFormPanelWrapperComponent } from './fields/bee-form-panel-wrapper/bee-form-panel-wrapper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BeeFormButtonSelectComponent } from './fields/bee-form-button-select/bee-form-button-select.component';
import { BeeFormTextareaComponent } from './fields/bee-form-textarea/bee-form-textarea.component';
import { SubmissionDialogComponent } from './dialogs/submission-dialog/submission-dialog.component';
import { BeeInputPhoneComponent } from './fields/bee-input-phone/bee-input-phone.component';
import { BeeComponentsModule } from 'src/app/bee-components/bee-components.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationWrapperComponent } from './components/animation-wrapper/animation-wrapper.component';
import { BeePipesModule } from 'src/app/bee-pipes/bee-pipes.module';
import { FormResultComponent } from './components/form-result/form-result.component';
import { BeeFormCheckboxSelectComponent } from './fields/bee-form-checkbox-select/bee-form-checkbox-select.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { BeeFormCalcInfoComponent } from './fields/bee-form-calc-info/bee-form-calc-info.component';
import { VoCollegeShippingCalculatorComponent } from './fields/customers/vo-college/vo-college-shipping-calculator/vo-college-shipping-calculator.component';
import { BeeUnitNameComponent } from './fields/bee-unit-name/bee-unit-name.component';
import { BeeDropdownComponent } from './fields/bee-dropdown/bee-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { BeeShowDateComponent } from './fields/bee-show-date/bee-show-date.component';
import { BeeRadioButtonsComponent } from './fields/bee-radio-buttons/bee-radio-buttons.component';
import { BeeCommentWrapperComponent } from './fields/bee-comment-wrapper/bee-comment-wrapper.component';
import { AllowedToEditDirective } from 'src/app/admin/directives/allowed-to-edit.directive';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    declarations: [
        ShowFormComponent,
        BeeShowFormComponent,
        BeeFormInputComponent,
        BeeFormLineComponent,
        BeeFormTextComponent,
        BeeFormMulticheckComponent,
        BeeFormCheckboxComponent,
        BeeFormSliderComponent,
        BeeFormPanelWrapperComponent,
        BeeFormButtonSelectComponent,
        BeeFormTextareaComponent,
        SubmissionDialogComponent,
        BeeInputPhoneComponent,
        AnimationWrapperComponent,
        FormResultComponent,
        BeeFormCheckboxSelectComponent,
        BeeFormCalcInfoComponent,
        VoCollegeShippingCalculatorComponent,
        BeeUnitNameComponent,
        BeeDropdownComponent,
        BeeShowDateComponent,
        BeeRadioButtonsComponent,
        BeeCommentWrapperComponent
    ],
    exports: [
        BeeShowFormComponent,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule
    ],
    imports: [
        CommonModule,
        BeeComponentsModule,
        BeeFormsRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BeePipesModule,
        //MATERIAL
        MatCheckboxModule,
        MatStepperModule,
        MatIconModule,
        MatSliderModule,
        MatExpansionModule,
        MatListModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        AllowedToEditDirective,
        //END MATERIAL
        FormlyModule.forRoot({
            types: [
                { name: 'bee-input', component: BeeFormInputComponent },
                { name: 'bee-line', component: BeeFormLineComponent },
                { name: 'bee-text', component: BeeFormTextComponent },
                { name: 'bee-multicheck', component: BeeFormMulticheckComponent },
                { name: 'bee-checkbox', component: BeeFormCheckboxComponent },
                { name: 'bee-slider', component: BeeFormSliderComponent },
                { name: 'bee-button-select', component: BeeFormButtonSelectComponent },
                { name: 'bee-textarea', component: BeeFormTextareaComponent },
                { name: 'bee-input-phone', component: BeeInputPhoneComponent },
                { name: 'bee-input-checkbox-select', component: BeeFormCheckboxSelectComponent },
                { name: 'bee-calc-info', component: BeeFormCalcInfoComponent },
                { name: 'bee-unit-name', component: BeeUnitNameComponent},
                { name: 'bee-dropdown', component: BeeDropdownComponent },
                { name: 'bee-show-date', component: BeeShowDateComponent },
                { name: 'bee-radio-buttons', component: BeeRadioButtonsComponent },
                //CUSTOMER SPECIFIC
                { name: 'vo-college-shipping-calculator', component: VoCollegeShippingCalculatorComponent }
            ],
            wrappers: [
                { name: 'bee-panel-wrapper', component: BeeFormPanelWrapperComponent },
                { name: 'animation', component: AnimationWrapperComponent },
                { name: 'bee-comment-wrapper', component: BeeCommentWrapperComponent }
            ],
            validators: [
                { name: 'requiredTrue', validation: Validators.requiredTrue },
            ],
            extensions: [
                
            ],
        }),
        FormlyMaterialModule,
        TranslateModule.forRoot()
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false, showError: true }
        }
    ]
})
export class BeeFormsModule { }
