import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { BeePipesModule } from 'src/app/bee-pipes/bee-pipes.module';

@Component({
  selector: 'vo-college-success-story-card',
  templateUrl: './vo-college-success-story-card.component.html',
  styleUrls: ['./vo-college-success-story-card.component.scss']
})
export class VoCollegeSuccessStoryCardComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Output() buttonClick = new EventEmitter();

  backgroundColors: string[] = [
    "#913c55d9",
    "#78467D",
    "#E07E41",
    "#C85B87"
  ];

  constructor() { }

  click() {
    this.buttonClick.emit(this.item);
  }

  ngOnInit(): void {
    
  }

  getOrg(i): string {
    if(i.properties) {
      return i.properties["org"];
    }

    return "";
  }

  getBackgroundColor(): string {
    let i = this.index % this.backgroundColors.length;
    return this.backgroundColors[i];
  }

  isOdd(): boolean {
    return this.index % 2 == 1;
  }
}

@NgModule({
  declarations: [VoCollegeSuccessStoryCardComponent],
  exports: [VoCollegeSuccessStoryCardComponent],
  imports: [
    CommonModule,
    BeePipesModule
  ]
})
export class VoCollegeSuccessStoryCardModule {}