<section id="search-results-list">
  <h1 [innerHtml]="'SEARCH.RESULT' | translate"></h1>
  <div class="buttons margin-top xlg">
    <button (click)="getListItems('')"
      class="bee-select-button lg filled"
            [ngClass]="{'selected': buttonStatus=='All', 'inactive': buttonStatus!='All'}">
            {{'SEARCH.RESULT_TYPE.ALL' | translate}}
    </button>
    <button (click)="getListItems('CMS_PAGE')"
      class="bee-select-button lg filled"
            [ngClass]="{'selected': buttonStatus=='CMS_PAGE', 'inactive': buttonStatus!='CMS_PAGE'}">
            {{'SEARCH.RESULT_TYPE.CMS_PAGE' | translate}}
    </button>
    <button (click)="getListItems('BLOG')"
      class="bee-select-button lg filled"
            [ngClass]="{'selected': buttonStatus=='BLOG', 'inactive': buttonStatus!='BLOG'}">
            {{'SEARCH.RESULT_TYPE.BLOG' | translate}}
    </button>
    <button (click)="getListItems('MEDIABANKFILE')"
      class="bee-select-button lg filled"
            [ngClass]="{'selected': buttonStatus=='MEDIABANKFILE', 'inactive': buttonStatus!='MEDIABANKFILE'}">
            {{'SEARCH.RESULT_TYPE.MEDIABANKFILE' | translate}}
    </button>
    <button (click)="getListItems('COURSE')"
      class="bee-select-button lg filled"
            [ngClass]="{'selected': buttonStatus=='COURSE', 'inactive': buttonStatus!='COURSE'}">
            {{'SEARCH.RESULT_TYPE.COURSE' | translate}}
    </button>
  </div>

  <div class="container">
    <p id="search-result-text">{{'SEARCH.SEARCH_ON' | translate}} "{{query}}" {{'SEARCH.GAVE' | translate}} {{searchItem.totalResultCount}} {{'SEARCH.HITS' | translate}} </p>
    <div id="dropdown" (click)="menuFunction()" [ngClass]="open ? 'open' : 'closed'">
      <span class="sort">{{'SEARCH.SORT_RESULT' | translate}} <bee-animated-icon class="md-36"
        start="keyboard_arrow_down" 
        end="keyboard_arrow_left" 
        [colorStart]="'primaryColor' | beeSiteStyle:'#00a0d6'" 
        [colorEnd]="'primaryColor' | beeSiteStyle:'#00a0d6'"  
        [animate]="open"
        [animateFromParent]="true"></bee-animated-icon>
      </span>
      <div class="dropdown-content">
        <p (click)="filterResults('alphabetical')" [innerHtml]="'CORE.SORTING.ALPHABETICAL' | translate"></p>
        <p (click)="filterResults('date')" [innerHtml]="'CORE.SORTING.DATE' | translate"></p>
      </div>
    </div>
  </div>
  <hr class="divider">

  <div class="search-results" *ngIf="query != ''">
    <div class="search-category-box" *ngFor="let searchCategory of searchItem.indexes" >
      <div class="item-cat">

        <h4><label *ngIf="searchCategory.items.length"> <span [innerHtml]="searchCategory.name | translate"></span> ({{searchCategory.items.length}}) </label></h4>
      </div>
        <div *ngFor="let item of searchCategory.items"
          class="search-item">
          <a (click)="navigateOnItemClick(item.objectType, item.objectKey)">
            <h4 class="item">
              <span class="icon" [ngClass]="{'news': searchCategory.indexType=='BLOG',
                'services': searchCategory.indexType=='CMS_PAGE',
                'play': searchCategory.indexType=='MEDIABANKFILE',
                'course': searchCategory.indexType=='COURSE'}"></span>

              {{item.name}}
              <span class="time" *ngIf="item.objectType === 'BLOG' || item.objectType === 'TPLAY'"> |      {{item.updatedTimeUtc | date}}</span>
            </h4>
          </a>
          <p class="description" [innerHtml]="item.description"></p>
          <a (click)="navigateOnItemClick(item.objectType, item.objectKey)" class="hide-mobile"><p class="url">/{{getItemUrl(item)}}</p></a>
        </div>
      <hr>

    </div>
  </div>

</section>
