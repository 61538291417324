import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CMSContainer } from '../../cms-models';
import { ContainerService } from '../container.service';
import { GenericContainer } from 'src/app/admin/generic-container/generic-container';
import { GenericContainerService } from 'src/app/admin/generic-container/generic-container.service';

@Component({
  selector: 'app-cms-module-instance-dialog-container',
  templateUrl: './cms-module-instance-dialog-container.component.html',
  styleUrls: ['./cms-module-instance-dialog-container.component.scss']
})
export class CmsModuleInstanceDialogContainerComponent implements OnInit {
  public container: CMSContainer;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<CmsModuleInstanceDialogContainerComponent>, 
    private genericContainerService: GenericContainerService) { 
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.genericContainerService.Get(this.data.dialogId)
    .subscribe((data: GenericContainer) => {
      this.container = new CMSContainer(data.container);
    });    
  }

  close() {
    this.dialogRef.close();
  }

}
