import { SearchService } from '../../frontend/modules/services/search.service';
import { Component, OnInit, ElementRef, Renderer2, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ISearchItem } from 'src/app/search-item';

@Component({
  selector: 'bee-search-overlay',
  templateUrl: './bee-search-overlay.component.html',
  styleUrls: ['./bee-search-overlay.component.scss']
})
export class BeeSearchOverlayComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  private initialized: boolean = false;
  public searchItem: ISearchItem;
  private _query: string;

  set query(value:string) {
    this._query = value;

    //Call for search
    this.search();
    console.log(this._query);
  }

  get query() {
    return this._query;
  }


  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if (!targetElement) {
          return;
      }

      const clickedInside = this.el.nativeElement.contains(targetElement);
      if (!clickedInside && this.initialized) {
          this.closeSelf();
      }
  }

  constructor(private _searchService: SearchService, private router: Router, private renderer2: Renderer2,private el:ElementRef) {
    this.searchItem = { indexes: [], totalResultCount: 0};
  }

  @ViewChild('search') searchSection: ElementRef;

  ngOnInit() {
    this.searchInput.nativeElement.focus();

    setTimeout(() => {
      this.initialized = true;
    }, 300);
  }

  search() {
    if(this._query === ""){
      return this._query;
    }// is this really needed?

    this._searchService.getSearchItems(this._query.toLowerCase()) // to.lowercase solves matching issues
      .subscribe(data => this.searchItem = data);
  }

  searchRoute() {
    console.log(this.el);
    this.router.navigate(['modules/search/', this._query]);
    
    this.closeSelf();
  }

  closeSelf() {
    //Emit the close event before we destroy the element!
    this.close.emit();
    this.renderer2.removeChild(this.el,this.el.nativeElement);
  }

  navigateOnItemClick(indexType,name) {
    this.closeSelf();

    if(indexType == "CMS_PAGE"){
      this.router.navigate(["/" , name.toLowerCase()]);
    }
    else if(indexType == "BLOG"){
      this.router.navigate(["/modules/blog/" , name.toLowerCase()]);
    }
    else if(indexType == "MEDIABANKFILE"){
      this.router.navigate(["/modules/media/" , name.toLowerCase()]);
    }
    else if(indexType == "COURSE"){
      this.router.navigate(["/modules/academy/"], { fragment: name.toLowerCase() });
    }
  }

  log(val){
    console.log(val);
  }
}
