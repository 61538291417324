import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/configuration/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  /**
   * GetActiveTags
   */
  public GetActiveTags() {
    return this.http.get(this.config.getApiEndpoint() + "api-tag/GetActiveTags");
  }

  /**
   * GetTagByUrlRewrite
urlRewrite   */
  public GetTagByUrlRewrite(urlRewrite) {
    return this.http.get(this.config.getApiEndpoint() + "api-tag/GetTagByUrlRewrite?urlRewrite=" + urlRewrite);
  }
}
