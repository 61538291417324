import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'bee-item-list-featured',
  templateUrl: './item-list-featured.component.html',
  styleUrls: ['./item-list-featured.component.scss']
})
export class ItemListFeaturedComponent implements OnInit {
  @Input() items: any[];
  @Input() title: string;
  @Input() limit: number = 11;

  @Output() goTo: EventEmitter<any> = new EventEmitter();
  @Output() tagSelect: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  goToItem(item) {
    this.goTo.emit(item);
  }

  selectTag(tag) {
    this.tagSelect.emit(tag);
  }
}
